import { Flex, Text } from "@mantine/core"
import { Dayjs } from "dayjs"
import styles from "./styles.module.scss"

export function renderHoursHeader({ from }: { from: number; to: number }) {
  const isPm = from >= 12
  let hour = from
  if (from === 0) {
    hour = 12
  } else if (from > 12) {
    hour = from - 12
  }
  return (
    <Flex className={styles["schedule-header-cell"]} align="center" justify="flex-start" fz="sm">
      {hour}:00{isPm ? "PM" : "AM"}
    </Flex>
  )
}

const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

const daysAbbr = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"]

export function renderDaysHeader({ from }: { from: number; to: number; index: number }, startOfSchedule: Dayjs) {
  const dayIndex = from % 7

  return (
    <Flex className={styles["schedule-header-cell"]} align="center" justify="center">
      <Text fw={400} mr="5px">
        {daysAbbr[dayIndex]}
      </Text>
      <Text fw={700}>{startOfSchedule.add(from, "day").format("D")}</Text>
    </Flex>
  )
}
