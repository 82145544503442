import { z } from "zod"
import { PartialUserSchema } from "./User"
import { BaseComparatorOperationSchema, SortEnumSchema } from "./utility"

export const SafetyReportStatusEnum = z.enum(["created", "inProgress", "resolved"])

export const OldSafetyReportFactorTypeEnum = z.enum([
  "miscommunication",
  "inattention",
  "failure_of_process",
  "missing_process",
  "pilot_error",
  "aircraft_malfunction",
  "weather",
  "terrain",
  "aerodrome",
  "passenger",
  "cargo",
])

export const SafetyReportFactorTypeEnum = z.enum([
  "weather_condition",
  "pilot_error",
  "mechanical_failure",
  "atc_issue",
  "fuel_management",
  "runway_incursion",
  "bird_strike",
  "procedure_deviation",
  "emergency_readiness",
  "crew_fatigue",
  "training_gap",
  "communication_failure",
  "security_threat",
  "cargo_handling",
  "navigation_error",
  "ground_incident",
  "regulatory_compliance",
  "passenger_safety",
  "medical_emergency",
  "maintenance_issue",
])

export const SafetyReportEntityTypeEnum = z.enum([
  "aircraft",
  "aerodrome",
  "pilot",
  "runway",
  "vendor",
  "mechanic",
  "staff",
  "other_personnel",
])

export const SafetyReportEntitySchema = z.object({
  id: z.string().ulid(),
  type: SafetyReportEntityTypeEnum,
  identifier: z.string(),
  scope: z.string().nullish(),
})

export const SafetyReportSchema = z.object({
  id: z.string().ulid(),
  organizationId: z.string().ulid(),
  type: z.string(),
  status: SafetyReportStatusEnum,
  details: z.string(),
  factors: SafetyReportFactorTypeEnum.array(),
  entities: SafetyReportEntitySchema.array(),
  createdBy: PartialUserSchema.nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  resolvedAt: z.coerce.date().nullish(),
  resolvedBy: PartialUserSchema.nullish(),
  resolution: z.string().nullish(),
})

export const SafetyReportInputSchema = z.object({
  details: SafetyReportSchema.shape.details,
  organizationId: SafetyReportSchema.shape.organizationId,
  isAnonymous: z.boolean(),
})

export type SafetyReportInput = z.infer<typeof SafetyReportInputSchema>
export type SafetyReportStatus = z.infer<typeof SafetyReportStatusEnum>
export type SafetyReportFactorType = z.infer<typeof SafetyReportFactorTypeEnum>
export type SafetyReportEntityType = z.infer<typeof SafetyReportEntityTypeEnum>
export type SafetyReportEntity = z.infer<typeof SafetyReportEntitySchema>
export type SafetyReport = z.infer<typeof SafetyReportSchema>

export const SafetyReportFilterSchema = z.object({
  status: BaseComparatorOperationSchema(SafetyReportStatusEnum).optional(),
  date: BaseComparatorOperationSchema(z.coerce.date()).optional(),
})

export const SafetyReportSortSchema = z.object({
  date: SortEnumSchema.optional(),
})

export type SafetyReportFilter = z.infer<typeof SafetyReportFilterSchema>
export type SafetyReportSort = z.infer<typeof SafetyReportSortSchema>
