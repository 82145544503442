import { z } from "zod"
import { SafetyAssessmentRecordStatusEnum } from "./Safety"
import { BaseComparatorOperationSchema, SortEnumSchema } from "./utility"

export const SchedulingSourceEnum = z.enum(["AirplaneManager", "SoarAdHoc"])
export const FlightLegStatusEnum = z.enum(["created", "on_hold", "approved", "awaiting_approval"])

export const SchedulingFlightLegCrewMemberSchema = z.object({
  flightLegId: z.string(),
  externalId: z.string(),
  email: z.string().nullish(),
  firstName: z.string().nullish(),
  lastName: z.string().nullish(),
  userId: z.string().nullish(),
})

export const SchedulingFlightLegSchema = z.object({
  id: z.string().ulid(),
  source: z.string(),

  organizationId: z.string().ulid().nullish(),
  flightId: z.string().nullish(),
  legId: z.string(),

  departureAirport: z.string(),
  destinationAirport: z.string(),

  departAt: z.coerce.date(),
  arriveAt: z.coerce.date().nullish(),

  aircraftTailNumber: z.string(),
  numPassengers: z.number().nullish(),
  passengerWeight: z.number().nullish(),
  flightIdentifier: z.string().nullish(),

  originalPayload: z.any(),
  importedAt: z.coerce.date(),
  createdAt: z.coerce.date(),
  status: FlightLegStatusEnum.default("created"),
  score: z.number().nullish(),
  createdByUserId: z.string().nullish(),
  isArchived: z.boolean(),
  archivedAt: z.coerce.date().nullish(),

  crew: SchedulingFlightLegCrewMemberSchema.array(),
  meta: z
    .object({
      slack: z
        .object({
          channel: z.string(),
          messageId: z.string(),
        })
        .optional(),
    })
    .passthrough()
    .optional()
    .default({}),
})

export const FlightLegScoreAdjustmentSchema = z.object({
  id: z.string().ulid(),
  flightLegId: z.string(),
  previousScore: z.number(),
  newScore: z.number(),
  comment: z.string().optional().default(""),
  createdAt: z.coerce.date(),
  decisionId: z.string().nullish(),
  assessmentId: z.string().nullish(),
  adjustedByUserId: z.string(),
})

export const FlightLegDecisionEnum = z.enum(["approve", "deny"])

export const FlightLegDecisionRecordSchema = z.object({
  id: z.string().ulid(),
  flightLegId: z.string(),
  decision: FlightLegDecisionEnum,

  createdAt: z.coerce.date(),
  userId: z.string().ulid(),
  comment: z.string().optional().default(""),
})

export const SchedulingFlightLegDetails = SchedulingFlightLegSchema.omit({
  id: true,
  importedAt: true,
  status: true,
  score: true,
  createdAt: true,
}).extend({
  crew: SchedulingFlightLegCrewMemberSchema.omit({ flightLegId: true }).array(),
})

export type FlightLegStatus = z.infer<typeof FlightLegStatusEnum>

export type SchedulingSource = z.infer<typeof SchedulingSourceEnum>
export type SchedulingFlightLeg = z.infer<typeof SchedulingFlightLegSchema>
export type SchedulingFlightLegDetails = z.infer<typeof SchedulingFlightLegDetails>
export type SchedulingFlightLegCrewMember = z.infer<typeof SchedulingFlightLegCrewMemberSchema>
export type FlightLegDecision = z.infer<typeof FlightLegDecisionEnum>
export type FlightLegScoreAdjustment = z.infer<typeof FlightLegScoreAdjustmentSchema>
export type FlightLegDecisionRecord = z.infer<typeof FlightLegDecisionRecordSchema>

export const FlightLegFilterSchema = z.object({
  status: BaseComparatorOperationSchema(FlightLegStatusEnum).optional(),
  date: BaseComparatorOperationSchema(z.coerce.date()).optional(),
})
export type FlightLegFilter = z.infer<typeof FlightLegFilterSchema>

export const FlightLegSortSchema = z.object({
  date: SortEnumSchema.optional(),
})
export type FlightLegSort = z.infer<typeof FlightLegSortSchema>
