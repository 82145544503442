const dataUrlRegex = /^data:.+\/(.+);base64,(.*)$/

export function fileToDataUrl(file: File): Promise<string> {
  const promise = new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result as string)
    }
    reader.onerror = (error) => {
      reject(error)
    }

    reader.readAsDataURL(file)
  })

  return promise
}

export function dataUrlToBase64(dataUrl: string) {
  const base64 = dataUrl.split(",")[1]

  return base64
}

export async function fileToJson(file: File) {
  const dataUrl = await fileToDataUrl(file)
  const base64string = dataUrlToBase64(dataUrl)
  return {
    body: base64string,
    contentType: file.type,
  }
}
