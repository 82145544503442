import { z } from "zod"

export const FileDetailsSchema = z
  .object({
    body: z.string(),
    contentType: z.string(),
  })
  .optional()

export type FileDetails = z.infer<typeof FileDetailsSchema>
