import { Box, BoxProps, Flex, FlexProps, MantineColor, Text, TextProps, useMantineTheme } from "@mantine/core"
import { Icon, TablerIconsProps } from "@tabler/icons-react"
import { ReactNode } from "react"

export function StatusDisplay({
  label = "",
  icon,
  color = "gray.5",
  labelProps = {},
  iconProps = {},
  iconWrapperProps = {},
  justify = "center",
  align = "center",
}: {
  label?: string | ReactNode
  icon?: Icon
  color?: MantineColor
  labelProps?: TextProps
  iconProps?: TablerIconsProps
  iconWrapperProps?: BoxProps
  justify?: FlexProps["justify"]
  align?: FlexProps["align"]
}) {
  const Icon = icon // so we can use as a component
  const theme = useMantineTheme()
  const colorValue = theme.fn.themeColor(color)

  return (
    <Flex h="100%" align={align} justify={justify} direction="column">
      {Icon && (
        <Box mb={{ base: "xs", md: "xl" }} {...iconWrapperProps}>
          {<Icon color={colorValue} size={72} {...iconProps} />}
        </Box>
      )}
      <Text fz="xl" color={color} {...labelProps}>
        {label}
      </Text>
    </Flex>
  )
}
