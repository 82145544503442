import { Box, Button, Flex, Menu, Text, UnstyledButton } from "@mantine/core"
import { SafetyReportStatus, SafetyReportStatusEnum } from "@soar/shared/types"
import { SafetyReportStatusConfig } from "@soar/shared/utils"
import { ReactElement, useMemo } from "react"
import { MaterialSymbol } from "../icons"

const allStatuses = [SafetyReportStatusEnum.enum.inProgress, SafetyReportStatusEnum.enum.created, SafetyReportStatusEnum.enum.resolved]

const SafetyReportStatusIcons: Record<SafetyReportStatus, ReactElement> = {
  [SafetyReportStatusEnum.enum.created]: <MaterialSymbol name="circle" />,
  [SafetyReportStatusEnum.enum.inProgress]: <MaterialSymbol name="clock_loader_40" />,
  [SafetyReportStatusEnum.enum.resolved]: <MaterialSymbol name="task_alt" />,
}

export function SafetyReportStatusDisplay({
  status,
}: {
  status: SafetyReportStatus
}) {
  const currentConfig = SafetyReportStatusConfig[status]
  return (
    <Flex display="inline-flex" align="center" gap={5}>
      <Flex align="center" c={currentConfig.color}>
        {SafetyReportStatusIcons[status]}
      </Flex>
      {currentConfig.label}
    </Flex>
  )
}

export function SafetyReportStatusMenu({
  status,
  onChange = () => {},
}: {
  status: SafetyReportStatus
  onChange?: (status: SafetyReportStatus) => void
}) {
  const { otherStatuses, currentConfig } = useMemo(() => {
    const otherStatuses = allStatuses.filter((statusFilter) => statusFilter !== status)
    const currentConfig = SafetyReportStatusConfig[status]

    return {
      otherStatuses,
      currentConfig,
    }
  }, [status])

  return (
    <Menu>
      <Menu.Target>
        <Button variant="subtle" fz="md">
          <Box fw={600} c="black">
            <SafetyReportStatusDisplay status={status} />
          </Box>
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        {otherStatuses.map((statusInLoop) => {
          return (
            <Menu.Item key={statusInLoop} onClick={() => onChange(statusInLoop)}>
              <Box fw={500}>
                <SafetyReportStatusDisplay status={statusInLoop} />
              </Box>
            </Menu.Item>
          )
        })}
      </Menu.Dropdown>
    </Menu>
  )
}

export function SafetyReportStatusBadge() {}
