import Link from "next/link"
import { CustomLinkContext } from "./LinkContext"

export interface ILinkProviderProps {
  linkComponent: typeof Link
  children: React.ReactNode
}

export const CustomLinkProvider = ({ linkComponent, children }: ILinkProviderProps) => {
  return <CustomLinkContext.Provider value={linkComponent}>{children}</CustomLinkContext.Provider>
}
