export const AirsigmetHazardProperties = {
  ICE: {
    label: "Icing",
  },
  IFR: {
    label: "IFR",
  },
  MT_OBSC: {
    label: "Mtn Obs",
  },
  "TURB-LO": {
    label: "Turbulence low",
  },
  "TURB-HI": {
    label: "Turbulence high",
  },
  SFC_WND: {
    label: "Surface wind",
  },
  LLWS: {
    label: "Low level wind shear",
  },
  M_FZLVL: {
    label: "Multiple freezing levels",
  },
}
