import {
  Alert,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  NumberInput,
  Stack,
  Text,
  Textarea,
  createStyles,
  useMantineTheme,
} from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { Aircraft, Reservation, ReservationSignOutEventDetails, ReservationSignOutEventDetailsSchema } from "@soar/shared/types"
import { isFieldRelevantForReservationType } from "@soar/shared/utils"
import {
  Icon123,
  IconAlertCircle,
  IconCalendar,
  IconChevronRight,
  IconCurrentLocation,
  IconPlaneTilt,
  IconReport,
  TablerIconsProps,
} from "@tabler/icons-react"
import dayjs from "dayjs"
import { ReactNode, useEffect } from "react"
import { PlaneDisplay } from "../planeDisplay"
import { FlightLegsInput, generateEmptyFlightRoute } from "./flightLegsInput"

function SignOutRow({ icon, label, children }: { icon: ReactNode; children: ReactNode; label: string }) {
  return (
    <>
      <Flex align="stretch" fw="500">
        <Flex align="flex-start" w="35%" style={{ flexShrink: 0 }}>
          {icon}
          <Text ml="5px">{label}</Text>
        </Flex>
        <div style={{ flexGrow: 1 }}>{children}</div>
      </Flex>
      <Divider />
    </>
  )
}

const useStyles = createStyles((theme) => ({
  active: {
    borderColor: theme.colors.blue[5],
  },
  metricsInput: {
    fontSize: "24px",
    height: "50px",
    flexGrow: 1,
    flexShrink: 1,
    textAlign: "center",
    fontWeight: "bold",
  },
}))

const iconProps: TablerIconsProps = {
  stroke: 2.5,
  height: 20,
}

export function ReservationSignOutForm({
  aircraft,
  reservation,
  onSubmit = () => {},
}: { reservation: Reservation; aircraft: Aircraft; onSubmit?: (submittedValues: ReservationSignOutEventDetails["details"]) => void }) {
  const theme = useMantineTheme()
  const { classes, cx } = useStyles()
  const form = useForm<ReservationSignOutEventDetails["details"]>({
    validate: zodResolver(ReservationSignOutEventDetailsSchema.shape.details),
    initialValues: {
      hobbs: aircraft.currentHobbs ?? 0,
      tach: aircraft.currentTach ?? 0,
      tach2: aircraft.currentTach2,
      legs: [generateEmptyFlightRoute()],
      discrepancies: "",
    },
  })

  useEffect(() => {
    form.setValues({
      tach: aircraft.currentTach,
      tach2: aircraft.currentTach2,
      hobbs: aircraft.currentHobbs,
    })
  }, [aircraft])

  useEffect(() => {
    form.setValues({
      legs: reservation.legs,
    })
  }, [reservation])

  const shouldShowLegs = isFieldRelevantForReservationType("legs", reservation.type)
  const hasSecondTach = parseInt(aircraft.numEngines ?? "0") >= 2

  return (
    <Box component="form" onSubmit={form.onSubmit((values) => onSubmit(values))}>
      <Stack spacing="md">
        <Stack spacing="xs" fz="smaller">
          <SignOutRow label="Date of flight" icon={<IconCalendar {...iconProps} />}>
            {dayjs(reservation.startTime).format("MMM D, YYYY")}
          </SignOutRow>
          <SignOutRow label="Aircraft" icon={<IconPlaneTilt {...iconProps} />}>
            <PlaneDisplay plane={aircraft} variant="text" />
          </SignOutRow>
          {shouldShowLegs && (
            <SignOutRow label="Flight route" icon={<IconCurrentLocation {...iconProps} />}>
              <FlightLegsInput form={form} />
            </SignOutRow>
          )}
          {reservation.estimatedFlightHours != null && reservation.estimatedFlightHours > 0 && (
            <SignOutRow label="Est. Flight Hours" icon={<Icon123 {...iconProps} />}>
              <Text>{reservation.estimatedFlightHours}</Text>
            </SignOutRow>
          )}
          {reservation.comment != null && reservation.comment.trim().length > 0 && (
            <SignOutRow label="Comments" icon={<IconReport {...iconProps} />}>
              <Text>{reservation.comment}</Text>
            </SignOutRow>
          )}
        </Stack>
        <Grid align="center" columns={24}>
          <Grid.Col span={10}>
            <NumberInput
              label="Hobbs out"
              hideControls
              precision={1}
              inputMode="decimal"
              classNames={{
                input: cx(classes.active, classes.metricsInput),
              }}
              {...form.getInputProps("hobbs")}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Center>
              <IconChevronRight size={30} style={{ marginTop: "20px" }} color={theme.colors.gray[5]} />
            </Center>
          </Grid.Col>
          <Grid.Col span={10}>
            <NumberInput
              label="Hobbs in"
              disabled
              inputMode="decimal"
              classNames={{
                input: cx(classes.metricsInput),
              }}
            />
          </Grid.Col>
        </Grid>
        <Grid align="center" columns={24}>
          <Grid.Col span={10}>
            <NumberInput
              label="Tach 1 out"
              hideControls
              precision={1}
              inputMode="decimal"
              classNames={{
                input: cx(classes.active, classes.metricsInput),
              }}
              {...form.getInputProps("tach")}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Center>
              <IconChevronRight size={30} style={{ marginTop: "20px" }} color={theme.colors.gray[5]} />
            </Center>
          </Grid.Col>
          <Grid.Col span={10}>
            <NumberInput
              label="Tach 1 in"
              disabled
              precision={1}
              inputMode="decimal"
              classNames={{
                input: cx(classes.metricsInput),
              }}
            />
          </Grid.Col>
        </Grid>
        {hasSecondTach && (
          <Grid align="center" columns={24}>
            <Grid.Col span={10}>
              <NumberInput
                label="Tach 2 out"
                hideControls
                precision={1}
                inputMode="decimal"
                classNames={{
                  input: cx(classes.active, classes.metricsInput),
                }}
                {...form.getInputProps("tach2")}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <Center>
                <IconChevronRight size={30} style={{ marginTop: "20px" }} color={theme.colors.gray[5]} />
              </Center>
            </Grid.Col>
            <Grid.Col span={10}>
              <NumberInput
                label="Tach 2 in"
                disabled
                precision={1}
                inputMode="decimal"
                classNames={{
                  input: cx(classes.metricsInput),
                }}
              />
            </Grid.Col>
          </Grid>
        )}
        <Alert variant="outline" icon={<IconAlertCircle size={16} />} hidden>
          If aircraft hours are different than these, update these hours to match aircraft.
        </Alert>
        <Textarea
          label={
            <Flex align="center">
              <IconAlertCircle size={20} />
              <Text ml="5px">Aircraft discrepencies</Text>
            </Flex>
          }
          placeholder="Instrument training, visual approach to KMEV, radial inter."
          {...form.getInputProps("discrepancies")}
        />
      </Stack>

      <Button type="submit" mt="xl" w="100%">
        Finish sign out
      </Button>
    </Box>
  )
}
