import { createClient } from "@supabase/supabase-js"

const API_URL = process.env["SUPABASE_API_URL"] ?? process.env["NEXT_PUBLIC_SUPABASE_API_URL"] ?? ""
/**
 * supabase anonymous client
 */
export const supabase = createClient(API_URL, process.env["SUPABASE_API_KEY"] ?? process.env["NEXT_PUBLIC_SUPABASE_API_KEY"] ?? "")

/**
 * supabase service client
 * Don't use this on the frontend.  Don't even set the NEXT_PUBLIC envar of this key
 */
const serviceKey = process.env["SUPABASE_SERVICE_KEY"]
export const getSupabaseService = () => {
  if (serviceKey == null) {
    throw new Error("missing SUPABASE_SERVICE_KEY to instantiate Supabase service client")
  }
  return createClient(API_URL, serviceKey)
}

export const AVATAR_BUCKET_NAME = "soar-avatars" as const
export const USER_AVATAR_PREFIX = "user/" as const
export const AIRCRAFT_AVATAR_PREFIX = "aircraft/" as const
export const ORG_AVATAR_PREFIX = "organization/" as const

export const RESERVATION_CHANGES_CHANNEL = "reservation-changes" as const
export const RESERVATION_CHANGED_EVENT = "reservation-updated" as const
export const RESERVATION_CREATED_EVENT = "reservation-created" as const
