import {
  Aircraft,
  AirportResult,
  ApiFlightMetrics,
  FlightMetrics,
  IWeatherDetails,
  MetarResponse,
  PirateWeather,
  StageOfFlight,
  TafForecastResponse,
  TafResponse,
  WeatherTimeGroup,
} from "@soar/shared/types"

import { group, time } from "console"
import { default as turfDistance } from "@turf/distance"

import dayjs, { Dayjs } from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)
dayjs.extend(timezone)

type WeatherType = "intensity" | "descriptor" | "precipitation" | "obscuration" | "other"
type WeatherGroup = "cloud" | "weather"

export type WeatherToken = {
  symbol: string
  group: WeatherGroup
  type: WeatherType
  meaning: string
  label?: string
}

export const cloudDescriptorTokens: WeatherToken[] = [
  {
    symbol: "SKC",
    group: "cloud",
    type: "descriptor",
    meaning: "sky clear",
  },

  {
    symbol: "NSC",
    group: "cloud",
    type: "descriptor",
    meaning: "no significant clouds",
  },

  {
    symbol: "FEW",
    group: "cloud",
    type: "descriptor",
    meaning: "few",
  },

  {
    symbol: "SCT",
    group: "cloud",
    type: "descriptor",
    meaning: "scattered",
  },

  {
    symbol: "BKN",
    group: "cloud",
    type: "descriptor",
    meaning: "broken",
  },

  {
    symbol: "OVC",
    group: "cloud",
    type: "descriptor",
    meaning: "overcast",
  },

  {
    symbol: "OVX",
    group: "cloud",
    type: "descriptor",
    meaning: "obscured",
  },
]

const weatherIntensityTokens: WeatherToken[] = [
  {
    symbol: "-",
    group: "weather",
    type: "intensity",
    meaning: "light",
  },

  {
    symbol: "+",
    group: "weather",
    type: "intensity",
    meaning: "heavy",
  },

  {
    symbol: "VC",
    group: "weather",
    type: "intensity",
    meaning: "vicinity",
  },
]

const weatherDescriptorTokens: WeatherToken[] = [
  {
    symbol: "BC",
    group: "weather",
    type: "descriptor",
    meaning: "patches",
  },

  {
    symbol: "BL",
    group: "weather",
    type: "descriptor",
    meaning: "blowing",
  },

  {
    symbol: "DR",
    group: "weather",
    type: "descriptor",
    meaning: "low drifting",
  },

  {
    symbol: "FZ",
    group: "weather",
    type: "descriptor",
    meaning: "freezing",
  },

  {
    symbol: "MI",
    group: "weather",
    type: "descriptor",
    meaning: "shallow",
  },

  {
    symbol: "PR",
    group: "weather",
    type: "descriptor",
    meaning: "partial",
  },

  {
    symbol: "SH",
    group: "weather",
    type: "descriptor",
    meaning: "shower",
  },

  {
    symbol: "TS",
    group: "weather",
    type: "descriptor",
    meaning: "thunderstorm",
  },
]

const weatherPrecipitationTokens: WeatherToken[] = [
  {
    symbol: "DZ",
    group: "weather",
    type: "precipitation",
    meaning: "drizzle",
  },

  {
    symbol: "GR",
    group: "weather",
    type: "precipitation",
    meaning: "hail",
  },

  {
    symbol: "GS",
    group: "weather",
    type: "precipitation",
    meaning: "small hail and/or snow pellets",
  },

  {
    symbol: "IC",
    group: "weather",
    type: "precipitation",
    meaning: "ice crystals",
  },

  {
    symbol: "PL",
    group: "weather",
    type: "precipitation",
    meaning: "ice pellets",
  },

  {
    symbol: "RA",
    group: "weather",
    type: "precipitation",
    meaning: "rain",
  },

  {
    symbol: "SG",
    group: "weather",
    type: "precipitation",
    meaning: "snow grains",
  },

  {
    symbol: "SN",
    group: "weather",
    type: "precipitation",
    meaning: "snow",
  },

  {
    symbol: "UP",
    group: "weather",
    type: "precipitation",
    meaning: "unknown precipitation",
  },
]

const weatherObscurationTokens: WeatherToken[] = [
  {
    symbol: "BR",
    group: "weather",
    type: "obscuration",
    meaning: "mist",
  },

  {
    symbol: "DU",
    group: "weather",
    type: "obscuration",
    meaning: "widespread dust",
  },

  {
    symbol: "FG",
    group: "weather",
    type: "obscuration",
    meaning: "fog",
  },

  {
    symbol: "FU",
    group: "weather",
    type: "obscuration",
    meaning: "smoke",
  },

  {
    symbol: "HZ",
    group: "weather",
    type: "obscuration",
    meaning: "haze",
  },

  {
    symbol: "SA",
    group: "weather",
    type: "obscuration",
    meaning: "sand",
  },

  {
    symbol: "VA",
    group: "weather",
    type: "obscuration",
    meaning: "volcanic ash",
  },
]

const weatherOtherTokens: WeatherToken[] = [
  {
    symbol: "DS",
    group: "weather",
    type: "other",
    meaning: "duststorm",
  },

  {
    symbol: "FC",
    group: "weather",
    type: "other",
    meaning: "funnel clouds",
  },

  {
    symbol: "PO",
    group: "weather",
    type: "other",
    meaning: "dust/sand storms",
  },

  {
    symbol: "SQ",
    group: "weather",
    type: "other",
    meaning: "squall",
  },

  {
    symbol: "SS",
    group: "weather",
    type: "other",
    meaning: "sandstorm",
  },
]

const weatherClearToken: WeatherToken = {
  symbol: "CLR",
  group: "weather",
  type: "other",
  meaning: "clear",
}

class TokenizationError extends Error {
  type: string

  constructor(_type: string, message?: string) {
    super(message)
    this.type = _type
  }
}

function takeCharacters(value: string, num: number) {
  if (value.length >= num) {
    return [value.slice(0, num), value.slice(num)]
  } else {
    throw new TokenizationError("weather")
  }
}

function parseIntensity(rawWeather: string) {
  for (const token of weatherIntensityTokens) {
    if (rawWeather.startsWith(token.symbol)) {
      const [_parsed, remaining] = takeCharacters(rawWeather, token.symbol.length)
      return {
        token,
        remaining,
      }
    }
  }
  return undefined
}

function tokenizeWith(rawWeather: string, tokens: WeatherToken[]) {
  for (const token of tokens) {
    if (rawWeather.startsWith(token.symbol)) {
      const [_parsed, remaining] = takeCharacters(rawWeather, token.symbol.length)
      return {
        token,
        remaining,
      }
    }
  }
  return undefined
}

const weatherTokensOrder = [
  weatherIntensityTokens,
  weatherDescriptorTokens,
  weatherPrecipitationTokens,
  weatherObscurationTokens,
  weatherOtherTokens,
]

export function tokenizeWeather(rawWeather: string) {
  if (rawWeather === weatherClearToken.symbol) {
    return [weatherClearToken]
  }

  const tokens: WeatherToken[] = []
  let remainingWeather = rawWeather

  for (const tokenList of weatherTokensOrder) {
    if (remainingWeather.length <= 0) {
      break
    }

    const result = tokenizeWith(remainingWeather, tokenList)

    if (result != null) {
      tokens.push(result.token)
      remainingWeather = result.remaining
    }
  }

  return tokens
}

export function translatePrecipitation(tokens: WeatherToken[]) {
  let intensity: WeatherToken | undefined
  let remainingTokens = [...tokens]

  if (tokens[0].type === "intensity") {
    intensity = tokens[0]
    remainingTokens = remainingTokens.slice(1)
  }

  const precipitationDescriptors = remainingTokens.map((token) => token.meaning).join(" ")
  if (intensity?.symbol === "VC") {
    return `${precipitationDescriptors} in vicinity`
  } else {
    let intensityDescriptor = "moderate"
    if (intensity != null) {
      intensityDescriptor = intensity.meaning
    }
    return `${intensityDescriptor} ${precipitationDescriptors}`
  }
}

export function translateWeatherTokens(weatherTokens: WeatherToken[]) {
  if (weatherTokens.length === 0) {
    return null
  }
  const precipitationDescriptors: WeatherToken[] = []
  const weatherDescriptors: string[] = []

  let remainingTokens = [...weatherTokens]

  for (const tokenIndex in weatherTokens) {
    const token = weatherTokens[tokenIndex]

    if (["intensity", "descriptor", "precipitation"].includes(token.type)) {
      precipitationDescriptors.push(token)
      remainingTokens = remainingTokens.slice(parseInt(tokenIndex) + 1)
    } else {
      break
    }
  }

  if (precipitationDescriptors.length > 0) {
    weatherDescriptors.push(translatePrecipitation(precipitationDescriptors))
  }
  for (const token of remainingTokens) {
    weatherDescriptors.push(token.meaning)
  }
  return weatherDescriptors.join(", ")
}

export function translateWeather(rawWeather: string | null | undefined) {
  if (rawWeather == null || rawWeather.trim().length === 0) {
    return "No significant weather"
  }

  const tokenGroups = rawWeather.split(" ").map((weatherPart) => tokenizeWeather(weatherPart))
  const descriptions = tokenGroups
    .map((group) => translateWeatherTokens(group))
    .filter((description): description is string => description != null)
  return descriptions.join(", ")
}

export function tokenizeCloudDescriptor(cloudDescriptor: string) {
  const descriptor = cloudDescriptor.trim()
  return cloudDescriptorTokens.find((token) => token.symbol === descriptor)
}

function gatherWeatherTimes(weatherData: {
  metar?: MetarResponse
  taf: TafResponse
  weather: PirateWeather | undefined
}) {
  const metarTime = weatherData.metar == null ? undefined : dayjs().add(-5, "minute").toDate()
  const metarEndTime = metarTime == null ? undefined : dayjs().add(15, "minute").toDate()

  /*
  const tafTimes = weatherData.taf.fcsts
    .map((forecast) => {
      const { timeFrom, timeTo, fcstChange, timeGroup } = forecast
      return {
        forecast,
        type: fcstChange,
        timeGroup,
        dateFrom: new Date(timeFrom * 1000),
        dateTo: new Date(timeTo * 1000),
      }
    })
    .sort((a, b) => a.timeGroup - b.timeGroup)
    */

  const tafTimes = partitionTafGroups(weatherData.taf).map((group) => {
    return {
      ...group,
      data: undefined,
      forecast: group.data,
    }
  })

  const lastTafEndTime = tafTimes[tafTimes.length - 1]!.dateTo

  const pirateWeatherHourlyTimes: PirateWeather.HourData[] =
    weatherData.weather?.hourly.data.filter((hourData) => {
      return new Date(hourData.time * 1000) > lastTafEndTime
    }) ?? []

  const lastHourlyData =
    pirateWeatherHourlyTimes.length > 0 ? new Date(pirateWeatherHourlyTimes[pirateWeatherHourlyTimes.length - 1]!.time) : undefined

  const pirateWeatherDailyTimes: PirateWeather.DayData[] =
    weatherData.weather != null && lastHourlyData != null
      ? weatherData.weather.daily.data.filter((dayData) => {
          return new Date((dayData.time + 60) * 1000) > lastHourlyData
        })
      : []

  return {
    metarTime: metarTime == null ? null : { timeFrom: metarTime!, timeTo: metarEndTime! },
    tafTimes,
    pirateWeatherHourlyTimes,
    pirateWeatherDailyTimes,
  }
}

const tafForecastDataFields: Array<keyof TafForecastResponse> = [
  "wdir",
  "wspd",
  "wgst",
  "wshearHgt",
  "wshearDir",
  "wshearSpd",
  "visib",
  "altim",
  "vertVis",
  "wxString",
  "notDecoded",
  "clouds",
  "icgTurb",
]

type SplitTafForecastGroup = {
  data: TafForecastResponse
  type: string | null
  timeGroup: number
  dateFrom: Date
  dateTo: Date
}

export function findSunriseSunsetTimes(targetDate: Date | Dayjs, weather: PirateWeather | undefined) {
  if (weather == null) {
    return undefined
  }
  const targetDay = dayjs(targetDate)

  let weatherToUse = weather.daily.data.find((dayWeather) => {
    const dayDate = dayjs.unix(dayWeather.time)
    const isAfterPeriod = targetDay.isAfter(dayDate)
    const diffInHours = targetDay.diff(dayDate, "hour")
    return isAfterPeriod && diffInHours <= 24
  })

  if (weatherToUse == null) {
    weatherToUse = weather.daily.data.at(-1)
  }

  const sunrise = dayjs.unix(weatherToUse!.sunriseTime)
  const sunset = dayjs.unix(weatherToUse!.sunsetTime)

  return { sunrise, sunset }
}

export function partitionTafGroups(taf: TafResponse) {
  const newTafTimes: SplitTafForecastGroup[] = []

  for (let index = 0; index < taf.fcsts.length; index = index + 1) {
    const forecast = taf.fcsts[index]
    const nextForecast = taf.fcsts[index + 1]

    const nextForecastIsTemporary = nextForecast?.fcstChange === "TEMPO"
    const { timeFrom, timeTo, fcstChange, timeGroup } = forecast
    const tafGroupTime = new Date(timeFrom * 1000)
    const tafGroupTimeEnd = new Date((nextForecastIsTemporary ? nextForecast.timeFrom : timeTo) * 1000)

    /*
     */

    if (fcstChange === "TEMPO") {
      const previousTimeGroup = newTafTimes[newTafTimes.length - 1]
      const previousForecast = previousTimeGroup.data as TafForecastResponse
      const newForecast = { ...previousForecast }
      for (const fieldName of tafForecastDataFields) {
        const field = forecast[fieldName]
        if ((Array.isArray(field) && field.length > 0) || (!Array.isArray(field) && field != null)) {
          // @ts-ignore
          newForecast[fieldName] = field
        }
      }

      newTafTimes.push({
        data: newForecast,
        type: fcstChange,
        timeGroup,
        dateFrom: tafGroupTime,
        dateTo: tafGroupTimeEnd,
        //  pirateWeather: pirateWeather,
      })

      newTafTimes.push({
        ...previousTimeGroup,
        dateFrom: tafGroupTimeEnd,
        dateTo: new Date(previousForecast.timeTo * 1000),
      })
    } else {
      newTafTimes.push({
        data: forecast,
        type: fcstChange,
        timeGroup,
        dateFrom: tafGroupTime,
        dateTo: tafGroupTimeEnd,
        // pirateWeather: pirateWeather,
      })
    }
  }
  return newTafTimes
}

export function collectWeatherTimesByTafGroups(weatherData: {
  metar?: MetarResponse
  taf: TafResponse
  weather: PirateWeather | undefined
}): WeatherTimeGroup[] {
  const metarTime = weatherData.metar == null ? undefined : dayjs().add(-5, "minute").toDate()
  const metarEndTime = metarTime == null ? undefined : dayjs().add(15, "minute").toDate()

  const tafTimes = partitionTafGroups(weatherData.taf)
  const newTafTimes: WeatherTimeGroup[] = tafTimes.map((group) => {
    const pirateWeather =
      weatherData.weather != null
        ? weatherData.weather.hourly.data.find((hourGroup) => {
            const groupStartTime = new Date(hourGroup.time * 1000)
            const groupEndTime = new Date((hourGroup.time + 60) * 1000)
            return group.dateFrom >= groupStartTime && group.dateFrom < groupEndTime
          }) ?? weatherData.weather.hourly.data[0]
        : undefined

    return {
      ...group,
      source: "taf",
      pirateWeather,
    }
  })

  const timeGroups: WeatherTimeGroup[] = []
  if (metarTime != null && metarEndTime != null) {
    timeGroups.push({
      source: "metar",
      dateFrom: metarTime,
      dateTo: metarEndTime,
      data: weatherData.metar!,
      type: undefined,
      pirateWeather: weatherData.weather?.currently,
    })
  }
  for (const timeGroup of newTafTimes) {
    if (metarEndTime == null || timeGroup.dateFrom >= metarEndTime) {
      timeGroups.push(timeGroup)
    } else if (metarEndTime != null && timeGroup.dateTo >= metarEndTime) {
      const newTimeGroup = {
        ...timeGroup,
        dateFrom: metarEndTime,
      }

      timeGroups.push(newTimeGroup)
    } else {
    }
  }
  const timeGroupsWithSunSplit =
    weatherData.weather != null
      ? timeGroups.flatMap((timeGroup) => {
          const daySunriseSunset = findSunriseSunsetTimes(timeGroup.dateFrom, weatherData.weather)!

          const sunriseTime = daySunriseSunset.sunrise.add(2, "hours").add(1, "minute").toDate()
          const sunsetTime = daySunriseSunset.sunset.add(-2, "hours").add(-1, "minute").toDate()
          const encompassesSunriseTime = timeGroup.dateFrom < sunriseTime && timeGroup.dateTo > sunriseTime
          const encompassesSunsetTime = timeGroup.dateFrom < sunsetTime && timeGroup.dateTo > sunsetTime

          if (encompassesSunriseTime) {
            //this timegroup encompasses sunrise time
            //console.log("timegroup around sunrise time: ", timeGroup.dateFrom)

            return [
              {
                ...timeGroup,
                dateTo: sunriseTime,
              },
              {
                ...timeGroup,
                dateFrom: sunriseTime,
              },
            ]
          } else if (encompassesSunsetTime) {
            //this timegroup encompasses sunset time
            // console.log("timegroup around sunset time: ", timeGroup.dateFrom)
            const nextDaySunriseSunset = findSunriseSunsetTimes(dayjs(timeGroup.dateFrom).add(24, "hours"), weatherData.weather)!
            const nextDaySunrise = nextDaySunriseSunset.sunrise.add(2, "hours").add(1, "minute").toDate()
            const encompassesNextDaySunrise = timeGroup.dateFrom < nextDaySunrise && timeGroup.dateTo > nextDaySunrise
            if (encompassesNextDaySunrise) {
              return [
                {
                  ...timeGroup,
                  dateTo: sunsetTime,
                },
                {
                  ...timeGroup,
                  dateFrom: sunsetTime,
                  dateTo: nextDaySunrise,
                },
                {
                  ...timeGroup,
                  dateFrom: nextDaySunrise,
                },
              ]
            } else {
              return [
                {
                  ...timeGroup,
                  dateTo: sunsetTime,
                },
                {
                  ...timeGroup,
                  dateFrom: sunsetTime,
                },
              ]
            }
          } else {
            return timeGroup
          }
        })
      : timeGroups

  return timeGroupsWithSunSplit
}

function getWeather(weatherString: string | null) {
  return translateWeather(weatherString)
}

export function computeWeatherToUse(
  time: Date,
  weatherData: {
    metar?: MetarResponse
    taf: TafResponse
    weather: PirateWeather | undefined
  },
) {
  /*
  if (new Date() > time) {
    return { decision: "no-data-past", data: undefined } as const
  }
  */

  const weatherWithTimes = gatherWeatherTimes(weatherData)
  //console.log("Weather with Times: ", time, weatherWithTimes)

  if (weatherWithTimes.metarTime != null && time >= weatherWithTimes.metarTime.timeFrom && time <= weatherWithTimes.metarTime.timeTo) {
    return { decision: "metar", data: weatherData.metar! } as const
  }

  for (const timeGroupIndex in weatherWithTimes.tafTimes) {
    const timeGroup = weatherWithTimes.tafTimes[timeGroupIndex]
    if (time >= timeGroup.dateFrom && time <= timeGroup.dateTo) {
      return { decision: "taf", data: timeGroup.forecast, group: parseInt(timeGroupIndex) } as const
    }
  }

  for (const hourData of weatherWithTimes.pirateWeatherHourlyTimes) {
    const dateFrom = new Date(hourData.time * 1000)
    const dateTo = new Date((hourData.time + 60 * 60) * 1000)

    if (time >= dateFrom && time <= dateTo) {
      return { decision: "pirateWeatherHourlyData", data: hourData } as const
    }
  }

  for (const dayData of weatherWithTimes.pirateWeatherDailyTimes) {
    const dateFrom = new Date(dayData.time * 1000)
    const dateTo = new Date((dayData.time + 60 * 60 * 24) * 1000)
    // console.log("hourly: ", dateFrom, dateTo)

    if (time >= dateFrom && time <= dateTo) {
      return { decision: "pirateWeatherDailyData", data: dayData } as const
    }
  }

  return { decision: "no-data-future", data: undefined } as const
}

function getWeatherStringForPirateWeather(weather: PirateWeather.HourData | PirateWeather.DayData) {
  const probability = weather.precipProbability

  if (probability >= 0.25 && probability < 0.5) {
    return `Low chance of ${weather.precipType}`
  }

  if (probability >= 0.5 && probability < 0.75) {
    return `Medium chance of ${weather.precipType}`
  }

  if (probability >= 0.75) {
    return `High chance of ${weather.precipType}`
  }

  return "No significant forecasted weather"
}

export function computeWeatherBasedOnMetar(data: MetarResponse) {
  const weatherDetails: IWeatherDetails = {
    type: "metar",
  }

  weatherDetails.windDir = data.wdir
  weatherDetails.windSpd = data.wspd
  weatherDetails.windGst = data.wgst ?? undefined
  weatherDetails.clouds = data.clouds
  weatherDetails.visibility = data.visib
  weatherDetails.flightRules = getFlightRules(data) ?? undefined
  weatherDetails.weather = getWeather(data.wxString)

  return weatherDetails
}
export function computeWeatherBasedOnTafGroup(data: TafForecastResponse, tafGroup: number, icaoId: string | undefined) {
  const weatherDetails: IWeatherDetails = {
    type: "taf",
  }

  if (typeof data.wdir === "number" || (typeof data.wdir === "string" && data.wdir === "VRB")) {
    weatherDetails.windDir = data.wdir
  }
  weatherDetails.tafGroup = tafGroup
  weatherDetails.tafSource = icaoId

  weatherDetails.windSpd = data.wspd ?? undefined
  weatherDetails.windGst = data.wgst ?? undefined
  weatherDetails.clouds = data.clouds
  weatherDetails.visibility = data.visib ?? undefined
  weatherDetails.flightRules = getFlightRules(data) ?? undefined
  weatherDetails.weather = getWeather(data.wxString)

  return weatherDetails
}

export function computeWeatherBasedOnTime(
  time: Date,
  weatherData: {
    metar?: MetarResponse
    taf: TafResponse
    weather: PirateWeather | undefined
  },
): IWeatherDetails {
  const weatherToUse = computeWeatherToUse(time, weatherData)

  let weatherDetails: IWeatherDetails = {
    type: weatherToUse.decision,
  }

  if (weatherToUse.decision === "metar") {
    weatherDetails = {
      ...computeWeatherBasedOnMetar(weatherToUse.data),
      ...weatherDetails,
    }
  }

  if (weatherToUse.decision === "taf") {
    weatherDetails = {
      ...computeWeatherBasedOnTafGroup(weatherToUse.data, weatherToUse.group, weatherData.taf.icaoId ?? undefined),
      ...weatherDetails,
    }
  }

  if (weatherToUse.decision === "pirateWeatherHourlyData" || weatherToUse.decision === "pirateWeatherDailyData") {
    weatherDetails.windDir = weatherToUse.data.windBearing
    weatherDetails.windSpd = weatherToUse.data.windSpeed * 0.8689762
    weatherDetails.windGst = weatherToUse.data.windGust * 0.8689762
    weatherDetails.visibility = weatherToUse.data.visibility
    weatherDetails.cloudCover = weatherToUse.data.cloudCover
    weatherDetails.weather = getWeatherStringForPirateWeather(weatherToUse.data)
  }

  return weatherDetails
}

export function getCeiling(weather: MetarResponse | TafForecastResponse) {
  if (weather.vertVis != null) {
    return Number(weather.vertVis)
  }

  const sortedClouds = weather.clouds.sort((cloudA, cloudB) => {
    return (cloudA.base ?? 0) - (cloudB.base ?? 0)
  })

  if (sortedClouds.length === 0) {
    return null
  }

  if (sortedClouds[0].cover === "CLR") {
    return null
  }

  const ceiling = sortedClouds.find((cloudGroup) => cloudGroup.cover === "BKN" || cloudGroup.cover === "OVC")

  if (ceiling == null) {
    return null
  }

  return ceiling.base
}

export function parseVisibility(weather: MetarResponse | TafForecastResponse) {
  const rawVisibility = weather.visib
  if (typeof rawVisibility === "string") {
    return parseInt(rawVisibility.replace(/[+-]/g, ""))
  }
  return rawVisibility
}

export function getFlightRules(weather: MetarResponse | TafForecastResponse) {
  const ceiling = getCeiling(weather)
  const visibility = parseVisibility(weather)

  if (visibility == null) {
    return null
  }

  const hasCeiling = ceiling != null

  if ((hasCeiling && ceiling <= 1000) || visibility <= 3) {
    return "IFR"
  } else if ((hasCeiling && ceiling > 1000 && ceiling <= 3000) || visibility <= 5) {
    return "MVFR"
  } else {
    return "VFR"
  }
}

export function computeFlightMetrics({
  destination,
  departure,
  aircraft,
  date,
  departureTimeZone,
}: {
  destination?: AirportResult
  departure?: AirportResult
  aircraft?: Aircraft
  date?: Date
  departureTimeZone?: string
}) {
  if (destination == null || departure == null || date == null || departureTimeZone == null) {
    return
  }

  const departureDate = dayjs(date).tz(departureTimeZone, true)

  const distanceNm =
    turfDistance([destination.longitude, destination.latitude], [departure.longitude, departure.latitude], { units: "miles" }) * 0.868976
  const ete = distanceNm / (aircraft?.airspeed ?? 110)
  const eta = departureDate.add(ete, "hour")
  const totalFuelBurn = (aircraft?.fuelBurn ?? 9) * ete

  return {
    departureDate,
    distanceNm,
    ete,
    eta,
    totalFuelBurn,
  }
}

export const StageOfFlightLabels: Record<StageOfFlight, string> = {
  departure: "Departure",
  enroute: "En Route",
  destination: "Arrival",
}

export type ThreatLevel = "red" | "orange" | "yellow"
export const ThreatCutoffs: Record<ThreatLevel, number> = {
  red: 800,
  orange: 600,
  yellow: 400,
} as const

export const ThreatColors: Record<ThreatLevel, string> = {
  red: "#F95349",
  orange: "#F88113",
  yellow: "#ECCE8E",
} as const

export function determineThreatCategory(value: number): ThreatLevel | undefined {
  if (value >= ThreatCutoffs.red) {
    return "red"
  } else if (value >= ThreatCutoffs.orange) {
    return "orange"
  } else if (value >= ThreatCutoffs.yellow) {
    return "yellow"
  }
  return
}

export function convertFlightMetrics(input: ApiFlightMetrics): FlightMetrics {
  const departure = {
    ...input.departure,
    sunrise: input.departure.sunrise != null ? dayjs(input.departure.sunrise).tz(input.departure.timeZoneName) : undefined,
    sunset: input.departure.sunset != null ? dayjs(input.departure.sunset).tz(input.departure.timeZoneName) : undefined,
  }

  const destination = {
    ...input.destination,
    sunrise: input.destination.sunrise != null ? dayjs(input.destination.sunrise).tz(input.destination.timeZoneName) : undefined,
    sunset: input.destination.sunset != null ? dayjs(input.destination.sunset).tz(input.destination.timeZoneName) : undefined,
  }

  return {
    ...input,
    departure,
    destination,
    departureDate: dayjs(input.departureDate).tz(input.departure.timeZoneName),
    eta: dayjs(input.eta).tz(input.destination.timeZoneName),
  }
}

export function calculateTempDewpointPressure(
  weather: MetarResponse | TafForecastResponse | undefined,
  pirateWeatherHourData: PirateWeather.HourData | PirateWeather.DayData | PirateWeather.CurrentlyData | undefined,
  elevationFeet: number,
) {
  if (weather == null && pirateWeatherHourData == null) {
    return undefined
  }

  let temperature: number | undefined
  let dewpoint: number | undefined
  let pressure: number | undefined
  let altim: number | undefined

  if (pirateWeatherHourData != null) {
    if ("temperature" in pirateWeatherHourData) {
      temperature = converFarenheitToCelsius(pirateWeatherHourData.temperature)
    } else {
      const avgTemperature = (pirateWeatherHourData.temperatureMax + pirateWeatherHourData.temperatureMin) / 2
      temperature = converFarenheitToCelsius(avgTemperature)
    }
    dewpoint = converFarenheitToCelsius(pirateWeatherHourData.dewPoint)
  }

  if (weather != null && "temp" in weather && typeof weather.temp === "number") {
    temperature = weather.temp
    dewpoint = weather.dewp
    if ("altim" in weather) {
      altim = weather.altim
    }
  }

  if (pressure == null && pirateWeatherHourData != null) {
    pressure = pirateWeatherHourData.pressure
    if (altim == null) {
      altim = convertPressureToAltimiter(pirateWeatherHourData.pressure, elevationFeet)
    }
  }

  let densityAltitude: number | undefined = undefined

  if (altim != null && temperature != null) {
    densityAltitude = calculateDensityAltitudeFromPressure(altim, elevationFeet, temperature)
  }

  return {
    altim,
    temperature,
    dewpoint,
    pressure,
    densityAltitude,
  }
}

export function calculateDensityAltitude(
  weather: MetarResponse | TafForecastResponse | undefined,
  pirateWeatherHourData: PirateWeather.HourData | PirateWeather.DayData | undefined,
  elevation: number,
) {
  const data = calculateTempDewpointPressure(weather, pirateWeatherHourData, elevation)
  if (data == null || data.altim == null || data.temperature == null) {
    return null
  }
  const densityAltitude = calculateDensityAltitudeFromPressure(data.altim, elevation, data.temperature)
  return densityAltitude
}

// https://pilotinstitute.com/how-to-calculate-density-altitude/
export function calculateDensityAltitudeFromPressure(pressureMillibars: number, fieldElevationFt: number, temperatureCelsius: number) {
  const pressureInHg = convertMillibarsToInchesMercury(pressureMillibars)
  const elevationDistance = (29.92 - pressureInHg) * 1000
  const pressureAltitude = elevationDistance + fieldElevationFt

  const standardTemperature = 15 - (fieldElevationFt / 1000) * 2
  // console.log("standardTemperature: ", standardTemperature)
  const densityAltitude = pressureAltitude + 118.8 * (temperatureCelsius - standardTemperature)

  return densityAltitude
}

export function convertCelsiusToFarenheit(temperatureCelsius: number) {
  return (9 / 5) * temperatureCelsius + 32
}

export function converFarenheitToCelsius(temperatureFarenheit: number) {
  return ((temperatureFarenheit - 32) * 5) / 9
}

export function convertMillibarsToInchesMercury(pressureMb: number) {
  return 0.02953 * pressureMb
}

export function convertPressureToAltimiter(pressure: number, elevationFeet: number) {
  const elevationMeters = 0.3048 * elevationFeet
  const altimiter =
    (pressure - 0.3) * (1 + ((1013.25 ** 0.190284 * 0.0065) / 288) * (elevationMeters / (pressure - 0.3) ** 0.190284)) ** (1 / 0.190284)

  return altimiter
}
