import { Box, Button, Divider, Flex, Group, Paper, Skeleton, Stack, Text, Title } from "@mantine/core"
import {
  AirportFlightMetrics,
  AirportResult,
  FlightRule,
  Risk,
  SafetyAssessmentGroup,
  SafetyBriefMode,
  StageOfFlight,
} from "@soar/shared/types"
import { AssessmentThreatColors, StageOfFlightLabels, formatDate, getAssessmentThreatScoreLevel } from "@soar/shared/utils"
import { P, match } from "ts-pattern"
import { useFlightRuleStyles } from "../weather/useFlightRuleStyles"
import { calculateTimeOfDayTimeToDisplay } from "../weather/utils"
import { OverviewSafetyAssessmentGroup, OverviewSafetyRisksGroup, OverviewThreatCount } from "./overviewCardCommon"

export function AirportOverviewSafetyCard({
  airport,
  risks,
  stageOfFlight,
  date,
  flightRules,
  onExpandClick = () => {},
  airportFlightMetrics,
  assessmentThreats,
  safetyBriefMode,
  loading = false,
}: {
  loading?: boolean
  date?: Date
  airport?: AirportResult
  risks?: Risk[]
  stageOfFlight: StageOfFlight
  flightRules?: FlightRule
  airportFlightMetrics?: AirportFlightMetrics
  assessmentThreats?: SafetyAssessmentGroup
  safetyBriefMode: SafetyBriefMode
  onExpandClick?: (stageOfFlight: StageOfFlight) => void
}) {
  const { classes: flightFulesClasses } = useFlightRuleStyles()
  const timeOfDayTimeToDisplay = calculateTimeOfDayTimeToDisplay(date, airportFlightMetrics)
  const safetyAssessmentLevel = assessmentThreats != null ? getAssessmentThreatScoreLevel(assessmentThreats?.score) : undefined
  const safetyAssessmentColor = safetyAssessmentLevel != null ? AssessmentThreatColors[safetyAssessmentLevel] : undefined

  return (
    <Paper radius="lg" pt="sm" pb="md" px="lg" shadow="lg">
      <Flex justify="space-between" align="flex-start">
        <Box>
          <Title order={4} style={{ fontSize: "1.3rem" }}>
            {StageOfFlightLabels[stageOfFlight]}
          </Title>
          <Title order={3} style={{ fontSize: "2.3rem" }}>
            {airport?.icao_id ?? airport?.faa_airport_id ?? <Skeleton width={40} height={20} />}
          </Title>

          {flightRules != null && (
            <Text className={flightFulesClasses[flightRules]} fw={600}>
              {flightRules} conditions
            </Text>
          )}
        </Box>
        <Box>
          <Stack spacing="xs">
            <Flex align="center" fw={600}>
              <span className="material-symbols-outlined">calendar_today</span>
              <Text span ml="xs" w={130}>
                {date == null ? (
                  <Skeleton width={95} height={12} display="inline-block" />
                ) : (
                  formatDate(date, { date: { format: "long" }, timeZone: airportFlightMetrics?.timeZoneName })
                )}
              </Text>
            </Flex>
            <Flex align="center" fw={600}>
              <span className="material-symbols-outlined">{stageOfFlight === "departure" ? "flight_takeoff" : "flight_land"}</span>
              <Text span ml="xs" w={130} tt="lowercase">
                {date == null ? (
                  <Skeleton width={95} height={12} display="inline-block" />
                ) : (
                  formatDate(date, { time: true, timeZone: airportFlightMetrics?.timeZoneName })
                )}
              </Text>
            </Flex>
            <Flex align="center" fw={600}>
              <span className="material-symbols-outlined">{timeOfDayTimeToDisplay === "sunset" ? "clear_night" : "sunny"}</span>
              <Text span ml="xs" w={130}>
                {match({ timeOfDayTimeToDisplay, airportFlightMetrics })
                  .with({ airportFlightMetrics: P.nullish }, () => <Skeleton width={95} height={12} display="inline-block" />)
                  .with({ airportFlightMetrics: { sunrise: P.not(P.nullish) }, timeOfDayTimeToDisplay: "sunrise" }, (result) =>
                    formatDate(result.airportFlightMetrics.sunrise, { time: true, timeZone: result.airportFlightMetrics?.timeZoneName }),
                  )
                  .with({ airportFlightMetrics: { sunset: P.not(P.nullish) }, timeOfDayTimeToDisplay: "sunset" }, (result) =>
                    formatDate(result.airportFlightMetrics.sunset, { time: true, timeZone: result.airportFlightMetrics?.timeZoneName }),
                  )
                  .otherwise(() => (
                    <>not available</>
                  ))}
              </Text>
            </Flex>
          </Stack>
        </Box>
      </Flex>
      <Divider mx="xl" mt="xl" mb="lg" />
      <Title order={5} mb="sm">
        <OverviewThreatCount
          safetyBriefMode={safetyBriefMode}
          assessmentThreats={assessmentThreats}
          isLoading={risks == null}
          risks={risks}
        />
      </Title>
      {safetyBriefMode === "data" ? (
        <OverviewSafetyRisksGroup risks={risks} />
      ) : (
        <OverviewSafetyAssessmentGroup assessmentThreats={assessmentThreats} color={safetyAssessmentColor} />
      )}
      {/*
      <Flex align="center" justify="center" mt="xl">
        <Button variant="subtle" onClick={() => onExpandClick(stageOfFlight)}>
          View Details
        </Button>
      </Flex>
      */}
    </Paper>
  )
}
