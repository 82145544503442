import { UserRole } from "@soar/shared/types"

export const roleConfig: Record<
  UserRole,
  {
    label: string
    description: string
  }
> = {
  [UserRole.enum.PILOT]: {
    label: "Pilot",
    description: "This role is for pilots who don't need access to view any organization data.",
  },
  [UserRole.enum.MECHANIC]: {
    label: "Mechanic",
    description: "This role is for mechanics who don't need access to view any organization data.",
  },
  [UserRole.enum.STAFF_PILOT]: {
    label: "Pilot (Opcon)",
    description: "This role is for pilots who need access to view organizational data.",
  },
  [UserRole.enum.ADMIN]: {
    label: "Administrator",
    description: "This role is for people who need similar access as the account owner.",
  },
  [UserRole.enum.SUPERADMIN]: {
    label: "Super administrator",
    description: "This role owns the account",
  },
}
