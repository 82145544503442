import { IScheduleSegment } from "./schedule.interface"

/**
 * Compute the start and end of each segment
 */
export function computeSegments(from: number, to: number, segmentsCount: number) {
  const segments: IScheduleSegment[] = []
  const segmentSize = (to - from) / segmentsCount

  for (let index = 0; index < segmentsCount; index++) {
    const segment = {
      from: index * segmentSize + from,
      to: (index + 1) * segmentSize + from,
    }
    segments.push(segment)
  }

  return segments
}

export function calculateScheduleWidth(
  currentWidth: number,
  rowHeaderWidth: number,
  segmentsCount: number,
  minCellWidth: number | undefined,
) {
  let scheduleWidth = currentWidth - rowHeaderWidth

  if (minCellWidth != null) {
    const minWidth = minCellWidth * segmentsCount
    if (minWidth > scheduleWidth) {
      scheduleWidth = minWidth
    }
  }

  return scheduleWidth
}
