import { Popover, Text } from "@mantine/core"
import { Pirep } from "@soar/shared/types"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import { CSSProperties } from "react"

dayjs.extend(relativeTime)

const defaultPirepStyle: CSSProperties = {
  width: "100%",
  overflowWrap: "break-word",
  inlineSize: "100%",
}

const numberFormatter = new Intl.NumberFormat()

function PirepAdditionalData({
  pirep,
}: {
  pirep: Pirep
}) {
  const duration = dayjs(pirep.obsTime).fromNow()
  return (
    <Text span>
      <Text c="black" span fw={600}>
        Reported:{" "}
      </Text>
      {duration} from a {pirep.aircraftType}
      {(pirep.fltLvl != null || pirep.fltLvlType != null) && (
        <Text span>
          {" "}
          @ {pirep.fltLvl != null && `${numberFormatter.format(pirep.fltLvl)}'`}
          {pirep.fltLvlType != null && pirep.fltLvlType !== "OTHER" && ` ${pirep.fltLvlType}`}
        </Text>
      )}
    </Text>
  )
}

export function PirepDisplay({ pirep, rawMode = false, condensed = false }: { pirep: Pirep; rawMode?: boolean; condensed?: boolean }) {
  const shouldDisplayTranslatedPirep = !rawMode && pirep.translation != null && (pirep.translation ?? "").trim().length > 0
  return (
    <Popover width={400} disabled>
      <Popover.Target>
        <Text span fz={{ base: "sm", md: "md" }} style={defaultPirepStyle} c="gray.7">
          {condensed && "PIREP: "}
          {shouldDisplayTranslatedPirep ? pirep.translation : pirep.rawOb}
          {!condensed && (
            <Text mt="xs">
              <PirepAdditionalData pirep={pirep} />
            </Text>
          )}
        </Text>
      </Popover.Target>
      <Popover.Dropdown>
        <Text>
          {pirep.translation == null && "Missing translation: "}
          {pirep.rawOb}
        </Text>
      </Popover.Dropdown>
    </Popover>
  )
}
