import { Box, Button, Center, Divider, Flex, Group, Overlay, Paper, Skeleton, Stack, Text, Title } from "@mantine/core"
import { SafetyAssessmentThreat, StageOfFlight } from "@soar/shared/types"
import { AssessmentThreatColors, getAssessmentThreatScoreLevel } from "@soar/shared/utils"
import { IconCheck } from "@tabler/icons-react"
import { RiskBadge } from "../weather"
import { FocusedNotamHeader } from "../weather/NotamList"
import { OverviewSafetyAssessmentGroup, OverviewSafetyRisksGroup } from "./overviewCardCommon"

export function PreflightOverviewSafetyCard({
  threats = [],
  onExpandClick = () => {},
  score,
}: {
  score?: number
  threats?: SafetyAssessmentThreat[]
  onExpandClick?: (stageOfFlight: StageOfFlight | "preflight") => void
}) {
  const safetyAssessmentLevel = score != null ? getAssessmentThreatScoreLevel(score) : undefined
  const safetyAssessmentColor = safetyAssessmentLevel != null ? AssessmentThreatColors[safetyAssessmentLevel] : undefined
  return (
    <>
      <Paper radius="lg" pt="sm" pb="md" px="lg" shadow="lg">
        <Flex justify="space-between" align="flex-start">
          <Box>
            <Title order={4} style={{ fontSize: "1.3rem" }}>
              Pre-flight
            </Title>
            <Title order={3} style={{ fontSize: "2.3rem" }}>
              Factors
            </Title>
          </Box>
          <Box />
        </Flex>
        <Divider mx="xl" mt="xl" mb="lg" />
        <Title order={5} mb="sm">
          Score ({score == null ? <Skeleton height={13} width={13} display="inline-block" mx="2px" /> : <Text span>{score}</Text>})
        </Title>
        {threats == null || score == null ? (
          <>
            <Skeleton height={8} radius="xl" mt="sm" />
            <Skeleton height={8} radius="xl" mt="sm" />
          </>
        ) : (
          <Group>
            <OverviewSafetyAssessmentGroup assessmentThreats={{ score, threats }} color={safetyAssessmentColor} />
          </Group>
        )}
        {/*
        <Flex align="center" justify="center" mt="xl">
          <Button variant="subtle" onClick={() => onExpandClick("preflight")}>
            View Details
          </Button>
        </Flex>
        */}
      </Paper>
    </>
  )
}
