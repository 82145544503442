import {
  Alert,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  NumberInput,
  Stack,
  Text,
  Textarea,
  createStyles,
  useMantineTheme,
} from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import {
  Aircraft,
  RESERVATION_SIGN_OUT_EVENT_NAME,
  Reservation,
  ReservationSignInEventDetails,
  ReservationSignInEventDetailsSchema,
  ReservationSignOutEvent,
} from "@soar/shared/types"
import { formatDate, getLatestSignOutEvent } from "@soar/shared/utils"
import { IconAlertCircle, IconCalendar, IconChevronRight, IconCurrentLocation, IconPlaneTilt } from "@tabler/icons-react"
import { ReactNode, useEffect, useState } from "react"
import { PlaneDisplay } from "../planeDisplay"
import { FlightLegsInput, generateEmptyFlightRoute } from "./flightLegsInput"

function SignOutRow({ icon, label, children }: { icon: ReactNode; children: ReactNode; label: string }) {
  return (
    <>
      <Flex align="stretch" fw="500">
        <Flex align="flex-start" w="35%" style={{ flexShrink: 0 }}>
          {icon}
          <Text ml="5px">{label}</Text>
        </Flex>
        <div style={{ flexGrow: 1 }}>{children}</div>
      </Flex>
      <Divider />
    </>
  )
}

const useStyles = createStyles((theme) => ({
  active: {
    borderColor: theme.colors.blue[5],
  },
  metricsInput: {
    fontSize: "24px",
    height: "50px",
    flexGrow: 1,
    flexShrink: 1,
    textAlign: "center",
    fontWeight: "bold",
  },
}))

const iconProps = {
  stroke: 2.5,
  height: 20,
}

export function ReservationSignInForm({
  aircraft,
  reservation,
  onSubmit = () => {},
}: { reservation: Reservation; aircraft: Aircraft; onSubmit?: (submittedValues: ReservationSignInEventDetails["details"]) => void }) {
  const theme = useMantineTheme()
  const { classes, cx } = useStyles()
  const form = useForm<ReservationSignInEventDetails["details"]>({
    validate: zodResolver(ReservationSignInEventDetailsSchema.shape.details),
    initialValues: {
      hobbs: aircraft.currentHobbs ?? 0,
      tach: aircraft.currentTach ?? 0,
      tach2: aircraft.currentTach2,
      discrepancies: "",
      remarks: "",
      legs: [generateEmptyFlightRoute()],
    },
  })

  const [signOutEvent, setSignOutEvent] = useState<ReservationSignOutEvent>()

  useEffect(() => {
    const signOutEvent = getLatestSignOutEvent(reservation)
    if (signOutEvent?.type === RESERVATION_SIGN_OUT_EVENT_NAME) {
      setSignOutEvent(signOutEvent)
      form.setValues({
        discrepancies: signOutEvent.details.discrepancies,
        legs: signOutEvent.details.legs,
        tach: signOutEvent.details.tach,
        tach2: signOutEvent.details.tach2,
        hobbs: signOutEvent.details.hobbs,
      })
    }
  }, [reservation])

  const hasSecondTach = parseInt(aircraft.numEngines ?? "0") >= 2

  return (
    <Box component="form" onSubmit={form.onSubmit((values) => onSubmit(values))}>
      <Stack spacing="md">
        <Stack spacing="xs" fz="smaller">
          <SignOutRow label="Date of flight" icon={<IconCalendar {...iconProps} />}>
            {formatDate(reservation.startTime, { date: { format: "long" } })}
          </SignOutRow>
          <SignOutRow label="Aircraft" icon={<IconPlaneTilt {...iconProps} />}>
            <PlaneDisplay plane={aircraft} variant="text" />
          </SignOutRow>
          <SignOutRow label="Flight route" icon={<IconCurrentLocation {...iconProps} />}>
            <FlightLegsInput form={form} />
          </SignOutRow>
        </Stack>
        <Grid align="center" columns={24}>
          <Grid.Col span={10}>
            <NumberInput
              label="Hobbs out"
              hideControls
              precision={1}
              value={signOutEvent?.details.hobbs}
              disabled
              inputMode="decimal"
              classNames={{
                input: cx(classes.metricsInput),
              }}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Center>
              <IconChevronRight size={30} style={{ marginTop: "20px" }} color={theme.colors.gray[5]} />
            </Center>
          </Grid.Col>
          <Grid.Col span={10}>
            <NumberInput
              label="Hobbs in"
              hideControls
              inputMode="decimal"
              precision={1}
              classNames={{
                input: cx(classes.active, classes.metricsInput),
              }}
              {...form.getInputProps("hobbs")}
            />
          </Grid.Col>
        </Grid>

        <Grid align="center" columns={24}>
          <Grid.Col span={10}>
            <NumberInput
              label="Tach 1 out"
              hideControls
              inputMode="decimal"
              precision={1}
              value={signOutEvent?.details.tach}
              disabled
              classNames={{
                input: cx(classes.metricsInput),
              }}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Center>
              <IconChevronRight size={30} style={{ marginTop: "20px" }} color={theme.colors.gray[5]} />
            </Center>
          </Grid.Col>
          <Grid.Col span={10}>
            <NumberInput
              label="Tach 1 in"
              hideControls
              inputMode="decimal"
              precision={1}
              classNames={{
                input: cx(classes.active, classes.metricsInput),
              }}
              {...form.getInputProps("tach")}
            />
          </Grid.Col>
        </Grid>
        {hasSecondTach && (
          <Grid align="center" columns={24}>
            <Grid.Col span={10}>
              <NumberInput
                label="Tach 2 out"
                hideControls
                inputMode="decimal"
                precision={1}
                value={signOutEvent?.details.tach2}
                disabled
                classNames={{
                  input: cx(classes.metricsInput),
                }}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <Center>
                <IconChevronRight size={30} style={{ marginTop: "20px" }} color={theme.colors.gray[5]} />
              </Center>
            </Grid.Col>
            <Grid.Col span={10}>
              <NumberInput
                label="Tach 2 in"
                hideControls
                inputMode="decimal"
                precision={1}
                classNames={{
                  input: cx(classes.active, classes.metricsInput),
                }}
                {...form.getInputProps("tach2")}
              />
            </Grid.Col>
          </Grid>
        )}

        <Textarea
          label={
            <Flex align="center">
              <IconAlertCircle size={20} />
              <Text ml="5px">Personal Remarks</Text>
            </Flex>
          }
          placeholder=""
          {...form.getInputProps("remarks")}
        />

        <Textarea
          label={
            <Flex align="center">
              <IconAlertCircle size={20} />
              <Text ml="5px">Aircraft discrepencies</Text>
            </Flex>
          }
          placeholder="Instrument training, visual approach to KMEV, radial inter."
          {...form.getInputProps("discrepancies")}
        />
      </Stack>

      <Button type="submit" mt="xl" w="100%">
        Finish sign in
      </Button>
    </Box>
  )
}
