import { Badge, MantineTheme, useMantineTheme } from "@mantine/core"
import { ReservationStatus } from "@soar/shared/types"
import { labelForReservationStatus } from "@soar/shared/utils"

export function getColorsForStatus(theme: MantineTheme, status: ReservationStatus) {
  if (status === "canceled") {
    return {
      base: "gray.8",
      from: theme.colors.gray[4],
      to: theme.colors.gray[8],
    }
  } else if (status === "completed") {
    return {
      base: "teal.7",
      from: theme.colors.cyan[4],
      to: theme.colors.lime[5],
    }
  } else if (status === "inProgress") {
    return {
      base: "indigo.6",
      from: theme.colors.indigo[4],
      to: theme.colors.indigo[6],
    }
  }
  return undefined
}

export function ReservationStatusBadge({ status }: { status: ReservationStatus | undefined }) {
  if (status == null) {
    return <div />
  }
  const theme = useMantineTheme()
  const colorForStatus = getColorsForStatus(theme, status)
  return (
    <Badge variant="light" color={colorForStatus?.base}>
      {labelForReservationStatus[status]}
    </Badge>
  )
}
