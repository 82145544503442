import { ActionIcon, Box, Button, Chip, Drawer, Flex, MediaQuery, SegmentedControl, Stack, Text, createStyles } from "@mantine/core"
import { IconCalendar, IconChevronLeft, IconChevronRight, IconFilter } from "@tabler/icons-react"
import { ReactNode } from "react"
//import { DatePicker } from "@mantine/dates"
import { DatePicker } from "./DatePicker"

import { useDisclosure } from "@mantine/hooks"
import { ScheduleFilter, ScheduleView } from "./schedule.interface"

const noop = () => {}
const useStyles = createStyles((theme) => ({
  hidden: {
    display: "none",
  },
  show: {
    display: "block",
  },
  hiddenLtMd: {
    display: "block",
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      display: "none",
    },
  },
  showGtMd: {
    display: "none",
    [`@media (min-width: ${theme.breakpoints.md})`]: {
      display: "block",
    },
  },
}))

interface IScheduleControlsProps {
  className?: string
  view?: ScheduleView
  onViewChange?: (view: ScheduleView) => void
  date?: Date
  isSelectedDate?: boolean
  onDateIncrement?: (amount: number) => void
  onDateChange?: (date: Date) => void
  onCurrentDateClick?: () => void
  filters?: ScheduleFilter[]
  onFiltersChange?: (filters: ScheduleFilter[]) => void
  renderLocationPicker?: () => ReactNode
}

export function ScheduleControls({
  className,
  view = "week",
  onViewChange = noop,
  date = new Date(),
  isSelectedDate = false,
  onDateIncrement = noop,
  onDateChange = noop,
  onCurrentDateClick = noop,
  filters = ["aircraft", "instructors"],
  onFiltersChange = noop,
  renderLocationPicker = () => <></>,
}: IScheduleControlsProps) {
  const { classes } = useStyles()
  const [opened, drawerHandlers] = useDisclosure(false)
  const nowButton = (
    <Button
      className={classes.hidden}
      variant={isSelectedDate ? "filled" : "outline"}
      radius="xl"
      onClick={() => {
        onCurrentDateClick()
      }}
    >
      Now
    </Button>
  )
  const filterDrawerButton = (
    <ActionIcon className={classes.hidden} onClick={drawerHandlers.toggle} color="blue.3">
      <IconFilter />
    </ActionIcon>
  )

  return (
    <Flex
      className={className}
      align={{ base: "stretch", xs: "center" }}
      justify="space-between"
      direction={{ base: "column", xs: "row" }}
      gap={{ base: "sm", xs: 0 }}
    >
      <Flex justify="space-between">
        <SegmentedControl
          value={view}
          color="blue"
          onChange={onViewChange}
          data={[
            { label: "Day", value: "day" },
            { label: "Week", value: "week" },
          ]}
        />
        <Flex align="center" gap="md">
          <MediaQuery smallerThan="xs" styles={{ display: "block" }}>
            {nowButton}
          </MediaQuery>
          <MediaQuery smallerThan="xs" styles={{ display: "block" }}>
            {filterDrawerButton}
          </MediaQuery>
        </Flex>
      </Flex>
      <Flex justify="space-between" align="center" gap="xs">
        <ActionIcon onClick={() => onDateIncrement(-1)} mr="xs">
          <IconChevronLeft />
        </ActionIcon>
        <DatePicker
          value={date}
          onChange={(selectedDate: Date | null) => {
            if (selectedDate != null) {
              onDateChange(selectedDate)
            }
          }}
          view={view}
        />
        <ActionIcon onClick={() => onDateIncrement(1)} ml="xs">
          <IconChevronRight />
        </ActionIcon>
        <MediaQuery largerThan="xs" styles={{ display: "block" }}>
          {nowButton}
        </MediaQuery>
      </Flex>
      <Flex align="center" gap="md">
        <MediaQuery smallerThan="md" styles={{ display: "none" }}>
          <Box>{renderLocationPicker()}</Box>
        </MediaQuery>
        <MediaQuery smallerThan="lg" styles={{ display: "none" }}>
          <Chip.Group multiple value={filters} onChange={onFiltersChange}>
            <Chip value="aircraft">Aircraft</Chip>
            <Chip value="instructors">Pilots</Chip>
          </Chip.Group>
        </MediaQuery>
        <MediaQuery smallerThan="lg" largerThan="xs" styles={{ display: "block" }}>
          {filterDrawerButton}
        </MediaQuery>
      </Flex>
      <Drawer position="right" onClose={drawerHandlers.close} opened={opened}>
        <Stack spacing="lg" px="sm">
          <MediaQuery smallerThan="md" styles={{ display: "block" }}>
            <Box className={classes.hidden}>
              <Text fw={700} mb="sm">
                Location:
              </Text>
              {renderLocationPicker()}
            </Box>
          </MediaQuery>
          <Box>
            <Text fw={700} mb="sm">
              Quick Filters:
            </Text>
            <Chip.Group multiple value={filters} onChange={onFiltersChange}>
              <Chip value="aircraft">Aircraft</Chip>
              <Chip value="instructors">Pilots</Chip>
            </Chip.Group>
          </Box>
        </Stack>
      </Drawer>
    </Flex>
  )
}
