import { CSSProperties, ReactElement, ReactNode } from "react"
import { IScheduleItemProps, ScheduleItem } from "./ScheduleItem"

import styles from "./Schedule.module.scss"

import { ISchedule } from "./schedule.interface"

export type renderRowHeaderElement = <TSchedule, TScheduleItem>(args: {
  index: number
  schedule: ISchedule<TSchedule, TScheduleItem>
}) => ReactNode

type renderBackgroundCellElement = <TScheduleSource, TScheduleItem>(args: {
  rowIndex: number
  columnIndex: number
  cellWidth: number
  schedule?: ISchedule<TScheduleSource, TScheduleItem>
}) => ReactNode

export interface IScheduleRowProps<TScheduleSource, TScheduleItem> {
  index: number
  min: number
  max: number
  rowHeaderWidth: number
  rowHeight: number
  scheduleWidth: number
  schedule: ISchedule<TScheduleSource, TScheduleItem>
  segmentWidth: number
  segmentCount: number
  renderScheduleItem?: IScheduleItemProps<TScheduleSource, TScheduleItem>["renderScheduleItem"]
  renderRowHeader?: renderRowHeaderElement
  renderBackgroundCell?: renderBackgroundCellElement
}

export function DefaultRowHeader<TScheduleSource, TScheduleItem>({
  schedule,
}: {
  schedule: ISchedule<TScheduleSource, TScheduleItem>
}) {
  const defaultStyle: CSSProperties = {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0 5px",
    fontWeight: "bold",
  }
  const styles = schedule.color ? { ...defaultStyle, backgroundColor: schedule.color, color: "#FFF" } : defaultStyle
  return <div style={styles}>{schedule.label}</div>
}

export function ScheduleRow<TScheduleSource, TScheduleItem>({
  index,
  min,
  max,
  rowHeaderWidth,
  rowHeight,
  scheduleWidth,
  schedule,
  renderScheduleItem,
  renderRowHeader,
  renderBackgroundCell,
  segmentWidth,
  segmentCount,
}: IScheduleRowProps<TScheduleSource, TScheduleItem>) {
  const rowBackgroundCells: ReactElement[] = []
  const shouldUseRenderBackroundCell = typeof renderBackgroundCell === "function"
  const backgroundCellCommonStyles: CSSProperties = {
    width: segmentWidth,
    zIndex: "0",
    height: "100%",
  }
  for (let i = 0; i < segmentCount; i++) {
    rowBackgroundCells.push(
      shouldUseRenderBackroundCell ? (
        <div style={{ ...backgroundCellCommonStyles }}>
          {renderBackgroundCell({
            rowIndex: index,
            columnIndex: i,
            cellWidth: segmentWidth,
            schedule: schedule,
          })}
        </div>
      ) : (
        <div key={i} style={{ ...backgroundCellCommonStyles, border: "thin solid #EEE" }} />
      ),
    )
  }

  const shouldUseRenderRowHeader = typeof renderRowHeader === "function"

  return (
    <div className={styles["schedule-row"]} style={{ height: `${rowHeight}px` }}>
      <div className={styles["schedule-row-header"]} style={{ width: rowHeaderWidth }}>
        {shouldUseRenderRowHeader ? renderRowHeader({ schedule, index }) : <DefaultRowHeader schedule={schedule} />}
      </div>
      <div style={{ width: rowHeaderWidth }} className={styles["schedule-row-header-placeholder"]} />
      <div className={styles["schedule-row-items-wrapper"]}>
        {rowBackgroundCells}

        {schedule.items.map((item, index) => (
          <ScheduleItem
            key={index}
            color={schedule.color}
            scheduleWidth={scheduleWidth}
            min={min}
            max={max}
            item={item}
            renderScheduleItem={renderScheduleItem}
            schedule={schedule}
          />
        ))}
      </div>
    </div>
  )
}
