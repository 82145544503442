import { Box, Button, Flex, NumberInput, Select, TextInput } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import {
  AircraftDetails,
  AircraftDetailsWithAvatar,
  AircraftDetailsWithAvatarSchema,
  EngineCounts,
  EngineTypes,
  Location,
} from "@soar/shared/types"
import { FileDetails } from "@soar/shared/types"
import { useEffect } from "react"

import { EditableAvatar } from "../avatar/"

export function EditAircraftForm({
  locations,
  initialValues,
  onSubmit = () => {},
  submitButtonText = "Create Aircraft",
  onClose = () => {},
}: {
  locations: Location[]
  initialValues?: AircraftDetails
  onSubmit?: (aircraftDetails: AircraftDetailsWithAvatar) => void
  onClose?: () => void
  submitButtonText?: string
}) {
  const form = useForm<AircraftDetailsWithAvatar>({
    validate: zodResolver(AircraftDetailsWithAvatarSchema),
    validateInputOnChange: true,
  })

  useEffect(() => {
    if (initialValues != null) {
      form.setValues(initialValues)
    }
  }, [initialValues])

  const locationOptions = locations.map((l) => ({ label: l.name, value: l.id }))
  const engineTypeOptions = EngineTypes.options.map((o) => ({ label: o, value: o }))
  const engineCountOptions = EngineCounts.options.map((o) => ({ label: o, value: o }))
  const showSecondTach = parseInt(form.values.numEngines ?? "0") >= 2

  const onAvatarChange = (avatarFile: FileDetails) => {
    form.setValues({ avatar: avatarFile })
  }

  return (
    <Box>
      <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
        <EditableAvatar value={form.values.avatarUrl} onChange={onAvatarChange} />
        <TextInput label="Tail Number" withAsterisk {...form.getInputProps("tailNumber")} />
        <Select label="Location" withAsterisk placeholder="Pick a location" data={locationOptions} {...form.getInputProps("locationId")} />
        <TextInput label="Manufacturer" withAsterisk {...form.getInputProps("manufacturer")} />
        <TextInput label="Model" withAsterisk {...form.getInputProps("model")} />
        <Select label="Engine Type" withAsterisk data={engineTypeOptions} {...form.getInputProps("engineType")} />
        <Select label="Engine Count" withAsterisk data={engineCountOptions} {...form.getInputProps("numEngines")} />
        <NumberInput label="Current Hobbs" precision={1} {...form.getInputProps("currentHobbs")} />
        <NumberInput label="Current Tach 1" precision={1} {...form.getInputProps("currentTach")} />
        {showSecondTach && <NumberInput label="Current Tach 2" precision={1} {...form.getInputProps("currentTach2")} />}

        <Flex justify="flex-end" mt="xl" pt="md" gap="sm">
          <Button onClick={onClose} variant="default">
            Cancel
          </Button>
          <Button type="submit" disabled={!form.isValid()}>
            {submitButtonText}
          </Button>
        </Flex>
      </form>
    </Box>
  )
}
