import { Box, Flex, Skeleton, Text, Title } from "@mantine/core"
import { Risk } from "@soar/shared/types"
import { commonBodyProps } from "../safety/utils"
import { RiskBadge } from "./RiskBadge"

export function WindSpeedDetails({
  speed,
  direction,
  gusts,
  loading,
  risk,
}: {
  speed?: number
  direction?: number | string
  gusts?: number
  loading: boolean
  risk?: Risk
}) {
  return (
    <Box>
      {risk != null ? (
        <RiskBadge severity={risk.severity} label={risk.label} size="md" showIcon={false} />
      ) : (
        <Title order={5} tt="uppercase">
          Wind
        </Title>
      )}
      {loading && <Skeleton radius="xl" height={20} />}
      {!loading && speed != null && direction != null && (
        <Flex gap="sm" align={{ md: "center", base: "flex-start" }} direction={{ base: "column", md: "row" }}>
          <Text {...commonBodyProps}>
            {typeof direction === "string" ? direction : `${Math.round(direction).toString().padStart(3, "0")}°`} at {Math.round(speed)}{" "}
            {gusts != null ? ` - ${Math.round(gusts)} ` : ""} kts
          </Text>
        </Flex>
      )}
    </Box>
  )
}
