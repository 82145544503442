import { z } from "zod"
import { BaseEventSchema } from "../BaseEvent"
import { ReservationLegSchema } from "./components"

export const RESERVATION_SIGN_OUT_EVENT_NAME = "RESERVATION_SIGN_OUT" as const
export const RESERVATION_SIGN_IN_EVENT_NAME = "RESERVATION_SIGN_IN" as const
export const RESERVATION_CANCELLATION_EVENT_NAME = "RESERVATION_CANCELLED" as const
export const RESERVATION_UPDATED_EVENT_NAME = "RESERVATION_UPDATED" as const

export const ReservationSignOutEventSchema = BaseEventSchema.extend({
  type: z.literal(RESERVATION_SIGN_OUT_EVENT_NAME),
  version: z.literal(1),
  parentType: z.literal("reservation").default("reservation").optional(),
  details: z.object({
    legs: ReservationLegSchema.array(),
    hobbs: z.number().min(0),
    tach: z.number().min(0),
    tach2: z.number().min(0).optional(),
    discrepancies: z.string(),
  }),
})

export const ReservationSignInEventSchema = BaseEventSchema.extend({
  type: z.literal(RESERVATION_SIGN_IN_EVENT_NAME),
  version: z.literal(1),
  parentType: z.literal("reservation").default("reservation").optional(),
  details: z.object({
    legs: ReservationLegSchema.array(),
    remarks: z.string().default(""),
    hobbs: z.number().min(0),
    tach: z.number().min(0),
    tach2: z.number().min(0).optional(),
    discrepancies: z.string(),
  }),
})

export const ReservationCancellationEventSchema = BaseEventSchema.extend({
  type: z.literal(RESERVATION_CANCELLATION_EVENT_NAME),
  version: z.literal(1),
  parentType: z.literal("reservation").default("reservation").optional(),
  details: z.object({
    reason: z.string().optional(),
  }),
})

export const ReservationUpdatedEventSchema = BaseEventSchema.extend({
  type: z.literal(RESERVATION_UPDATED_EVENT_NAME),
  version: z.literal(1),
  parentType: z.literal("reservation").default("reservation").optional(),
  details: z.object({
    reason: z.string().optional(),
    changed: z.record(
      z.object({
        oldValue: z.any(),
        newValue: z.any(),
      }),
    ),
  }),
})

export const ReservationEventSchema = z.union([
  ReservationSignOutEventSchema,
  ReservationSignInEventSchema,
  ReservationCancellationEventSchema,
])
export type ReservationEvent = z.infer<typeof ReservationEventSchema>

export const ReservationSignOutEventDetailsSchema = ReservationSignOutEventSchema.omit({ id: true })
export const ReservationSignInEventDetailsSchema = ReservationSignInEventSchema.omit({ id: true })
export const ReservationCancellationEventDetailsSchema = ReservationCancellationEventSchema.omit({ id: true })

export type ReservationSignInEvent = z.infer<typeof ReservationSignInEventSchema>
export type ReservationSignInEventDetails = z.infer<typeof ReservationSignInEventDetailsSchema>

export type ReservationSignOutEvent = z.infer<typeof ReservationSignOutEventSchema>
export type ReservationSignOutEventDetails = z.infer<typeof ReservationSignOutEventDetailsSchema>

export type ReservationCancellationEvent = z.infer<typeof ReservationCancellationEventSchema>
export type ReservationCancellationEventDetails = z.infer<typeof ReservationCancellationEventDetailsSchema>
