import { ClerkProvider } from "@clerk/nextjs"
import { MantineProvider } from "@mantine/core"
import { trpc } from "@soar/frontend/trpc-client"
import type { AppProps } from "next/app"
import Head from "next/head"
import Link from "next/link"
import posthog, { PostHogConfig } from "posthog-js"
import { PostHogProvider } from "posthog-js/react"
import { IntercomProvider } from "react-use-intercom"
// import { Auth0Provider } from "@auth0/auth0-react"
import SelectionsProvider from "../components/SelectionsProvider"

import AppInner from "../components/AppInner"
import Layout from "../components/Layout"

import "./styles.css"

import { DatesProvider } from "@mantine/dates"
import { Notifications } from "@mantine/notifications"
import { CustomLinkProvider, PAGE_BACKGROUND_COLOR, customThemeOverrides } from "@soar/frontend/components"
import { useSetAtom } from "jotai"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { intercomUnreadAtom } from "../components/IntercomLink"
import { generateTitle } from "../lib/title"

const isDevelopment = process.env.NODE_ENV === "development"
// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== "undefined" && process.env.NEXT_PUBLIC_POSTHOG_KEY != null) {
  const config: Partial<PostHogConfig> = isDevelopment
    ? {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST ?? "https://app.posthog.com",
        disable_session_recording: true,
        autocapture: false,
        capture_pageview: false,
        session_recording: {
          maskAllInputs: false,
        },
        // Enable debug mode in development
        loaded: (posthog) => {
          posthog.debug()
        },
      }
    : {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST ?? "https://app.posthog.com",
        session_recording: {
          maskAllInputs: false,
        },
      }

  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, config)
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const setIntercomUnreadCount = useSetAtom(intercomUnreadAtom)

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture("$pageview")
    router.events.on("routeChangeComplete", handleRouteChange)

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [])

  return (
    <PostHogProvider client={posthog}>
      <ClerkProvider
        appearance={{
          signIn: {
            variables: {
              colorPrimary: "#136DFB",
            },
          },
        }}
      >
        <IntercomProvider
          appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID ?? "INTERCOM_APP_ID_MISSING"}
          autoBoot
          autoBootProps={{
            customAttributes: {
              environment: process.env.NEXT_PUBLIC_ENV ?? "development",
            },
          }}
          onUnreadCountChange={(count) => {
            setIntercomUnreadCount(count)
          }}
        >
          <AppInner>
            <MantineProvider withGlobalStyles withNormalizeCSS theme={customThemeOverrides}>
              <DatesProvider settings={{ firstDayOfWeek: 0 }}>
                <CustomLinkProvider linkComponent={Link}>
                  <SelectionsProvider>
                    <Layout>
                      <Notifications />
                      <Head>
                        <meta
                          name="viewport"
                          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, viewport-fit=cover"
                        />
                        <title>{generateTitle()}</title>
                        <meta name="description" content="Soar - Flight Safety" />
                        <link rel="mask-icon" href="/appicons/glyph-soar-pure.svg" color="#FFFFFF" />
                        <meta name="apple-mobile-web-app-capable" content="yes" />
                        <meta name="theme-color" content={PAGE_BACKGROUND_COLOR} />
                        <link rel="apple-touch-icon" href="/appicons/maskable_icon.png" />
                        <link rel="apple-touch-icon" sizes="152x152" href="/appicons/maskable_icon_x152.png" />
                        <link rel="apple-touch-icon" sizes="180x180" href="/appicons/maskable_icon_x180.png" />
                        <link rel="apple-touch-icon" sizes="167x167" href="/appicons/maskable_icon_x167.png" />
                        <link rel="manifest" href="/manifest.json" />
                      </Head>
                      <Component {...pageProps} />
                    </Layout>
                  </SelectionsProvider>
                </CustomLinkProvider>
              </DatesProvider>
            </MantineProvider>
          </AppInner>
        </IntercomProvider>
      </ClerkProvider>
    </PostHogProvider>
  )
}

// export default MyApp
export default trpc.withTRPC(MyApp)
