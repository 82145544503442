import { ReactNode } from "react"
import styles from "./Schedule.module.scss"
import { ISchedule, IScheduleItem } from "./schedule.interface"

export interface renderScheduleItemElement<TSchedule, TScheduleItem> {
  (args: {
    start: number
    end: number
    item: IScheduleItem<TScheduleItem>
    schedule: ISchedule<TSchedule, TScheduleItem>
    color?: string
  }): ReactNode
}

export interface IScheduleItemProps<TSchedule, TScheduleItem> {
  min: number
  max: number
  scheduleWidth: number
  item: IScheduleItem<TScheduleItem>
  color?: string
  renderScheduleItem?: renderScheduleItemElement<TSchedule, TScheduleItem>
  schedule: ISchedule<TSchedule, TScheduleItem>
}

export function ScheduleItem<TSchedule, TScheduleItem>({
  min,
  max,
  scheduleWidth,
  item,
  color,
  renderScheduleItem,
  schedule,
}: IScheduleItemProps<TSchedule, TScheduleItem>) {
  const normalizedMax = max - min
  const start = ((item.from - min) / normalizedMax) * scheduleWidth
  const end = ((item.to - min) / normalizedMax) * scheduleWidth

  const defaultStyle = { left: start, right: scheduleWidth - end }

  const itemStyles = color ? { ...defaultStyle, backgroundColor: color, color: "#FFF" } : defaultStyle

  const hasRenderScheduleItem = renderScheduleItem != null
  return hasRenderScheduleItem ? (
    <div className={styles["schedule-item"]} style={defaultStyle}>
      {renderScheduleItem({
        start,
        end,
        item,
        color,
        schedule,
      })}
    </div>
  ) : (
    <div className={styles["schedule-item"]} style={itemStyles}>
      {item.label}
    </div>
  )
}
