import {
  RESERVATION_SIGN_IN_EVENT_NAME,
  RESERVATION_SIGN_OUT_EVENT_NAME,
  Reservation,
  ReservationEvent,
  ReservationStatus,
} from "@soar/shared/types"
import { getSortedReservationEvents } from "./sorts"

export const SIGN_OUT_EVENT_NAMES: string[] = [RESERVATION_SIGN_OUT_EVENT_NAME, RESERVATION_SIGN_IN_EVENT_NAME]
export function getLatestSignOutEvent(reservation: Reservation): ReservationEvent | undefined {
  const sortedEvents = getSortedReservationEvents(reservation)
  const signOutEvent = sortedEvents.find((event) => SIGN_OUT_EVENT_NAMES.includes(event.type))
  return signOutEvent
}

export const labelForReservationStatus: Record<ReservationStatus | "default", string> = {
  [ReservationStatus.enum.inProgress]: "In progress",
  [ReservationStatus.enum.canceled]: "Canceled",
  [ReservationStatus.enum.completed]: "Completed",
  default: "",
}

export function getStatusForReservation(reservation: Reservation): ReservationStatus | undefined {
  if (reservation.status != null) {
    return reservation.status
  }

  // TODO: Remove when we don't need this anymore
  const latestEvent = getLatestSignOutEvent(reservation)
  if (latestEvent?.type === "RESERVATION_SIGN_OUT") {
    return ReservationStatus.enum.inProgress
  } else if (latestEvent?.type === "RESERVATION_SIGN_IN") {
    return ReservationStatus.enum.completed
  }

  return undefined
}
