import { Box, Flex, Title } from "@mantine/core"
import type { ReactNode } from "react"

export function SectionHeader({
  title,
  timePeriod,
}: {
  title: string | ReactNode
  timePeriod: string | ReactNode
}) {
  return (
    <Flex justify="space-between" mb="md" align="center">
      <Title order={4} fz="md">
        {title}
      </Title>
      <Box c="dimmed" fz="sm">
        {timePeriod}
      </Box>
    </Flex>
  )
}
