import dayjs, { Dayjs } from "dayjs"

import advancedFormat from "dayjs/plugin/advancedFormat"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

export const DATE_FORMAT = "M/D" as const
export const DATE_FORMAT_LONG = "MMM D" as const
export const YEAR_FORMAT = "YYYY" as const
export const WEEKDAY_FORMAT = "ddd" as const
export const TIME_FORMAT_12H = "h:mma" as const
export const TIME_FORMAT_24H = "HH:mm" as const

export interface DateFormatOptions {
  date?:
    | {
        format?: "short" | "long"
        includeYear?: boolean
        includeWeekday?: boolean
      }
    | boolean
  time?: boolean | "12h" | "24h"
  timeFormatOverride?: "format_12h" | "format_24h"
  timeZone?: string
  includeTimezone?: boolean
  separator?: string
}

function getDateFormat(options: DateFormatOptions["date"]) {
  if (options === false || options === undefined) {
    return undefined
  } else if (options === true) {
    return {
      format: "short",
      includeYear: true,
      includeWeekday: false,
    }
  }
  const format = options.format ?? "short"
  const includeYear = options.includeYear ?? true
  const includeWeekday = options.includeWeekday ?? false

  return { format, includeYear, includeWeekday }
}

function getTimeFormat(options: DateFormatOptions["time"], override: DateFormatOptions["timeFormatOverride"]) {
  if (options === false || options === undefined) {
    return undefined
  }
  if (override != null) {
    return override.replace("format_", "") as "12h" | "24h"
  }
  if (options === true) {
    return "12h"
  }
  return options
}

/**
 * Format a date
 * Pass in true for `date` or `time` in formattingOptions for default
 * Specify a separator between date and time with `separator`
 * Defaults for date are `short`, withYear, and no weekday
 * Defaults for date are `12h`, withYear, and no weekday
 **/
export function formatDate(date: Date | Dayjs, formattingOptions: DateFormatOptions): string {
  const dateFormat = getDateFormat(formattingOptions.date)
  const timeFormat = getTimeFormat(formattingOptions.time, formattingOptions.timeFormatOverride)
  const hasTimezone = formattingOptions.timeZone != null
  const includeTimezone = formattingOptions.includeTimezone ?? hasTimezone
  const dateToFormat = hasTimezone ? dayjs(date).tz(formattingOptions.timeZone) : dayjs(date)

  const formats: string[] = []
  if (dateFormat != null) {
    let datePartFormat = ""
    if (dateFormat.includeWeekday) {
      datePartFormat = `${datePartFormat}${WEEKDAY_FORMAT} `
    }

    if (dateFormat.format === "short") {
      datePartFormat = datePartFormat + DATE_FORMAT
      if (dateFormat.includeYear) {
        datePartFormat = `${datePartFormat}/${YEAR_FORMAT}`
      }
    } else {
      datePartFormat = datePartFormat + DATE_FORMAT_LONG
      if (dateFormat.includeYear) {
        datePartFormat = `${datePartFormat}, ${YEAR_FORMAT}`
      }
    }

    formats.push(datePartFormat)
  }

  if (timeFormat != null) {
    if (timeFormat === "12h") {
      formats.push(TIME_FORMAT_12H)
    }
    if (timeFormat === "24h") {
      formats.push(TIME_FORMAT_24H)
    }
  }

  const format = formats.join(formattingOptions.separator ?? " ")
  const baseDateString = dateToFormat.format(format)
  if (includeTimezone && timeFormat != null) {
    const timezone = dateToFormat.format("z").toLowerCase()
    return `${baseDateString} ${timezone}`
  } else {
    return baseDateString
  }
}
