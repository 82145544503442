import {
  AssessmentThreatCutoffs,
  AssessmentThreatLevel,
  SafetyAssessmentRecordStatus,
  SafetyAssessmentRecordStatusEnum,
} from "@soar/shared/types"

const SafetyAssessmentRecordStatusLabels = {
  [SafetyAssessmentRecordStatusEnum.enum.created]: "Started",
  [SafetyAssessmentRecordStatusEnum.enum.finished]: "Finished",
  [SafetyAssessmentRecordStatusEnum.enum.upcoming]: "Upcoming",
}

export const WEIGHT_PER_PASSENGER = 190
export const WEIGHT_PER_LUGGAGE = 20

export function getSafetyAssessmentLabel(status?: SafetyAssessmentRecordStatus) {
  if (status != null && status in SafetyAssessmentRecordStatusLabels) {
    return SafetyAssessmentRecordStatusLabels[status]
  }
  return ""
}

export const DefaultAssessmentThreatCutoffs: Record<"overall" | "stageOfFlight", AssessmentThreatCutoffs> = {
  stageOfFlight: {
    orange: 10,
    red: 40,
  },
  overall: {
    orange: 10,
    red: 40,
  },
}

export function getAssessmentThreatScoreLevel(
  score: number,
  cutoffs: AssessmentThreatCutoffs = DefaultAssessmentThreatCutoffs.overall,
): AssessmentThreatLevel {
  if (score >= cutoffs.red) {
    return "red"
  } else if (score >= cutoffs.orange) {
    return "orange"
  } else {
    return "clear"
  }
}

export const AssessmentThreatColors: Record<AssessmentThreatLevel, string> = {
  clear: "#37B24D",
  red: "#F95349",
  orange: "#F88113",
} as const

export const AssessmentThreatLabels: Record<AssessmentThreatLevel, string> = {
  clear: "low",
  orange: "moderate",
  red: "high",
} as const
