import { Badge, Box, Flex, List, Paper, Popover, Skeleton, Space, Stack, Text, Title, TitleOrder } from "@mantine/core"
import { IntersectionResult, NewNotam, Notam, Risk, StageOfFlight, TimeIntersectionResult } from "@soar/shared/types"
import { ThreatColors, determineThreatCategory } from "@soar/shared/utils"
import { CSSProperties, ReactNode } from "react"
import { ActivityWindowDisplay, TimeIntersectionDisplay } from "../safety"
import { ThreatHeader } from "../safety/threatHeader"
import { useTimezone } from "../safety/timezoneContext"
import { SAFETY_BRIEFING_COLORS } from "./utils"
export { ThreatHeader as FocusedNotamHeader } from "../safety/threatHeader"

const transitionStyles: CSSProperties = {
  opacity: 0,
  animationName: "fadeIn",
  animationDuration: "0.9s",
  animationTimingFunction: "ease-out",
  animationFillMode: "forwards",
}
const defaultNotamStyle: CSSProperties = {
  width: "100%",
  overflowWrap: "break-word",
  inlineSize: "100%",
  whiteSpace: "pre-wrap",
}

export function NotamDisplay({
  notam,
  delay,
  rawMode = false,
  showMapped = false,
}: { notam: Notam; delay?: number; rawMode?: boolean; showMapped?: boolean }) {
  const styles: CSSProperties =
    delay == null ? defaultNotamStyle : { ...defaultNotamStyle, ...transitionStyles, animationDelay: `${delay * 0.01}s` }
  if (rawMode) {
    return (
      <Text fz={{ base: "sm", md: "md" }} style={styles}>
        {notam.text}
      </Text>
    )
  }
  return (
    <Popover width={400}>
      <Popover.Target>
        <Text span fz={{ base: "sm", md: "md" }} style={styles}>
          {notam.translation}
          {showMapped && notam.geometry?.mapped != null && notam.geometry.mapped && (
            <Badge mx="xs" styles={{ root: { backgroundColor: SAFETY_BRIEFING_COLORS.notam.base, color: "#FFF" } }}>
              On Map
            </Badge>
          )}
        </Text>
      </Popover.Target>
      <Popover.Dropdown>
        <Text>{notam.text} </Text>
      </Popover.Dropdown>
    </Popover>
  )
}

export function NewNotamDisplay({
  notam,
  delay,
  rawMode = false,
  showMapped = false,
  showExtended = false,
  timeIntersection,
  routeIntersection,
  stageOfFlight,
}: {
  notam: NewNotam
  delay?: number
  rawMode?: boolean
  showMapped?: boolean
  showExtended?: boolean
  timeIntersection?: TimeIntersectionResult
  routeIntersection?: IntersectionResult
  stageOfFlight?: StageOfFlight
}) {
  const { departureTimeZone } = useTimezone()
  const styles: CSSProperties =
    delay == null ? defaultNotamStyle : { ...defaultNotamStyle, ...transitionStyles, animationDelay: `${delay * 0.01}s` }

  const showIntersectionResult = timeIntersection != null && routeIntersection != null

  return (
    <Popover width={400} disabled={rawMode}>
      <Popover.Target>
        <Text span fz={{ base: "sm", md: "md" }} style={styles} c="gray.7">
          {notam.translation != null && !rawMode ? notam.translation : notam.text}
          {showMapped && notam.geojson != null && (
            <Badge mx="xs" styles={{ root: { backgroundColor: SAFETY_BRIEFING_COLORS.notam.base, color: "#FFF" } }}>
              On Map
            </Badge>
          )}

          {showExtended && (
            <Text c="black" mt="lg">
              {showIntersectionResult && (
                <TimeIntersectionDisplay
                  timeIntersection={timeIntersection}
                  intersectionTime={routeIntersection.intersectionTime}
                  stageOfFlight={stageOfFlight}
                />
              )}
              <Text>
                <Text span fw={600}>
                  Active:{" "}
                </Text>
                <Text span>
                  <ActivityWindowDisplay from={notam.effectiveStart} to={notam.effectiveEnd} timeZone={departureTimeZone ?? ""} />
                </Text>
              </Text>
            </Text>
          )}
        </Text>
      </Popover.Target>
      <Popover.Dropdown>
        <Text>
          {notam.translation == null && "Missing translation: "}
          {notam.text}
        </Text>
      </Popover.Dropdown>
    </Popover>
  )
}

export function NotamList({
  notams,
  newNotams,
  loading,
  rawMode = false,
  showMapped = false,
  risks,
  importantLabel,
  regularLabel,
}: {
  rawMode?: boolean
  notams?: Notam[]
  newNotams?: NewNotam[]
  loading: boolean
  showMapped?: boolean
  risks?: Risk[]
  importantLabel?: ReactNode
  regularLabel?: ReactNode
}) {
  const hideNotams = loading || newNotams == null
  if (hideNotams) {
    return (
      <Stack spacing="xs">
        <Skeleton radius="xl" height={10} />
        <Skeleton radius="xl" height={10} />
        <Skeleton radius="xl" height={10} />
        <Skeleton radius="xl" height={10} />
        <Skeleton radius="xl" height={10} />
        <Skeleton radius="xl" height={10} />
      </Stack>
    )
  }

  if (newNotams?.length === 0) {
    return <Text> None active </Text>
  }
  const { importantNotams, regularNotams } = newNotams.reduce(
    (notamMemo, notam) => {
      const risk = risks?.find((risk) => risk.sourceId === notam.id)
      if (risk != null) {
        return {
          importantNotams: [...notamMemo.importantNotams, { notam, risk }],
          regularNotams: notamMemo.regularNotams,
        }
      } else {
        return {
          importantNotams: notamMemo.importantNotams,
          regularNotams: [...notamMemo.regularNotams, notam],
        }
      }
    },
    { importantNotams: [] as { notam: NewNotam; risk: Risk }[], regularNotams: [] as NewNotam[] },
  )
  const sortedImportantNotams = importantNotams.sort((a, b) => b.risk.severity - a.risk.severity)

  return (
    <>
      {sortedImportantNotams.length > 0 && (
        <>
          {importantLabel}
          <Stack spacing={8} maw="100%" bg="#F2F7FF" px="sm" py="lg">
            {sortedImportantNotams.map(({ risk, notam }) => (
              <Paper key={notam.id} mb="sm" px="sm" py="md" radius="md" shadow="sm">
                <ThreatHeader label={risk.label} threatValue={risk.severity} order={6} />
                <NewNotamDisplay notam={notam} rawMode={rawMode} showMapped={showMapped} />
              </Paper>
            ))}
          </Stack>
        </>
      )}
      <Space h="xl" my="xs" />
      {regularLabel}
      <List spacing={20} maw="100%" styles={{ itemWrapper: { whiteSpace: "pre-wrap" } }} listStyleType="none" px="md" pb="xl">
        {regularNotams!.map((notam, index) => (
          <List.Item key={notam.id}>
            <NewNotamDisplay key={notam.id} notam={notam} delay={index} rawMode={rawMode} showMapped={showMapped} />
          </List.Item>
        ))}
      </List>
    </>
  )
}
