import type { MantineSize } from "@mantine/core"

const increasedMantineSizes: Record<MantineSize, MantineSize> = {
  xs: "sm",
  sm: "md",
  md: "lg",
  lg: "xl",
  xl: "xl",
}

export function increaseSize(size: MantineSize): MantineSize {
  return increasedMantineSizes[size]
}

const decreasedMantineSizes: Record<MantineSize, MantineSize | string> = {
  xs: "2px",
  sm: "xs",
  md: "sm",
  lg: "md",
  xl: "lg",
}

export function decreaseSize(size: MantineSize) {
  return decreasedMantineSizes[size]
}
