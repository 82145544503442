import { Box, Divider, Flex, Paper, Text, createStyles } from "@mantine/core"
import { usePreferences } from "@soar/frontend/contexts"
import { Aircraft, Location, Reservation, ReservationActions, ReservationTypes, TimeFormat } from "@soar/shared/types"
import {
  DateFormatOptions,
  formatDate,
  getStatusForReservation,
  isFieldRelevantForReservationType,
  isRegisteredUser,
} from "@soar/shared/utils"
import dayjs from "dayjs"
import { getReservationTypeColors } from "./colors"
import { ReservationActionMenu } from "./reservationActionMenu"
import { ReservationStatusBadge } from "./reservationStatusBadge"

const useStyles = createStyles((theme) => ({
  date: {
    borderRadius: theme.radius.md,
    backgroundColor: theme.colors.blue[0],
    borderLeft: "8px solid",
  },
  [`rtype-${ReservationTypes.enum.flight}`]: {
    backgroundColor: getReservationTypeColors(theme, ReservationTypes.enum.flight).lighter,
    borderLeftColor: getReservationTypeColors(theme, ReservationTypes.enum.flight).primary,
  },
  [`rtype-${ReservationTypes.enum.ground}`]: {
    backgroundColor: getReservationTypeColors(theme, ReservationTypes.enum.ground).lighter,
    borderLeftColor: getReservationTypeColors(theme, ReservationTypes.enum.ground).primary,
  },
  [`rtype-${ReservationTypes.enum.maintenance}`]: {
    backgroundColor: getReservationTypeColors(theme, ReservationTypes.enum.maintenance).lighter,
    borderLeftColor: getReservationTypeColors(theme, ReservationTypes.enum.maintenance).primary,
  },
  [`rtype-${ReservationTypes.enum.unavailable}`]: {
    backgroundColor: getReservationTypeColors(theme, ReservationTypes.enum.unavailable).lighter,
    borderLeftColor: getReservationTypeColors(theme, ReservationTypes.enum.unavailable).primary,
  },
  details: {
    flexGrow: 1,
  },
}))

export function ReservationListCard({
  reservation,
  aircraft,
  location,
  onCancelClick = () => {},
  onUpdateClick = () => {},
}: {
  reservation: Reservation
  aircraft?: Aircraft
  location?: Location
  onCancelClick?: (reservation: Reservation) => void
  onUpdateClick?: (reservation: Reservation) => void
}) {
  const { classes, cx } = useStyles()
  const { timeFormat: timeFormatPreference } = usePreferences()
  const startTime = dayjs(reservation.startTime)
  const endTime = dayjs(reservation.endTime)
  const endTimeSameDay = endTime.isBefore(startTime.endOf("day"))

  const reservationStatus = getStatusForReservation(reservation)
  const aircraftModel =
    aircraft?.model != null && aircraft?.manufacturer != null ? `${aircraft.manufacturer} ${aircraft.model}` : "Unknown airplane"
  const locationName = reservation.legs.at(0)?.from ?? location?.airportCode ?? location?.name?.slice(0, 4)?.toUpperCase()

  console.log("timeFormatPreference: ", timeFormatPreference)
  const timeFormat: DateFormatOptions = { time: true, timeFormatOverride: timeFormatPreference }
  const dayTimeFormat: DateFormatOptions = {
    date: { includeWeekday: true, includeYear: false },
    time: true,
    timeFormatOverride: timeFormatPreference,
  }

  return (
    <Paper shadow="xs" radius="sm">
      {" "}
      <Flex p="sm" align="stretch">
        <Flex
          className={cx(classes.date, classes[`rtype-${reservation.type}`])}
          ta="center"
          px="sm"
          mr="sm"
          direction="column"
          justify="space-between"
        >
          <Box>
            <Text fz="md">{startTime.format("MMM")}</Text>
            <Text fz="lg" lh="0.9" fw={700}>
              {startTime.format("DD")}
            </Text>
            <Text fz="sm">{startTime.format("YYYY")}</Text>
          </Box>
          <Text fw={600}>{locationName}</Text>
        </Flex>
        <Flex direction="column" justify="space-between" className={classes.details}>
          <Flex justify="space-between">
            <Text fw={600}>
              {formatDate(startTime, timeFormat)}
              {" - "}
              {formatDate(endTime, endTimeSameDay ? timeFormat : dayTimeFormat)}
            </Text>
            <Flex align="center" gap="sm">
              <ReservationStatusBadge status={reservationStatus} />
              <ReservationActionMenu
                reservation={reservation}
                disabledActions={[ReservationActions.enum.signOut]}
                onCancelClick={onCancelClick}
                onUpdateClick={onUpdateClick}
              />
            </Flex>
          </Flex>
          {reservation.users
            .map((user) => {
              const userObject = user.user
              const hasFirstLastName = isRegisteredUser(userObject)
              const isUndefined = userObject == null
              if (hasFirstLastName) {
                return `${userObject.firstName} ${userObject.lastName}`
              } else if (isUndefined) {
                return "Unknown"
              } else {
                return userObject.email
              }
            })
            .join(", ")}
          <Divider color="gray.1" my="xs" />

          <Flex justify="space-between">
            <Box>
              {isFieldRelevantForReservationType("aircraft", reservation.type) && (
                <>
                  <Text fw={500} span>
                    {aircraft?.tailNumber ?? "Unknown Tail Number"}
                  </Text>{" "}
                  <Text span>{aircraftModel}</Text>
                </>
              )}
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Paper>
  )
}
