import { z } from "zod"

const LocalStorageValidator = z.object({
  organizationId: z.string().nullish(),
  locationIds: z.string().array().nullish(),
  locationId: z.string().nullish(),
  profile: z
    .object({
      type: z.enum(["user", "organization"]),
      id: z.string(),
    })
    .nullish(),
})

type LocalStoragePayload = z.infer<typeof LocalStorageValidator>

const EMPTY_STORAGE_PAYLOAD = {} as const

const LOCAL_STORAGE_KEY = "soar-storage" as const

function checkForLocalStorage() {
  if (window?.localStorage == null) {
    throw "no localStorage found"
  }
}

export function grabFromLocalStorage(): LocalStoragePayload | null {
  try {
    checkForLocalStorage()
    const contents = localStorage.getItem(LOCAL_STORAGE_KEY)

    if (contents == null) {
      throw `nothing stored in ${LOCAL_STORAGE_KEY}`
    }

    const parsedContents = JSON.parse(contents)

    return LocalStorageValidator.parse(parsedContents)
  } catch {
    return null
  }
}

export function storeItems(payload: Partial<LocalStoragePayload>): boolean {
  try {
    checkForLocalStorage()

    const existingContents = grabFromLocalStorage()
    const baseStoragePayload = existingContents == null ? EMPTY_STORAGE_PAYLOAD : existingContents
    const storagePayload = { ...baseStoragePayload, ...payload }

    LocalStorageValidator.parse(storagePayload)

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(storagePayload))

    return true
  } catch (e) {
    return false
  }
}
