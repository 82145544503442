import { z } from "zod"

export const LocationSchema = z.object({
  id: z.string(),
  name: z.string().min(1),
  organizationId: z.string(),
  airportCode: z.string().min(3).nullish(),
})

export const LocationDetailsSchema = LocationSchema.omit({ id: true })

export type Location = z.infer<typeof LocationSchema>
export type LocationDetails = z.infer<typeof LocationDetailsSchema>
