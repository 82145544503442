import { Box, Chip, Flex, useMantineTheme } from "@mantine/core"
import { IconCrown, IconTrash, IconX } from "@tabler/icons-react"

export function PilotChip({ inCharge, name }: { inCharge?: boolean; name: string }) {
  const theme = useMantineTheme()
  return (
    <Flex align="center" gap={5}>
      <Flex
        align="center"
        sx={(theme) => ({
          borderRadius: `${theme.radius.lg}px`,
          border: `2px solid ${theme.colors.indigo[8]}`,
          backgroundColor: inCharge ? theme.colors.indigo[8] : theme.colors.indigo[1],
          color: inCharge ? theme.white : theme.colors.dark[8],
          padding: "3px 20px",
          width: "250px",
        })}
        gap="xs"
      >
        {inCharge && <IconCrown color={theme.white} stroke={3} />}
        {name}
      </Flex>
      <IconTrash size={20} />
    </Flex>
  )
}
