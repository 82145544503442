import { Aircraft, RegisteredUser } from "@soar/shared/types"
import { createContext } from "react"

interface ILocationObjectsCache {
  getUser: (userId: string) => RegisteredUser | undefined
  getAircraft: (aircraftId?: string | null) => Aircraft | undefined
  setUserEntries: (users: RegisteredUser[]) => void
  setAircraftEntries: (aircraft: Aircraft[]) => void
}

const aircraftCache = new Map<string, Aircraft>()
function getAircraftFromCache(id?: string | null): Aircraft | undefined {
  if (id == null) {
    return undefined
  }
  return aircraftCache.get(id)
}
function setAircraftEntries(aircraft: Aircraft[]) {
  for (const a of aircraft) {
    aircraftCache.set(a.id, a)
  }
}

const userCache = new Map<string, RegisteredUser>()
function getUserFromCache(id: string): RegisteredUser | undefined {
  return userCache.get(id)
}
function setUserEntries(users: RegisteredUser[]) {
  for (const u of users) {
    userCache.set(u.id, u)
  }
}

export const locationObjectsCacheContext = createContext<ILocationObjectsCache>({
  getUser: getUserFromCache,
  getAircraft: getAircraftFromCache,
  setUserEntries,
  setAircraftEntries,
})
