import * as ToggleGroup from "@radix-ui/react-toggle-group"
import { ReactNode } from "react"
import "./SegmentedControl.scss"

export type SegmentedControlOptions = Array<{
  value: string
  label: string | ReactNode
}>

export function SegmentedControl({
  options = [],
  defaultValue,
  value,
  onChange = () => {},
  variant,
}: {
  defaultValue?: string
  options: SegmentedControlOptions
  value?: string
  onChange?: (value: string) => void
  variant?: "pill"
}) {
  const variantClassname = variant != null ? `variant-${variant}` : ""
  const onValueChange = (value: string | undefined) => {
    if (value != null && value.length > 0) {
      onChange(value)
    }
  }
  return (
    <ToggleGroup.Root
      type="single"
      className={`SegmentedControl ${variantClassname}`}
      defaultValue={defaultValue}
      value={value}
      onValueChange={onValueChange}
    >
      {options.map((option) => (
        <ToggleGroup.Item className="SegmentedControlItem" value={option.value} key={option.value}>
          {option.label}
        </ToggleGroup.Item>
      ))}
    </ToggleGroup.Root>
  )
}
