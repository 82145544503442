import { ActionIcon, Box, Chip, CloseButton, Flex, Group, Portal, Stack, Text, Title, useMantineTheme } from "@mantine/core"
import { useElementSize, useMediaQuery } from "@mantine/hooks"
import {
  AirSigmet,
  AirportFlightMetrics,
  AirportResult,
  DataWithIntersections,
  FlightRule,
  GroupedAerodromeRisk,
  IEnrouteRisk,
  NewNotam,
  Pirep,
  Risk,
  SpecialUseAirspace,
} from "@soar/shared/types"
import { ThreatColors, determineThreatCategory, formatDate } from "@soar/shared/utils"
import { IconX } from "@tabler/icons-react"
import bbox from "@turf/bbox"
import booleanPointInPolygon from "@turf/boolean-point-in-polygon"
import buffer from "@turf/buffer"
import centerOfMass from "@turf/center-of-mass"
import greatCircle from "@turf/great-circle"
import { Coord, Feature, Point, feature as turfFeature, featureCollection, lineString, point, polygon } from "@turf/helpers"
import { Atom, WritableAtom, atom, useAtom, useAtomValue } from "jotai"
import maplibregl, { GeoJSONSource, MapMouseEvent, MarkerOptions } from "maplibre-gl"
import { ReactNode, useEffect, useMemo, useRef, useState } from "react"
import { MapComponent, MapRefPayload } from "../map"
import { ModalOrDrawer } from "../modalOrDrawer"
import { AirspaceDisplay } from "../safety/AirspaceDisplay"
import { PirepDisplay } from "../safety/PirepDisplay"
import { TimezoneContext, useTimezone } from "../safety/timezoneContext"
import { AirsigmetDisplay } from "./AirsigmetList"
import { MapFilterChip } from "./MapFilterChip"
import { FocusedNotamHeader, NewNotamDisplay } from "./NotamList"
import { SunsetSunriseDisplay } from "./SunsetSunriseDisplay"
import { getFlightRulesColor, useFlightRuleStyles } from "./useFlightRuleStyles"

const popupOptions: maplibregl.PopupOptions = { className: "soar-resultmap-popup", maxWidth: "none", anchor: "bottom" }
function generatePopupOptions(isSmallSize: boolean) {
  let maxWidth = "500px"
  if (isSmallSize) {
    maxWidth = "65vw"
  }
  return {
    ...popupOptions,
    maxWidth,
  }
}

function airSigmetToPolygon(airsigmet: AirSigmet) {
  if (airsigmet.coords.length >= 4) {
    return polygon([airsigmet.coords.map(({ lat, lon }) => [lon, lat])])
  }
  return null
}

function filterAirSigmetsAtPoint(
  airsigmets: DataWithIntersections<AirSigmet>[],
  point: Coord,
  riskAirsigmetMap: Map<string | number, Risk>,
  filters: Record<ThreatLevel, boolean>,
) {
  return airsigmets.filter(({ data: airsigmet }) => {
    const airsigmetPolygon = airSigmetToPolygon(airsigmet)
    const risk = riskAirsigmetMap.get(airsigmet.airSigmetId)
    const threatLevel = risk != null ? determineThreatCategory(risk?.severity) ?? "none" : "none"
    return airsigmetPolygon != null && booleanPointInPolygon(point, airsigmetPolygon) && filters[threatLevel]
  })
}

function createMarkerElement(color: string, icon: string, adjustments?: { marginTop?: number; imageSize?: string }) {
  const element = document.createElement("div")
  const image = document.createElement("img")
  const imageSize = adjustments?.imageSize ?? "18px"
  image.src = icon
  image.style.height = imageSize
  image.style.width = imageSize
  image.style.display = "block"
  if (adjustments?.marginTop != null) {
    image.style.marginTop = `${adjustments.marginTop}px`
  }
  element.style.color = "#FFF"
  element.style.width = "32px"
  element.style.height = "32px"
  element.style.backgroundColor = color
  element.style.borderRadius = "100%"
  element.style.border = `thin solid ${color}`
  element.style.boxShadow = "3px 3px 10px #666"
  element.style.display = "flex"
  element.style.alignItems = "center"
  element.style.justifyContent = "center"
  element.append(image)
  return element
}

function generateNotamMarker(notam: NewNotam, risk: Risk | undefined, color: string) {
  let markerSettings: MarkerOptions = { color }

  if (risk?.riskIdentifier === "flight_restriction") {
    const markerElement = createMarkerElement(color, "/airspace-restriction.svg")
    markerSettings = { element: markerElement }
  } else if (risk?.riskIdentifier === "active_moa") {
    const markerElement = createMarkerElement(color, "/noun-fighter-jet-39087.svg", { imageSize: "24px", marginTop: -2 })
    markerSettings = { element: markerElement }
  } else if (risk?.riskIdentifier === "active_restricted_airspace") {
    const markerElement = createMarkerElement(color, "/airspace-restriction.svg")
    markerSettings = { element: markerElement }
  }
  const marker = new maplibregl.Marker(markerSettings)
  return marker
}

type RiskObject = {
  type: "polygon" | "point"
  feature: Feature
  id: string
  risk: Risk | undefined
  marker?: (color: string) => maplibregl.Marker
  handler?: (map: maplibregl.Map, popup: maplibregl.Popup) => () => void
}

function addThreatMarkersToMap(riskObjects: RiskObject[], sourceId: string, color: string, map: maplibregl.Map, popup: maplibregl.Popup) {
  const markersOnly = riskObjects.filter((riskObject) => {
    return riskObject.type === "point"
  })
  const markers: maplibregl.Marker[] = []

  for (const markerRiskObject of markersOnly) {
    if (markerRiskObject.marker != null) {
      const marker = markerRiskObject.marker(color)
      marker.setLngLat((markerRiskObject.feature as Feature<Point>).geometry.coordinates as [number, number])
      if (markerRiskObject.handler != null) {
        const coreHandler = markerRiskObject.handler(map, popup)
        const handler = (e: MouseEvent) => {
          if (e != null) {
            e.stopPropagation()
          }
          coreHandler()
        }
        marker.getElement().addEventListener("click", handler)
      }
      marker.addTo(map)
      markers.push(marker)
    }
  }
  return markers
}
function addThreatPolygonsToMap(riskObjects: RiskObject[], sourceId: string, color: string, map: maplibregl.Map) {
  const polygons = riskObjects
    .filter((riskObject) => {
      return riskObject.type === "polygon"
    })
    .map((riskObject) => {
      return riskObject.feature
    })
  let source: maplibregl.GeoJSONSource | undefined = map.getSource(sourceId) as GeoJSONSource | undefined
  const geojson = featureCollection(polygons)

  if (source == null) {
    map.addSource(sourceId, {
      type: "geojson",
      data: geojson,
    })
    source = map.getSource(sourceId) as GeoJSONSource
  }

  //@ts-ignore
  source.setData(geojson)

  if (map.getLayer(sourceId) == null) {
    map.addLayer(
      {
        id: sourceId,
        type: "fill",
        source: sourceId,
        layout: {},
        paint: {
          "fill-color": color,
          "fill-opacity": 0.25,
        },
      },
      "route",
    )

    map.addLayer(
      {
        id: `${sourceId}-outline`,
        type: "line",
        source: sourceId,
        layout: {},
        paint: {
          "line-color": color,
          "line-width": 3,
        },
      },
      "route",
    )
  }
}

const infoBoxId = "soar-resultmap-infobox"
class CustomLayerControl {
  _map: maplibregl.Map | undefined
  _container: HTMLDivElement | undefined

  onAdd(map: maplibregl.Map) {
    this._map = map
    this._container = document.createElement("div")
    this._container.className = "soar-resultmap-infobox maplibregl-ctrl"
    this._container.id = infoBoxId
    return this._container
  }

  onRemove() {
    if (this._container != null && this._container.parentNode != null) {
      this._container.parentNode.removeChild(this._container)
    }
    this._map = undefined
  }
}
class CloseButtonControl {
  _map: maplibregl.Map | undefined
  _container: HTMLDivElement | undefined

  _onRender: (element: HTMLDivElement) => void
  constructor(onRender: (element: HTMLDivElement) => void) {
    this._onRender = onRender
  }

  onAdd(map: maplibregl.Map) {
    this._map = map
    this._container = document.createElement("div")
    this._container.className = "closeButton"
    this._container.id = "map-close-button"
    this._onRender(this._container)
    return this._container
  }

  onRemove() {
    if (this._container != null && this._container.parentNode != null) {
      this._container.parentNode.removeChild(this._container)
    }
    this._map = undefined
  }
}

const redFilterAtom = atom<boolean>(false)
const yellowFilterAtom = atom<boolean>(false)
const orangeFilterAtom = atom<boolean>(false)
const noneFilterAtom = atom<boolean>(false)
const someFilterSetAtom = atom<boolean>((get) => {
  const redSet = get(redFilterAtom)
  const yellowSet = get(yellowFilterAtom)
  const orangeSet = get(orangeFilterAtom)
  const noneSet = get(noneFilterAtom)
  const someSet = [redSet, yellowSet, orangeSet, noneSet].some((a) => a)
  return someSet
})
const generateShowFilterAtom = (baseAtom: WritableAtom<boolean, boolean[], unknown>) => {
  return atom(
    (get) => {
      const valueSet = get(baseAtom)
      const someSet = get(someFilterSetAtom)
      return someSet ? valueSet : true
    },
    (get, set, update: boolean) => {
      set(baseAtom, update)
    },
  )
}
const showRedAtom = generateShowFilterAtom(redFilterAtom)
const showOrangeAtom = generateShowFilterAtom(orangeFilterAtom)
const showYellowAtom = generateShowFilterAtom(yellowFilterAtom)
const showNoneAtom = generateShowFilterAtom(noneFilterAtom)

function filterHandler(layerName: string, value: boolean, markers: Array<maplibregl.Marker>, map: maplibregl.Map) {
  const visibility = value ? "visible" : "none"
  for (const marker of markers) {
    if (value) {
      marker.getElement().style.display = "flex"
      // marker.addTo(map)
    } else {
      marker.getElement().style.display = "none"
      // marker.remove()
    }
  }

  map.setLayoutProperty(layerName, "visibility", visibility)
  map.setLayoutProperty(`${layerName}-outline`, "visibility", visibility)
}

type ThreatLevel = "red" | "orange" | "yellow" | "none"

export function ResultMap({
  destination,
  departure,
  departureDetails,
  destinationDetails,
  enrouteNotams,
  opened = false,
  onClose = () => {},
  airSigmets,
  children,
  title,
  rawMode = false,
  timeZone,
  enrouteRisks,
  pireps,
}: {
  destination?: AirportResult
  departure?: AirportResult
  enrouteNotams?: DataWithIntersections<NewNotam>[]
  enrouteRisks?: IEnrouteRisk
  pireps?: DataWithIntersections<Pirep>[]
  opened?: boolean
  onClose?: () => void
  airSigmets?: DataWithIntersections<AirSigmet>[]
  children?: ReactNode
  title?: ReactNode
  rawMode?: boolean
  timeZone?: string
  departureDetails?: {
    time?: Date
    flightRules?: FlightRule
    flightMetrics?: AirportFlightMetrics
  }
  destinationDetails?: {
    time?: Date
    flightRules?: FlightRule
    flightMetrics?: AirportFlightMetrics
  }
}) {
  const ref = useRef<MapRefPayload>(null)
  const [isMapLoaded, setIsMapLoaded] = useState<boolean>(false)

  const theme = useMantineTheme()
  const isSmallSize = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const flightRulesStyles = useFlightRuleStyles()

  const timezoneContextValue = useTimezone()

  const [redFilter, setRedFilter] = useAtom(redFilterAtom)
  const [orangeFilter, setOrangeFilter] = useAtom(orangeFilterAtom)
  const [yellowFilter, setYellowFilter] = useAtom(yellowFilterAtom)
  const [noneFilter, setNoneFilter] = useAtom(noneFilterAtom)
  const someSet = useAtomValue(someFilterSetAtom)
  const showRedThreats = useAtomValue(showRedAtom)
  const showOrangeThreats = useAtomValue(showOrangeAtom)
  const showYellowThreats = useAtomValue(showYellowAtom)
  const showNoneThreats = useAtomValue(showNoneAtom)

  const load = (map: maplibregl.Map) => {
    setIsMapLoaded(true)

    map.addControl(new CustomLayerControl(), "top-left")
    map.addControl(new CloseButtonControl(() => {}), "top-right")
    if (!isSmallSize) {
      map.addControl(new maplibregl.NavigationControl(), "top-right")
    }

    if (destination != null && departure != null) {
      if (map.getLayer("route") != null) {
        map.removeLayer("route")

        map.removeSource("route")
      }
      const isLocalFlight = departure != null && departure.id === destination?.id

      const route = isLocalFlight
        ? buffer(point([departure.longitude, departure.latitude]), 100 * 1.15078, { units: "miles" })
        : greatCircle([departure.longitude, departure.latitude], [destination.longitude, destination.latitude])

      map.addSource("route", {
        type: "geojson",
        data: route,
      })

      map.addLayer({
        id: "route",
        type: "line",
        source: "route",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "#12b886",
          "line-width": 5,
        },
      })

      const departureColor = getFlightRulesColor(departureDetails?.flightRules, theme)
      const departureElement = createMarkerElement(departureColor ?? "#333", "/takeoff.svg")
      const departureMarker = new maplibregl.Marker({
        element: departureElement,
      }).setLngLat([departure.longitude, departure.latitude])

      departureMarker.addTo(map)

      departureMarker.getElement().addEventListener("click", (e) => {
        if (departureDetailsPopupRef.current != null) {
          const _popup = new maplibregl.Popup(generatePopupOptions(isSmallSize))
            .setDOMContent(departureDetailsPopupRef.current)
            .setLngLat([departure.longitude, departure.latitude])
            .addTo(map)
        }
        e.stopPropagation()
      })

      if (isLocalFlight) {
        const boundingBox = bbox(route)
        const bounds = new maplibregl.LngLatBounds([boundingBox[0], boundingBox[1]], [boundingBox[2], boundingBox[3]])
        map.fitBounds(bounds, {
          padding: 80,
        })
      } else {
        map.fitBounds(
          new maplibregl.LngLatBounds([destination.longitude, destination.latitude], [departure.longitude, departure.latitude]),
          {
            padding: 80,
          },
        )

        const destinationColor = getFlightRulesColor(destinationDetails?.flightRules, theme)
        const destinationElement = createMarkerElement(destinationColor ?? "#333", "/landing.svg")
        const destinationMarker = new maplibregl.Marker({
          element: destinationElement,
        }).setLngLat([destination.longitude, destination.latitude])

        destinationMarker.addTo(map)

        destinationMarker.getElement().addEventListener("click", (e) => {
          if (destinationDetailsPopupRef.current != null) {
            const _popup = new maplibregl.Popup(generatePopupOptions(isSmallSize))
              .setDOMContent(destinationDetailsPopupRef.current)
              .setLngLat([destination.longitude, destination.latitude])
              .addTo(map)
          }
          e.stopPropagation()
        })
      }
    }
  }
  const { riskNotamMap, riskPirepMap, riskAirsigmetMap, riskNone, riskYellow, riskOrange, riskRed, riskCount } = useMemo(() => {
    const allRisks: RiskObject[] = []
    const riskNone: RiskObject[] = []
    const riskYellow: RiskObject[] = []
    const riskOrange: RiskObject[] = []
    const riskRed: RiskObject[] = []

    const riskCount: Record<ThreatLevel, number> = {
      red: 0,
      orange: 0,
      yellow: 0,
      none: 0,
    }

    const riskNotamMap = new Map<string | number, Risk>()
    const riskPirepMap = new Map<string | number, Risk>()
    const riskAirsigmetMap = new Map<string | number, Risk>()

    for (const risk of enrouteRisks?.notamRisks ?? []) {
      if (risk.sourceId != null) {
        riskNotamMap.set(risk.sourceId, risk)
      }
    }

    for (const risk of enrouteRisks?.pirepsRisks ?? []) {
      if (risk.sourceId != null) {
        riskPirepMap.set(risk.sourceId, risk)
      }
    }

    for (const risk of enrouteRisks?.airSigmetRisks ?? []) {
      if (risk.sourceId != null) {
        riskAirsigmetMap.set(risk.sourceId, risk)
      }
    }

    for (const airSigmet of airSigmets ?? []) {
      const risk = riskAirsigmetMap.get(airSigmet.data.airSigmetId)
      if (risk != null) {
        const feature = airSigmetToPolygon(airSigmet.data)
        const type = "polygon"

        if (feature == null) {
          continue
        }

        const riskObject: RiskObject = {
          type,
          feature,
          risk,
          id: `airsigmet-${airSigmet.data.airSigmetId}`,
        }
        const threatLevel = determineThreatCategory(risk.severity) ?? "none"
        riskCount[threatLevel] = riskCount[threatLevel] + 1
        allRisks.push(riskObject)
      }
    }
    for (const notamWithIntersection of enrouteNotams ?? []) {
      const notam = notamWithIntersection.data
      const risk = riskNotamMap.get(notam.id)

      if (notam.geojson != null) {
        const feature = notam.geojson.type === "Feature" ? notam.geojson : turfFeature(notam.geojson)
        const type = "polygon"

        const riskObject: RiskObject = {
          type,
          risk,
          feature: feature as Feature,
          id: `notam-${notam.id}`,
        }

        const center = centerOfMass(notam.geojson)
        const marker = (color: string) => generateNotamMarker(notam, risk, color)

        const markerHandlerFactory = (map: maplibregl.Map, popup: maplibregl.Popup) => () => {
          setSelectedNotam(notamWithIntersection)
          if (notam.geojson == null) {
            return
          }
          if (selectedNotamPopupRef.current != null) {
            const coord = center
            popup.setDOMContent(selectedNotamPopupRef.current).setLngLat(coord.geometry.coordinates as [number, number])
            popup.addTo(map)
          }
        }

        const markerRiskObject: RiskObject = {
          type: "point",
          feature: center,
          risk,
          id: `notam-marker-${notam.id}`,
          marker,
          handler: markerHandlerFactory,
        }

        const threatLevel = risk != null ? determineThreatCategory(risk.severity) ?? "none" : "none"
        riskCount[threatLevel] = riskCount[threatLevel] + 1

        allRisks.push(riskObject)
        allRisks.push(markerRiskObject)
      }
    }

    const cleanupFunctions = (pireps ?? []).map((pirepWithIntersections) => {
      const pirep = pirepWithIntersections.data
      const pirepCoord = point([pirep.lng, pirep.lat])

      const handler = (currentMap: maplibregl.Map, popup: maplibregl.Popup) => () => {
        setSelectedPirep(pirepWithIntersections)
        if (pirepPopupRef.current != null) {
          popup.setDOMContent(pirepPopupRef.current).setLngLat([pirep.lng, pirep.lat]).addTo(currentMap)
        }
      }
      const marker = (color: string) => {
        const markerElement = createMarkerElement(color, "/person_FILL1_wght400_GRAD0_opsz24.svg", { marginTop: -5 })
        const marker = new maplibregl.Marker({ element: markerElement })
        return marker
      }

      const risk = riskPirepMap.get(pirep.pirepId)
      const markerRiskObject: RiskObject = {
        type: "point",
        feature: pirepCoord,
        id: `pirep-${pirep.pirepId}`,
        risk,
        marker,
        handler,
      }

      const threatLevel = risk != null ? determineThreatCategory(risk.severity) ?? "none" : "none"
      riskCount[threatLevel] = riskCount[threatLevel] + 1

      allRisks.push(markerRiskObject)
    })

    for (const aerodromeRiskWithIntersectionResult of enrouteRisks?.aerodromeRisks ?? []) {
      const aerodromeRisk = aerodromeRiskWithIntersectionResult.data
      if (aerodromeRisk.aerodrome != null) {
        const { notam, aerodrome, risk } = aerodromeRisk
        const marker = (color: string) => {
          const element = createMarkerElement(color, "/no-entry.svg")
          const marker = new maplibregl.Marker({ element })
          return marker
        }

        const handler = (map: maplibregl.Map, popup: maplibregl.Popup) => () => {
          setAerodromeRisk(aerodromeRiskWithIntersectionResult)
          if (aerodromePopupRef.current != null) {
            popup.setDOMContent(aerodromePopupRef.current).setLngLat([aerodrome.longitude, aerodrome.latitude]).addTo(map)
          }
        }

        const markerRiskObject: RiskObject = {
          type: "point",
          feature: point([aerodrome.longitude, aerodrome.latitude]),
          id: `notam-${notam.id}`,
          risk,
          marker,
          handler,
        }

        const threatLevel = risk != null ? determineThreatCategory(risk.severity) ?? "none" : "none"
        riskCount[threatLevel] = riskCount[threatLevel] + 1

        allRisks.push(markerRiskObject)
      }
    }

    for (const airspace of enrouteRisks?.airspace ?? []) {
      const airspaceDetails = airspace.data
      const polygon = JSON.parse(airspaceDetails.airspace.geography)
      const center = centerOfMass(polygon)
      const riskObject: RiskObject = {
        type: "polygon",
        risk: airspaceDetails.risks[0],
        feature: turfFeature(polygon) as Feature,
        id: `airspace-${airspaceDetails.airspace.id}`,
      }

      const marker = (color: string) => {
        const element = createMarkerElement(color, "/airspace-restriction.svg")
        const marker = new maplibregl.Marker({ element })
        return marker
      }

      const handler = (map: maplibregl.Map, popup: maplibregl.Popup) => () => {
        setSelectedAirspace({
          data: {
            airspace: airspaceDetails.airspace,
            risk: airspaceDetails.risks[0],
            notams: airspaceDetails.notams,
          },
          timeIntersection: airspace.timeIntersection,
          routeIntersection: airspace.routeIntersection,
        })
        if (airspaceDetailsPopupRef.current != null) {
          popup
            .setDOMContent(airspaceDetailsPopupRef.current)
            .setLngLat([center.geometry.coordinates[0], center.geometry.coordinates[1]])
            .addTo(map)
        }
      }

      const riskMarkerObject: RiskObject = {
        type: "point",
        risk: airspaceDetails.risks[0],
        feature: center,
        id: `airspace-marker-${airspaceDetails.airspace.id}`,
        marker,
        handler,
      }

      allRisks.push(riskObject)
      allRisks.push(riskMarkerObject)
      const risk = airspace.data.risks[0]
      const threatLevel = risk != null ? determineThreatCategory(risk.severity) ?? "none" : "none"
      riskCount[threatLevel] = riskCount[threatLevel] + 1
    }

    for (const riskObject of allRisks) {
      const severityClass = determineThreatCategory(riskObject.risk?.severity ?? 0)
      if (severityClass === "red") {
        riskRed.push(riskObject)
      } else if (severityClass === "orange") {
        riskOrange.push(riskObject)
      } else if (severityClass === "yellow") {
        riskYellow.push(riskObject)
      } else {
        riskNone.push(riskObject)
      }
    }

    return {
      riskNotamMap,
      riskPirepMap,
      riskAirsigmetMap,
      riskRed,
      riskOrange,
      riskYellow,
      riskNone,
      riskCount,
    }
  }, [enrouteRisks, airSigmets, enrouteNotams, pireps])

  const [allMarkers, setAllMarkers] = useState<Record<"red" | "yellow" | "orange" | "none", Array<maplibregl.Marker>>>({
    red: [],
    orange: [],
    yellow: [],
    none: [],
  })
  useEffect(() => {
    let localMarkers: maplibregl.Marker[] = []
    if (isMapLoaded) {
      const currentMap = ref.current?.map!
      const popup = new maplibregl.Popup(generatePopupOptions(isSmallSize))

      addThreatPolygonsToMap(riskNone, "threat-none", "#BBB", currentMap)
      const noneMarkers = addThreatMarkersToMap(riskNone, "threat-none", "#BBB", currentMap, popup)

      addThreatPolygonsToMap(riskYellow, "threat-yellow", ThreatColors["yellow"], currentMap)
      const yellowMarkers = addThreatMarkersToMap(riskYellow, "threat-yellow", ThreatColors["yellow"], currentMap, popup)

      addThreatPolygonsToMap(riskOrange, "threat-orange", ThreatColors["orange"], currentMap)
      const orangeMarkers = addThreatMarkersToMap(riskOrange, "threat-orange", ThreatColors["orange"], currentMap, popup)

      addThreatPolygonsToMap(riskRed, "threat-red", ThreatColors["red"], currentMap)
      const redMarkers = addThreatMarkersToMap(riskRed, "threat-red", ThreatColors["red"], currentMap, popup)
      localMarkers = noneMarkers.concat(yellowMarkers).concat(orangeMarkers).concat(redMarkers)

      setAllMarkers({
        red: redMarkers,
        yellow: yellowMarkers,
        orange: orangeMarkers,
        none: noneMarkers,
      })
    }
    return () => {
      for (const marker of localMarkers) {
        marker.remove()
      }
    }
  }, [riskNone, riskYellow, riskOrange, riskRed, ref, isMapLoaded])

  useEffect(() => {
    if (isMapLoaded && airSigmets != null) {
      const currentMap = ref.current?.map!

      const popup = new maplibregl.Popup(generatePopupOptions(isSmallSize))
      const showThreatFilters = {
        none: showNoneThreats,
        yellow: showYellowThreats,
        orange: showOrangeThreats,
        red: showRedThreats,
      }
      const clickHandler = (e: MapMouseEvent) => {
        e.originalEvent.stopPropagation()
        const allSigmets = filterAirSigmetsAtPoint(airSigmets, [e.lngLat.lng, e.lngLat.lat], riskAirsigmetMap, showThreatFilters)
        if (allSigmets.length > 0) {
          setSelectedAirsigmets(allSigmets)
          if (selectedAirsigmetsPopupRef.current != null) {
            popup.setDOMContent(selectedAirsigmetsPopupRef.current).setLngLat(e.lngLat).addTo(currentMap)
          }
        }
      }

      currentMap.on("click", clickHandler)
      return () => {
        popup.remove()
        currentMap.off("click", clickHandler)
      }
    }
    return () => {}
  }, [airSigmets, enrouteRisks, riskAirsigmetMap, isMapLoaded, ref, showYellowThreats, showOrangeThreats, showRedThreats, showNoneThreats])

  useEffect(() => {
    if (!opened) {
      setIsMapLoaded(false)
      setRedFilter(false)
      setOrangeFilter(false)
      setYellowFilter(false)
      setNoneFilter(false)
      // console.log("unload")
    }
  }, [opened])

  useEffect(() => {
    if (isMapLoaded) {
      const map = ref.current?.map!
      const layerName = "threat-red"
      const value = showRedThreats
      const markers = allMarkers.red
      filterHandler(layerName, value, markers, map)
    }
  }, [showRedThreats, isMapLoaded])

  useEffect(() => {
    if (isMapLoaded) {
      const map = ref.current?.map!
      const layerName = "threat-orange"
      const value = showOrangeThreats
      const markers = allMarkers.orange
      filterHandler(layerName, value, markers, map)
    }
  }, [showOrangeThreats, isMapLoaded])

  useEffect(() => {
    if (isMapLoaded) {
      const map = ref.current?.map!
      const layerName = "threat-yellow"
      const value = showYellowThreats
      const markers = allMarkers.yellow
      filterHandler(layerName, value, markers, map)
    }
  }, [showYellowThreats, isMapLoaded])

  useEffect(() => {
    if (isMapLoaded) {
      const map = ref.current?.map!
      const layerName = "threat-none"
      const value = showNoneThreats
      const markers = allMarkers.none
      filterHandler(layerName, value, markers, map)
    }
  }, [showNoneThreats, isMapLoaded])

  const aerodromePopupRef = useRef<HTMLDivElement>(null)
  const selectedAirsigmetsPopupRef = useRef<HTMLDivElement>(null)
  const selectedNotamPopupRef = useRef<HTMLDivElement>(null)
  const pirepPopupRef = useRef<HTMLDivElement>(null)
  const departureDetailsPopupRef = useRef<HTMLDivElement>(null)
  const destinationDetailsPopupRef = useRef<HTMLDivElement>(null)
  const airspaceDetailsPopupRef = useRef<HTMLDivElement>(null)

  const [aerodromeRisk, setAerodromeRisk] = useState<DataWithIntersections<GroupedAerodromeRisk>>()
  const [selectedAirsigmets, setSelectedAirsigmets] = useState<DataWithIntersections<AirSigmet>[]>()
  const [selectedNotam, setSelectedNotam] = useState<DataWithIntersections<NewNotam>>()
  const [selectedPirep, setSelectedPirep] = useState<DataWithIntersections<Pirep>>()
  const [selectedAirspace, setSelectedAirspace] =
    useState<DataWithIntersections<{ risk: Risk; airspace: SpecialUseAirspace; notams: DataWithIntersections<NewNotam>[] }>>()

  const selectedNotamRisk = selectedNotam != null ? riskNotamMap.get(selectedNotam.data.id) : null
  const selectedPirepRisk = selectedPirep != null ? riskPirepMap.get(selectedPirep.data.pirepId) : null
  const infoBoxElement = document.getElementById(infoBoxId)
  const mapCloseButtonElement = document.getElementById("map-close-button")

  return (
    <ModalOrDrawer
      opened={opened}
      onClose={onClose}
      modalProps={{
        size: "xl",
        fullScreen: true,
        withCloseButton: false,
        styles: {
          body: {
            padding: "0px",
          },
          header: { justifyContent: "space-between", flexDirection: "row-reverse", alignItems: "flex-start" },
          title: { width: "100%" },
          close: { margin: "5px 15px 0 30px" },
        },
      }}
      drawerProps={{
        size: "100%",
        withCloseButton: false,
        styles: {
          body: {
            padding: "0px",
          },
          header: { justifyContent: "space-between", alignItems: "flex-start" },
          title: { width: "100%" },
          close: { margin: "5px 15px 0 30px" },
        },
      }}
    >
      <MapComponent ref={ref} height="100vh" onLoad={load} />
      <Box display="none">
        {mapCloseButtonElement != null && (
          <Portal target={mapCloseButtonElement}>
            <div className="maplibregl-ctrl">
              <ActionIcon onClick={onClose} variant="subtle">
                <Flex justify="center" align="center">
                  <IconX height={15} color={theme.colors.gray[7]} stroke={4} />
                </Flex>
              </ActionIcon>
            </div>
          </Portal>
        )}
        {infoBoxElement != null && (
          <Portal target={infoBoxElement}>
            <Flex direction={isSmallSize ? "column" : "row"} gap="sm" align="flex-start">
              <div id="info-box">{children}</div>
              <Flex gap={isSmallSize ? "xs" : "sm"}>
                {riskCount.red > 0 && (
                  <MapFilterChip checked={redFilter} onChange={setRedFilter} backgroundColor={ThreatColors.red} color="#FFF">
                    {riskCount.red}
                  </MapFilterChip>
                )}
                {riskCount.orange > 0 && (
                  <MapFilterChip checked={orangeFilter} onChange={setOrangeFilter} backgroundColor={ThreatColors.orange} color="#FFF">
                    {riskCount.orange}
                  </MapFilterChip>
                )}
                {riskCount.yellow > 0 && (
                  <MapFilterChip checked={yellowFilter} onChange={setYellowFilter} backgroundColor={ThreatColors.yellow} color="#FFF">
                    {riskCount.yellow}
                  </MapFilterChip>
                )}
                {riskCount.none > 0 && (
                  <MapFilterChip checked={noneFilter} onChange={setNoneFilter} backgroundColor="#BBB" color="#FFF">
                    {riskCount.none}
                  </MapFilterChip>
                )}
              </Flex>
            </Flex>
          </Portal>
        )}
        <div ref={pirepPopupRef}>
          {selectedPirepRisk != null ? (
            <FocusedNotamHeader label={selectedPirepRisk.label} threatValue={selectedPirepRisk.severity} order={6} />
          ) : (
            <Title order={5} mb="xs">
              PIREP
            </Title>
          )}
          {selectedPirep != null && <PirepDisplay pirep={selectedPirep.data} rawMode={rawMode} />}
        </div>
        <div ref={selectedNotamPopupRef}>
          {selectedNotamRisk != null ? (
            <FocusedNotamHeader label={selectedNotamRisk.label} threatValue={selectedNotamRisk.severity} order={6} />
          ) : (
            <Title order={5} mb="xs">
              NOTAM
            </Title>
          )}
          {selectedNotam != null && (
            <NewNotamDisplay
              notam={selectedNotam.data}
              rawMode={rawMode}
              timeIntersection={selectedNotam.timeIntersection}
              routeIntersection={selectedNotam.routeIntersection}
              showExtended={true}
              stageOfFlight="enroute"
            />
          )}
        </div>

        <div ref={selectedAirsigmetsPopupRef}>
          {selectedAirsigmets != null && (
            <Stack spacing="lg">
              {selectedAirsigmets.map((airsigmetWithIntersection) => {
                const id = airsigmetWithIntersection.data.airSigmetId
                const risk = riskAirsigmetMap.get(id)
                return (
                  <Text key={id} fz="md">
                    {risk != null && <FocusedNotamHeader label={risk.label} threatValue={risk.severity} order={6} />}
                    <AirsigmetDisplay rawMode={rawMode} airsigmet={airsigmetWithIntersection} stageOfFlight="enroute" />
                  </Text>
                )
              })}
            </Stack>
          )}
        </div>
        <div ref={aerodromePopupRef}>
          {aerodromeRisk != null && (
            <>
              <FocusedNotamHeader label={aerodromeRisk.data.risk.label} threatValue={aerodromeRisk.data.risk.severity} order={6} />
              <NewNotamDisplay
                key={aerodromeRisk.data.notam.id}
                notam={aerodromeRisk.data.notam}
                rawMode={rawMode}
                showExtended={true}
                timeIntersection={aerodromeRisk.timeIntersection}
                routeIntersection={aerodromeRisk.routeIntersection}
                stageOfFlight="enroute"
              />
            </>
          )}
        </div>
        <div ref={airspaceDetailsPopupRef}>
          {selectedAirspace != null &&
            (selectedAirspace.data.notams.length > 0 ? (
              <Stack fz="md">
                {selectedAirspace.data.notams.map((notam) => {
                  return (
                    <Box key={notam.data.id}>
                      <FocusedNotamHeader
                        label={selectedAirspace.data.risk.label}
                        threatValue={selectedAirspace.data.risk.severity}
                        order={6}
                      />
                      <NewNotamDisplay
                        key={notam.data.id}
                        notam={notam.data}
                        rawMode={rawMode}
                        showExtended={true}
                        timeIntersection={notam.timeIntersection}
                        routeIntersection={notam.routeIntersection}
                        stageOfFlight="enroute"
                      />
                    </Box>
                  )
                })}
              </Stack>
            ) : (
              <Box fz="md">
                <FocusedNotamHeader label={selectedAirspace.data.risk.label} threatValue={selectedAirspace.data.risk.severity} order={6} />
                <AirspaceDisplay
                  airspace={selectedAirspace.data.airspace}
                  timeIntersection={selectedAirspace.timeIntersection}
                  routeIntersection={selectedAirspace.routeIntersection}
                />
              </Box>
            ))}
        </div>
        <div ref={departureDetailsPopupRef}>
          <Box fz={{ base: "sm", md: "md" }}>
            <Title order={4}>{departure?.icao_id ?? departure?.faa_airport_id}</Title>
            {departureDetails?.flightRules != null && (
              <Text className={flightRulesStyles.classes[departureDetails?.flightRules] ?? ""} my="xs">
                {departureDetails.flightRules} conditions @ takeoff
                {departureDetails.time != null && (
                  <Text span> ({formatDate(departureDetails.time, { time: true, timeZone: timeZone })})</Text>
                )}
              </Text>
            )}
            <Stack mt="md" spacing={2}>
              <Flex>
                <Text className="material-symbols-outlined" mt={-3}>
                  altitude
                </Text>
                <Text ml="xs">{departure?.elevation} ft</Text>
              </Flex>
              <SunsetSunriseDisplay date={departureDetails?.time} airportFlightMetrics={departureDetails?.flightMetrics} />
            </Stack>
          </Box>
        </div>
        <div ref={destinationDetailsPopupRef}>
          <Box fz={{ base: "sm", md: "md" }}>
            <Title order={4}>{destination?.icao_id ?? destination?.faa_airport_id}</Title>
            {destinationDetails?.flightRules != null && (
              <Text className={flightRulesStyles.classes[destinationDetails?.flightRules] ?? ""} my="xs">
                {destinationDetails.flightRules} conditions @ landing
                {destinationDetails.time != null && (
                  <Text span> ({formatDate(destinationDetails.time, { time: true, timeZone: timeZone })})</Text>
                )}
              </Text>
            )}
            <Stack mt="md" spacing={2}>
              <Flex>
                <Text className="material-symbols-outlined" mt={-3}>
                  altitude
                </Text>
                <Text ml="xs">{destination?.elevation} ft</Text>
              </Flex>
              <SunsetSunriseDisplay date={destinationDetails?.time} airportFlightMetrics={destinationDetails?.flightMetrics} />
            </Stack>
          </Box>
        </div>
      </Box>
    </ModalOrDrawer>
  )
}
