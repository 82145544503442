import { Aircraft, AircraftWithOrgDetails, Location, Organization, PermissionName, RegisteredUser, UserRole } from "@soar/shared/types"
import { ScopeDependencies } from "@soar/shared/utils"
import { createContext, useContext } from "react"

function noop() {
  console.warn("noop called")
}

export type DBUserStatus = "initial" | "loading" | "error" | "success" | "not_found"
export type OrgDetailsWithRole = {
  organization: Pick<Organization, "id" | "name" | "avatarUrl">
  roles: { role: UserRole }[]
}

export interface UserContextType {
  user?: RegisteredUser
  isLoaded: boolean
  isAuthenticated: boolean
  isSignedInToAuthService: boolean | undefined
  status: DBUserStatus
  hasActiveSubscription: boolean
  refetch: () => void
  logout: () => Promise<void>
  hasPermission: (permission: PermissionName, scopeDependencies?: ScopeDependencies) => boolean
  hasPermissionForOrganization: (organizationId: string, permission: PermissionName) => boolean
  locations: Location[]
  organizations: OrgDetailsWithRole[]
  aircraft: AircraftWithOrgDetails[]
  refetchAircraft: () => Promise<AircraftWithOrgDetails[]>
  isAircraftLoading: boolean
  isAircraftSuccess: boolean
  isOrgDataLoaded: boolean
}

export const UserContext = createContext<UserContextType>({
  isLoaded: false,
  isAuthenticated: false,
  isSignedInToAuthService: undefined,
  status: "loading",
  refetch: noop,
  logout: () => Promise.resolve(),
  hasPermission: () => false,
  hasPermissionForOrganization: () => false,
  locations: [],
  organizations: [],
  hasActiveSubscription: false,
  aircraft: [],
  refetchAircraft: () => Promise.resolve([]),
  isAircraftLoading: false,
  isAircraftSuccess: false,
  isOrgDataLoaded: false,
})

export const useUser = () => useContext(UserContext)
