import { Box, Flex, Title, TitleOrder } from "@mantine/core"
import { ThreatColors, determineThreatCategory } from "@soar/shared/utils"
import { ReactNode } from "react"

export function ThreatHeader({
  label,
  threatValue,
  icon,
  order = 4,
}: { label: string; threatValue: number; icon?: ReactNode; order?: TitleOrder }) {
  const threatCategory = determineThreatCategory(threatValue)
  const threatColor = threatCategory != null ? ThreatColors[threatCategory] : "#555"
  return <BaseThreatHeader order={order} icon={icon} color={threatColor} label={label} />
}

export function BaseThreatHeader({
  label,
  icon,
  order = 4,
  color,
  fz,
  iconSize = 26,
  variant = "normal",
}: {
  label: string
  icon?: ReactNode
  order?: TitleOrder
  color: string
  fz?: string | number
  iconSize?: number
  variant?: "pill" | "normal"
}) {
  return (
    <Title
      order={order}
      tt="uppercase"
      mb="xs"
      fz={fz}
      sx={(theme) =>
        variant === "pill"
          ? {
              backgroundColor: color,
              color: theme.white,
              borderRadius: theme.radius.lg,
              padding: "3px 12px",
            }
          : {}
      }
    >
      <Flex align="center">
        {variant === "normal" && (
          <Box style={{ backgroundColor: color, width: `${iconSize}px`, height: `${iconSize}px`, borderRadius: "100%" }} mr="5px">
            {icon}
          </Box>
        )}
        {label}
      </Flex>
    </Title>
  )
}
