import {
  Affix,
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  Grid,
  Group,
  Paper,
  Radio,
  SegmentedControl,
  Select,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
  Title,
  createStyles,
} from "@mantine/core"
import { DateInput } from "@mantine/dates"
import { useForm, zodResolver } from "@mantine/form"
import {
  FileDetails,
  InstructorCertSchema,
  InstructorCertTypeEnum,
  MedicalCertTypeEnum,
  PilotLicenseTypeEnum,
  User,
  UserDetails,
  UserDetailsSchema,
} from "@soar/shared/types"
import { labelForInstructorCertType, labelForMedicalCertType, labelForPilotLicenseType } from "@soar/shared/utils"
import { IconCalendar } from "@tabler/icons-react"
import { useEffect, useRef } from "react"
import { EditableAvatar } from "../../avatar"

const EMPTY_INSTRUCTOR_CERTS = {
  [InstructorCertTypeEnum.enum.CFI]: { enabled: false },
  [InstructorCertTypeEnum.enum.CFII]: { enabled: false },
  [InstructorCertTypeEnum.enum.MEI]: { enabled: false },
} as const

export function UserProfileForm({ user, onSubmit = () => {} }: { user: User; onSubmit?: (details: UserDetails) => void }) {
  const formRef = useRef<HTMLFormElement>(null)
  const form = useForm<UserDetails>({
    initialValues: {
      firstName: "",
      lastName: "",
      middleName: "",
      avatarUrl: "",
      email: "",
      phone: "",
      instructorCerts: {
        ...EMPTY_INSTRUCTOR_CERTS,
      },
      dismissedSafetyAIBanner: true,
    },
    validate: zodResolver(UserDetailsSchema),
  })

  useEffect(() => {
    const { id, registrationDate, ...rest } = user
    const hasInstructorCerts = "instructorCerts" in rest && rest.instructorCerts != null
    const hasMedicalCert = "medicalCertType" in rest && rest.medicalCertType != null
    const hasPilotCert = "pilotCertType" in rest && rest.pilotCertType != null

    form.setValues({
      ...rest,
      instructorCerts: hasInstructorCerts ? InstructorCertSchema.parse(rest.instructorCerts) : { ...EMPTY_INSTRUCTOR_CERTS },
      pilotCertType: hasPilotCert ? rest.pilotCertType : "NONE",
      medicalCertType: hasMedicalCert ? rest.medicalCertType : "NONE",
    })
  }, [user])

  const onAvatarChange = (avatarFile: FileDetails) => {
    form.setValues({ avatar: avatarFile })
  }

  const handleSubmit = (values: UserDetails) => {
    onSubmit(values)
  }

  return (
    <Box component="form" onSubmit={form.onSubmit((values) => handleSubmit(values))} ref={formRef} pb="60px">
      <Affix position={{ bottom: 20, right: 20 }}>
        <Button onClick={() => formRef.current?.requestSubmit()}>Save Changes</Button>
      </Affix>
      <Box mb="xl">
        <Paper shadow="md" p="sm">
          <Title order={3} mb="sm">
            Basic Information
          </Title>
          <Flex>
            <EditableAvatar labelText="" value={form.values.avatarUrl} onChange={onAvatarChange} />
            <Grid columns={24} ml="xl">
              <Grid.Col span={12}>
                <TextInput label="First name" {...form.getInputProps("firstName")} />
              </Grid.Col>
              <Grid.Col span={12}>
                <TextInput label="Last name" {...form.getInputProps("lastName")} />
              </Grid.Col>
            </Grid>
          </Flex>
        </Paper>
      </Box>

      <Box mb="xl">
        <Title order={3} mb="sm">
          Certificates
        </Title>
        <Grid align="stretch">
          <Grid.Col sm={4} xs={12}>
            <Paper shadow="sm" p="md" h="100%">
              <Title order={4} mb="sm">
                Pilot Certificate
              </Title>
              <Radio.Group {...form.getInputProps("pilotCertType")}>
                <Stack>
                  <Radio value="NONE" label="None" />
                  <Radio value={PilotLicenseTypeEnum.enum.STUDENT} label={labelForPilotLicenseType(PilotLicenseTypeEnum.enum.STUDENT)} />
                  <Radio value={PilotLicenseTypeEnum.enum.SPORT} label={labelForPilotLicenseType(PilotLicenseTypeEnum.enum.SPORT)} />
                  <Radio
                    value={PilotLicenseTypeEnum.enum.RECREATIONAL}
                    label={labelForPilotLicenseType(PilotLicenseTypeEnum.enum.RECREATIONAL)}
                  />
                  <Radio value={PilotLicenseTypeEnum.enum.PRIVATE} label={labelForPilotLicenseType(PilotLicenseTypeEnum.enum.PRIVATE)} />
                  <Radio
                    value={PilotLicenseTypeEnum.enum.COMMERCIAL}
                    label={labelForPilotLicenseType(PilotLicenseTypeEnum.enum.COMMERCIAL)}
                  />
                  <Radio value={PilotLicenseTypeEnum.enum.AIRLINE} label={labelForPilotLicenseType(PilotLicenseTypeEnum.enum.AIRLINE)} />
                </Stack>
              </Radio.Group>
            </Paper>
          </Grid.Col>
          <Grid.Col sm={5} xs={12}>
            <Paper shadow="sm" p="md" h="100%">
              <Title order={4} mb="sm">
                Instructor Certificate
              </Title>
              <Stack w="100%">
                <Flex justify="space-between" align="center">
                  <Checkbox
                    label={labelForInstructorCertType(InstructorCertTypeEnum.enum.CFI)}
                    {...form.getInputProps(`instructorCerts.${InstructorCertTypeEnum.enum.CFI}.enabled`, { type: "checkbox" })}
                  />
                  <DateInput
                    icon={<IconCalendar size={16} />}
                    label="Expires"
                    w="130px"
                    valueFormat="M/D/YYYY"
                    clearable={false}
                    {...form.getInputProps(`instructorCerts.${InstructorCertTypeEnum.enum.CFI}.expiration`)}
                  />
                </Flex>
                <Flex justify="space-between" align="center">
                  <Checkbox
                    label={labelForInstructorCertType(InstructorCertTypeEnum.enum.CFII)}
                    {...form.getInputProps(`instructorCerts.${InstructorCertTypeEnum.enum.CFII}.enabled`, { type: "checkbox" })}
                  />

                  <DateInput
                    icon={<IconCalendar size={16} />}
                    label="Expires"
                    w="130px"
                    valueFormat="M/D/YYYY"
                    clearable={false}
                    {...form.getInputProps(`instructorCerts.${InstructorCertTypeEnum.enum.CFII}.expiration`)}
                  />
                </Flex>
                <Flex justify="space-between" align="center">
                  <Checkbox
                    label={labelForInstructorCertType(InstructorCertTypeEnum.enum.MEI)}
                    {...form.getInputProps(`instructorCerts.${InstructorCertTypeEnum.enum.MEI}.enabled`, { type: "checkbox" })}
                  />
                  <DateInput
                    icon={<IconCalendar size={16} />}
                    label="Expires"
                    w="130px"
                    valueFormat="M/D/YYYY"
                    clearable={false}
                    {...form.getInputProps(`instructorCerts.${InstructorCertTypeEnum.enum.MEI}.expiration`)}
                  />
                </Flex>
              </Stack>
            </Paper>
          </Grid.Col>
          <Grid.Col sm={3} xs={12}>
            <Paper shadow="sm" p="md" h="100%">
              <Title order={4} mb="sm">
                Medical Certificate
              </Title>
              <Radio.Group {...form.getInputProps("medicalCertType")}>
                <Stack>
                  <Radio value={"NONE"} label="None" />
                  <Radio value={MedicalCertTypeEnum.enum.CLASS_1} label={labelForMedicalCertType(MedicalCertTypeEnum.enum.CLASS_1)} />
                  <Radio value={MedicalCertTypeEnum.enum.CLASS_2} label={labelForMedicalCertType(MedicalCertTypeEnum.enum.CLASS_2)} />
                  <Radio value={MedicalCertTypeEnum.enum.CLASS_3} label={labelForMedicalCertType(MedicalCertTypeEnum.enum.CLASS_3)} />
                </Stack>
              </Radio.Group>
              <DateInput label="Date of Exam" mt="sm" valueFormat="MM/DD/YYYY" {...form.getInputProps("medicalCertExamDate")} />
            </Paper>
          </Grid.Col>
        </Grid>
      </Box>

      <Grid align="stretch">
        <Grid.Col md={6} xs={12}>
          <Paper shadow="md" p="sm" h="100%">
            <Title order={3} mb="sm">
              Contact Info
            </Title>
            <TextInput label="Email address" {...form.getInputProps("email")} />
            <TextInput label="Phone number" {...form.getInputProps("phone")} />

            <Divider
              label={
                <Text fz="sm" fw="500">
                  Emergency Contact
                </Text>
              }
              mt="xl"
              mb="xs"
            />
            <TextInput label="Name" {...form.getInputProps("emergencyContactName")} />
            <TextInput label="Phone number" {...form.getInputProps("emergencyContactPhone")} />
            <TextInput label="Email address" {...form.getInputProps("emergencyContactEmail")} />
          </Paper>
        </Grid.Col>
        <Grid.Col md={6} xs={12}>
          <Paper shadow="md" p="sm" h="100%">
            <Title order={3} mb="sm">
              Home Address
            </Title>
            <TextInput label="Street address" {...form.getInputProps("homeAddressStreet1")} />
            <TextInput label="" {...form.getInputProps("homeAddressStreet2")} />
            <TextInput label="City" {...form.getInputProps("homeAddressCity")} />
            <TextInput label="State" {...form.getInputProps("homeAddressState")} />
            <TextInput label="Zip" {...form.getInputProps("homeAddressZip")} />
          </Paper>
        </Grid.Col>
      </Grid>
    </Box>
  )
}
