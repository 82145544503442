import { z } from "zod"
import { FileDetailsSchema } from "./File"

export const OrganizationSchema = z.object({
  id: z.string(),
  name: z.string().min(1),
  shortName: z.string().max(5).nullish(),

  businessAddressStreet: z.string().nullish(),
  businessAddressStreet2: z.string().nullish(),
  businessAddressCity: z.string().nullish(),
  businessAddressState: z.string().nullish(),
  businessAddressZip: z.string().nullish(),

  contactPhone: z.string().nullish(),
  contactEmail: z.string().email().nullish(),
  avatarUrl: z.string().nullish(),
})

export const OrganizationToEditSchema = OrganizationSchema.omit({ id: true })
export const OrganizationDetailsWithAvatarSchema = OrganizationToEditSchema.extend({ avatar: FileDetailsSchema })

export type Organization = z.infer<typeof OrganizationSchema>
export type OrganizationToEdit = z.infer<typeof OrganizationToEditSchema>
export type OrganizationDetailsWithAvatar = z.infer<typeof OrganizationDetailsWithAvatarSchema>
