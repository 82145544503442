import { Select, SelectItem, SelectProps } from "@mantine/core"
import { IconClock } from "@tabler/icons-react"
import dayjs from "dayjs"

const timeOptions = Array.from({ length: 24 }, (_, i) => {
  return [i, i + 0.25, i + 0.5, i + 0.75]
}).flat()

function parseTime(time: number | string) {
  const parsedTime = typeof time === "string" ? Number.parseFloat(time) : time

  const isPM = parsedTime >= 12
  const hour = Math.floor(parsedTime)
  const minute = Math.floor((parsedTime - hour) * 60)
  let formattedHour = hour
  if (hour === 0) {
    formattedHour = 12
  } else if (hour > 12) {
    formattedHour = hour - 12
  }
  const formattedMinute = `${minute}`.padStart(2, "0")
  const formattedTime = `${formattedHour}:${formattedMinute}${isPM ? "PM" : "AM"}`

  return { formattedTime, hour, minute }
}
function parseTimeInput(input: string) {
  const parsedTimeResult = input.match(timeRegex)
  if (parsedTimeResult == null) {
    return null
  }
  const groups = parsedTimeResult.groups ?? {}
  const parsedHour = Number.parseInt(groups["hour"] ?? "-1")
  const parsedMinute = Number.parseInt(groups["minute"] ?? "-1")
  const parsedMeridian = groups["meridian"] ?? ""

  return {
    hour: parsedHour,
    minute: parsedMinute,
    meridian: parsedMeridian.toUpperCase(),
  }
}

const timeOptions12h = timeOptions.map((time) => {
  const parsedTime = parseTime(time)
  return { value: `${time}`, label: parsedTime.formattedTime }
})

const timeRegex = /^(?<hour>\d{1,2}):(?<minute>\d{2}) ?(?<meridian>PM|AM)$/i

export function TimePickerNew({
  value = new Date(),
  onChange = () => {},
  selectProps = {},
}: {
  value?: Date
  onChange?: (date: Date) => void
  selectProps?: Partial<SelectProps>
}) {
  const dayValue = dayjs(value)
  const selectValue = `${dayValue.get("hour") + dayValue.get("minute") / 60}`

  const onSelectChange = (time: string) => {
    const parsedTime = parseTime(time)
    const newTime = dayValue.set("hour", parsedTime.hour).set("minute", parsedTime.minute)
    onChange(newTime.toDate())
  }

  const matchesSearch = (input: string, item: SelectItem) => {
    return (item.label ?? item.value).toUpperCase().startsWith(input)
  }

  return (
    <Select
      data={timeOptions12h}
      searchable
      value={selectValue}
      onChange={onSelectChange}
      filter={matchesSearch}
      {...selectProps}
      dropdownPosition="bottom"
    />
  )
}
