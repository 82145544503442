import { DEFAULT_THEME, MantineTheme } from "@mantine/core"
import { ReservationType, ReservationTypes } from "@soar/shared/types"

type ReservationTypeColor = {
  primary: string
  lighter: string
  darker: string
}

export function getReservationTypeColors(theme: MantineTheme, type: ReservationType): ReservationTypeColor {
  if (type === ReservationTypes.enum.flight) {
    return {
      primary: theme.colors.blue[6],
      lighter: theme.colors.blue[0],
      darker: theme.colors.blue[9],
    }
  } else if (type === ReservationTypes.enum.ground) {
    return {
      primary: theme.colors.lime[8],
      lighter: theme.colors.lime[0],
      darker: theme.colors.lime[9],
    }
  } else if (type === ReservationTypes.enum.maintenance) {
    return {
      primary: theme.colors.orange[6],
      lighter: theme.colors.orange[0],
      darker: theme.colors.orange[9],
    }
  } else if (type === ReservationTypes.enum.unavailable) {
    return {
      primary: theme.colors.gray[6],
      lighter: theme.colors.gray[0],
      darker: theme.colors.gray[9],
    }
  } else {
    return {
      primary: theme.colors.gray[6],
      lighter: theme.colors.gray[0],
      darker: theme.colors.gray[9],
    }
  }
}

export const reservationTypeColors: Record<ReservationType, string> = {
  flight: DEFAULT_THEME.colors.blue[6],
  ground: DEFAULT_THEME.colors.lime[9],
  maintenance: DEFAULT_THEME.colors.orange[6],
  unavailable: DEFAULT_THEME.colors.gray[6],
}
