import { Box, Chip, ChipProps, Flex, Text, createStyles } from "@mantine/core"
import { ReactNode } from "react"

const useStyles = createStyles((theme, { color }: { color: string }) => ({
  shadow: {
    boxShadow: `2px 2px 3px ${theme.colors.gray[6]}`,
  },
  displayNone: {
    display: "none",
  },
  backgroundColor: {
    backgroundColor: theme.white,
    ["&[data-checked]:not([data-disabled]):hover"]: {
      backgroundColor: color,
    },
    ["&[data-checked]:not([data-disabled])"]: {
      backgroundColor: color,
    },
  },
  coloredCircle: {
    width: "12px",
    height: "12px",
    borderRadius: "100%",
    backgroundColor: color,
    display: "inline-block",
    ["[data-checked]:not([data-disabled]) &"]: {
      backgroundColor: "#FFF",
    },
  },
  consistentPadding: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    ["&[data-checked]"]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  },
}))

export function MapFilterChip({
  children,
  backgroundColor,
  color,
  checked,
  onChange,
  chipProps,
}: {
  children?: ReactNode
  backgroundColor: string
  color: string
  checked: boolean
  onChange: (checked: boolean) => void
  chipProps?: Partial<ChipProps>
}) {
  const { classes, cx } = useStyles({ color: backgroundColor })
  return (
    <Chip
      {...chipProps}
      variant="filled"
      checked={checked}
      onChange={onChange}
      className={cx()}
      styles={{
        label: {},
      }}
      classNames={{
        label: cx(classes.shadow, classes.consistentPadding, classes.backgroundColor),
        iconWrapper: classes.displayNone,
        checkIcon: classes.displayNone,
      }}
    >
      <Text c={checked ? color : backgroundColor} fw={600}>
        <Flex align="center" justify="center">
          <Box className={classes.coloredCircle} mr={5} />
          <Box>{children}</Box>
        </Flex>
      </Text>
    </Chip>
  )
}
