import { Profile } from "@soar/shared/types"
import { atom } from "jotai"
import { storeItems } from "./localStorage"

export const profileAtom = atom<Profile | null>(null)
export const profileAtomWithStorage = atom(
  (get) => get(profileAtom),
  (_get, set, payload: Profile | null) => {
    set(profileAtom, payload)

    // store in local storage
    const storeValue =
      payload == null
        ? undefined
        : {
            type: payload.type,
            id: payload.type === "user" ? payload.user.id : payload.organization.id,
          }
    const stored = storeItems({ profile: storeValue })
  },
)
