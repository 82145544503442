import { Avatar, Box, Button, Divider, PasswordInput, Stack, Text, TextInput, Title, createStyles } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { FileDetails, InstructorCertTypeEnum, User, UserDetails, UserDetailsSchema } from "@soar/shared/types"
import { isRegisteredUser, labelForInstructorCertType, labelForMedicalCertType, labelForPilotLicenseType } from "@soar/shared/utils"
import dayjs from "dayjs"
import { useCallback, useEffect } from "react"
import { z } from "zod"
import { EditableAvatar } from "../../avatar"
import { CustomLink } from "../../customLink"
import { UserProfileUnregistered } from "./UserProfileUnregistered"

const SimpleProfileFormSchema = UserDetailsSchema.pick({
  firstName: true,
  lastName: true,
  avatar: true,
  avatarUrl: true,
})
type SimpleProfileFormValues = z.infer<typeof SimpleProfileFormSchema>

const useStyles = createStyles({
  transparentBg: {
    backgroundColor: "transparent",
  },
})

export function SimpleUserProfile({
  user,
  canEdit = false,
  canResetPassword,
  onResetPasswordClick = () => {},
  onUpdateUser = () => {},
}: {
  user: User
  canEdit?: boolean
  canResetPassword?: boolean
  onResetPasswordClick?: () => void
  onUpdateUser?: (userDetails: Partial<UserDetails>) => void
}) {
  const { classes } = useStyles()
  const isRegistered = isRegisteredUser(user)
  if (!isRegistered) {
    return <UserProfileUnregistered email={user.email} />
  }

  const form = useForm<SimpleProfileFormValues>({
    initialValues: {
      firstName: "",
      lastName: "",
      avatarUrl: "",
    },
    validate: zodResolver(SimpleProfileFormSchema),
  })

  useEffect(() => {
    form.setValues({
      firstName: user.firstName,
      lastName: user.lastName,
      avatarUrl: user.avatarUrl,
    })
    form.resetDirty()
    /*
    form.setDirty({
      firstName: false,
      lastName: false,
      avatarUrl: false
    })
    */
  }, [user])

  const onAvatarChange = (avatarFile: FileDetails) => {
    form.setValues({ avatar: avatarFile })
  }
  const onSubmit = (values: SimpleProfileFormValues) => {
    onUpdateUser(values)
  }

  return (
    <Stack spacing="lg">
      <Box>
        <Title order={3}>Profile</Title>
        <Text color="dimmed" fz="sm">
          Manage your Soar profile
        </Text>
      </Box>
      <Divider w="100%" />
      <Box>
        {canEdit ? (
          <EditableAvatar labelText="" value={form.values.avatarUrl} onChange={onAvatarChange} radius="100%" />
        ) : (
          <Avatar src={user.avatarUrl} size="xl" style={{ borderRadius: "100%" }} mr="xl" mt="xl" mb="xl">
            {user.firstName[0]?.toUpperCase()}
            {user.lastName[0]?.toUpperCase()}
          </Avatar>
        )}
      </Box>

      {canEdit ? (
        <Box
          component="form"
          onSubmit={form.onSubmit((values) => {
            onSubmit(values)
          })}
        >
          <Stack w="100%">
            <Box>
              <Text fw={500} fz="sm">
                E-mail
              </Text>
              <Text>{user.email}</Text>
              {canResetPassword && (
                <Button
                  onClick={onResetPasswordClick}
                  variant="subtle"
                  styles={{
                    root: {
                      paddingLeft: 0,
                      paddingRight: 0,
                    },
                  }}
                >
                  Reset Password
                </Button>
              )}
            </Box>
            <TextInput label="First name" {...form.getInputProps("firstName")} classNames={{ input: classes.transparentBg }} />
            <TextInput label="Last name" {...form.getInputProps("lastName")} classNames={{ input: classes.transparentBg }} />
            <Button
              w={175}
              type="submit"
              variant="gradient"
              gradient={{ from: "#136DFB", to: "#0351CC", deg: 180 }}
              disabled={!form.isDirty()}
            >
              Update
            </Button>
          </Stack>
        </Box>
      ) : (
        <Box />
      )}
    </Stack>
  )
}
