import { Box, Skeleton, Stack, Text, Title } from "@mantine/core"
import { Risk } from "@soar/shared/types"
import { tokenizeCloudDescriptor } from "@soar/shared/utils"
import { commonBodyProps } from "../safety/utils"
import { RiskBadge } from "./RiskBadge"

function getCloudDescriptor(descriptor: string) {
  const token = tokenizeCloudDescriptor(descriptor)
  if (token != null) {
    return token.meaning
  }
  return `unknown clouds (${descriptor})`
}

const numberFormatter = new Intl.NumberFormat()

export function CloudCoverDetails({
  clouds,
  cloudCover,
  loading,
  risk,
}: {
  clouds?: Array<{ cover: string; base: number | null }>
  cloudCover?: number
  loading: boolean
  risk?: Risk
}) {
  return (
    <Box>
      {risk != null ? (
        <RiskBadge severity={risk.severity} label={risk.label} size="md" showIcon={false} />
      ) : (
        <Title order={5} tt="uppercase">
          Clouds
        </Title>
      )}
      {loading && <Skeleton radius="xl" height={20} />}
      {!loading && clouds != null && (
        <Stack spacing="1px" {...commonBodyProps}>
          {clouds.map((cloudGroup, index) => {
            if (cloudGroup.cover === "CLR" || (index === 0 && cloudGroup.cover === "SKC" && cloudGroup.base === 0)) {
              return <Box key={index}> Clear </Box>
            }
            return (
              <Box key={index}>
                <Text span tt="capitalize">
                  {getCloudDescriptor(cloudGroup.cover)}
                </Text>{" "}
                {numberFormatter.format(cloudGroup.base!)}'
              </Box>
            )
          })}
        </Stack>
      )}
      {cloudCover != null && <Text>{Math.round(cloudCover * 100)}% cloud coverage</Text>}
    </Box>
  )
}
