import { Badge, Box, Flex, List, Paper, Popover, Skeleton, Space, Stack, Text, Title, TitleOrder } from "@mantine/core"
import {
  AirSigmet,
  AirportResult,
  DataWithIntersections,
  GroupedAerodromeRisk,
  IEnrouteRisk,
  IntersectionResult,
  NewNotam,
  Notam,
  Pirep,
  Risk,
  SpecialUseAirspace,
  StageOfFlight,
  TimeIntersectionResult,
} from "@soar/shared/types"
import { CSSProperties, ReactNode, useMemo } from "react"
import { AirsigmetDisplay } from "../weather/AirsigmetList"
import { FocusedNotamHeader, NewNotamDisplay } from "../weather/NotamList"
import { AirspaceDisplay } from "./AirspaceDisplay"
import { PirepDisplay } from "./PirepDisplay"

type RiskWithSubject =
  | {
      id: string
      type: "notam"
      notam: NewNotam
      risk: Risk
      routeIntersection?: IntersectionResult
      timeIntersection?: TimeIntersectionResult
    }
  | {
      id: string
      type: "airsigmet"
      airSigmet: AirSigmet
      routeIntersection: IntersectionResult
      timeIntersection: TimeIntersectionResult
      risk: Risk
    }
  | {
      id: string
      type: "pirep"
      pirep: Pirep
      risk: Risk
      routeIntersection: IntersectionResult
      timeIntersection: TimeIntersectionResult
    }
  | {
      id: string
      type: "aerodrome"
      aerodrome: AirportResult
      notam: NewNotam
      risk: Risk
      routeIntersection: IntersectionResult
      timeIntersection: TimeIntersectionResult
    }
  | {
      id: string
      type: "airspace"
      risk: Risk
      airspace: SpecialUseAirspace
      routeIntersection: IntersectionResult
      timeIntersection: TimeIntersectionResult
    }

function RiskWithSubjectDisplay({
  riskWithSubject,
  rawMode,
  stageOfFlight,
}: {
  riskWithSubject: RiskWithSubject
  rawMode: boolean
  stageOfFlight?: StageOfFlight
}) {
  if (riskWithSubject.type === "notam") {
    return (
      <>
        <FocusedNotamHeader label={riskWithSubject.risk.label} threatValue={riskWithSubject.risk.severity} order={6} />
        <NewNotamDisplay
          key={riskWithSubject.notam.id}
          notam={riskWithSubject.notam}
          rawMode={rawMode}
          showExtended={true}
          timeIntersection={riskWithSubject.timeIntersection}
          routeIntersection={riskWithSubject.routeIntersection}
          stageOfFlight={stageOfFlight}
        />
      </>
    )
  } else if (riskWithSubject.type === "airsigmet") {
    const recomposedAirsigmetWithIntersection = {
      data: riskWithSubject.airSigmet,
      timeIntersection: riskWithSubject.timeIntersection,
      routeIntersection: riskWithSubject.routeIntersection,
    }
    return (
      <>
        <FocusedNotamHeader label={riskWithSubject.risk.label} threatValue={riskWithSubject.risk.severity} order={6} />
        <AirsigmetDisplay airsigmet={recomposedAirsigmetWithIntersection} rawMode={rawMode} stageOfFlight={stageOfFlight} />
      </>
    )
  } else if (riskWithSubject.type === "aerodrome") {
    return (
      <>
        <FocusedNotamHeader label={riskWithSubject.risk.label} threatValue={riskWithSubject.risk.severity} order={6} />
        <NewNotamDisplay
          key={riskWithSubject.notam.id}
          notam={riskWithSubject.notam}
          rawMode={rawMode}
          showExtended={true}
          timeIntersection={riskWithSubject.timeIntersection}
          routeIntersection={riskWithSubject.routeIntersection}
          stageOfFlight={stageOfFlight}
        />
      </>
    )
  } else if (riskWithSubject.type === "pirep") {
    return (
      <>
        <FocusedNotamHeader label={riskWithSubject.risk.label} threatValue={riskWithSubject.risk.severity} order={6} />
        <PirepDisplay key={riskWithSubject.pirep.pirepId} pirep={riskWithSubject.pirep} rawMode={rawMode} />
      </>
    )
  } else if (riskWithSubject.type === "airspace") {
    return (
      <>
        <FocusedNotamHeader label={riskWithSubject.risk.label} threatValue={riskWithSubject.risk.severity} order={6} />
        <AirspaceDisplay
          airspace={riskWithSubject.airspace}
          timeIntersection={riskWithSubject.timeIntersection}
          routeIntersection={riskWithSubject.routeIntersection}
        />
      </>
    )
  }
  return <></>
}

export function RiskList({
  rawMode = false,
  notams,
  airSigmets,
  pireps,
  loading,
  notamRisks,
  airsigmetRisks,
  aerodromeRisks,
  airspace,
  pirepsRisks,
  stageOfFlight,
}: {
  rawMode?: boolean
  notams?: NewNotam[] | DataWithIntersections<NewNotam>[]
  airSigmets?: DataWithIntersections<AirSigmet>[]
  pireps?: DataWithIntersections<Pirep>[]
  airspace?: IEnrouteRisk["airspace"]
  loading: boolean
  notamRisks?: Risk[]
  airsigmetRisks?: Risk[]
  aerodromeRisks?: DataWithIntersections<GroupedAerodromeRisk>[]
  pirepsRisks?: Risk[]
  stageOfFlight?: StageOfFlight
}) {
  const showLoading =
    loading ||
    notams == null ||
    notamRisks == null ||
    airsigmetRisks == null ||
    aerodromeRisks == null ||
    airSigmets == null ||
    pirepsRisks == null ||
    pireps == null ||
    airspace == null

  if (showLoading) {
    return (
      <Stack spacing="xs">
        <Skeleton radius="xl" height={10} />
        <Skeleton radius="xl" height={10} />
        <Skeleton radius="xl" height={10} />
        <Skeleton radius="xl" height={10} />
        <Skeleton radius="xl" height={10} />
        <Skeleton radius="xl" height={10} />
      </Stack>
    )
  }

  const { sortedRisks, regularNotams, regularPireps, regularAirsigmets } = useMemo(() => {
    const { importantNotams, regularNotams } = notams.reduce(
      (notamMemo, notamObject) => {
        const notam = "data" in notamObject ? notamObject.data : notamObject
        const risk = notamRisks?.find((risk) => risk.sourceId === notam.id)
        if (risk != null) {
          const notamRiskGroup: RiskWithSubject = {
            type: "notam",
            risk,
            notam,
            id: `${notam.id}`,
            timeIntersection: "timeIntersection" in notamObject ? notamObject.timeIntersection : undefined,
            routeIntersection: "routeIntersection" in notamObject ? notamObject.routeIntersection : undefined,
          }
          return {
            importantNotams: [...notamMemo.importantNotams, notamRiskGroup],
            regularNotams: notamMemo.regularNotams,
          }
        } else {
          return {
            importantNotams: notamMemo.importantNotams,
            regularNotams: [...notamMemo.regularNotams, notam],
          }
        }
      },
      { importantNotams: [] as RiskWithSubject[], regularNotams: [] as NewNotam[] },
    )

    const { regularAirsigmets, importantAirsigmets } = airSigmets.reduce(
      (memo, airSigmetWithIntersection) => {
        const risk = airsigmetRisks?.find((risk) => risk.sourceId === airSigmetWithIntersection.data.airSigmetId)
        const airSigmet = airSigmetWithIntersection?.data
        if (risk != null) {
          const riskWithAirsigmet: RiskWithSubject = {
            type: "airsigmet",
            airSigmet: airSigmetWithIntersection!.data,
            risk,
            timeIntersection: airSigmetWithIntersection!.timeIntersection,
            routeIntersection: airSigmetWithIntersection!.routeIntersection,
            id: `${airSigmet?.airSigmetId}`,
          }
          return {
            importantAirsigmets: [...memo.importantAirsigmets, riskWithAirsigmet],
            regularAirsigmets: memo.regularAirsigmets,
          }
        } else {
          return {
            importantAirsigmets: memo.importantAirsigmets,
            regularAirsigmets: [...memo.regularAirsigmets, airSigmetWithIntersection],
          }
        }
      },
      { importantAirsigmets: [] as RiskWithSubject[], regularAirsigmets: [] as DataWithIntersections<AirSigmet>[] },
    )

    const aerodromeRisksWithSubject = aerodromeRisks.map(({ data: { risk, notam, aerodrome }, routeIntersection, timeIntersection }) => {
      const aerodromeRiskWithSubject: RiskWithSubject = {
        type: "aerodrome",
        risk,
        notam,
        aerodrome,
        routeIntersection,
        timeIntersection,
        id: `${notam.id}`,
      }
      return aerodromeRiskWithSubject
    })

    const { importantPireps, regularPireps } = pireps.reduce(
      (memo, pirepObject) => {
        const pirep = pirepObject.data
        const risk = pirepsRisks?.find((risk) => risk.sourceId === pirep.pirepId)
        if (risk != null) {
          const pirepRiskGroup: RiskWithSubject = {
            type: "pirep",
            risk,
            pirep,
            id: `${pirep.pirepId}`,
            timeIntersection: pirepObject.timeIntersection,
            routeIntersection: pirepObject.routeIntersection,
          }
          return {
            importantPireps: [...memo.importantPireps, pirepRiskGroup],
            regularPireps: memo.regularPireps,
          }
        } else {
          return {
            importantPireps: memo.importantPireps,
            regularPireps: [...memo.regularPireps, pirep],
          }
        }
      },
      { importantPireps: [] as RiskWithSubject[], regularPireps: [] as Pirep[] },
    )

    // biome-ignore lint/complexity/useFlatMap: breaks build
    const airspaceRisksWithSubject = airspace
      .map((a) => {
        if (a.data.notams.length > 0) {
          const notamsWithSubject = a.data.notams.map((notam) => {
            const notamWithSubject: RiskWithSubject = {
              type: "notam",
              risk: a.data.risks[0],
              notam: notam.data,
              id: `${notam.data.id}`,
              timeIntersection: notam.timeIntersection,
              routeIntersection: notam.routeIntersection,
            }
            return notamWithSubject
          })
          return notamsWithSubject
        } else {
          const riskWithSubject: RiskWithSubject = {
            id: `${a.data.airspace.id}`,
            type: "airspace",
            airspace: a.data.airspace,
            risk: a.data.risks[0],
            timeIntersection: a.timeIntersection,
            routeIntersection: a.routeIntersection,
          }
          return [riskWithSubject]
        }
      })
      .flat()

    // console.log({airSigmetsWithRisk, aerodromeRisksWithSubject, importantPireps})
    const sortedRisks = importantNotams
      .concat(importantAirsigmets)
      .concat(aerodromeRisksWithSubject)
      .concat(importantPireps)
      .concat(airspaceRisksWithSubject)
      .sort((a, b) => {
        const aHasTimeIntersection = "timeIntersection" in a && (a.timeIntersection?.withinExact ?? false)
        const bHasTimeIntersection = "timeIntersection" in b && (b.timeIntersection?.withinExact ?? false)
        const aSeverity = aHasTimeIntersection ? a.risk.severity : a.risk.severity - 1000
        const bSeverity = bHasTimeIntersection ? b.risk.severity : b.risk.severity - 1000

        return bSeverity - aSeverity // b.risk.severity - a.risk.severity
      })

    return {
      sortedRisks,
      regularNotams,
      regularPireps,
      regularAirsigmets,
    }

    // const sortedImportantNotams = importantNotams.sort((a, b) => b.risk.severity - a.risk.severity)
  }, [notamRisks, airsigmetRisks, aerodromeRisks, airSigmets, notams, pirepsRisks, pireps, airspace])

  return (
    <>
      {sortedRisks.length > 0 && (
        <>
          <Stack spacing={8} maw="100%" bg="#F2F7FF" px="sm" py="lg">
            {sortedRisks.map((riskWithSubject) => (
              <Paper key={riskWithSubject.id} mb="sm" px="sm" py="md" radius="md" shadow="sm">
                <RiskWithSubjectDisplay riskWithSubject={riskWithSubject} rawMode={rawMode} stageOfFlight={stageOfFlight} />
              </Paper>
            ))}
          </Stack>
          <Space h="xs" mt="xs" />
        </>
      )}
      <List spacing={20} maw="100%" styles={{ itemWrapper: { whiteSpace: "pre-wrap" } }} listStyleType="none" pt="xl" pb="xl" px="xl">
        <>
          {(regularPireps ?? []).map((pirep) => (
            <List.Item key={pirep.pirepId}>
              <PirepDisplay key={pirep.pirepId} pirep={pirep} rawMode={rawMode} condensed={true} />
            </List.Item>
          ))}

          {(regularAirsigmets ?? []).map((airsigmet) => (
            <List.Item key={airsigmet.data.airSigmetId}>
              <AirsigmetDisplay airsigmet={airsigmet} rawMode={rawMode} condensed />
            </List.Item>
          ))}

          {regularNotams!.map((notam, index) => (
            <List.Item key={notam.id}>
              <NewNotamDisplay key={notam.id} notam={notam} delay={index} rawMode={rawMode} />
            </List.Item>
          ))}
        </>
      </List>
    </>
  )
}
