import { DEFAULT_THEME, MantineThemeOverride, SegmentedControlStylesParams } from "@mantine/core"

export const customThemeOverrides: MantineThemeOverride = {
  fontFamily: "Inter, sans-serif",
  colors: {
    brandBlue: ["#e5f4ff", "#cde2ff", "#9cc3fe", "#67a1fc", "#3c84fb", "#2072fa", "#0d69fb", "#0059e0", "#004fc9", "#0044b3"],
  },
  spacing: {
    "4xs": "1px",
    "3xs": "2px",
    "2xs": "5px",
    ...DEFAULT_THEME.spacing,
    "2xl": "2.5rem",
    "3xl": "3.5rem",
  },
  primaryColor: "brandBlue",
  components: {
    Modal: {
      styles: (theme) => ({
        close: {
          order: -1,
          marginLeft: "-0.5rem",
          marginRight: "0.5rem",
        },
        header: {
          marginBottom: `${theme.spacing.xs}px`,
          justifyContent: "flex-start",
        },
      }),
    },
    Popover: {
      styles: (theme) => ({
        dropdown: {
          marginTop: "-8px",
          boxShadow: theme.shadows.lg,
        },
      }),
    },
    Select: {
      styles: (theme) => ({
        dropdown: {
          marginTop: "-8px",
          boxShadow: theme.shadows.lg,
        },
      }),
    },
    DatePicker: {},
    TextInput: {
      defaultProps: (theme) => ({
        labelProps: {
          c: theme.black,
        },
      }),
    },
  },
}
