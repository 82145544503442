import { z } from "zod"
import { FileDetailsSchema } from "./File"

export const TimeFormatEnum = z.enum(["format_12h", "format_24h"])
export type TimeFormat = z.infer<typeof TimeFormatEnum>

export const PilotLicenseTypeEnum = z.enum(["STUDENT", "SPORT", "RECREATIONAL", "PRIVATE", "COMMERCIAL", "AIRLINE"])
export type PilotLicenseType = z.infer<typeof PilotLicenseTypeEnum>

export const InstructorCertTypeEnum = z.enum(["CFI", "CFII", "MEI"])
export const InstructorCertSchema = z.record(
  InstructorCertTypeEnum,
  z.object({
    enabled: z.boolean(),
    expiration: z.coerce.date().nullish(),
  }),
)

export type InstructorCertType = z.infer<typeof InstructorCertTypeEnum>

export const MedicalCertTypeEnum = z.enum(["CLASS_1", "CLASS_2", "CLASS_3"])

export type MedicalCertType = z.infer<typeof MedicalCertTypeEnum>

export const RegisteredUserSchema = z.object({
  id: z.string(),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  middleName: z.string().nullish(),
  nickname: z.string().nullish(),
  avatarUrl: z.string().nullish(),
  email: z.string().email(),
  phone: z.string().nullish(),
  registrationDate: z.coerce.date(),
  emergencyContactName: z.string().nullish(),
  emergencyContactEmail: z.string().nullish(),
  emergencyContactPhone: z.string().nullish(),
  homeAddressStreet1: z.string().nullish(),
  homeAddressStreet2: z.string().nullish(),
  homeAddressCity: z.string().nullish(),
  homeAddressState: z.string().nullish(),
  homeAddressZip: z.string().nullish(),
  stripeCustomerId: z.string().nullish(),
  stripeSubscriptionStatus: z.string().nullish(),
  stripeSubscriptionEndDate: z.coerce.date().nullish(),
  stripeSubscriptionPlan: z.string().nullish(),
  stripeSubscriptionPaymentModel: z.string().nullish(),
  stripeSubscriptionCancellationDate: z.coerce.date().nullish(),
  freeTrialEndDate: z.coerce.date().nullish(),
  pilotCertType: PilotLicenseTypeEnum.nullish(),
  instructorCerts: InstructorCertSchema.nullish(),
  medicalCertType: MedicalCertTypeEnum.nullish(),
  medicalCertExamDate: z.coerce.date().nullish(),
  timeFormatPreference: TimeFormatEnum.default("format_12h"),
  canCreateOrg: z.boolean().default(false),
  dismissedSafetyAIBanner: z.boolean().default(false),
})

export const PartialUserSchema = RegisteredUserSchema.pick({
  id: true,
  firstName: true,
  lastName: true,
  nickname: true,
  email: true,
  phone: true,
  avatarUrl: true,
})

export const UnregisteredUserSchema = z.object({
  id: z.string(),
  email: z.string().email(),
  registrationDate: z.literal(null),
  canCreateOrg: z.boolean().default(false),
})

export const UserSchema = z.union([RegisteredUserSchema, UnregisteredUserSchema])

export const UserDetailsSchema = RegisteredUserSchema.omit({
  id: true,
  registrationDate: true,
  canCreateOrg: true,
  timeFormatPreference: true,
}).extend({
  pilotCertType: PilotLicenseTypeEnum.or(z.literal("NONE")).nullish(),
  medicalCertType: MedicalCertTypeEnum.or(z.literal("NONE")).nullish(),
  avatar: FileDetailsSchema,
})

export type User = z.infer<typeof UserSchema>
export type UserDetails = z.infer<typeof UserDetailsSchema>
export type PartialUser = z.infer<typeof PartialUserSchema>
export type UnregisteredUser = z.infer<typeof UnregisteredUserSchema>
export type RegisteredUser = z.infer<typeof RegisteredUserSchema>
