import { ActionIcon, MantineColor, Menu } from "@mantine/core"
import { Reservation, ReservationActions, ReservationStatus } from "@soar/shared/types"
import { getActionsForReservation, getStatusForReservation } from "@soar/shared/utils"
import { IconDotsVertical } from "@tabler/icons-react"
import { SyntheticEvent } from "react"

export function ReservationActionMenu({
  reservation,
  color = "gray.7",
  disabledActions = [],
  onSignOutClick = () => {},
  onUpdateClick = () => {},
  onCancelClick = () => {},
  onSelect = () => {},
}: {
  reservation: Reservation
  disabledActions?: ReservationActions[]
  color?: MantineColor
  onSignOutClick?: (reservation: Reservation) => void
  onUpdateClick?: (reservation: Reservation) => void
  onCancelClick?: (reservation: Reservation) => void
  onSelect?: () => void
}) {
  const actionsForStatus = getActionsForReservation(reservation)
  const status = getStatusForReservation(reservation)
  const menuOptions = actionsForStatus.filter((action) => !disabledActions.includes(action))
  if (menuOptions.length === 0) {
    return <></>
  }
  return (
    <Menu shadow="xl">
      <Menu.Target>
        <ActionIcon
          color={color}
          variant="transparent"
          onClick={(e: SyntheticEvent) => {
            e.stopPropagation()
          }}
        >
          <IconDotsVertical />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {menuOptions.includes(ReservationActions.enum.signOut) && (
          <Menu.Item
            onClick={(e: SyntheticEvent) => {
              e.stopPropagation()
              onSignOutClick(reservation)
              onSelect()
            }}
          >
            {status === ReservationStatus.enum.inProgress ? "Sign In Reservation" : "Sign Out Reservation"}
          </Menu.Item>
        )}
        {menuOptions.includes(ReservationActions.enum.update) && (
          <Menu.Item
            onClick={(e: SyntheticEvent) => {
              e.stopPropagation()
              onUpdateClick(reservation)
              onSelect()
            }}
          >
            Update Reservation
          </Menu.Item>
        )}
        {menuOptions.includes(ReservationActions.enum.cancel) && (
          <Menu.Item
            onClick={(e: SyntheticEvent) => {
              e.stopPropagation()
              onCancelClick(reservation)
              onSelect()
            }}
          >
            Cancel Reservation
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  )
}
