import { Dayjs } from "dayjs"

export function getOrganizationUserStatus(registrationDate: Dayjs | Date | undefined | null, disabled: boolean) {
  const isRegistered = registrationDate != null
  const isEnabled = disabled === false
  if (isRegistered && isEnabled) {
    return "Active"
  }

  const statuses: string[] = []
  if (!isRegistered) {
    statuses.push("Unregistered")
  }
  if (!isEnabled) {
    statuses.push("Deactivated")
  }

  return statuses.join(", ")
}
