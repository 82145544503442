import { z } from "zod"

export type RawPirep = {
  pirepId: number
  receiptTime: string
  obsTime: number
  qcField: number
  icaoId: string
  acType: string
  lat: number
  lon: number
  fltLvl: string
  fltLvlType: "OTHER" | "DURD" | "DURC" | "GRND" | "UNKN"
  cloudBas1: null | number
  cloudTop1: null | number
  cloudCvg1: null | string
  cloudBas2: null | number
  cloudTop2: null | number
  cloudCvg2: null | string
  visib: null | number
  wxString: string
  temp: null | number
  wdir: null | number
  wspd: null | number
  icgBas1: null | string
  icgTop1: null | string
  icgInt1: null | string
  icgType1: null | string
  icgBas2: null | string
  icgTop2: null | string
  icgInt2: null | string
  icgType2: null | string
  tbBas1: null | string
  tbTop1: null | string
  tbInt1: null | string
  tbType1: null | string
  tbFreq1: null | string
  tbBas2: null | string
  tbTop2: null | string
  tbInt2: null | string
  tbType2: null | string
  tbFreq2: null | string
  vertGust: null | number | string
  brkAction: null | string
  pirepType: "PIREP" | "AIREP" | "Urgent PIREP"
  rawOb: string
}

export type PirepIcingIntensity = null | "NEG" | PirepIcingIntensityOption | PirepIcingIntensityGroup

export const PirepIcingIntensityOptionEnum = z.enum(["TRC", "LGT", "MOD", "SEV", "HVY"])
export type PirepIcingIntensityOption = z.infer<typeof PirepIcingIntensityOptionEnum>

export const PirepIcingTypeOptionEnum = z.enum(["RIME", "MIXED", "CLEAR"])
export type PirepIcingTypeOption = z.infer<typeof PirepIcingTypeOptionEnum>

export type PirepIcingIntensityGroup = {
  from: PirepIcingIntensityOption
  to: PirepIcingIntensityOption
}
export type PirepIcing = {
  base: null | number
  baseMod: null | "ABV" | "BLO"
  top: null | number
  topMod: null | "ABV" | "BLO"
  intensity: PirepIcingIntensity
  type: null | PirepIcingTypeOption
}

export type PirepTurbulenceIntensity = null | "NEG" | PirepTurbulenceIntensityOption | PirepTurbulenceIntensityGroup
export const PirepTurbulenceIntensityOptionEnum = z.enum(["LGT", "MOD", "SEV", "EXTM"])
export type PirepTurbulenceIntensityOption = z.infer<typeof PirepTurbulenceIntensityOptionEnum>

export type PirepTurbulenceIntensityGroup = {
  from: PirepTurbulenceIntensityOption
  to: PirepTurbulenceIntensityOption
}
export const PirepTurbulenceTypeOptionEnum = z.enum(["CHOP", "CAT", "LLWS"])
export type PirepTurbulenceTypeOption = z.infer<typeof PirepTurbulenceTypeOptionEnum>

export const PirepTurbulenceFrequencyOptionEnum = z.enum(["OCNL", "CONT", "CONS", "INT"])
export type PirepTurbulenceFrequencyOption = z.infer<typeof PirepTurbulenceFrequencyOptionEnum>

export type PirepTurbulence = {
  base: null | number
  baseMod: null | "ABV" | "BLO"
  top: null | number
  topMod: null | "ABV" | "BLO"
  intensity: PirepTurbulenceIntensity
  type: null | PirepTurbulenceTypeOption
  frequency: null | PirepTurbulenceFrequencyOption
}
export type FlightLevelType = "OTHER" | "DURD" | "DURC" | "GRND" | "UNKN"

export const CloudCoverageEnum = z.enum(["SKC", "NSC", "FEW", "SCT", "BKN", "OVC", "OVX", "CLR", "VMC", "VFR"])
export type CloudCoverageOption = z.infer<typeof CloudCoverageEnum>

export type Pirep = {
  pirepId: number
  receiptTime: Date
  obsTime: Date
  qcField: number
  icaoId: string
  aircraftType: string
  lat: number
  lng: number
  fltLvl: null | number
  fltLvlType: FlightLevelType
  cloudBase1: null | number
  cloudTop1: null | number
  cloudCvg1: null | CloudCoverageOption
  cloudBase2: null | number
  cloudTop2: null | number
  cloudCvg2: null | CloudCoverageOption
  visib: null | number
  wxString: null | string
  temp: null | number
  wdir: null | number
  wspd: null | number

  icing1: PirepIcing | null
  icing2: PirepIcing | null
  turbulence1: PirepTurbulence | null
  turbulence2: PirepTurbulence | null
  translation?: string
  vertGust: null | number | string
  brkAction: null | string
  pirepType: "PIREP" | "AIREP"
  isUrgent: boolean
  rawOb: string
}
