import { z } from "zod"

export const PermissionNameEnum = z.enum([
  "ADD_AIRCRAFT_PROFILES",
  "ADD_NEW_ORGANIZATION",
  "ADD_PEOPLE_PROFILES",
  "APPROVE_FLIGHT_LEG",
  "CANCEL_RESERVATION_BLOCK",
  "CANCEL_RESERVATION_FLIGHT",
  "CANCEL_RESERVATION_GROUND",
  "CANCEL_RESERVATION_MAINTENANCE",
  "CREATE_RESERVATION_BLOCK",
  "CREATE_RESERVATION_FLIGHT",
  "CREATE_RESERVATION_GROUND",
  "CREATE_RESERVATION_MAINTENANCE",
  "DELETE_ORGANIZATION",
  "EDIT_AIRCRAFT_PROFILES",
  "EDIT_ORGANIZATION_SETTINGS",
  "EDIT_PEOPLE_LOCATIONS",
  "EDIT_PEOPLE_ROLES",
  "EDIT_RESERVATION_BLOCK",
  "EDIT_RESERVATION_FLIGHT",
  "EDIT_RESERVATION_GROUND",
  "EDIT_RESERVATION_MAINTENANCE",
  "SIGN_RESERVATION_IN",
  "SIGN_RESERVATION_OUT",
  "VIEW_SAFETY_FEEDBACK",
  "VIEW_AIRCRAFT_LIST",
  "VIEW_AIRCRAFT_PROFILES",
  "VIEW_ORGANIZATION_SETTINGS",
  "VIEW_ORGANIZATION_DASHBOARD",
  "VIEW_PEOPLE_LIST",
  "VIEW_PEOPLE_PROFILES",
])
export const PermissionsScopeEnum = z.enum(["IS_ON_RESERVATION", "OTHER_USERS_ARE_PILOTS", "OTHER_USERS_ARE_STAFF_PILOTS"])

export type PermissionName = z.infer<typeof PermissionNameEnum>
export type PermissionScope = z.infer<typeof PermissionsScopeEnum>

export type PermissionConditions = {
  scopes?: PermissionScope[]
  condition?: (arg: any) => boolean
  conditionDescription?: string
}

export type Permission = PermissionConditions & {
  permission: PermissionName
}
