import { z } from "zod"
export const idSchema = z.string().min(1).max(26)
export const idSchemaULID = z.string().ulid()

export const SortEnumSchema = z.enum(["asc", "desc"])
export type SortEnum = z.infer<typeof SortEnumSchema>

export type SortOperation = {
  order: SortEnum
  field: string
}

export function EqualsComparatorOperationSchema<T extends z.ZodTypeAny>(schema: T) {
  return z.object({
    type: z.literal("EQ"),
    value: schema,
  })
}

export type EqualsComparatorOperation<T> = {
  type: "NEQ"
  value: T
}

export function NotEqualsComparatorOperationSchema<T extends z.ZodTypeAny>(schema: T) {
  return z.object({
    type: z.literal("NEQ"),
    value: schema,
  })
}

export type NotEqualsComparatorOperation<T> = {
  type: "NEQ"
  value: T
}

export function InComparatorOperationSchema<T extends z.ZodTypeAny>(schema: T) {
  return z.object({
    type: z.literal("IN"),
    value: schema.array(),
  })
}
export type InComparatorOperation<T> = {
  type: "IN"
  value: T[]
}

export function NotInComparatorOperationSchema<T extends z.ZodTypeAny>(schema: T) {
  return z.object({
    type: z.literal("NOTIN"),
    value: schema.array(),
  })
}
export type NotInComparatorOperation<T> = {
  type: "NOTIN"
  value: T[]
}

export function GreaterThanComparatorOperationSchema<T extends z.ZodTypeAny>(schema: T) {
  return z.object({
    type: z.literal("GT"),
    value: schema,
  })
}
export type GreaterThanComparatorOperation<T> = {
  type: "GT"
  value: T
}

export function GreaterThanOrEqualsComparatorOperationSchema<T extends z.ZodTypeAny>(schema: T) {
  return z.object({
    type: z.literal("GTE"),
    value: schema,
  })
}
export type GreaterThanOrEqualsComparatorOperation<T> = {
  type: "GTE"
  value: T
}

export function LesserThanComparatorOperationSchema<T extends z.ZodTypeAny>(schema: T) {
  return z.object({
    type: z.literal("LT"),
    value: schema,
  })
}
export type LesserThanComparatorOperation<T> = {
  type: "LT"
  value: T
}

export function LesserThanOrEqualsComparatorOperationSchema<T extends z.ZodTypeAny>(schema: T) {
  return z.object({
    type: z.literal("LTE"),
    value: schema,
  })
}
export type LesserThanOrEqualsComparatorOperation<T> = {
  type: "LTE"
  value: T
}

export function BaseComparatorOperationSchema<T extends z.ZodTypeAny>(schema: T) {
  return z.union([
    EqualsComparatorOperationSchema(schema),
    NotEqualsComparatorOperationSchema(schema),
    InComparatorOperationSchema(schema),
    NotInComparatorOperationSchema(schema),
    GreaterThanComparatorOperationSchema(schema),
    GreaterThanOrEqualsComparatorOperationSchema(schema),
    LesserThanComparatorOperationSchema(schema),
    LesserThanOrEqualsComparatorOperationSchema(schema),
  ])
}

export type BaseComparatorOperation<T> =
  | EqualsComparatorOperation<T>
  | NotEqualsComparatorOperation<T>
  | InComparatorOperation<T>
  | NotInComparatorOperation<T>
  | GreaterThanComparatorOperation<T>
  | GreaterThanOrEqualsComparatorOperation<T>
  | LesserThanComparatorOperation<T>
  | LesserThanOrEqualsComparatorOperation<T>

// @ts-ignore
export function AndComparatorOperationSchema<T extends z.ZodTypeAny>(schema: T) {
  return z.object({
    type: z.literal("AND"),
    comparators: z.lazy(() => ComparatorOperationSchema(schema)).array(),
  })
}

export type AndComparatorOperation<T> = {
  type: "AND"
  comparators: ComparatorOperation<T>[]
}

// @ts-ignore
export function OrComparatorOperationSchema<T extends z.ZodTypeAny>(schema: T) {
  return z.object({
    type: z.literal("OR"),
    comparators: z.lazy(() => ComparatorOperationSchema(schema).array()),
  })
}
export type OrComparatorOperation<T> = {
  type: "OR"
  comparators: ComparatorOperation<T>[]
}

// @ts-ignore
export function ComparatorOperationSchema<T extends z.ZodTypeAny>(schema: T) {
  return z.union([OrComparatorOperationSchema(schema), AndComparatorOperationSchema(schema), BaseComparatorOperationSchema(schema)])
}

export type ComparatorOperation<T> = AndComparatorOperation<T> | OrComparatorOperation<T> | BaseComparatorOperation<T>

/*
const comparator: ComparatorOperation<string> = {
  type: 'OR',
  comparators: [
    {
      type: 'AND',
      comparators: [
        {
          type: "IN",
          value: ["3", "4"]
        },
        {
          type: "NOTIN",
          value: ["5", "6", "4"]
        }
      ]
    },
    {
      type: 'LTE',
      value: "blah"
    }
  ]
}
*/

export const ThirdPartyIntegrationSourcesEnum = z.enum(["Slack"])
