import { Reservation, ReservationType, ReservationUser } from "@soar/shared/types"
import { isFieldRelevantForReservationType } from "./fields"

/**
 * Determine if reservation intersects a timespan based on the following criteria
 *  - reservation starts before timespan start and ends after timespan ends
 *  - reservation starts after timespan start and before timespan ends
 *  - reservation ends after timespan start and before timespan ends
 */
export function reservationIntersectsTimespan(reservation: Reservation, timespanStart: Date, timespanEnd: Date) {
  const reservationSurroundsTimespan =
    reservation.startTime.getTime() <= timespanStart.getTime() && reservation.endTime.getTime() >= timespanEnd.getTime()
  const reservationStartsDuringTimespan =
    reservation.startTime.getTime() >= timespanStart.getTime() && reservation.startTime.getTime() <= timespanEnd.getTime()
  const reservationEndsDuringTimespan =
    reservation.endTime.getTime() >= timespanStart.getTime() && reservation.startTime.getTime() <= timespanEnd.getTime()

  return reservationSurroundsTimespan || reservationStartsDuringTimespan || reservationEndsDuringTimespan
}

/** Filters a list of reservations that intersects timespan
 */
export function filterReservationsByTimespan(reservations: Reservation[], timespanStart: Date, timespanEnd: Date) {
  return reservations.filter((reservation) => {
    return reservationIntersectsTimespan(reservation, timespanStart, timespanEnd)
  })
}

// Filter reservations for any matching pilots or aircraft based on reservation type we are filtering for
export function filterReservationsByRelevantSubjects(
  reservations: Reservation[],
  reservationType: ReservationType,
  users: ReservationUser[],
  aircraftId: string | undefined,
) {
  const selectedPilotsIds = users.map((p) => p.userId)

  const shouldFilterPilots =
    isFieldRelevantForReservationType("users", reservationType) || isFieldRelevantForReservationType("pic", reservationType)
  const shouldFilterAircraft = isFieldRelevantForReservationType("aircraft", reservationType)

  const filteredReservations = reservations.filter((reservation) => {
    const hasAnyCommonPilot =
      shouldFilterPilots &&
      selectedPilotsIds.reduce((memo, selectedPilotId) => {
        return (
          memo ||
          reservation.users.some(
            (resUser: ReservationUser) => "userId" in resUser && resUser.userId != null && resUser.userId === selectedPilotId,
          )
        )
      }, false)
    const hasSameAircraft = shouldFilterAircraft && reservation.aircraftId != null && reservation.aircraftId === aircraftId
    return hasAnyCommonPilot || hasSameAircraft
  })
  return filteredReservations
}
