import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  NumberInput,
  SegmentedControl,
  Select,
  Stack,
  Text,
  Textarea,
  Title,
  createStyles,
} from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"
import { TransformedValues, UseFormReturnType, useForm, zodResolver } from "@mantine/form"
import { usePreferences } from "@soar/frontend/contexts"
import {
  Aircraft,
  BaseReservationDetails,
  FlightReservationDetailsSchema,
  GroundReservationDetailsSchema,
  Location,
  MaintenanceReservationDetailsSchema,
  RegisteredUser,
  ReservationDetails,
  ReservationType,
  ReservationTypes,
  Timeslot,
  UnavailableReservationDetailsSchema,
  User,
  UserRole,
  isReservationTimeValid,
  reservationLegLengthErrorMessage,
  reservationTimeRefinementConfig,
} from "@soar/shared/types"
import { formatDate, getUserName, isFieldRelevantForReservationType, isRegisteredUser, reservationTypeLabels } from "@soar/shared/utils"
import {
  Icon123,
  IconCalendar,
  IconCheck,
  IconChevronLeft,
  IconChevronRight,
  IconClockHour4,
  IconClockHour8,
  IconCurrentLocation,
  IconPlaneTilt,
  IconReport,
  IconUser,
} from "@tabler/icons-react"
import dayjs from "dayjs"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import { ReactNode, useEffect, useState } from "react"
import { FlightLegsInput, generateEmptyFlightRoute } from "../reservations/flightLegsInput"
import { TimePickerNew } from "../timePicker"
import { PilotSelectionRow } from "../users"

dayjs.extend(isSameOrAfter)

const useStyles = createStyles((theme) => ({
  title: {
    borderBottom: `thin solid ${theme.colors.gray[2]}`,
  },
  field: {
    marginBottom: theme.spacing.md,
  },
  label: {
    width: "30px",
    flexShrink: 0,
    alignItems: "center",
  },
  labelIcon: {
    marginRight: "7px",
  },
  input: {
    flexGrow: 1,
  },
}))

const reservationTypeOptions = ReservationTypes.options.map((type) => {
  const label = reservationTypeLabels[type]
  return { label: <Text tt="capitalize">{label}</Text>, value: type }
})

function onDayChange(form: UseFormReturnType<BaseReservationDetails>, key: "startTime" | "endTime") {
  return (date: Date, alsoUpdate?: "startTime" | "endTime") => {
    const selectedDate = dayjs(date)
    const currentDateTime = dayjs(form.values[key])
    const newDateTime = currentDateTime.set("year", selectedDate.year()).set("month", selectedDate.month()).set("date", selectedDate.date())
    const setValue = {
      [key]: newDateTime.toDate(),
    }

    if (alsoUpdate != null) {
      const currentDateTime = dayjs(form.values[alsoUpdate])
      const newDateTime = currentDateTime
        .set("year", selectedDate.year())
        .set("month", selectedDate.month())
        .set("date", selectedDate.date())
      setValue[alsoUpdate] = newDateTime.toDate()
    }

    form.setValues(setValue)
  }
}
function onTimeChange(form: UseFormReturnType<BaseReservationDetails>, key: "startTime" | "endTime") {
  return (date: Date) => {
    const selectedDate = dayjs(date)
    const currentDateTime = dayjs(form.values[key])
    const newDateTime = currentDateTime.set("hour", selectedDate.hour()).set("minute", selectedDate.minute())

    const setValue = {
      [key]: newDateTime.toDate(),
    }
    if (key === "startTime") {
      const currentEndTime = dayjs(form.values["endTime"])
      const diff = currentEndTime.diff(currentDateTime)
      const newEndTime = newDateTime.add(diff, "milliseconds")
      setValue["endTime"] = newEndTime.toDate()
    }

    form.setValues(setValue)
  }
}

function makeAircraftOptions(aircrafts: Aircraft[]) {
  return aircrafts.map((aircraft) => ({
    value: aircraft.id,
    label: `${aircraft.tailNumber} - ${aircraft.manufacturer} ${aircraft.model}`,
  }))
}

function makeLocationOptions(locations: Location[]) {
  return locations.map((location) => ({
    value: location.id,
    label: `${location.airportCode} - ${location.name}`,
  }))
}
function makeUserOptions(users: UserWithRoles[], shouldBeDisabled?: (user: User) => boolean) {
  const hasDisableHandler = shouldBeDisabled != null

  const userOptions = users
    .filter((userWithRoles): userWithRoles is { user: RegisteredUser; roles: UserRole[] } => {
      return isRegisteredUser(userWithRoles.user)
    })
    .map((userWithRole) => {
      const { user } = userWithRole
      const baseUserOption = {
        label: `${user!.firstName} ${user?.lastName}`,
        value: user.id,
      }
      const userOptions = baseUserOption

      if (!hasDisableHandler) {
        return userOptions
      }
      return {
        ...userOptions,
        disabled: shouldBeDisabled(user),
      }
    })
  return userOptions
}

/*
function isPic(picUser?: RegisteredUser, inputUser: RegisteredUser){
  return picUser != null && picUser.id === inputUser.id
}
*/
const datePickerProps = {
  inputFormat: "dddd MMM D",
  icon: <IconCalendar />,
  size: "sm",
  clearable: false,
  mr: "sm",
  styles: { input: { width: "175px" } },
} as const

function ReservationFormRow({ label, children }: { children: ReactNode | ReactNode[]; label: ReactNode | ReactNode[] }) {
  const { classes } = useStyles()
  return (
    <Flex className={classes.field}>
      <Flex className={classes.label}>{label}</Flex>
      <Flex className={classes.input}>{children}</Flex>
    </Flex>
  )
}

function getSchemaForReservationType(type: ReservationType) {
  if (type === ReservationTypes.enum.flight) {
    return FlightReservationDetailsSchema
  } else if (type === ReservationTypes.enum.ground) {
    return GroundReservationDetailsSchema
  } else if (type === ReservationTypes.enum.maintenance) {
    return MaintenanceReservationDetailsSchema
  } else {
    return UnavailableReservationDetailsSchema
  }
}

interface UserWithRoles {
  user: User
  roles: UserRole[]
}

export function ReservationForm({
  locations,
  aircraft,
  users,
  isLoading,
  value,
  mode = "create",
  openTimeslots = [],
  onSubmit = () => {},
  onCancelClick = () => {},
  onFormChange = () => {},
  onAvailableTimesDateChange = () => {},
}: {
  locations: Location[]
  aircraft: Aircraft[]
  users: UserWithRoles[]
  isLoading: boolean
  value?: Partial<BaseReservationDetails>
  mode?: "create" | "edit"
  openTimeslots?: Timeslot[]
  onSubmit?: (reservationDetails: ReservationDetails) => void
  onCancelClick?: () => void
  onFormChange?: (reservationDetails: BaseReservationDetails) => void
  onAvailableTimesDateChange?: (date: Date) => void
}) {
  const { classes } = useStyles()
  const labelIconProps = { height: 20, className: classes.labelIcon } as const

  const defaultStartDate = dayjs().add(1, "hour").startOf("hour")
  const defaultEndDate = defaultStartDate.add(1, "hour")
  const [availableTimesDate, setAvailableTimesDate] = useState(value?.startTime ?? defaultStartDate.toDate())
  const { timeFormat } = usePreferences()

  const incrementAvailableTimesDate = () => {
    setAvailableTimesDate(dayjs(availableTimesDate).add(1, "day").toDate())
  }
  const decrementAvailableTimesDate = () => {
    setAvailableTimesDate(dayjs(availableTimesDate).add(-1, "day").toDate())
  }

  useEffect(() => {
    onAvailableTimesDateChange(availableTimesDate)
  }, [availableTimesDate])

  const form = useForm({
    initialValues: {
      type: value?.type ?? "flight",
      startTime: value?.startTime ?? defaultStartDate.toDate(),
      endTime: value?.endTime ?? defaultEndDate.toDate(),
      locationId: value?.locationId ?? "",
      aircraftId: value?.aircraftId ?? null,
      legs: value?.legs ?? [generateEmptyFlightRoute()],
      users: value?.users ?? [],
      comment: value?.comment ?? "",
    } as BaseReservationDetails,
    validate: (values) => {
      const baseSchema = getSchemaForReservationType(values.type)
      const zodResolverResults = zodResolver(baseSchema)(values)

      // For some reason the refinement isn't working on the new schemas so I'm checking it myself here
      const endTimeIsValid = isReservationTimeValid(values)
      if (!endTimeIsValid) {
        zodResolverResults[reservationTimeRefinementConfig.path.join(".")] = reservationTimeRefinementConfig.message
      }

      return zodResolverResults
    },
    transformValues: (values): ReservationDetails => {
      const baseSchema = getSchemaForReservationType(values.type)
      const shouldPruneExtraUsers =
        isFieldRelevantForReservationType("pic", values.type) && !isFieldRelevantForReservationType("users", values.type)
      const users = shouldPruneExtraUsers ? values.users.filter((u) => u.isPic) : values.users

      return baseSchema.parse({ ...values, users })
    },

    validateInputOnChange: true,
  })

  const picUser = form.values.users.find((user) => user.isPic)
  const locationInputProps = form.getInputProps("locationId")
  const locationDependentFieldsReady = form.values.locationId.length > 0 && !isLoading
  const handleCancelClick = () => {
    form.reset()
    onCancelClick()
  }

  useEffect(() => {
    const shouldPruneExtraUsers =
      isFieldRelevantForReservationType("pic", form.values.type) && !isFieldRelevantForReservationType("users", form.values.type)
    const users = shouldPruneExtraUsers ? form.values.users.filter((u) => u.isPic) : form.values.users

    onFormChange({ ...form.values, users })
  }, [form.values])

  const isPicRelevant = isFieldRelevantForReservationType("pic", form.values.type)
  const isUsersRelevant = isFieldRelevantForReservationType("users", form.values.type)
  const disableUserIfSelected = (user: User) => form.values.users.some((selectedUser) => selectedUser.userId === user.id)

  const userOptions = makeUserOptions(users, isUsersRelevant ? disableUserIfSelected : undefined)
  const handleSubmit = (values: TransformedValues<typeof form>) => {
    onSubmit(values)
  }

  return (
    <Box>
      <Title className={classes.title} order={4} fw={500} mb="sm">
        {mode === "create" ? "Schedule a new event" : "Edit reservation"}
      </Title>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <SegmentedControl color="blue.6" data={reservationTypeOptions} mb="xl" {...form.getInputProps("type")} disabled={mode === "edit"} />

        <ReservationFormRow label={<IconCurrentLocation {...labelIconProps} />}>
          <Select
            data={makeLocationOptions(locations)}
            w="100%"
            {...locationInputProps}
            onChange={(value) => {
              locationInputProps.onChange(value)
              form.setValues({
                users: [],
                aircraftId: "",
              })
            }}
          />
        </ReservationFormRow>

        {isFieldRelevantForReservationType("aircraft", form.values.type) && (
          <ReservationFormRow label={<IconPlaneTilt {...labelIconProps} />}>
            <Select
              data={makeAircraftOptions(aircraft)}
              w="100%"
              searchable
              nothingFound="No aircraft found"
              disabled={!locationDependentFieldsReady}
              {...form.getInputProps("aircraftId")}
            />
          </ReservationFormRow>
        )}

        {(isPicRelevant || isUsersRelevant) && (
          <Flex className={classes.field}>
            <Flex className={classes.label} align="flex-start" mt={8}>
              <IconUser {...labelIconProps} />
            </Flex>
            <Stack className={classes.input}>
              <Select
                data={userOptions}
                w="100%"
                searchable
                nothingFound="Couldn't find user"
                disabled={!locationDependentFieldsReady}
                value={isUsersRelevant ? null : picUser?.userId}
                placeholder="Add instructor or other pilots if needed"
                onChange={(userId) => {
                  if (userId != null) {
                    if (isUsersRelevant) {
                      const userAssociationObject = { isPic: false, userId }
                      form.setFieldValue("users", [...form.values.users, userAssociationObject])
                    } else {
                      const userAssociationObject = { isPic: true, userId }
                      // TODO: can do this with one pass with for loop
                      const normalizedUsers = form.values.users.map((formUser) => ({
                        ...formUser,
                        isPic: formUser.userId === userId,
                      }))
                      if (!normalizedUsers.some((formUser) => formUser.userId === userId)) {
                        normalizedUsers.push(userAssociationObject)
                      }
                      form.setFieldValue("users", normalizedUsers)
                    }
                  }
                }}
              />
              {isUsersRelevant && (
                <Stack spacing={5}>
                  {form.values.users.map((formUser) => {
                    // TODO: may want to use a Map here
                    const userWithRole = users.find((u) => formUser.userId === u.user.id)
                    const onRemove = () => {
                      const usersWithoutCurrent = form.values.users.filter((user) => user.userId !== formUser.userId)
                      form.setFieldValue("users", usersWithoutCurrent)
                    }
                    const onInChargeSelect = () => {
                      const newUsersValue = form.values.users.map((user) => {
                        return {
                          ...user,
                          isPic: user.userId === formUser.userId,
                        }
                      })
                      form.setFieldValue("users", newUsersValue)
                    }

                    return (
                      <PilotSelectionRow
                        key={formUser.userId}
                        inCharge={!!formUser.isPic}
                        name={getUserName(userWithRole?.user)}
                        onRemove={onRemove}
                        onInChargeSelect={onInChargeSelect}
                      />
                    )
                  })}
                </Stack>
              )}
            </Stack>
          </Flex>
        )}

        {/*
        {isFieldRelevantForReservationType("pic", form.values.type) && (
          <Flex className={classes.field}>
            <Flex className={classes.label}>
              <IconUser {...labelIconProps} />
              {getFieldLabelForReservationType("pic", form.values.type) ?? "Pilot"}
            </Flex>
            <Flex className={classes.input}>
              <Select
                data={makeUserOptions(users)}
                w="100%"
                searchable
                nothingFound="Couldn't find user"
                disabled={!locationDependentFieldsReady}
                value={picUser?.userId ?? null}
                onChange={(userId) => {
                  if (userId != null) {
                    const usersWithoutCurrent = form.values.users.filter((user) => user.userId !== userId)
                    const usersDowngradedNonPic = usersWithoutCurrent.map((user) => ({ ...user, isPic: false }))
                    const userAssociationObject = { isPic: true, userId }
                    form.setFieldValue("users", [...usersDowngradedNonPic, userAssociationObject])
                  }
                }}
              />
            </Flex>
          </Flex>
        )}
        {isFieldRelevantForReservationType("users", form.values.type) && (
          <Flex className={classes.field}>
            <Flex className={classes.label} align="start">
              <IconUserPlus {...labelIconProps} />
              Addl. Pilots
            </Flex>
            <Flex className={classes.input}>
              <MultiSelect
                data={makeUserOptions(users, (user) => user.id === picUser?.userId)}
                w="100%"
                searchable
                nothingFound="Couldn't find user"
                disabled={!locationDependentFieldsReady}
                value={form.values.users.filter((user) => !user.isPic).map((user) => user.userId)}
                onChange={(userIds) => {
                  if (userIds != null) {
                    const userAssociationObjects = userIds.map((userId) => ({ isPic: false, userId }))
                    const users =
                      picUser != null ? [{ isPic: true, userId: picUser.userId }, ...userAssociationObjects] : userAssociationObjects
                    form.setFieldValue("users", users)
                  }
                }}
              />
            </Flex>
          </Flex>
        )}
        */}

        {isFieldRelevantForReservationType("legs", form.values.type) && (
          <>
            <Flex className={classes.field}>
              <Flex className={classes.label} align="flex-start" mt={8}>
                <IconCurrentLocation {...labelIconProps} />
              </Flex>
              <Flex className={classes.input} direction="column">
                <FlightLegsInput form={form} />
              </Flex>
            </Flex>
            {Object.keys(form.errors).some((key) => {
              return key.startsWith("legs") && form.errors[key] === reservationLegLengthErrorMessage
            }) && (
              <Text color="red" w="100%" mt="sm">
                {reservationLegLengthErrorMessage}
              </Text>
            )}
          </>
        )}

        <Divider my="sm" />
        <Flex className={classes.field}>
          <Flex className={classes.label}>
            <IconClockHour4 {...labelIconProps} />
          </Flex>
          <Flex className={classes.input}>
            <DatePickerInput
              {...datePickerProps}
              defaultValue={value?.startTime ?? defaultStartDate.toDate()}
              onChange={(date) => {
                if (date != null) {
                  const handler = onDayChange(form, "startTime")
                  setAvailableTimesDate(date)
                  handler(date, "endTime")
                }
              }}
            />
            <TimePickerNew
              selectProps={{ w: 100 }}
              value={form.values.startTime}
              onChange={(date) => {
                if (date != null) {
                  const handler = onTimeChange(form, "startTime")
                  handler(date)
                }
              }}
            />
          </Flex>
        </Flex>
        <Flex className={classes.field} wrap="wrap">
          <Flex className={classes.label}>
            <IconClockHour8 {...labelIconProps} />
          </Flex>
          <Flex className={classes.input}>
            <DatePickerInput
              {...datePickerProps}
              defaultValue={value?.endTime ?? defaultEndDate.toDate()}
              value={form.values.endTime}
              onChange={(date) => {
                if (date != null) {
                  const handler = onDayChange(form, "endTime")
                  handler(date)
                }
              }}
            />
            <TimePickerNew selectProps={{ w: 100 }} value={form.values.endTime} onChange={onTimeChange(form, "endTime")} />
          </Flex>
          {form.errors["endTime"] && (
            <Text color="red" w="100%" mt="sm">
              {form.errors["endTime"]}
            </Text>
          )}
        </Flex>

        <Box>
          <Text ta="center" fw="bold">
            {" "}
            Available Times
          </Text>
          <Flex justify="space-around" w="100%">
            <ActionIcon onClick={decrementAvailableTimesDate} color="dark" variant="subtle">
              <IconChevronLeft />
            </ActionIcon>
            <Text fw={600}>{formatDate(availableTimesDate, { date: { includeWeekday: true } })}</Text>
            <ActionIcon onClick={incrementAvailableTimesDate} color="dark" variant="subtle">
              <IconChevronRight />
            </ActionIcon>
          </Flex>
          <Grid gutter="xs">
            {openTimeslots.map((timeslot) => {
              const isAllDay =
                dayjs(availableTimesDate).startOf("day").isSame(timeslot.start) &&
                dayjs(timeslot.end).isAfter(dayjs(availableTimesDate).endOf("day"))
              return (
                <Grid.Col key={`${timeslot.start.getTime()}-${timeslot.end.getTime()}`} span={isAllDay ? 12 : 6} px="md">
                  <Badge variant="outline" color="dark.5" w="100%">
                    <Flex justify="space-around">
                      {isAllDay ? (
                        <Text> All Day </Text>
                      ) : (
                        <>
                          <Text>{formatDate(timeslot.start, { time: true, timeFormatOverride: timeFormat })}</Text>
                          <Text px="10px">-</Text>
                          <Text>{formatDate(timeslot.end, { time: true, timeFormatOverride: timeFormat })}</Text>
                        </>
                      )}
                    </Flex>
                  </Badge>
                </Grid.Col>
              )
            })}
          </Grid>
        </Box>

        <Divider my="sm" />

        {isFieldRelevantForReservationType("legs", form.values.type) && (
          <Flex className={classes.field}>
            <Flex className={classes.label}>
              <Icon123 />
            </Flex>
            <Flex className={classes.input}>
              <NumberInput
                w="100%"
                {...form.getInputProps("estimatedFlightHours")}
                min={0}
                placeholder="Estimated flight hours"
                inputMode="decimal"
                precision={1}
              />
            </Flex>
          </Flex>
        )}

        <Flex className={classes.field}>
          <Flex className={classes.label}>
            <IconReport />
          </Flex>
          <Flex className={classes.input}>
            <Textarea w="100%" placeholder="Add notes or comments" {...form.getInputProps("comment")} />
          </Flex>
        </Flex>

        <Divider my="sm" />
        <Flex justify="space-between">
          <Button color="dark" variant="outline" onClick={handleCancelClick}>
            Cancel
          </Button>
          <Button type="submit">
            <IconCheck />
            {mode === "create" ? "Confirm Reservation" : "Update Reservation"}
          </Button>
        </Flex>
      </form>
    </Box>
  )
}
