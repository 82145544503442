import { Box, Button, Center, Divider, Flex, Group, Overlay, Paper, ScrollArea, Skeleton, Space, Stack, Text, Title } from "@mantine/core"
import { SafetyAssessmentGroup, SafetyAssessmentThreat } from "@soar/shared/types"
import { AssessmentThreatList } from "./AssessmentThreatList"

export function PreflightDetailSafetyCard({
  threats,
}: {
  threats?: SafetyAssessmentGroup
}) {
  return (
    <>
      <ScrollArea h="100%">
        <Flex pb="xl" pt="md" direction="column" mih="97vh">
          <Flex justify="space-between" align="flex-start" px="xl">
            <Box>
              <Title order={4} style={{ fontSize: "1.3rem" }}>
                Pre-flight
              </Title>
              <Title order={3} style={{ fontSize: "2.3rem" }}>
                Factors
              </Title>
            </Box>
            <Box />
          </Flex>
          <Space mx="xl" mt="xl" mb="lg" />
          <Box bg="#F2F7FF" style={{ flexGrow: 1, paddingBottom: "75px" }}>
            <Space h="xl" />
            {threats?.score != null && threats.score > 0 && (
              <Box px="lg" fw={700}>
                Score ({threats?.score})
              </Box>
            )}
            {threats != null && <AssessmentThreatList assessmentThreats={threats} />}
          </Box>
        </Flex>
      </ScrollArea>
    </>
  )
}
