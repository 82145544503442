import { Badge, BadgeProps, Text } from "@mantine/core"
import { StageOfFlight, TimeIntersectionResult } from "@soar/shared/types"
import { formatDate } from "@soar/shared/utils"
import dayjs from "dayjs"
import { useTimezone } from "./timezoneContext"

const stageOfFlightDisplay = {
  departure: "takeoff",
  enroute: "pass time",
  destination: "landing",
} as const

const badgeProps: BadgeProps = {
  variant: "dot",
  tt: "none",
  size: "lg",
}

export function TimeIntersectionDisplay({
  intersectionTime,
  timeIntersection,
  stageOfFlight,
}: {
  intersectionTime?: Date
  timeIntersection: TimeIntersectionResult
  stageOfFlight?: StageOfFlight
}) {
  const { departureTimeZone } = useTimezone()
  const intersectionTimeDay = intersectionTime != null ? dayjs(intersectionTime) : undefined
  const activeDuringFlight = timeIntersection.withinExact
  const color = activeDuringFlight ? "brandBlue.7" : "gray.7"
  const activeText = activeDuringFlight ? "Active" : "Inactive"
  const stageOfFlightText = stageOfFlight != null ? stageOfFlightDisplay[stageOfFlight] : ""

  if (intersectionTimeDay == null) {
    return (
      <Text c={color} fw={600}>
        {activeText} during flight
      </Text>
    )
  }

  return (
    <Text c={color} fw={600}>
      {activeText} @ {stageOfFlightText} (
      <Text span tt="lowercase">
        {formatDate(intersectionTimeDay, { time: true, timeZone: departureTimeZone })}
      </Text>
      )
    </Text>
  )
}
