import { Flex, Text, createStyles } from "@mantine/core"
import { ReactNode } from "react"

export const useInfoBoxStyles = createStyles((theme) => ({
  wrapper: {
    flexDirection: "row",
    flexWrap: "wrap",
    [theme.fn.largerThan("md")]: {
      flexDirection: "row",
    },
  },
  itemWrapper: {
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: "50%",
    [theme.fn.largerThan("md")]: {
      flexBasis: 0,
    },
    ["& + &"]: {
      borderColor: theme.colors.gray[3],
      borderTopStyle: "none",
      borderLeftStyle: "none",
      [theme.fn.largerThan("md")]: {
        borderLeftStyle: "solid",
        borderTopStyle: "none",
      },
    },
  },

  label: {},

  value: {},
}))

export function InfoBox({
  label,
  value,
}: {
  label: string
  value?: string | ReactNode
}) {
  const { cx, classes } = useInfoBoxStyles()
  return (
    <Flex className={classes.itemWrapper}>
      <Text fw={700} fz="md" mb={{ base: 2, md: "xs" }}>
        {label}
      </Text>
      <Text fw={500} fz="sm" color="gray.7">
        {value}
      </Text>
    </Flex>
  )
}
