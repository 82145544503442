import { MantineSize, Text, Title } from "@mantine/core"
import { SidewayAirplaneIcon } from "../icons/sidewayAirplaneIcon"

const sizeSpecs: Record<
  MantineSize,
  {
    gap: MantineSize
    fontSize: MantineSize
    iconSize: number
  }
> = {
  xs: {
    fontSize: "0.9rem",
    iconSize: 12,
    gap: "5px",
  },
  sm: {
    fontSize: "1.3rem",
    iconSize: 15,
    gap: "5px",
  },
  md: {
    fontSize: "xs",
    iconSize: 5,
    gap: "5",
  },
  lg: {
    fontSize: "2.3rem",
    iconSize: 25,
    gap: "lg",
  },
  xl: {
    fontSize: "xs",
    iconSize: 5,
    gap: "5",
  },
}

export function DepartureDestinationDisplay({ from, to, size = "lg" }: { from: string; to: string; size?: MantineSize }) {
  const sizeSpec = sizeSpecs[size] ?? sizeSpecs.lg
  return (
    <Title order={3} fz={sizeSpec.fontSize}>
      {from}
      <Text span mx={sizeSpec.gap}>
        <SidewayAirplaneIcon width={sizeSpec.iconSize} height={sizeSpec.iconSize} />
      </Text>
      {to}
    </Title>
  )
}
