import { Box, Button, Divider, Flex, Space, Stack, Text, Title } from "@mantine/core"
import { FlightLegDecision, FlightLegDecisionRecord, FlightLegScoreAdjustment, SafetyAssessmentRecord } from "@soar/shared/types"
import { FlightLegDecisionConfig, FlightLegStatusConfig, formatDate } from "@soar/shared/utils"
import { match } from "ts-pattern"
import { InfoBox, useInfoBoxStyles } from "../infoBox"
import { AssessmentThreatDisplayList, AssessmentThreatList } from "./AssessmentThreatList"

export function RiskAssessmentView({
  riskAssessment,
  timezone,
  decision,
  scoreAdjustment,
  userName = "Unknown",
  canApprove = false,
  onDecision = () => {},
}: {
  riskAssessment?: SafetyAssessmentRecord
  decision?: FlightLegDecisionRecord
  scoreAdjustment?: FlightLegScoreAdjustment
  timezone?: string
  userName?: string
  canApprove?: boolean
  onDecision?: (decision: FlightLegDecision) => void
}) {
  const { cx, classes } = useInfoBoxStyles()
  const state = {
    isInReview: scoreAdjustment != null && decision == null,
    canApprove,
    decision: decision?.decision,
  }
  return (
    <Box>
      <Box c="dimmed" fw={700}>
        {match(state)
          .with({ decision: "approve" }, () => FlightLegStatusConfig.approved.label)
          .with({ decision: "deny" }, () => FlightLegStatusConfig.on_hold.label)
          .with({ isInReview: true, canApprove: true }, () => FlightLegStatusConfig.awaiting_approval.label)
          .otherwise(() => FlightLegStatusConfig.approved.label)}
      </Box>
      <Flex justify="space-between" pb="lg" align="center">
        <Box fw={700} fz="2.3rem" ml={-2}>
          {scoreAdjustment != null
            ? `${scoreAdjustment.previousScore} to ${scoreAdjustment.newScore}`
            : (riskAssessment?.assessment.results.preflight.score ?? 0) +
              (riskAssessment?.assessment.results.departure.score ?? 0) +
              (riskAssessment?.assessment.results.enroute.score ?? 0) +
              (riskAssessment?.assessment.results.destination.score ?? 0)}
        </Box>
        <Flex>
          {state.isInReview && canApprove && (
            <>
              <Button variant="subtle" onClick={() => onDecision("approve")}>
                {FlightLegDecisionConfig.approve.cta}
              </Button>
              <Button variant="subtle" onClick={() => onDecision("deny")}>
                {FlightLegDecisionConfig.deny.cta}
              </Button>
            </>
          )}
        </Flex>
      </Flex>
      <Divider mb={5} />
      <Flex className={cx(classes.wrapper)}>
        <InfoBox label="By" value={userName} />
        <InfoBox
          label="At"
          value={
            <Text c="gray.7" fw={500}>
              {formatDate(new Date(riskAssessment?.createdAt ?? 0), {
                date: { format: "long" },
                time: true,
                includeTimezone: true,
                timeZone: timezone,
              })}
            </Text>
          }
        />
      </Flex>
      <Space h="xl" my="sm" />
      <Title order={4} fz="1.5rem">
        Results
      </Title>
      <Divider mt={4} mb="md" />
      <Stack spacing="xl" px="sm" pt="xs">
        {scoreAdjustment != null && (
          <Box py="sm">
            <Title order={5} fz="lg">
              Rationale for adjusted risk
            </Title>
            <Text
              fz="sm"
              c="dimmed"
              pl={5}
              mt={5}
              sx={(theme) => ({
                borderLeft: `1px solid ${theme.colors.gray[5]}`,
              })}
            >
              {scoreAdjustment?.comment}
            </Text>
          </Box>
        )}
        <Box>
          <Title order={5} fz="lg">
            Preflight
          </Title>
          <AssessmentThreatDisplayList
            assessmentThreats={riskAssessment?.assessment.results.preflight}
            mitigations={riskAssessment?.mitigations}
          />
        </Box>
        <Box>
          <Text fw={700} fz="lg">
            Departure
          </Text>
          <AssessmentThreatDisplayList
            assessmentThreats={riskAssessment?.assessment.results.departure}
            mitigations={riskAssessment?.mitigations}
          />
        </Box>
        <Box>
          <Text fw={700} fz="lg">
            Enroute
          </Text>
          <AssessmentThreatDisplayList
            assessmentThreats={riskAssessment?.assessment.results.enroute}
            mitigations={riskAssessment?.mitigations}
          />
        </Box>
        <Box>
          <Text fw={700} fz="lg">
            Arrival
          </Text>
          <AssessmentThreatDisplayList
            assessmentThreats={riskAssessment?.assessment.results.destination}
            mitigations={riskAssessment?.mitigations}
          />
        </Box>
      </Stack>
    </Box>
  )
}
