import { Flex, Text } from "@mantine/core"
import { IconExclamationCircle } from "@tabler/icons-react"

export function AlternateTafBanner({
  target,
  source,
}: {
  target: string
  source: string
}) {
  return (
    <Flex fw={600} c="orange.7" align="flex-start" fz="sm">
      <Flex ml="-2px" mr="3px" mt="1px" align="center">
        <IconExclamationCircle size={18} />
      </Flex>
      <Text>
        Forecasted weather data is unavailable at {target}. TAF data from {source} used instead.
      </Text>
    </Flex>
  )
}
