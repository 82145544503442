import { createStyles } from "@mantine/core"

export const useSavedAirportStyles = createStyles((theme) => ({
  saveAirportIcon: {
    "&.saved": {
      fontVariationSettings: "'FILL' 1",
      color: "#F95349",
    },
  },
  overviewInfoIcon: {
    //fontSize: `${theme.fontSizes.lg} !important`,
  },
}))
