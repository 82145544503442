import { Box, Button, ButtonProps, Checkbox, Select, Stack, Textarea, Title } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import { showNotification } from "@mantine/notifications"
import { BUTTON_GRADIENT_COLORS, ModalOrDrawer } from "@soar/frontend/components"
import { organizationsForUserAtom } from "@soar/frontend/contexts"
import { trpc } from "@soar/frontend/trpc-client"
import { SafetyReport, SafetyReportInput, SafetyReportInputSchema, SafetyReportSchema } from "@soar/shared/types"
import { useAtomValue } from "jotai"
import { ReactNode, useEffect } from "react"

export function SafetyReportingButton({
  children = "Open",
  buttonProps = {},
}: {
  children?: ReactNode
  buttonProps?: ButtonProps
}) {
  const organizations = useAtomValue(organizationsForUserAtom)
  const [modalState, modalHandlers] = useDisclosure(false)
  if ((organizations ?? []).length === 0) {
    return <Box />
  }
  return (
    <>
      <Button {...buttonProps} onClick={modalHandlers.open}>
        {children}
      </Button>
      <SafetyReportModal opened={modalState} onClose={modalHandlers.close} />
    </>
  )
}

export function SafetyReportModal({
  opened,
  onClose = () => {},
  onSuccess = () => {},
}: {
  opened: boolean
  onClose?: () => void
  onSuccess?: (post: SafetyReport) => void
}) {
  const { mutateAsync } = trpc.safetyReport.createSafetyReport.useMutation()
  const organizations = useAtomValue(organizationsForUserAtom)

  const handleSubmit = (safetyReport: SafetyReport) => {
    onClose()
    form.reset()
    showNotification({
      color: "green",
      message: "Safety report has been submitted!",
    })
    onSuccess(safetyReport)
  }

  const form = useForm<SafetyReportInput>({
    initialValues: {
      details: "",
      organizationId: "",
      isAnonymous: false,
    },
    validate: zodResolver(SafetyReportInputSchema),
  })

  useEffect(() => {
    if (organizations != null) {
      form.setValues({
        organizationId: organizations[0].id,
      })
    }
  }, [organizations])

  return (
    <ModalOrDrawer opened={opened} onClose={onClose} title={<Title order={3}>Submit safety report</Title>}>
      <Box
        component="form"
        onSubmit={form.onSubmit(async (values) => {
          try {
            const safetyReportResponse = await mutateAsync({
              ...values,
            })
            const safetyReport = SafetyReportSchema.parse(safetyReportResponse)

            handleSubmit(safetyReport)
          } catch {}
        })}
      >
        <Stack>
          <Select
            label="Organization"
            data={organizations?.map((org) => ({ value: org.id, label: org.name })) ?? []}
            {...form.getInputProps("organizationId")}
          />

          <Textarea label="Report details" {...form.getInputProps("details")} />

          <Checkbox label="Anonymous report?" {...form.getInputProps("isAnonymous")} />

          <Button variant="gradient" gradient={{ ...BUTTON_GRADIENT_COLORS, deg: 180 }} type="submit">
            Submit
          </Button>
        </Stack>
      </Box>
    </ModalOrDrawer>
  )
}
