import type { Dayjs } from "dayjs"
import { IReservation, Reservation } from "./Reservation"

export const DENSITY = {
  EMPTY: "EMPTY",
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH",
  FULL: "FULL",
  BLOCKED: "BLOCKED",
} as const

export type Density = typeof DENSITY[keyof typeof DENSITY]
export type IWeekDensityOld = {
  density: Density[]
  reservations: IReservation[]
  day: Dayjs
}

export type IWeek = {
  reservations: Reservation[]
  day: Dayjs
}

export type IWeekDensity = IWeek & {
  density: Density[]
}
