import { Box, Button, Divider, Flex, List, Loader, Stack, Stepper, Text, ThemeIcon, Title } from "@mantine/core"
import { LocationDetails, OrganizationDetailsWithAvatar } from "@soar/shared/types"
import { IconCheck, IconCircleX, IconExternalLink, IconPlane, IconUsers } from "@tabler/icons-react"
import { useReducer, useState } from "react"
import { CustomLink } from "../../customLink"
import { EditLocationForm } from "../../locations"
import { NewOrganizationForm } from "../form"

type StepAction = { type: "next" } | { type: "previous" }

type StepState = {
  hasNext: boolean
  hasPrevious: boolean
  step: number
  maxSteps: number
}

function stepsReducer(state: StepState, action: StepAction): StepState {
  if (action.type === "next") {
    const canNextStep = state.step < state.maxSteps - 1
    const nextStep = canNextStep ? state.step + 1 : state.step
    return {
      hasPrevious: nextStep > 0 && state.maxSteps > 1,
      hasNext: nextStep < state.maxSteps - 1,
      step: nextStep,
      maxSteps: state.maxSteps,
    }
  } else if (action.type === "previous") {
    const canPrevStep = state.step > 0 && state.maxSteps > 1
    const prevStep = canPrevStep ? state.step - 1 : state.step
    return {
      hasPrevious: prevStep > 0 && state.maxSteps > 1,
      hasNext: prevStep < state.maxSteps - 1,
      step: prevStep,
      maxSteps: state.maxSteps,
    }
  } else {
    return state
  }
}

type PartialLocationDetails = Omit<LocationDetails, "organizationId">
type QueryStatus = "idle" | "loading" | "error" | "success"

function labelForStatus(status?: QueryStatus) {
  switch (status) {
    case "loading":
      return "Creating"
    case "error":
      return "Error creating"
    case "success":
      return "Created"
    default:
      return "Creating"
  }
}
function iconForStatus(status?: QueryStatus) {
  switch (status) {
    case "loading":
      return <Loader size="sm" />
    case "error":
      return (
        <ThemeIcon size="lg" radius="lg" color="red">
          <IconCircleX />
        </ThemeIcon>
      )
    case "success":
      return (
        <ThemeIcon size="lg" radius="lg" color="green">
          <IconCheck />
        </ThemeIcon>
      )
    default:
      return <Loader size="sm" color="gray" />
  }
}

export function CreateOrganizationWizard({
  onSubmit,
  createOrganizationStatus,
  createLocationStatus,
  addUserStatus,
}: {
  onSubmit?: (orgDetails: OrganizationDetailsWithAvatar, locationDetails: PartialLocationDetails) => void
  createOrganizationStatus?: QueryStatus
  createLocationStatus?: QueryStatus
  addUserStatus?: QueryStatus
}) {
  const [{ step }, dispatch] = useReducer(stepsReducer, { hasNext: true, hasPrevious: false, step: 0, maxSteps: 3 })
  const nextStep = () => dispatch({ type: "next" })
  const previousStep = () => dispatch({ type: "previous" })

  const [orgDetails, setOrgDetails] = useState<OrganizationDetailsWithAvatar | undefined>()
  const [locationDetails, setLocationDetails] = useState<PartialLocationDetails | undefined>()

  const onOrgSubmitClick = (submittedOrgDetails: OrganizationDetailsWithAvatar) => {
    setOrgDetails(submittedOrgDetails)
    nextStep()
  }

  const onLocationSubmitClick = (submittedLocationDetails: PartialLocationDetails) => {
    setLocationDetails(submittedLocationDetails)
    if (onSubmit != null) {
      onSubmit(orgDetails!, submittedLocationDetails)
    }
    nextStep()
  }

  const statuses = [createOrganizationStatus, createLocationStatus, addUserStatus]
  const allSuccessful = statuses.every((status) => status === "success")
  const hasError = statuses.some((status) => status === "error")

  let headerText = "Finishing up..."

  if (allSuccessful) {
    headerText = "All finished!"
  }
  if (hasError) {
    headerText = "Something went wrong."
  }

  return (
    <Box>
      <Stepper active={step} breakpoint="sm">
        <Stepper.Step label="First step" description="Enter Organization Details">
          <NewOrganizationForm submitButtonText="Next" onSubmit={onOrgSubmitClick} initialValues={orgDetails} />
        </Stepper.Step>
        <Stepper.Step label="Second step" description="Enter Location Details">
          <EditLocationForm
            submitButtonText="Create Organization"
            onSubmit={onLocationSubmitClick}
            initialValues={locationDetails}
            replaceResetButtonWith={
              <Button color="gray" onClick={previousStep}>
                Previous
              </Button>
            }
          />
        </Stepper.Step>
        <Stepper.Completed>
          <Flex justify="center" direction="column" align="center" mt="xl">
            <Title order={3}>{headerText}</Title>
            <Stack w="300px" mt="xl">
              <Flex align="center">
                <Flex mr="lg" align="center">
                  {iconForStatus(createOrganizationStatus)}
                </Flex>
                {labelForStatus(createOrganizationStatus)} organization.
              </Flex>
              <Flex align="center">
                <Flex mr="lg" align="center">
                  {iconForStatus(createLocationStatus)}
                </Flex>
                {labelForStatus(createLocationStatus)} first location.
              </Flex>
              <Flex align="center">
                <Flex mr="lg" align="center">
                  {iconForStatus(addUserStatus)}
                </Flex>
                {labelForStatus(addUserStatus)} first user for the organization.
              </Flex>
              {allSuccessful && (
                <>
                  <Divider my="xl" />
                  <Title order={4}>What's next?</Title>
                  <List spacing="md" listStyleType="none">
                    <List.Item
                      icon={
                        <ThemeIcon variant="light" radius="xl">
                          <IconPlane />
                        </ThemeIcon>
                      }
                    >
                      <CustomLink href="/manage/aircraft" passHref legacyBehavior>
                        <Text component="a">
                          <Flex align="center">
                            Add your first aircraft.
                            <IconExternalLink style={{ marginLeft: "3px" }} size={16} />
                          </Flex>
                        </Text>
                      </CustomLink>
                    </List.Item>
                    <List.Item
                      icon={
                        <ThemeIcon variant="light" radius="xl">
                          <IconUsers />
                        </ThemeIcon>
                      }
                    >
                      <CustomLink href="/manage/users" passHref legacyBehavior>
                        <Text component="a">
                          <Flex align="center">
                            Add some more users.
                            <IconExternalLink style={{ marginLeft: "3px", flexShrink: 0 }} size={16} />
                          </Flex>
                        </Text>
                      </CustomLink>
                    </List.Item>
                  </List>
                </>
              )}
            </Stack>
          </Flex>
        </Stepper.Completed>
      </Stepper>
    </Box>
  )
}
