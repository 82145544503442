import {
  Anchor,
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Paper,
  PasswordInput,
  SimpleGrid,
  SimpleGridBreakpoint,
  Stack,
  Text,
  TextInput,
  Title,
  createStyles,
  useMantineTheme,
} from "@mantine/core"
import { InstructorCertType, User } from "@soar/shared/types"
import { isRegisteredUser, labelForInstructorCertType, labelForMedicalCertType, labelForPilotLicenseType } from "@soar/shared/utils"
import dayjs from "dayjs"
import { CustomLink } from "../../customLink"
import { UserProfileUnregistered } from "./UserProfileUnregistered"

const useStyles = createStyles((theme) => ({
  avatar: {
    borderRadius: "100%",
  },
  nickname: {
    minHeight: "1em",
  },
  detailsGrid: {
    flexGrow: 1,
    marginTop: "2px",
  },
  detailsHeader: {
    fontSize: theme.fontSizes.md,
    color: theme.colors.gray[6],
    fontWeight: 500,
  },
  detailsContent: {
    fontWeight: 500,
  },
  certCardHeader: {
    fontSize: theme.fontSizes.md,
  },
}))

export function UserProfile({
  user,
  canEdit = false,
  canResetPassword,
  onResetPasswordClick,
}: { user: User; canEdit?: boolean; canResetPassword?: boolean; onResetPasswordClick?: () => void }) {
  const isRegistered = isRegisteredUser(user)
  if (!isRegistered) {
    return <UserProfileUnregistered email={user.email} />
  }

  const { classes } = useStyles()
  const theme = useMantineTheme()

  const certGridCols: SimpleGridBreakpoint[] = [
    { maxWidth: theme.breakpoints.xs, cols: 1 },
    { maxWidth: theme.breakpoints.sm, cols: 2 },
    { maxWidth: theme.breakpoints.xl, cols: 3 },
  ]
  const detailGridCols: SimpleGridBreakpoint[] = [
    { maxWidth: theme.breakpoints.xs, cols: 1 },
    { maxWidth: theme.breakpoints.xl, cols: 3 },
  ]

  const validInstructorCerts = Object.entries(user.instructorCerts ?? {}).filter(([_certType, details]) => details.enabled)
  const hasInstructorCerts = user.instructorCerts != null && validInstructorCerts.length > 0

  return (
    <Box h="100%" w="100%">
      <Flex mb="xl" justify="space-between">
        <Flex align="center">
          <Avatar src={user.avatarUrl} size="xl" className={classes.avatar} mr="xl">
            {user.firstName.at(0)?.toUpperCase()}
            {user.lastName.at(0)?.toUpperCase()}
          </Avatar>
          <Box>
            <Title>
              {user.firstName} {user.lastName}
            </Title>
            <Text c="gray.6" ml="3px" fw={600} className={classes.nickname}>
              {user.nickname}
            </Text>
            {canResetPassword && (
              <Button variant="subtle" onClick={onResetPasswordClick} compact>
                Reset Password
              </Button>
            )}
          </Box>
        </Flex>
        <Flex align="center" justify="flex-end">
          {canEdit && (
            <CustomLink href="/profile/edit" passHref legacyBehavior>
              <Button component="a">Edit</Button>
            </CustomLink>
          )}
        </Flex>
      </Flex>
      <Stack spacing="xl" mt="sm">
        <Box>
          <Title order={2} mb="md">
            Certifications
          </Title>
          <SimpleGrid cols={3} spacing="xl" breakpoints={certGridCols}>
            {user.pilotCertType != null && (
              <Paper shadow="md" radius="sm" p="md">
                <Title order={3} className={classes.certCardHeader}>
                  Pilot certificate
                </Title>
                <Divider color="gray.2" mt="xs" mb="sm" />
                <Box>
                  <Title order={4} tt="capitalize">
                    {labelForPilotLicenseType(user.pilotCertType)} license
                  </Title>
                </Box>
              </Paper>
            )}
            {hasInstructorCerts && (
              <Paper shadow="md" radius="sm" p="md">
                <Title order={3} className={classes.certCardHeader}>
                  Instructor certificate
                </Title>
                <Divider color="gray.2" mt="xs" mb="sm" />
                <Box>
                  {validInstructorCerts.map(([certType, details]) => (
                    <Flex justify="space-between">
                      <Title order={5}>{labelForInstructorCertType(certType as InstructorCertType)}</Title>
                      <Text fz="sm" c="dimmed" ta="right">
                        {" "}
                        Expires: {dayjs(details.expiration).format("MM/DD/YYYY")}{" "}
                      </Text>
                    </Flex>
                  ))}
                </Box>
              </Paper>
            )}
            {user.medicalCertType != null && (
              <Paper shadow="md" radius="sm" p="md">
                <Title order={3} className={classes.certCardHeader}>
                  Medical certificate
                </Title>
                <Divider color="gray.2" mt="xs" mb="sm" />
                <Box>
                  <Title order={4}>{labelForMedicalCertType(user.medicalCertType)}</Title>
                  <Text> Date of Exam: {dayjs(user.medicalCertExamDate).format("MM/DD/YYYY")} </Text>
                </Box>
              </Paper>
            )}
          </SimpleGrid>
        </Box>
        <Box>
          <Title order={2} mb="xl">
            About
          </Title>
          <SimpleGrid cols={3} verticalSpacing="lg" className={classes.detailsGrid} breakpoints={detailGridCols}>
            <Box p="md">
              <Title order={3} className={classes.detailsHeader}>
                Home address
              </Title>
              <Box className={classes.detailsContent}>
                <Text>{user.homeAddressStreet1}</Text>
                {(user.homeAddressStreet2 ?? "").length > 0 && <Text>{user.homeAddressStreet2}</Text>}
                <Text>
                  {user.homeAddressCity != null ? `${user.homeAddressCity}, ` : ""} {user.homeAddressState ?? ""}{" "}
                  {user.homeAddressZip ?? ""}
                </Text>
              </Box>
            </Box>
            <Box p="md">
              <Title order={3} className={classes.detailsHeader}>
                Phone number
              </Title>
              <Box className={classes.detailsContent}>
                <Text>{user.phone ?? "Not entered"}</Text>
              </Box>
            </Box>
            <Box p="md">
              <Title order={3} className={classes.detailsHeader}>
                Email Address
              </Title>
              <Box className={classes.detailsContent}>
                <Text>{user.email}</Text>
              </Box>
            </Box>
            <Box p="md">
              <Title order={3} className={classes.detailsHeader}>
                Emergency Contact
              </Title>
              <Box className={classes.detailsContent}>
                {(user.emergencyContactName ?? "").length > 0 && <Text>{user.emergencyContactName}</Text>}
                {(user.emergencyContactEmail ?? "").length > 0 && <Text>{user.emergencyContactEmail}</Text>}
                {(user.emergencyContactPhone ?? "").length > 0 && <Text>{user.emergencyContactPhone}</Text>}
              </Box>
            </Box>
            <Box hidden>
              <Title order={3} className={classes.detailsHeader}>
                Photo ID
              </Title>
              <Box className={classes.detailsContent}>
                <Text component="a" href="#" c="blue.7">
                  {"Driver's license"}
                </Text>
              </Box>
            </Box>
            <Box hidden>
              <Title order={3} className={classes.detailsHeader}>
                TSA citizenship verification
              </Title>
              <Box className={classes.detailsContent}>
                <Text component="a" href="#" c="blue.7">
                  {"US Passport"}
                </Text>
              </Box>
            </Box>
          </SimpleGrid>
        </Box>
      </Stack>
    </Box>
  )
}
