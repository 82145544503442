import dayjs from "dayjs"
import UTC from "dayjs/plugin/utc"
import { z } from "zod"

dayjs.extend(UTC)

/*
    "flight": {
      "quote": null,
      "customer": {
        "id": 206884,
        "name": "Quickbooks Test"
      },
      "id": 652047,
      "operationType": 1,
      "status": 0,
      "title": "",
      "tripNumber": "448Z-3",
      "utcCreated": "2024-03-26T17:51:16.4687700",
      "utcModified": "2024-03-26T17:51:37.4585881"
    },
    "arrvFBO": {
      "id": 1220,
      "name": "Atlantic Aviation"
    },
    "arrvFBOPrivate": null,
    "crew": [
      {
        "dateOfBirth": "1983-12-29T00:00:00.0000000",
        "email": "bigred23z@gmail.com",
        "gender": "M",
        "medicalClass": 0,
        "medicalDate": "0001-01-01T00:00:00.0000000",
        "nameFirst": "Aaron",
        "nameLast": "Zamboni",
        "nameMiddle": "",
        "priority": 1,
        "type": 1,
        "userProfileID": 17
      },
      {
        "dateOfBirth": "1983-01-12T00:00:00.0000000",
        "email": "matthew@247.dev",
        "gender": "M",
        "medicalClass": 0,
        "medicalDate": "0001-01-01T00:00:00.0000000",
        "nameFirst": "Matt",
        "nameLast": "O'Leary",
        "nameMiddle": "Lee",
        "priority": 1,
        "type": 1,
        "userProfileID": 19
      }
    ],
    "deptFBO": null,
    "deptFBOPrivate": null,
    "passengers": [
      {
        "gender": "M",
        "passengerID": 49513,
        "weightLB": 123
      },
      {
        "gender": "",
        "passengerID": 720607,
        "weightLB": 0
      },
      {
        "gender": "F",
        "passengerID": 167715,
        "weightLB": 143
      }
    ],
    "arrvAirport": {
      "city": "Los Angeles",
      "country": "US",
      "iata": "LAX",
      "icao": "KLAX",
      "name": "Los Angeles Intl",
      "state": "CA"
    },
    "arrvCustomLocation": null,
    "arrvLocal": "2024-04-30T19:29:00.0000000",
    "arrvUTC": "2024-05-01T02:29:00.0000000",
    "deptAirport": {
      "city": "Big Timber",
      "country": "US",
      "iata": "6S0",
      "icao": "K6S0",
      "name": "Big Timber",
      "state": "MT"
    },
    "deptCustomLocation": null,
    "deptLocal": "2024-04-30T18:15:00.0000000",
    "deptUTC": "2024-05-01T00:15:00.0000000",
    "id": 1747146,
    "isTBA": false
*/

export const AirplaneManagerSMSFlightComponent = z.object({
  customer: z.object({
    id: z.number(),
    name: z.string(),
  }),
  id: z.number(),
  operationType: z.number(),
  status: z.number(),
  title: z.string(),
  tripNumber: z.string(),
  utcCreated: z.coerce.date(),
  utcModified: z.coerce.date(),
  quote: z
    .object({
      id: z.number(),
      quoteNumber: z.string(),
      utcCreated: z.coerce.date(),
      utcModified: z.coerce.date(),
    })
    .nullish(),
})

export const AirplaneManagerSMSAirportComponent = z.object({
  city: z.string(),
  country: z.string(),
  iata: z.string(),
  icao: z.string(),
  name: z.string(),
  state: z.string(),
})

export const AirplaneManagerSMSCustomLocationComponent = z.object({
  city: z.string(),
  code: z.string(),
  country: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  name: z.string(),
  state: z.string(),
})

export const AirplaneManagerSMSGenderComponent = z.enum(["M", "F", ""]).nullish()
export const AirplaneManagerSMSPassengerComponent = z.object({
  gender: AirplaneManagerSMSGenderComponent,
  passengerID: z.number(),
  weightLB: z.number(),
})

export const AirplaneManagerSMSCrewComponent = z.object({
  userProfileID: z.number(),
  dateOfBirth: z.coerce.date(),
  gender: AirplaneManagerSMSGenderComponent,
  email: z.string().nullish(),
  medicalClass: z.number(),
  medicalDate: z.coerce.date(),
  nameFirst: z.string().nullish(),
  nameMiddle: z.string().nullish(),
  nameLast: z.string().nullish(),
  priority: z.number(),
  type: z.number(),
})

export const AirplaneManagerSMSAircraftComponent = z.object({
  faaTypeID: z.string(),
  id: z.number(),
  makeModel: z.string(),
  tailNumber: z.string(),
  type: z
    .literal(1)
    .or(z.literal(2))
    .or(z.literal(3))
    .or(z.literal(4))
    .or(z.literal(5))
    .or(z.literal(5))
    .or(z.literal(6))
    .or(z.literal(7))
    .or(z.literal(8))
    .or(z.literal(9)),
})

export const utcDateTransformSchema = z
  .string()
  .transform((string) => {
    return dayjs.utc(string).toDate()
  })
  .pipe(z.date())

export const AirplaneManagerSMSFlightLegSchema = z.object({
  id: z.number(),
  flight: AirplaneManagerSMSFlightComponent,
  isTBA: z.boolean(),
  deptLocal: z.coerce.date(),
  deptUTC: utcDateTransformSchema,
  arrvLocal: z.coerce.date(),
  arrvUTC: utcDateTransformSchema,
  aircraft: AirplaneManagerSMSAircraftComponent.nullish(),

  deptAirport: AirplaneManagerSMSAirportComponent.nullish(),
  deptCustomLocation: AirplaneManagerSMSCustomLocationComponent.nullish(),

  arrvAirport: AirplaneManagerSMSAirportComponent.nullish(),
  arrvCustomLocation: AirplaneManagerSMSCustomLocationComponent.nullish(),

  passengers: AirplaneManagerSMSPassengerComponent.array(),
  crew: AirplaneManagerSMSCrewComponent.array(),
})

export type AirplaneManagerSMSFlightLeg = z.infer<typeof AirplaneManagerSMSFlightLegSchema>
