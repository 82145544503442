import { Box, Button, Divider, Flex, Group, Paper, Skeleton, Text, Title } from "@mantine/core"
import { IEnrouteRisk, Risk, SafetyAssessmentGroup, SafetyBriefMode, StageOfFlight } from "@soar/shared/types"
import { AssessmentThreatColors, StageOfFlightLabels, getAssessmentThreatScoreLevel } from "@soar/shared/utils"
import { SidewayAirplaneIcon } from "../icons/sidewayAirplaneIcon"
import { DepartureDestinationDisplay } from "./departureDestinationDisplay"
import { OverviewSafetyAssessmentGroup, OverviewSafetyRisksGroup, OverviewThreatCount } from "./overviewCardCommon"

export function EnrouteOverviewSafetyCard({
  from,
  to,
  flightTime,
  risks,
  onExpandClick = () => {},
  assessmentThreats,
  safetyBriefMode,
  loading = false,
}: {
  loading?: boolean
  from: string
  to: string
  flightTime?: string
  risks?: IEnrouteRisk
  assessmentThreats?: SafetyAssessmentGroup
  safetyBriefMode: SafetyBriefMode
  onExpandClick?: (stageOfFlight: StageOfFlight) => void
}) {
  const allRisks = (risks?.notamRisks ?? [])
    .concat(risks?.airSigmetRisks ?? [])
    .concat(risks?.pirepsRisks ?? [])
    .concat(
      (risks?.airspace ?? []).flatMap((airspaceWithIntersection) => {
        return airspaceWithIntersection.data.risks
      }),
    )
    .sort((a, b) => b.severity - a.severity)

  const safetyAssessmentLevel = assessmentThreats != null ? getAssessmentThreatScoreLevel(assessmentThreats?.score) : undefined
  const safetyAssessmentColor = safetyAssessmentLevel != null ? AssessmentThreatColors[safetyAssessmentLevel] : undefined

  return (
    <Paper radius="lg" pt="sm" pb="md" px="lg" shadow="lg">
      <Flex justify="space-between" align="flex-start">
        <Box>
          <Title order={4} style={{ fontSize: "1.3rem" }}>
            {StageOfFlightLabels.enroute}
          </Title>
          <DepartureDestinationDisplay from={from} to={to} />
          <Text fw={600}>
            Flight time: {flightTime != null ? flightTime : <Skeleton display="inline-block" height={13} width={40} mx="2px" />}
          </Text>
        </Box>
        <Box />
      </Flex>
      <Divider mx="xl" mt="xl" mb="lg" />
      <Title order={5} mb="sm">
        <OverviewThreatCount
          safetyBriefMode={safetyBriefMode}
          assessmentThreats={assessmentThreats}
          isLoading={risks == null}
          risks={allRisks}
        />
      </Title>
      {safetyBriefMode === "data" ? (
        <OverviewSafetyRisksGroup risks={allRisks} />
      ) : (
        <OverviewSafetyAssessmentGroup assessmentThreats={assessmentThreats} color={safetyAssessmentColor} />
      )}
      {/*
      <Flex align="center" justify="center" mt="xl">
        <Button variant="subtle" onClick={() => onExpandClick("enroute")}>
          View Details
        </Button>
      </Flex>
      */}
    </Paper>
  )
}
