import { Flex, Text } from "@mantine/core"
import { IconExclamationCircle } from "@tabler/icons-react"

export function WeatherDataBanner() {
  return (
    <Flex fw={600} c="orange.7" align="flex-start" fz="sm">
      <Flex ml="-2px" mr="3px" mt="2px" align="center">
        <IconExclamationCircle size={18} />
      </Flex>
      <Text>METAR and TAF data unavailable for selected time period: Weather data is prediction based.</Text>
    </Flex>
  )
}
