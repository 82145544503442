import { Flex, Skeleton, Text } from "@mantine/core"
import { AirportFlightMetrics } from "@soar/shared/types"
import { formatDate } from "@soar/shared/utils"
import { P, match } from "ts-pattern"
import { calculateTimeOfDayTimeToDisplay } from "./utils"

export function SunsetSunriseDisplay({
  date,
  airportFlightMetrics,
}: {
  date?: Date
  airportFlightMetrics?: AirportFlightMetrics
}) {
  const timeOfDayTimeToDisplay = calculateTimeOfDayTimeToDisplay(date, airportFlightMetrics)
  return (
    <Flex align="center">
      <span className="material-symbols-outlined">{timeOfDayTimeToDisplay === "sunset" ? "clear_night" : "sunny"}</span>
      <Text span ml="xs" w={130}>
        {match({ timeOfDayTimeToDisplay, airportFlightMetrics })
          .with({ airportFlightMetrics: P.nullish }, () => <Skeleton width={95} height={12} display="inline-block" />)
          .with({ airportFlightMetrics: { sunrise: P.not(P.nullish) }, timeOfDayTimeToDisplay: "sunrise" }, (result) =>
            formatDate(result.airportFlightMetrics.sunrise, { time: true, timeZone: result.airportFlightMetrics?.timeZoneName }),
          )
          .with({ airportFlightMetrics: { sunset: P.not(P.nullish) }, timeOfDayTimeToDisplay: "sunset" }, (result) =>
            formatDate(result.airportFlightMetrics.sunset, { time: true, timeZone: result.airportFlightMetrics?.timeZoneName }),
          )
          .otherwise(() => (
            <>Not available</>
          ))}
      </Text>
    </Flex>
  )
}
