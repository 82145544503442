import { z } from "zod"

// No idea how i'mma do this with zod.switch coming but here's something for now
export const BaseEventSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  createdBy: z.string(),
  type: z.string().min(5),
  version: z.number().min(1),
  parentId: z.string(),
  parentType: z.string().optional(),
  details: z.object({}),
})
