import { Badge, Box, Button, Flex, Indicator, Portal, Text, createStyles } from "@mantine/core"
import { headerButtonPortalId } from "@soar/frontend/components"
import { atom, useAtomValue } from "jotai"
import { useIntercom } from "react-use-intercom"

export const intercomUnreadAtom = atom<number>(0)

const useStyles = createStyles({
  fontSize: {
    fontSize: "20px !important",
  },
})

export function IntercomLink() {
  const { show } = useIntercom()
  const intercomUnreadCount = useAtomValue(intercomUnreadAtom)
  const { classes, cx } = useStyles()

  return (
    <Box>
      <Button
        variant="subtle"
        onClick={show}
        rightIcon={intercomUnreadCount > 0 ? <Badge variant="filled">{intercomUnreadCount}</Badge> : undefined}
        px={0}
      >
        <Flex align="center" gap="xs">
          <Box mt="5px">
            <Text span className={cx("material-symbols-outlined", classes.fontSize)}>
              chat
            </Text>
          </Box>
          <Text>Help</Text>
        </Flex>
      </Button>
    </Box>
  )
}

export function IntercomLinkInHeader() {
  const portalRoot = process.browser ? document.getElementById(headerButtonPortalId) : null

  if (portalRoot != null) {
    return (
      <Portal target={portalRoot}>
        <IntercomLink />
      </Portal>
    )
  } else {
    return <></>
  }
}
