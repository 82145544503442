import { z } from "zod"
import { AircraftSchema } from "./Aircraft"
import { AirportResultSchema } from "./Airport"

export const ImSafeEnum = z.enum(["illness", "medication", "stress", "alcohol", "fatigue", "emotion", "ok"])
export type ImSafeOption = z.infer<typeof ImSafeEnum>

export const IMSAFE_OPTIONS = [
  { value: ImSafeEnum.enum.illness, label: "Illness", consequence: "Illness can impair cognitive function and decision-making" },
  { value: ImSafeEnum.enum.medication, label: "Medication", consequence: "Medication can cause drowsiness and impaired coordination" },
  { value: ImSafeEnum.enum.stress, label: "Stress", consequence: "Stress can reduce concentration and increases fatigue" },
  { value: ImSafeEnum.enum.alcohol, label: "Alcohol", consequence: "Alcohol can impair judgment and coordination" },
  { value: ImSafeEnum.enum.fatigue, label: "Fatigue", consequence: "Fatigue can decrease reaction time and situational awareness" },
  { value: ImSafeEnum.enum.emotion, label: "Emotion", consequence: "Emotion can distract and impair decision-making" },
  { value: ImSafeEnum.enum.ok, label: "I'm Good", consequence: "" },
] as const

export const SafetyInputSchema = z.object({
  date: z.date(),
  departure: AirportResultSchema,
  destination: AirportResultSchema,
  aircraft: AircraftSchema.optional(),
  dateParts: z.object({
    year: z.number(),
    month: z.number(),
    day: z.number(),
    hour: z.number(),
    minute: z.number(),
  }),
  flightPlan: z.enum(["IFR", "VFR"]),
  flightTime: z.number(),
  imSafe: ImSafeEnum.array().optional(),
  numPassengers: z.number().optional(),
  passengersWithLuggage: z.boolean().optional(),
  includeSafetyAssessment: z.boolean().optional().default(false),
  schedulingFlightLegId: z.string().optional(),
  organizationId: z.string().optional(),
})

export type SafetyInput = z.infer<typeof SafetyInputSchema>

export type SafetyInitialValues = {
  departure?: string
  destination?: string
  time?: string
  aircraftTailNumber?: string
  schedulingFlightLegId?: string
  departureDate?: Date
  numPassengers?: number
  organizationId?: string
  departureTimeZone?: string
}

export type SafetyBriefMode = "assessment" | "data"

export const SafetyAssessmentThreat = z.object({
  id: z.string().ulid(),
  identifier: z.string(),
  label: z.string(),
  severity: z.number(),
  reasons: z.string().array(),
  triggers: z.any(),
})

export const SafetyAssessmentGroup = z.object({
  score: z.number().optional().default(0),
  threats: SafetyAssessmentThreat.array(),
})
export const SafetyAssessmentSchema = z.object({
  preflight: SafetyAssessmentGroup,
  departure: SafetyAssessmentGroup,
  enroute: SafetyAssessmentGroup,
  destination: SafetyAssessmentGroup,
})

export const SafetyAssessmentRecordStatusEnum = z.enum(["upcoming", "created", "finished"])
export const SafetyAssessmentMitigationSchema = z.object({
  id: z.string().ulid(),
  threatId: z.string().ulid().optional(),
  userId: z.string().ulid().optional(),
  identifier: z.string(),
  reason: z.string().optional(),
  mitigatedAt: z.coerce.date(),
})
export const SafetyAssessmentRecordSchema = z.object({
  id: z.string().ulid(),
  createdAt: z.coerce.date(),
  status: SafetyAssessmentRecordStatusEnum,
  finishedAt: z.coerce.date().optional(),
  assessment: z.object({
    params: z.object({
      userId: z.string().ulid(),
      aircraftId: z.string().ulid().optional(),
      organizationId: z.string().ulid().optional(),
      route: z.object({
        departure: z.string(),
        destination: z.string(),
        route: z.string().array().optional(),
      }),
      departAt: z.coerce.date(),
      numPassengers: z.number().optional(),
      passengersWithLuggage: z.boolean().optional(),
    }),
    results: SafetyAssessmentSchema,
  }),
  mitigations: SafetyAssessmentMitigationSchema.array(),
  flightLegId: z.string().optional(),
})

export type SafetyAssessmentThreat = z.infer<typeof SafetyAssessmentThreat>
export type SafetyAssessmentGroup = z.infer<typeof SafetyAssessmentGroup>
export type SafetyAssessment = z.infer<typeof SafetyAssessmentSchema>

export type SafetyAssessmentRecord = z.infer<typeof SafetyAssessmentRecordSchema>
export type SafetyAssessmentRecordStatus = z.infer<typeof SafetyAssessmentRecordStatusEnum>
export type SafetyAssessmentMitigation = z.infer<typeof SafetyAssessmentMitigationSchema>

export const AssessmentThreatLevelsEnum = z.enum(["clear", "orange", "red"])
export type AssessmentThreatLevel = z.infer<typeof AssessmentThreatLevelsEnum>

export type AssessmentThreatCutoffs = {
  orange: number
  red: number
}

export const ScoreAdjustmentSchema = z.object({
  adjustmentLevel: z.enum(["none", "moderate", "low"]),
  comment: z.string().min(5),
  previousScore: z.number(),
  newScore: z.number(),
})
export type ScoreAdjustment = z.infer<typeof ScoreAdjustmentSchema>
