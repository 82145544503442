import { DashboardTimespan } from "@soar/shared/types"
import dayjs from "dayjs"

export function convertTimespanToDate(timespan: DashboardTimespan) {
  const dateOptions = generateDateOptions()
  return dateOptions[timespan].day
}

export function getTimespanLabel(timespan: DashboardTimespan) {
  const dateOptions = generateDateOptions()
  const dateOption = dateOptions[timespan]
  if (timespan === "all") {
    return dateOption.label
  } else if (timespan === "today") {
    return dateOption.day.format("MMM D")
  } else {
    return `${dateOption.day.format("MMM D")} - ${dayjs().format("MMM D")}`
  }
}

function generateDateOptions() {
  const day = dayjs()
  return {
    today: {
      day: day.startOf("day"),
      label: day.startOf("day").format("MMM D"),
    },
    week_1: {
      day: day.subtract(1, "week").startOf("day"),
      label: "1 week",
    },
    week_4: {
      day: day.subtract(4, "week").startOf("day"),
      label: "4 weeks",
    },
    mtd: {
      day: day.startOf("month"),
      label: "Month to date",
    },
    ytd: {
      day: day.startOf("year"),
      label: "Year to date",
    },
    all: {
      day: dayjs(new Date(0)),
      label: "All time",
    },
  }
}
