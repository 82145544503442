import { Avatar, Box, MantineNumberSize, Text, UnstyledButton } from "@mantine/core"
import type { FileDetails } from "@soar/shared/types"
import { dataUrlToBase64, fileToDataUrl } from "@soar/shared/utils"
import { ChangeEvent, useRef, useState } from "react"

export function EditableAvatar({
  value,
  onChange,
  labelText = "Avatar: ",
  radius = "xl",
}: { labelText?: string; value?: string | null; onChange?: (file: FileDetails) => void; radius?: MantineNumberSize }) {
  const [dataUrl, setDataUrl] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files != null) {
      const file = e.target.files[0]
      const dataUrl = await fileToDataUrl(file)
      setDataUrl(dataUrl)

      if (onChange != null) {
        onChange({
          body: dataUrlToBase64(dataUrl),
          contentType: file.type,
        })
      }
    }
  }

  const onAvatarClick = () => {
    inputRef.current?.click()
  }

  return (
    <Box>
      <Text fw={500} fz="14px" mb="xs">
        {labelText}
      </Text>
      <UnstyledButton onClick={onAvatarClick}>
        <Avatar src={dataUrl ?? value} size="xl" radius={radius} />
      </UnstyledButton>
      <input ref={inputRef} id="avatar" type="file" accept="image/jpeg, image/png" onChange={handleChange} hidden />
    </Box>
  )
}
