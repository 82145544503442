import { useDisclosure } from "@mantine/hooks"
import { SafetyAssessmentMitigation, SafetyAssessmentRecord, ScoreAdjustment, User } from "@soar/shared/types"
import { Atom, atom, useAtom, useAtomValue } from "jotai"
import { atomFamily, atomWithReducer, useReducerAtom } from "jotai/utils"
import { useCallback, useMemo, useState } from "react"
import { ulid } from "ulid"

type MitigationAction =
  | {
      event: "addMitigation"
      threatId: string
      userId: string
      identifier: string
      reason?: string
    }
  | {
      event: "removeMitigation"
      threatId: string
    }
  | {
      event: "reset"
    }

function mitigationsReducer(state: SafetyAssessmentMitigation[], action: MitigationAction): SafetyAssessmentMitigation[] {
  if (action.event === "addMitigation") {
    const mitigationExists = state.find((mitigation) => mitigation.threatId === action.threatId)
    if (mitigationExists) {
      return state
    } else {
      return [
        ...state,
        {
          id: ulid(),
          threatId: action.threatId,
          userId: action.userId,
          identifier: action.identifier,
          mitigatedAt: new Date(),
          reason: action.reason,
        },
      ]
    }
  } else if (action.event === "removeMitigation") {
    return state.filter((mitigation) => mitigation.threatId !== action.threatId)
  } else if (action.event === "reset") {
    return []
  } else {
    return state
  }
}

type onSavePayload = {
  fn: (mitigations: SafetyAssessmentMitigation[], scoreAdjustment?: ScoreAdjustment) => void
}
type BriefStatus = Pick<SafetyAssessmentRecord, "finishedAt" | "status">

export const mitigationsAtom = atomWithReducer([], mitigationsReducer)
export const userAtom = atom<User | undefined>(undefined)
export const numThreatsAtom = atom<number | null>(null)
export const onSaveAtom = atom<onSavePayload>({ fn: () => {} })
export const saveStatusAtom = atom<string>("idle")
export const briefStatusAtom = atom<BriefStatus | undefined>(undefined)
export const scoreAtom = atom<number | undefined>(undefined)

export function useMitigations() {
  const [mitigations, dispatch] = useAtom(mitigationsAtom)
  const [user, setUser] = useAtom(userAtom)
  const [numThreats, setNumThreats] = useAtom(numThreatsAtom)
  const [onSave, setOnSave] = useAtom(onSaveAtom)
  const [saveStatus, setSaveStatus] = useAtom(saveStatusAtom)
  const [briefStatus, setBriefStatus] = useAtom(briefStatusAtom)

  const { percentage, valueMitigated, isFinishedMitigating } = useMemo(() => {
    const numMitigations = mitigations.length
    const percentage = numThreats != null && numThreats > 0 ? numMitigations / numThreats : 0
    const isFinishedMitigating = numThreats != null && numMitigations >= numThreats

    return {
      isFinishedMitigating,
      percentage,
      valueMitigated: percentage * 100,
    }
  }, [numThreats, mitigations])

  const handleSave = useCallback(
    (scoreAdjustment?: ScoreAdjustment) => {
      onSave.fn(mitigations, scoreAdjustment)
    },
    [mitigations, onSave],
  )

  return {
    mitigations,
    numThreats,
    percentage,
    valueMitigated,
    isFinishedMitigating,
    onSave: handleSave,
    setOnSave: (inOnSave: onSavePayload["fn"]) => {
      setOnSave({ fn: inOnSave })
    },
    saveStatus,
    setSaveStatus,
    briefStatus,
    setBriefStatus,
    addMitigation: (threatId: string, identifier: string, reason?: string) => {
      if (user != null) {
        dispatch({ event: "addMitigation", threatId, userId: user.id, identifier, reason })
      }
    },
    removeMitigation: (threatId: string) => {
      dispatch({ event: "removeMitigation", threatId })
    },
    reset: () => {
      setNumThreats(null)
      setSaveStatus("idle")
      setBriefStatus(undefined)
      dispatch({ event: "reset" })
    },
    setUser: (user: User) => {
      setUser(user)
    },
    setNumThreats(inNumThreats: number) {
      setNumThreats(inNumThreats)
    },
  }
}
