import { ActionIcon, ScrollArea } from "@mantine/core"
import { useElementSize, useHover, useMergedRef } from "@mantine/hooks"
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react"
import { Ref, forwardRef, useImperativeHandle, useMemo, useRef, useState } from "react"
import styles from "./Schedule.module.scss"

import { IScheduleHeaderProps, ScheduleHeader } from "./ScheduleHeader"
import { IScheduleRowProps, ScheduleRow } from "./ScheduleRow"
import { ISchedule } from "./schedule.interface"

import { calculateScheduleWidth, computeSegments } from "./utils"

export interface IScheduleRefPayload {
  scrollTo: (time: number) => void
}

export interface IScheduleProps<TScheduleSource, TScheduleItem> {
  schedules?: ISchedule<TScheduleSource, TScheduleItem>[]
  from: number
  to: number
  segmentsCount: number
  rowHeaderWidth: number
  minCellWidth?: number
  rowHeight?: number
  headerRowHeight?: number
  renderColumnHeader?: IScheduleHeaderProps["renderColumnHeader"]
  renderScheduleItem?: IScheduleRowProps<TScheduleSource, TScheduleItem>["renderScheduleItem"]
  renderRowHeader?: IScheduleRowProps<TScheduleSource, TScheduleItem>["renderRowHeader"]
  renderHorizontalScroll?: never
  renderTitle?: IScheduleHeaderProps["renderTitle"]
  renderBackgroundCell?: IScheduleRowProps<TScheduleSource, TScheduleItem>["renderBackgroundCell"]
}

// export const Schedule = forwardRef(function Schedule<TScheduleSource extends object, TScheduleItem extends object>({
export const Schedule = forwardRef(function Schedule<TScheduleSource extends object, TScheduleItem extends object>(
  {
    from,
    to,
    segmentsCount,
    rowHeaderWidth,
    minCellWidth,
    rowHeight = 40,
    headerRowHeight = rowHeight,
    renderColumnHeader,
    renderScheduleItem,
    renderRowHeader,
    renderBackgroundCell,
    renderTitle,
    schedules = [],
  }: IScheduleProps<TScheduleSource, TScheduleItem>,
  ref: Ref<IScheduleRefPayload>,
) {
  const { ref: elementSizeRef, width } = useElementSize()
  const { hovered, ref: hoverRef } = useHover()

  const [scrollPosition, onScrollPositionChange] = useState({ x: 0, y: 0 })
  const mergedRef = useMergedRef(hoverRef, elementSizeRef)

  const viewportRef = useRef<HTMLDivElement>(null)
  const scrollLeft = () => {
    viewportRef.current?.scrollTo({
      left: scrollPosition.x - 500,
      behavior: "smooth",
    })
  }

  const scrollRight = () => {
    viewportRef.current?.scrollTo({
      left: scrollPosition.x + 500,
      behavior: "smooth",
    })
  }

  const [scheduleWidth, segmentWidth] = useMemo(() => {
    const scheduleWidth = calculateScheduleWidth(width, rowHeaderWidth, segmentsCount, minCellWidth)

    const segmentWidth = scheduleWidth / segmentsCount

    return [scheduleWidth, segmentWidth]
  }, [width, rowHeaderWidth, segmentsCount, minCellWidth])

  const segments = useMemo(() => computeSegments(from, to, segmentsCount), [from, to, segmentsCount])

  const scheduleWiderThanContainer = scheduleWidth > width
  const shouldShowScrollHandlers = scheduleWiderThanContainer && hovered
  useImperativeHandle(ref, () => ({
    scrollTo: (time: number) => {
      const scrollPosition = time * segmentWidth
      // console.log(`scrolling to ${time} at ${scrollPosition} with segment width of ${segmentWidth} and rowHeaderWidth of ${rowHeaderWidth}`)

      viewportRef.current?.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      })
    },
  }))

  return (
    <ScrollArea
      className={styles["schedule-wrapper"]}
      ref={mergedRef}
      style={{ position: "relative" }}
      offsetScrollbars={scheduleWiderThanContainer}
      onScrollPositionChange={onScrollPositionChange}
      viewportRef={viewportRef}
      type={scheduleWiderThanContainer ? "auto" : "never"}
    >
      <div
        style={{
          position: "absolute",
          top: "calc(50% - 20px)",
          left: rowHeaderWidth,
          zIndex: 100,
          visibility: shouldShowScrollHandlers ? "visible" : "hidden",
        }}
      >
        <ActionIcon color="blue.5" variant="filled" size="md" radius="xl" onClick={scrollLeft}>
          <IconChevronLeft stroke={3} />
        </ActionIcon>
      </div>

      <div
        style={{
          position: "absolute",
          top: "calc(50% - 20px)",
          right: "0",
          zIndex: 100,
          visibility: shouldShowScrollHandlers ? "visible" : "hidden",
        }}
      >
        <ActionIcon color="blue.5" variant="filled" size="md" radius="xl" onClick={scrollRight}>
          <IconChevronRight stroke={3} />
        </ActionIcon>
      </div>

      <div className={styles["schedule"]} style={{ width: minCellWidth ? "max-content" : "100%" }}>
        <ScheduleHeader
          segments={segments}
          segmentWidth={segmentWidth}
          rowHeaderWidth={rowHeaderWidth}
          headerRowHeight={headerRowHeight}
          renderColumnHeader={renderColumnHeader}
          renderTitle={renderTitle}
        />
        {schedules.map((schedule, index) => (
          <ScheduleRow
            key={index}
            index={index}
            min={from}
            max={to}
            rowHeaderWidth={rowHeaderWidth}
            rowHeight={rowHeight}
            scheduleWidth={scheduleWidth}
            schedule={schedule}
            renderScheduleItem={renderScheduleItem}
            renderRowHeader={renderRowHeader}
            renderBackgroundCell={renderBackgroundCell}
            segmentCount={segmentsCount}
            segmentWidth={segmentWidth}
          />
        ))}
      </div>
    </ScrollArea>
  )
})
