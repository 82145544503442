import { Box, Card, Flex, Popover, Stack, Text, UnstyledButton, createStyles, getStylesRef } from "@mantine/core"
import dayjs, { Dayjs } from "dayjs"

import { PlaneDisplay } from "../../../planeDisplay"
import { UserDisplay } from "../../../userDisplay"

import { Aircraft, IWeekDensity, RegisteredUser, Reservation, ReservationTypes, ReservationUser, TimeFormat } from "@soar/shared/types"
import type { ISchedule, IScheduleItem } from "../../schedule.interface"

import { useDisclosure } from "@mantine/hooks"
import { usePreferences } from "@soar/frontend/contexts"
import { DateFormatOptions, formatDate, isAircraft, isRegisteredUser } from "@soar/shared/utils"
import { useContext, useState } from "react"
import { locationObjectsCacheContext } from "../../../locations"
import { ReservationCard, getReservationTypeColors } from "../../../reservations"

const useDensityStyles = createStyles((theme, _params) => ({
  EMPTY: {
    backgroundColor: theme.white,
  },
  LOW: {
    backgroundColor: theme.colors.blue[0],
  },
  MEDIUM: {
    backgroundColor: theme.colors.blue[4],
  },
  HIGH: {
    backgroundColor: theme.colors.blue[7],
  },
  FULL: {
    backgroundColor: theme.colors.blue[9],
  },
  BLOCKED: {
    backgroundColor: theme.colors.gray[5],
  },
  pill: {},
  listItem: {
    fontSize: "11px",
    color: theme.white,
    borderRadius: theme.radius.sm,
    padding: "2px 3px",
    textOverflow: "ellipsis",
    textAlign: "center",
    height: "23px",
    width: "100%",
  },
  listItemLg: {
    height: "46px",
  },
  listItemFull: {
    height: "69px",
  },
  container: {
    height: "100%",
    width: "100%",
  },
  segment: {
    ref: getStylesRef("segment"),
    height: "100%",
    flexGrow: 1,
    borderLeftWidth: "1px",
    borderTopWidth: "1px",
    borderBottomWidth: "1px",
    borderRightWidth: "0px",
    borderStyle: "solid",
    borderColor: theme.colors.gray[4],
    ["&:first-of-type"]: {
      borderTopLeftRadius: theme.radius.md,
      borderBottomLeftRadius: theme.radius.md,
    },
    ["&:last-of-type"]: {
      borderTopRightRadius: theme.radius.md,
      borderBottomRightRadius: theme.radius.md,
      borderRightWidth: "1px",
    },
  },
  reservationRow: {
    ":before": {
      content: '""',
      width: "6px",
      borderRadius: "3px",
      backgroundColor: "gray",
      display: "block",
      position: "absolute",
      top: "4px",
      bottom: "4px",
      left: "4px",
    },
    position: "relative",
    padding: "5px",
    paddingLeft: "15px",
    ["&:hover"]: {
      backgroundColor: theme.colors.blue[0],
    },
  },
  "reservationType-flight": {
    backgroundColor: getReservationTypeColors(theme, ReservationTypes.enum.flight).primary,
  },
  "reservationType-ground": {
    backgroundColor: getReservationTypeColors(theme, ReservationTypes.enum.ground).primary,
  },
  "reservationType-maintenance": {
    backgroundColor: getReservationTypeColors(theme, ReservationTypes.enum.maintenance).primary,
  },
  "reservationType-unavailable": {
    backgroundColor: getReservationTypeColors(theme, ReservationTypes.enum.unavailable).primary,
  },
  "reservationListItem-flight": {
    ":before": {
      backgroundColor: getReservationTypeColors(theme, ReservationTypes.enum.flight).primary,
    },
  },
  "reservationListItem-ground": {
    ":before": {
      backgroundColor: getReservationTypeColors(theme, ReservationTypes.enum.ground).primary,
    },
  },
  "reservationListItem-maintenance": {
    ":before": {
      backgroundColor: getReservationTypeColors(theme, ReservationTypes.enum.maintenance).primary,
    },
  },
  "reservationListItem-unavailable": {
    ":before": {
      backgroundColor: getReservationTypeColors(theme, ReservationTypes.enum.unavailable).primary,
    },
  },
  commaSeparatedList: {
    padding: 0,
    margin: 0,
    li: {
      display: "inline",
    },
    "li :after": {
      content: "', '",
    },
    "li:last-of-type :after": {
      content: "''",
    },
  },
}))

function formatTimeInRelationToDay(time: Dayjs, day: Dayjs, timePreference: TimeFormat) {
  // const format = time.isSame(day, "day") ? "HH:mm" : "ddd MMM D HH:mm"
  const format: DateFormatOptions = time.isSame(day, "day")
    ? { date: false, time: true, timeFormatOverride: timePreference }
    : { date: { format: "long", includeYear: false, includeWeekday: true }, time: true, timeFormatOverride: timePreference }
  return formatDate(time, format)
}

function formatTimeInRelationToDayCondensed(time: Dayjs, day: Dayjs, timePreference: TimeFormat) {
  // const format = time.isSame(day, "day") ? "HH:mm" : "MMM D"
  const format: DateFormatOptions = time.isSame(day, "day")
    ? { date: false, time: true, timeFormatOverride: timePreference }
    : { date: { format: "long", includeYear: false, includeWeekday: false }, time: false, timeFormatOverride: timePreference }

  return formatDate(time, format)
}

function ReservationGistUserList({ users }: { users: ReservationUser[] }) {
  const { classes } = useDensityStyles()
  const { getUser } = useContext(locationObjectsCacheContext)
  return (
    <Box component="ul" className={classes.commaSeparatedList}>
      {users.map((userRelation) => {
        const user = getUser(userRelation.userId)

        if (user == null) {
          return (
            <li key={userRelation.userId}>
              <Text span size="sm">
                Unknown User
              </Text>
            </li>
          )
        }

        return (
          <li key={userRelation.userId}>
            <Text span size="sm">
              {user.firstName} {user.lastName}
            </Text>
          </li>
        )
      })}
    </Box>
  )
}

function ReservationGist({
  source,
  reservation,
}: {
  source?: Aircraft | RegisteredUser
  reservation: Reservation
}) {
  const { classes } = useDensityStyles()
  const { getAircraft } = useContext(locationObjectsCacheContext)

  if (reservation.type === ReservationTypes.enum.unavailable) {
    return <Text> Blocked </Text>
  } else if (reservation.type === ReservationTypes.enum.maintenance) {
    return <Text> Maintenance </Text>
  } else if (reservation.type === ReservationTypes.enum.flight) {
    if (isAircraft(source)) {
      return <ReservationGistUserList users={reservation.users ?? []} />
    }
    if (isRegisteredUser(source) && reservation.aircraftId != null) {
      const aircraft = getAircraft(reservation.aircraftId)
      if (aircraft == null) {
        return <Text size="sm">Unknown Aircraft</Text>
      }

      return (
        <Text size="sm">
          <Text fw="bold" span>
            {aircraft.tailNumber}{" "}
          </Text>
          {aircraft.manufacturer} {aircraft.model}
        </Text>
      )
    }
  } else if (reservation.type === ReservationTypes.enum.ground) {
    const users = (reservation.users ?? []).filter((u) => u.userId !== source?.id)
    return <ReservationGistUserList users={users} />
  }

  return <Text> Unknown </Text>
}

export function DayAgendaCard({
  reservations,
  source,
  day = dayjs(),
  onSignOutClick = () => {},
  onUpdateClick = () => {},
  onCancelClick = () => {},
  closeParent = () => {},
}: {
  reservations: Reservation[]
  source?: Aircraft | RegisteredUser
  day?: Dayjs
  onSignOutClick?: (reservation: Reservation) => void
  onUpdateClick?: (reservation: Reservation) => void
  onCancelClick?: (reservation: Reservation) => void
  closeParent?: () => void
}) {
  const { timeFormat } = usePreferences()
  const { classes, cx } = useDensityStyles()
  const [selectedReservation, setSelectedReservation] = useState<Reservation>()
  if (selectedReservation != null) {
    return (
      <ReservationCard
        reservation={selectedReservation}
        source={source}
        onSignOutClick={onSignOutClick}
        onUpdateClick={onUpdateClick}
        onCancelClick={onCancelClick}
        closeParent={closeParent}
      />
    )
  }
  return (
    <Card w="350px">
      <Card.Section withBorder inheritPadding py="xs" bg="gray.1">
        <Flex align="center" justify="center" mr="xs">
          <Text fw={600} tt="capitalize" color="gray.6" mr="5px">
            {day.format("ddd")}
          </Text>
          <Text fw={700}>{day.format("D")}</Text>
        </Flex>
      </Card.Section>
      <Card.Section px="xs">
        <Flex
          align="center"
          justify="center"
          py="xs"
          sx={(theme) => ({
            borderBottom: `thin solid ${theme.colors.gray[2]}`,
          })}
        >
          {isAircraft(source) && <PlaneDisplay plane={source} size="sm" />}
          {isRegisteredUser(source) && <UserDisplay user={source} size="md" />}
        </Flex>
      </Card.Section>
      <Card.Section inheritPadding py="sm">
        <Stack spacing="xs">
          {reservations.map((reservation) => (
            <UnstyledButton
              key={reservation.id}
              className={cx(classes.reservationRow, classes[`reservationListItem-${reservation.type}`])}
              onClick={() => {
                setSelectedReservation(reservation)
              }}
            >
              <Flex fw="bold" gap="4px">
                <Text>{formatTimeInRelationToDay(dayjs(reservation.startTime), day, timeFormat)}</Text>
                <span>-</span>
                <Text>{formatTimeInRelationToDay(dayjs(reservation.endTime), day, timeFormat)}</Text>
              </Flex>
              <ReservationGist source={source} reservation={reservation} />
            </UnstyledButton>
          ))}
        </Stack>
      </Card.Section>
    </Card>
  )
}

export function renderWeekItemDensity({
  item,
  schedule,
}: {
  item: IScheduleItem<IWeekDensity>
  schedule: ISchedule<Aircraft | RegisteredUser, IWeekDensity>
}) {
  const { classes } = useDensityStyles()
  const source = schedule.source

  return (
    <Flex className={classes.container} align="center" justify="center">
      <Popover transitionProps={{ transition: "pop" }} position="top-start" shadow="xl" styles={{ dropdown: { padding: 0 } }} withinPortal>
        <Popover.Target>
          <UnstyledButton h="65%" w="90%">
            <Flex className={classes.pill} align="stretch" h="100%">
              {(item.source?.density ?? []).map((density, index) => {
                const densityClass = classes[density]

                return <Box key={index} className={`${classes.segment} ${densityClass}`} />
              })}
            </Flex>
          </UnstyledButton>
        </Popover.Target>
        <Popover.Dropdown>
          <DayAgendaCard reservations={item.source?.reservations ?? []} source={source} day={item.source?.day} />
        </Popover.Dropdown>
      </Popover>
    </Flex>
  )
}
function WeekItemReservation({
  reservation,
  source,
  currentDay,
  onSignOutClick = () => {},
  onUpdateClick = () => {},
  onCancelClick = () => {},
}: {
  reservation: Reservation
  source?: Aircraft | RegisteredUser
  currentDay: Dayjs
  onSignOutClick?: (reservation: Reservation) => void
  onUpdateClick?: (reservation: Reservation) => void
  onCancelClick?: (reservation: Reservation) => void
}) {
  const { timeFormat } = usePreferences()
  const { classes, cx } = useDensityStyles()
  const [popoverOpened, popoverHandlers] = useDisclosure(false)
  return (
    <Popover
      transitionProps={{ transition: "pop" }}
      position="top-start"
      shadow="xl"
      styles={{ dropdown: { padding: 0 } }}
      withinPortal
      onClose={popoverHandlers.close}
      opened={popoverOpened}
    >
      <Popover.Target>
        <UnstyledButton onClick={popoverHandlers.open}>
          <Box key={reservation.id} className={cx(classes.listItem, classes[`reservationType-${reservation.type}`])}>
            <Text fw={700} span mr="2px">
              {formatTimeInRelationToDayCondensed(dayjs(reservation.startTime), currentDay, timeFormat)} -{" "}
              {formatTimeInRelationToDayCondensed(dayjs(reservation.endTime), currentDay, timeFormat)}
            </Text>
          </Box>
        </UnstyledButton>
      </Popover.Target>
      <Popover.Dropdown>
        <ReservationCard
          reservation={reservation}
          source={source}
          onSignOutClick={onSignOutClick}
          onUpdateClick={onUpdateClick}
          onCancelClick={onCancelClick}
          closeParent={popoverHandlers.close}
        />
      </Popover.Dropdown>
    </Popover>
  )
}
function WeekItemOverflow({
  source,
  reservations,
  currentDay,
  numOverflowReservations,
  onSignOutClick = () => {},
  onUpdateClick = () => {},
  onCancelClick = () => {},
}: {
  reservations: Reservation[]
  source?: Aircraft | RegisteredUser
  currentDay: Dayjs
  numOverflowReservations: number
  onSignOutClick?: (reservation: Reservation) => void
  onUpdateClick?: (reservation: Reservation) => void
  onCancelClick?: (reservation: Reservation) => void
}) {
  const { classes, cx } = useDensityStyles()
  const [popoverOpened, popoverHandlers] = useDisclosure(false)
  return (
    <Popover
      transitionProps={{ transition: "pop" }}
      position="top-start"
      shadow="xl"
      styles={{ dropdown: { padding: 0 } }}
      withinPortal
      opened={popoverOpened}
      onClose={popoverHandlers.close}
    >
      <Popover.Target>
        <UnstyledButton onClick={popoverHandlers.open}>
          <Box className={cx(classes.listItem, classes["reservationType-flight"])} ta="center">
            <Text fw={700} span>
              +{numOverflowReservations} more
            </Text>
          </Box>
        </UnstyledButton>
      </Popover.Target>
      <Popover.Dropdown>
        <DayAgendaCard
          reservations={reservations}
          source={source}
          day={currentDay}
          onSignOutClick={onSignOutClick}
          onUpdateClick={onUpdateClick}
          onCancelClick={onCancelClick}
          closeParent={popoverHandlers.close}
        />
      </Popover.Dropdown>
    </Popover>
  )
}

const maxShownReservations = 3
export function WeekItem({
  item,
  schedule,
  onSignOutClick,
  onUpdateClick,
  onCancelClick,
}: {
  item: IScheduleItem<IWeekDensity>
  schedule: ISchedule<Aircraft | RegisteredUser, IWeekDensity>
  onSignOutClick?: (reservation: Reservation) => void
  onUpdateClick?: (reservation: Reservation) => void
  onCancelClick?: (reservation: Reservation) => void
}) {
  const { classes, cx } = useDensityStyles()
  const source = schedule.source

  const currentDay = item.source!.day
  const reservations = item.source?.reservations ?? []
  const reservationsToRender: (Reservation & { classes?: string })[] = []
  let slots = 0

  for (const reservation of reservations) {
    // const resStartsBeforeCurrentDay = reservation.startTime.getTime() < currentDay.startOf('day').toDate().getTime()
    // const resEndsAfterCurrentDay = reservation.endTime.getTime() > currentDay.endOf('day').toDate().getTime()
    // const resStartsAndEndsDifferentDay = resStartsBeforeCurrentDay && resEndsAfterCurrentDay

    if (slots >= maxShownReservations) {
      break
      /*
    } else if(resStartsAndEndsDifferentDay){
      slots = 3
      reservationsToRender = [{...reservation, classes: classes.listItemFull}]
    } else if (resStartsBeforeCurrentDay || resEndsAfterCurrentDay){
      if(slots >= 2){
        slots = 3
        reservationsToRender.push(reservation)
      }else{
        slots = slots + 2
        reservationsToRender.push({...reservation, classes: classes.listItemLg})
      }
    */
    } else {
      slots = slots + 1
      reservationsToRender.push(reservation)
    }
  }

  const numOverflowReservations = reservations.length - reservationsToRender.length

  return (
    <Flex className={classes.container} align="center" justify="center">
      <Stack spacing={2} justify="flex-start" h="100%" w="95%" pt={3}>
        {reservationsToRender.map((reservation) => {
          return (
            <WeekItemReservation
              source={schedule.source}
              reservation={reservation}
              currentDay={currentDay}
              onSignOutClick={onSignOutClick}
              onUpdateClick={onUpdateClick}
              onCancelClick={onCancelClick}
            />
          )
        })}
        {numOverflowReservations > 0 && (
          <WeekItemOverflow
            numOverflowReservations={numOverflowReservations}
            reservations={reservations}
            source={source}
            currentDay={currentDay}
            onSignOutClick={onSignOutClick}
            onUpdateClick={onUpdateClick}
            onCancelClick={onCancelClick}
          />
        )}
      </Stack>
    </Flex>
  )
}
export function renderWeekItem({
  item,
  schedule,
  onSignOutClick = () => {},
  onUpdateClick = () => {},
  onCancelClick = () => {},
}: {
  item: IScheduleItem<IWeekDensity>
  schedule: ISchedule<Aircraft | RegisteredUser, IWeekDensity>
  onSignOutClick?: (reservation: Reservation) => void
  onUpdateClick?: (reservation: Reservation) => void
  onCancelClick?: (reservation: Reservation) => void
}) {
  return (
    <WeekItem item={item} schedule={schedule} onUpdateClick={onUpdateClick} onSignOutClick={onSignOutClick} onCancelClick={onCancelClick} />
  )
}
