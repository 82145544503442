import { z } from "zod"

export const PostTypeEnum = z.enum(["asap_feedback"])
export const PostSchema = z.object({
  id: z.string().ulid(),
  postType: PostTypeEnum,
  postTypeVersion: z.number().default(1),
  content: z.string(),
  posterId: z.string().ulid().nullish(),
  organizationId: z.string().ulid(),
  mainImageUrl: z.string().url().nullish(),
  meta: z.unknown(),
  createdAt: z.coerce.date(),
})
export const ExpandedPostSchema = PostSchema.extend({
  postedBy: z
    .object({
      firstName: z.string(),
      lastName: z.string(),
    })
    .nullish(),
  organization: z.object({
    name: z.string(),
  }),
})

export const PostDetailsSchema = PostSchema.omit({
  id: true,
  posterId: true,
  createdAt: true,
})

export type Post = z.infer<typeof PostSchema>
export type PostDetails = z.infer<typeof PostDetailsSchema>
export type ExpandedPost = z.infer<typeof ExpandedPostSchema>
