import { SVGProps } from "react"

export function SidewayAirplaneIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <title>Sideways airplane</title>
      <path d="M1.53853e-06 8.4375L1.3125 8.4375L3.1875 10.9375L11.0313 10.9375L7.8125 -7.51289e-07L9.625 -6.72063e-07L16.0625 10.9375L23.4375 10.9375C23.875 10.9375 24.2448 11.0885 24.5469 11.3906C24.849 11.6927 25 12.0625 25 12.5C25 12.9375 24.849 13.3073 24.5469 13.6094C24.2448 13.9115 23.875 14.0625 23.4375 14.0625L16.0625 14.0625L9.625 25L7.8125 25L11.0313 14.0625L3.1875 14.0625L1.3125 16.5625L1.18338e-06 16.5625L1.15625 12.5L1.53853e-06 8.4375Z" />
      <defs>
        <linearGradient id="paint0_linear_747_365" x1="25" y1="12.5" x2="3.53774" y2="12.5" gradientUnits="userSpaceOnUse">
          <stop />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </svg>
  )
}
