import { MantineTheme, createStyles } from "@mantine/core"
import { FlightRule } from "@soar/shared/types"

export function getFlightRulesColor(flightRule: FlightRule | undefined | null, theme: MantineTheme) {
  if (flightRule === "VFR") {
    return theme.colors.green[8]
  } else if (flightRule === "MVFR") {
    return theme.colors.blue[8]
  } else if (flightRule === "IFR") {
    return theme.colors.red[8]
  }
  return undefined
}

export const useFlightRuleStyles = createStyles((theme) => ({
  VFR: {
    color: theme.colors.green[8],
  },
  MVFR: {
    color: theme.colors.blue[8],
  },
  IFR: {
    color: theme.colors.red[8],
  },
  circleVFR: {
    backgroundColor: theme.colors.green[8],
  },
  circleMVFR: {
    backgroundColor: theme.colors.blue[8],
  },
  circleIFR: {
    backgroundColor: theme.colors.red[8],
  },
  circleBase: {
    width: "0.8rem",
    height: "0.8rem",
    border: "1px solid transparent",
    display: "inline-block",
    borderRadius: "50%",
    marginRight: "5px",
  },
}))
