import dayjs from "dayjs"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import { z } from "zod"

import { idSchema } from "../utility"
import { ReservationEventSchema } from "./ReservationEvent"
import { ReservationLegSchema, ReservationStatus, ReservationTypes, ReservationUserSchema } from "./components"

dayjs.extend(isSameOrAfter)

export const BaseReservationSchema = z.object({
  id: idSchema,
  type: ReservationTypes,
  startTime: z.coerce.date(),
  endTime: z.coerce.date(),
  locationId: idSchema,
  aircraftId: idSchema.nullish(),
  legs: ReservationLegSchema.array(),
  comment: z.string().nullish(),
  createdAt: z.coerce.date(),
  estimatedFlightHours: z.number().min(0).nullish(),
  users: ReservationUserSchema.array(),
  events: ReservationEventSchema.array().default([]),
  status: ReservationStatus.nullish(),
})
export type BaseReservation = z.infer<typeof BaseReservationSchema>

const detailsOmitConfig = { id: true, createdAt: true, events: true } as const
export const BaseReservationDetailsSchema = BaseReservationSchema.omit(detailsOmitConfig)

export type BaseReservationDetails = z.infer<typeof BaseReservationDetailsSchema>

// BEGIN: Trying out some stricter schemas
export const reservationTimeRefinementConfig = {
  message: "End time must be at least an hour after start time ",
  path: ["endTime"],
}

export function isReservationTimeValid<T extends BaseReservationDetails>(reservation: T) {
  const startTime = dayjs(reservation.startTime)
  const endTime = dayjs(reservation.endTime)
  const timeValidity = endTime.isSameOrAfter(startTime.add(1, "hour"))
  return timeValidity
}

export const FlightReservationSchema = BaseReservationSchema.extend({
  type: z.literal(ReservationTypes.enum.flight),
  aircraftId: idSchema,
  users: ReservationUserSchema.array().min(1),
})
export const FlightReservationDetailsSchema = FlightReservationSchema.omit(detailsOmitConfig).refine(
  isReservationTimeValid,
  reservationTimeRefinementConfig,
)
export type FlightReservation = z.infer<typeof FlightReservationSchema>
export type FlightReservationDetails = z.infer<typeof FlightReservationDetailsSchema>

export const GroundReservationSchema = BaseReservationSchema.extend({
  type: z.literal(ReservationTypes.enum.ground),
  aircraftId: z.any().transform(() => null),
  users: ReservationUserSchema.array().min(1),
  legs: z.any().transform(() => []),
  estimatedFlightHours: z.any().transform(() => null),
})
export type GroundReservation = z.infer<typeof GroundReservationSchema>
export const GroundReservationDetailsSchema = GroundReservationSchema.omit(detailsOmitConfig).refine(
  isReservationTimeValid,
  reservationTimeRefinementConfig,
)
export type GroundReservationDetails = z.infer<typeof GroundReservationDetailsSchema>

export const MaintenanceReservationSchema = BaseReservationSchema.extend({
  type: z.literal(ReservationTypes.enum.maintenance),
  aircraftId: idSchema,
  legs: z.any().transform(() => []),
  users: z.any().transform(() => []),
  estimatedFlightHours: z.any().transform(() => null),
})
export type MaintenanceReservation = z.infer<typeof MaintenanceReservationSchema>
export const MaintenanceReservationDetailsSchema = MaintenanceReservationSchema.omit(detailsOmitConfig).refine(
  isReservationTimeValid,
  reservationTimeRefinementConfig,
)
export type MaintenanceReservationDetails = z.infer<typeof MaintenanceReservationDetailsSchema>

export const UnavailableReservationSchema = BaseReservationSchema.extend({
  type: z.literal(ReservationTypes.enum.unavailable),
  aircraftId: z.any().transform(() => null),
  legs: z.any().transform(() => []),
  estimatedFlightHours: z.any().transform(() => null),
})
export type UnavailableReservation = z.infer<typeof UnavailableReservationSchema>
export const UnavailableReservationDetailsSchema = UnavailableReservationSchema.omit(detailsOmitConfig).refine(
  isReservationTimeValid,
  reservationTimeRefinementConfig,
)
export type UnavailableReservationDetails = z.infer<typeof UnavailableReservationDetailsSchema>

export const ReservationSchema = z.discriminatedUnion("type", [
  FlightReservationSchema,
  GroundReservationSchema,
  MaintenanceReservationSchema,
  UnavailableReservationSchema,
])

export const ReservationDetailsSchema = z.union([
  FlightReservationDetailsSchema,
  GroundReservationDetailsSchema,
  MaintenanceReservationDetailsSchema,
  UnavailableReservationDetailsSchema,
])

export type Reservation = z.infer<typeof ReservationSchema>
export type ReservationDetails = z.infer<typeof ReservationDetailsSchema>

// END: Trying out some stricter schemas
