import { useAtom, useSetAtom } from "jotai"
import { useCallback, useEffect, useState } from "react"

import { trpc } from "@soar/frontend/trpc-client"

import {
  SelectionContext,
  grabFromLocalStorage,
  locationsForOrganizationAtom,
  organizationAtom,
  organizationAtomWithStorage,
  organizationsWithRolesForUserAtom,
  profileAtom,
  selectedLocationAtomWithStorage,
  selectedLocationsAtom,
  selectedLocationsAtomWithStorage,
  useUser,
} from "@soar/frontend/contexts"

import { Organization, Profile, UserRole } from "@soar/shared/types"

function SelectionsProvider({ children }) {
  const [selectedOrganization, setSelectedOrganization] = useAtom(organizationAtomWithStorage)
  const [_organization, setOrganization] = useAtom(organizationAtom)
  const setOrganizationsForUser = useSetAtom(organizationsWithRolesForUserAtom)

  const [_locationsForOrganization, setLocationsForOrganization] = useAtom(locationsForOrganizationAtom)
  const [_selectedLocationIds, setSelectedLocationIds] = useAtom(selectedLocationsAtomWithStorage)
  const [_selectedLocationId, setSelectedLocationId] = useAtom(selectedLocationAtomWithStorage)

  const [selectedProfile, setSelectedProfile] = useAtom(profileAtom)

  const [orgLookupId, setOrgLookupId] = useState<string | undefined>()
  const [_locationIds, setLocationIds] = useAtom(selectedLocationsAtom)

  const [shouldUpdateSelectedLocations, setShouldUpdateSelectedLocations] = useState(false)
  const [targetProfile, setTargetProfile] = useState<Profile["type"]>()

  const { isAuthenticated, user } = useUser()

  // Set List of Organizations the User is associated with
  const { refetch: orgRefetch } = trpc.user.getUserOrganizationsAndLocations.useQuery(undefined, {
    enabled: isAuthenticated,
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (orgsAndLocs) => {
      const organizations = orgsAndLocs.organizations.map((orgWithRoles) => ({
        organization: orgWithRoles.organization,
        roles: UserRole.array().parse(orgWithRoles.roles.map((roleObject) => roleObject.role)),
      }))
      setOrganizationsForUser(organizations)
      if (selectedOrganization == null && organizations.length > 0) {
        onOrganizationSelectSideEffects(organizations[0].organization)
      }
    },
  })

  const { refetch: locationsRefetch } = trpc.location.getLocationsByOrganization.useQuery(
    { organizationId: selectedOrganization?.id ?? "" },
    {
      enabled: isAuthenticated && selectedOrganization != null,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        // console.log("fetched locations; updating selections: ", shouldUpdateSelectedLocations)
        // Set all locations for the organization
        setLocationsForOrganization(data)

        // Select all locations when changing organization
        if (shouldUpdateSelectedLocations) {
          setSelectedLocationId(data?.at(0)?.id ?? null)
          setSelectedLocationIds(data.map((location) => location.id))
        }
      },
    },
  )

  const onOrganizationSelectSideEffects = (org: Organization) => {
    setSelectedOrganization(org)
    setLocationsForOrganization([])
    locationsRefetch()
    setSelectedLocationIds([])
    setShouldUpdateSelectedLocations(true)
  }
  const onOrganizationSelect = (org: Organization) => {
    //console.log("Org in onorgselect: ", org)
    setSelectedProfile({ type: "organization", organization: org })
    onOrganizationSelectSideEffects(org)
  }

  // Grab Organization for Context
  trpc.organization.getOrganization.useQuery(
    { id: orgLookupId ?? "" },
    {
      enabled: orgLookupId != null && isAuthenticated,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setOrganization(data)
        // onOrganizationSelect(data)
      },
    },
  )

  // Hydrate from localStorage
  useEffect(() => {
    const storageContents = grabFromLocalStorage()
    const organizationId = storageContents?.organizationId ?? undefined
    const locationIds = storageContents?.locationIds
    const locationId = storageContents?.locationId

    setOrgLookupId(organizationId)
    if (locationIds != null) {
      setLocationIds(locationIds)
    }
    if (locationId != null) {
      setSelectedLocationId(locationId)
    }
  }, [])

  useEffect(() => {
    if (user != null && selectedProfile == null) {
      const userProfile: Profile = { type: "user", user: user }
      setSelectedProfile(userProfile)
    }
  }, [user, selectedProfile])

  const onUserSelect = useCallback(() => {
    if (user != null) {
      const userProfile: Profile = { type: "user", user: user }
      setSelectedProfile(userProfile)
    }
  }, [setSelectedProfile, user])

  const ensureSelected = useCallback(
    (profileType: Profile["type"]) => {
      setTargetProfile(profileType)
    },
    [setTargetProfile],
  )

  useEffect(() => {
    if (targetProfile === "user") {
      if (user != null) {
        setSelectedProfile({ type: "user", user })
        setTargetProfile(undefined)
      }
    } else if (targetProfile === "organization") {
      if (selectedOrganization != null) {
        setSelectedProfile({ type: "organization", organization: selectedOrganization })
        setTargetProfile(undefined)
      }
    }
  }, [user, selectedOrganization, targetProfile])

  return (
    <SelectionContext.Provider
      value={{
        refetchOrgs: orgRefetch,
        onOrganizationSelect: onOrganizationSelect,
        refetchLocations: locationsRefetch,
        onUserSelect,
        selectedProfile: selectedProfile ?? undefined,
        ensureSelected,
      }}
    >
      {children}
    </SelectionContext.Provider>
  )
}

export default SelectionsProvider
