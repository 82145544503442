import { Box, Text } from "@mantine/core"
import { IntersectionResult, SpecialUseAirspace, TimeIntersectionResult } from "@soar/shared/types"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import { CSSProperties } from "react"
import { ActivityWindowDisplay } from "./ActivityWindowDisplay"
import { TimeIntersectionDisplay } from "./TimeIntersectionDisplay"
import { useTimezone } from "./timezoneContext"

dayjs.extend(relativeTime)

const defaultPirepStyle: CSSProperties = {
  width: "100%",
  overflowWrap: "break-word",
  inlineSize: "100%",
}

const numberFormatter = new Intl.NumberFormat()

function AirspaceAltitudeDisplay({
  value,
  code,
  unitMeasurement,
}: {
  value: number
  code: string | null | undefined
  unitMeasurement: string | null | undefined
}) {
  if (code === "SFC") {
    return <Text span>surface</Text>
  }
  if (code === "UNLTD") {
    return <Text span>unlimited</Text>
  }
  if (unitMeasurement === "FL") {
    return <Text span>FL{value}</Text>
  }
  return <Text span>{numberFormatter.format(value)} ft</Text>
}

export function AirspaceDisplay({
  airspace,
  timeIntersection,
  routeIntersection,
}: { airspace: SpecialUseAirspace; routeIntersection: IntersectionResult; timeIntersection: TimeIntersectionResult }) {
  const { departureTimeZone } = useTimezone()
  const typeName = airspace.typeCode === "P" ? "prohibited" : "restricted"
  return (
    <Box fz={{ base: "sm", md: "md" }}>
      <Text span style={defaultPirepStyle} c="gray.7">
        {airspace.name} is {typeName} from{" "}
        <AirspaceAltitudeDisplay value={airspace.lowerValue} code={airspace.lowerCode} unitMeasurement={airspace.lowerUnitMeasurement} /> to{" "}
        <AirspaceAltitudeDisplay value={airspace.upperValue} code={airspace.upperCode} unitMeasurement={airspace.upperUnitMeasurement} />
      </Text>
      <Text c="black" mt="lg">
        {timeIntersection != null && routeIntersection != null && (
          <TimeIntersectionDisplay
            timeIntersection={timeIntersection}
            intersectionTime={routeIntersection.intersectionTime}
            stageOfFlight={"enroute"}
          />
        )}
        <Text>
          <Text span fw={600}>
            Active:{" "}
          </Text>
          <Text span>
            <ActivityWindowDisplay continuous timeZone={departureTimeZone ?? ""} />
          </Text>
        </Text>
      </Text>
    </Box>
  )
}
