/**
 * Separates out an array of words into a list separated by commas and the last word separated by the word `and`
 * Does not include the oxford comma
 **/
export function englishList(words: string[]) {
  if (words.length === 0) {
    return null
  }

  if (words.length === 1) {
    return words[0]
  }

  const beginning = words.slice(0, words.length - 1)
  const end = words[words.length - 1]

  const resultingText = `${beginning.join(", ")} and ${end}`
  return resultingText
}

export function sentenceCase(input: string) {
  return input.charAt(0).toUpperCase() + input.slice(1)
}
