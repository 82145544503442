import { Box, Button, Flex, TextInput } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { LocationDetails, LocationDetailsSchema, Organization } from "@soar/shared/types"
import { ReactNode, useEffect } from "react"
import { z } from "zod"

const LocationDetailsWithoutOrg = LocationDetailsSchema.omit({ organizationId: true })

export function EditLocationForm({
  onSubmit = () => {},
  initialValues,
  submitButtonText = "Create Location",
  selectedOrganization,
  replaceResetButtonWith,
}: {
  onSubmit?: (organizationDetails: LocationDetails) => void
  initialValues?: LocationDetails | z.infer<typeof LocationDetailsWithoutOrg>
  submitButtonText?: string
  selectedOrganization?: Organization
  replaceResetButtonWith?: ReactNode
}) {
  const hasOrganization = selectedOrganization != null
  const form = useForm<LocationDetails>({
    validate: zodResolver(hasOrganization ? LocationDetailsSchema : LocationDetailsWithoutOrg),
    validateInputOnChange: true,
  })

  useEffect(() => {
    if (hasOrganization) {
      form.setValues({ organizationId: selectedOrganization.id })
    }
  }, [selectedOrganization])

  useEffect(() => {
    if (initialValues != null) {
      form.setValues(initialValues)
    }
  }, [initialValues])

  return (
    <Box>
      <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
        <TextInput label="Name" withAsterisk {...form.getInputProps("name")} />
        <TextInput label="Airport Code" withAsterisk {...form.getInputProps("airportCode")} />
        {hasOrganization && <TextInput label="Organization" value={selectedOrganization.name} disabled />}

        <Flex justify="space-between" mt="xl" pt="md">
          {replaceResetButtonWith ? (
            replaceResetButtonWith
          ) : (
            <Button onClick={form.reset} color="gray">
              Reset
            </Button>
          )}
          <Button type="submit" disabled={!form.isValid()}>
            {submitButtonText}
          </Button>
        </Flex>
      </form>
    </Box>
  )
}
