import { ActionIcon, Box, Card, Divider, Flex, Skeleton, Stack, Text, Title, px, rem } from "@mantine/core"
import { Airport, WeatherTimeGroupWithAdditionalWeather } from "@soar/shared/types"
import { formatDate } from "@soar/shared/utils"
import { IconExclamationCircle } from "@tabler/icons-react"
import { useMemo } from "react"
import { StatusDisplay } from "../statusDisplay"
import { RiskBadge } from "../weather"
import { useFlightRuleStyles } from "../weather/useFlightRuleStyles"
import { calculateTimeOfDayTimeToDisplayCore } from "../weather/utils"
import { useSavedAirportStyles } from "./useSavedAirportStyles"

const numberFormatter = new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 })

export function SavedAiportOverviewCardLoading() {
  const { classes, cx } = useSavedAirportStyles()

  return (
    <Card radius="md" py="md" shadow="md" h="100%">
      <Flex justify="space-between">
        <Title order={4} fz={px("1.3rem")}>
          <Flex align="center">
            <span className="material-symbols-outlined" style={{ marginTop: "-4px" }}>
              altitude
            </span>
            {<Skeleton ml={6} h={16} width={30} />}
          </Flex>
        </Title>
        <Flex gap={6}>
          <ActionIcon fw={700}>
            <Text
              span
              color="black"
              className={cx("material-symbols-outlined", classes.saveAirportIcon)}
              style={{
                fontVariationSettings: "'wght' 700",
                transform: "rotate(-30deg)",
                marginTop: "-6px",
              }}
            >
              send
            </Text>
          </ActionIcon>
          <ActionIcon>
            <Text span color="black" className={cx("material-symbols-outlined", classes.saveAirportIcon, { saved: false })}>
              favorite
            </Text>
          </ActionIcon>
        </Flex>
      </Flex>
      <Flex justify="space-between" mt={5}>
        <Box>
          <Skeleton h={42} width={100} />
          <Skeleton mt={10} h={12} width={120} />
        </Box>
        <Box />
      </Flex>
      <Box mt="sm">
        <Skeleton h={12} w={200} />
      </Box>
    </Card>
  )
}

export function SavedAiportOverviewCardError({
  identifier,
}: {
  identifier: string
}) {
  return (
    <Card radius="md" py="md" shadow="md" h="100%">
      <StatusDisplay
        label={`Sorry, we couldn't load ${identifier}.  Please try again later.`}
        icon={IconExclamationCircle}
        color="red.7"
        labelProps={{ fz: "md" }}
        iconProps={{ size: 50 }}
      />
    </Card>
  )
}

export function SavedAirportOverviewCard({
  airport,
  timeGroup,
  onDelete = () => {},
  onStartBrief = () => {},
  onClick,
  saved = false,
  timeZone,
}: {
  airport: Airport
  timeGroup: WeatherTimeGroupWithAdditionalWeather
  saved?: boolean
  onDelete?: () => void
  onClick?: () => void
  onStartBrief?: (airport: Airport) => void
  timeZone?: string
}) {
  const { classes, cx } = useSavedAirportStyles()
  const { classes: flightRulesClasses } = useFlightRuleStyles()
  const infoWidth = 90
  const timeOfDayTimeToDisplay = calculateTimeOfDayTimeToDisplayCore(timeGroup.dateFrom, timeGroup.sunrise, timeGroup.sunset)
  const allRisks = useMemo(() => {
    const allRisks = [...timeGroup.notamRisks, ...timeGroup.weatherRisks]
    allRisks.sort((a, b) => b.severity - a.severity)
    return allRisks
  }, [timeGroup])

  const handleStartBrief = () => {
    onStartBrief(airport)
  }

  return (
    <Card radius="md" onClick={onClick} py="md" shadow="md" style={{ cursor: "pointer" }} h="100%">
      <Flex justify="space-between">
        <Title order={4} fz={px("1.3rem")}>
          <Flex align="center">
            <span className="material-symbols-outlined" style={{ marginTop: "-4px" }}>
              altitude
            </span>
            <Text ml={6}>{numberFormatter.format(airport.elevation)}'</Text>
          </Flex>
        </Title>
        <Flex gap={6}>
          <ActionIcon
            onClick={(e) => {
              e.stopPropagation()
              handleStartBrief()
            }}
            fw={700}
          >
            <Text
              span
              color="black"
              className={cx("material-symbols-outlined", classes.saveAirportIcon)}
              style={{
                fontVariationSettings: "'wght' 700",
                transform: "rotate(-30deg)",
                marginTop: "-6px",
              }}
            >
              send
            </Text>
          </ActionIcon>
          <ActionIcon
            onClick={(e) => {
              e.stopPropagation()
              onDelete()
            }}
          >
            <Text span color="black" className={cx("material-symbols-outlined", classes.saveAirportIcon, { saved: saved })}>
              favorite
            </Text>
          </ActionIcon>
        </Flex>
      </Flex>
      <Flex justify="space-between">
        <Box>
          <Title order={3} fz={px("2.3rem")}>
            {airport.icaoId ?? airport.faaAirportId}
          </Title>
          {timeGroup.weatherDetails.flightRules != null && (
            <Box className={flightRulesClasses[timeGroup.weatherDetails.flightRules]} fw={600}>
              {timeGroup.weatherDetails.flightRules} conditions
            </Box>
          )}
        </Box>
        <Box>
          <Stack spacing={7} fz="sm" display="none" hidden>
            <Flex align="center" fw={600}>
              <span className={cx("material-symbols-outlined", classes.overviewInfoIcon)}>calendar_today</span>
              <Text span ml="xs" w={infoWidth}>
                {formatDate(timeGroup.dateFrom, { date: { format: "long" } })}
              </Text>
            </Flex>
            <Flex align="center" fw={600}>
              <span className={cx("material-symbols-outlined", classes.overviewInfoIcon)}>flight_takeoff</span>
              <Text span ml="xs" w={infoWidth} tt="lowercase">
                {formatDate(timeGroup.dateFrom, { time: true, timeZone })}
              </Text>
            </Flex>
            <Flex align="center" fw={600}>
              <span className={cx("material-symbols-outlined", classes.overviewInfoIcon)}>
                {timeOfDayTimeToDisplay === "sunset" ? "clear_night" : "sunny"}
              </span>
              <Text span ml="xs" w={infoWidth} tt="lowercase">
                {timeOfDayTimeToDisplay == null && <Skeleton width={95} height={12} display="inline-block" />}
                {timeOfDayTimeToDisplay === "sunrise" && formatDate(timeGroup.sunrise, { time: true, timeZone: timeZone })}
                {timeOfDayTimeToDisplay === "sunset" && formatDate(timeGroup.sunset, { time: true, timeZone: timeZone })}
              </Text>
            </Flex>
          </Stack>
        </Box>
      </Flex>
      <Box mt="sm">
        {allRisks.length > 0 ? (
          <Flex align="center">
            <RiskBadge severity={allRisks[0].severity} label={allRisks[0].label} size="md" />
            {allRisks.length > 1 && (
              <Text ml="sm" c="dimmed" fz="sm">
                +{allRisks.length - 1}
              </Text>
            )}
          </Flex>
        ) : (
          <Text c="dimmed" fz="sm">
            No signficant threats
          </Text>
        )}
      </Box>
    </Card>
  )
}
