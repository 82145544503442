import { Box, Button, Chip, Flex, Space, Text, Textarea, Title } from "@mantine/core"
import { UseFormReturnType, useForm, zodResolver } from "@mantine/form"
import { BUTTON_GRADIENT_COLORS } from "@soar/frontend/components"
import { ScoreAdjustment } from "@soar/shared/types"
import { AssessmentThreatColors, DefaultAssessmentThreatCutoffs, getAssessmentThreatScoreLevel } from "@soar/shared/utils"
import { useEffect, useMemo } from "react"

export function ScoreAdjustmentView({
  score,
  form,
  onSubmit = () => {},
}: {
  score: number
  form: UseFormReturnType<ScoreAdjustment>
  onSubmit?: (values: ScoreAdjustment) => void
}) {
  const { modifiedScore, modifiedScoreColor } = useMemo(() => {
    let modifiedScore = score
    if (form.values.adjustmentLevel === "low") {
      modifiedScore = Math.round(DefaultAssessmentThreatCutoffs.overall.orange / 2)
    } else if (form.values.adjustmentLevel === "moderate") {
      modifiedScore = Math.round(
        (DefaultAssessmentThreatCutoffs.overall.red - DefaultAssessmentThreatCutoffs.overall.orange) / 2 +
          DefaultAssessmentThreatCutoffs.overall.orange,
      )
    }
    const modifiedScoreLevel = getAssessmentThreatScoreLevel(modifiedScore)
    const modifiedScoreColor = AssessmentThreatColors[modifiedScoreLevel]

    return {
      modifiedScore,
      modifiedScoreColor,
    }
  }, [form.values.adjustmentLevel])

  useEffect(() => {
    form.setFieldValue("newScore", modifiedScore)
  }, [modifiedScore])

  useEffect(() => {
    form.setFieldValue("previousScore", score)
  }, [score])

  return (
    <Box component="form" onSubmit={form.onSubmit((values) => onSubmit(values))}>
      <Title order={2} mb={5}>
        High risk flight
      </Title>
      <Text>Would you like to adjust the risk level of this flight?</Text>
      <Flex justify="center" align="center" gap="xs">
        <Text fz={70} fw={500} color={modifiedScoreColor}>
          {modifiedScore}
        </Text>
        <Text>/ 100</Text>
      </Flex>
      <Chip.Group {...form.getInputProps("adjustmentLevel")}>
        <Flex
          gap="sm"
          justify={{ base: "stretch", md: "space-around" }}
          align={{ base: "stretch", md: "center" }}
          direction={{ base: "column", md: "row" }}
        >
          <Chip value="none" styles={{ label: { width: "100%", justifyContent: "center" } }}>
            Keep high risk
          </Chip>
          <Chip value="moderate" styles={{ label: { width: "100%", justifyContent: "center" } }}>
            Change to moderate risk
          </Chip>
          <Chip value="low" styles={{ label: { width: "100%", justifyContent: "center" } }}>
            Change to low risk
          </Chip>
        </Flex>
      </Chip.Group>
      <Textarea label="Rationale (required)" mt="xl" {...form.getInputProps("comment")} />
      <Space h="xl" />
      <Flex mt="xl" justify="center">
        <Button
          disabled={form != null && !form.isValid()}
          type="submit"
          w="100%"
          variant="gradient"
          gradient={{ ...BUTTON_GRADIENT_COLORS, deg: 180 }}
        >
          Submit assessment
        </Button>
      </Flex>
    </Box>
  )
}
