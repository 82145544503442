import { ActionIcon, Flex, Stack, Text } from "@mantine/core"
import { IconCrown, IconX } from "@tabler/icons-react"

export function PilotSelectionRow({
  name,
  inCharge = false,
  inChargeLabel = "Instructor",
  defaultLabel = "Pilot",
  onRemove = () => {},
  onInChargeSelect = () => {},
}: {
  name: string
  inCharge?: boolean
  inChargeLabel?: string
  defaultLabel?: string
  onRemove?: () => void
  onInChargeSelect?: () => void
}) {
  return (
    <Flex align="flex-start" justify="space-between">
      <Stack spacing={2}>
        <Text size="sm">{name}</Text>
        <Text size="xs">{inCharge ? inChargeLabel : defaultLabel}</Text>
      </Stack>
      <Flex gap="sm">
        <ActionIcon
          size="sm"
          onClick={onInChargeSelect}
          variant={inCharge ? "filled" : "transparent"}
          color={inCharge ? "blue.8" : undefined}
        >
          <IconCrown stroke={2} />
        </ActionIcon>
        <ActionIcon onClick={onRemove} size="sm">
          <IconX stroke={2} />
        </ActionIcon>
      </Flex>
    </Flex>
  )
}
