import { Organization, RegisteredUser } from "../schemas"

export type RegisteredUserProfile = {
  type: "user"
  user: RegisteredUser
}
export type OrganizationProfile = {
  type: "organization"
  organization: Organization
}

export type Profile = RegisteredUserProfile | OrganizationProfile

export function isOrganizationProfile(profile?: Profile): profile is OrganizationProfile {
  return profile != null && profile.type === "organization"
}
