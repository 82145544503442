import { AirSigmet, FlightRule, MetarCloudResponse } from "@soar/shared/types"
import { z } from "zod"

export const WeatherInputSchema = z.object({
  date: z.date(),
  departure: z.string().min(1).max(7),
  destination: z.string().min(1).max(7),
  aircraft: z.string().min(1).max(20),
})

export type WeatherInput = z.infer<typeof WeatherInputSchema>
