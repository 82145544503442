import {
  ActionIcon,
  Avatar,
  Box,
  Drawer,
  Flex,
  Menu,
  MenuProps,
  NavLink,
  NavLinkStylesNames,
  Stack,
  Text,
  UnstyledButton,
  createStyles,
} from "@mantine/core"
import { organizationsSelectableForUserAtom, profileAtomWithStorage, useSelections, useUser } from "@soar/frontend/contexts"
import { Organization, Profile, RegisteredUser } from "@soar/shared/types"
import { getUserName } from "@soar/shared/utils"
import { IconBuilding, IconChevronDown, IconChevronRight, IconPlus, IconUser, IconX } from "@tabler/icons-react"
import { atom, useAtom, useAtomValue } from "jotai"
import Link from "next/link"
import { ReactNode, createContext, useCallback } from "react"
import { useDrawerStyles } from "../../modalOrDrawer"

export const orgAvatar = <IconBuilding />
const useStyles = createStyles((theme) => ({
  dropdown: {
    background: "#0351CC",
    borderStyle: "none",
  },
  dropdownItem: {
    ["&:hover"]: {
      background: "transparent",
      backdropFilter: "brightness(115%)",
    },
    ["&[data-hovered]"]: {
      background: "transparent",
      backdropFilter: "brightness(115%)",
    },
  },
  dropdownLink: {
    color: theme.white,
  },
  divider: {
    borderColor: theme.colors.gray[6],
  },
}))

const nullProfileInfo = {
  avatar: undefined,
  label: "No profile selected",
}

export function getProfileInfo(profile?: Profile | null) {
  if (profile == null) {
    return nullProfileInfo
  } else if (profile.type === "user") {
    return {
      avatar: profile.user.avatarUrl,
      label: getUserName(profile.user),
    }
  } else if (profile.type === "organization") {
    return {
      avatar: profile.organization.avatarUrl,
      label: profile.organization.name,
    }
  }

  return nullProfileInfo
}
export function BackupProfileAvatar({
  selectedProfile,
  user,
}: {
  selectedProfile?: Profile
  user?: RegisteredUser
}) {
  if (selectedProfile?.type === "organization") {
    return orgAvatar
  } else if (user != null) {
    return `${user.firstName[0]}${user.lastName[0]}`
  } else {
    return <IconUser />
  }
}

const organizationOptionsAtom = atom((get) => {
  const orgsForUser = get(organizationsSelectableForUserAtom)
  const selectedProfile = get(profileAtomWithStorage)
  if (orgsForUser == null) {
    return orgsForUser
  } else if (selectedProfile?.type === "user") {
    return orgsForUser
  } else {
    return orgsForUser.filter((org) => org.id !== selectedProfile?.organization.id)
  }
})

export function OrganizationSwitcherMobileOption({
  label,
  avatar,
  onClick = () => {},
}: {
  label: string
  avatar: string | ReactNode
  onClick?: () => void
}) {
  const avatarIsString = typeof avatar === "string"
  return (
    <UnstyledButton onClick={onClick}>
      <Flex align="center">
        <Avatar src={avatarIsString ? avatar : null} mr="xs" color="blue" radius="xl">
          {avatarIsString ? undefined : avatar}
        </Avatar>
        <Text>{label}</Text>
      </Flex>
    </UnstyledButton>
  )
}

export function OrganizationSwitcherMobile({
  opened,
  children,
  onClose,
  onOrgSwitch = () => {},
  onUserSwitch = () => {},
}: {
  children: ReactNode
  opened: boolean
  onClose: () => void
  onOrgSwitch?: (organization: Organization) => void
  onUserSwitch?: () => void
}) {
  const organizationOptions = useAtomValue(organizationOptionsAtom)
  const { classes: drawerClasses } = useDrawerStyles()
  const [selectedProfile, setSelectedProfile] = useAtom(profileAtomWithStorage)
  const { user } = useUser()
  const { onOrganizationSelect, onUserSelect } = useSelections()

  const onUserClick = useCallback(() => {
    onUserSelect()
    onUserSwitch()
    onClose()
  }, [onUserSelect, onUserSwitch])

  const onOrganizationClick = (organization: Organization) => {
    onOrganizationSelect(organization)
    onOrgSwitch(organization)
    onClose()
  }

  return (
    <>
      {children}

      <Drawer
        opened={opened}
        onClose={onClose}
        position="bottom"
        size="50%"
        classNames={{
          content: drawerClasses.drawerRadius,
        }}
        styles={{
          body: {
            position: "relative",
          },
        }}
        withCloseButton={false}
        title={
          <Flex justify="flex-end">
            <ActionIcon onClick={onClose} style={{ position: "absolute", top: "10px", right: "10px" }}>
              <IconX fontWeight={600} strokeWidth={3} color="black" />
            </ActionIcon>
          </Flex>
        }
      >
        <Stack>
          {selectedProfile?.type !== "user" && (
            <OrganizationSwitcherMobileOption
              label={`${user?.firstName} ${user?.lastName}`}
              avatar={user?.avatarUrl ?? <IconUser />}
              onClick={onUserClick}
            />
          )}
          {(organizationOptions ?? []).map((organization) => {
            const onClick = () => {
              onOrganizationClick(organization)
            }
            return (
              <OrganizationSwitcherMobileOption
                key={organization.id}
                label={organization.name}
                avatar={organization.avatarUrl ?? orgAvatar}
                onClick={onClick}
              />
            )
          })}
        </Stack>
      </Drawer>
    </>
  )
}

export function OrganizationSwitcher({
  selectedProfile,
  user,
  onProfileSelect = () => {},
  showCreate = false,
  onOrgSwitch = () => {},
  onUserSwitch = () => {},
  children,
  menuProps = {},
}: {
  selectedProfile?: Profile
  user?: RegisteredUser
  onProfileSelect?: (organization: Profile) => void
  showCreate?: boolean
  onOrgSwitch?: (organization: Organization) => void
  onUserSwitch?: () => void
  children: ReactNode
  menuProps?: Partial<MenuProps>
}) {
  const organizationOptions = useAtomValue(organizationOptionsAtom)
  const orgsForUser = useAtomValue(organizationsSelectableForUserAtom)
  const { classes } = useStyles()
  const onOrganizationSelect = (organization: Organization) => {
    onProfileSelect({
      type: "organization",
      organization,
    })
    onOrgSwitch(organization)
  }

  const onUserSelect = () => {
    if (user != null) {
      onProfileSelect({
        type: "user",
        user,
      })
      onUserSwitch()
    }
  }
  const disabled = orgsForUser.length === 0 && showCreate === false

  return (
    <Menu
      zIndex={2000}
      closeOnItemClick
      width="target"
      shadow="1px 1px 5px #888"
      disabled={disabled}
      classNames={{
        dropdown: classes.dropdown,
        item: classes.dropdownItem,
        divider: classes.divider,
      }}
      {...menuProps}
    >
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown>
        {selectedProfile?.type !== "user" && user != null && (
          <>
            <Menu.Item key={user.id} onClick={onUserSelect}>
              <UnstyledButton component="div" className={classes.dropdownLink}>
                <Flex align="center">
                  <Avatar src={user.avatarUrl ?? null} mr="xs" color="blue" size="xs" radius="xl">
                    {user.firstName[0]}
                    {user.lastName[0]}
                  </Avatar>
                  <Text fz="sm">{getUserName(user)}</Text>
                </Flex>
              </UnstyledButton>
            </Menu.Item>
            {(organizationOptions.length > 0 || showCreate) && <Menu.Divider />}
          </>
        )}
        {(organizationOptions ?? []).map((organization) => {
          const orgAvatar = <IconBuilding />
          return (
            <Menu.Item key={organization.id} onClick={() => onOrganizationSelect(organization)}>
              <UnstyledButton component="div" className={classes.dropdownLink}>
                <Flex align="center">
                  <Avatar src={organization?.avatarUrl ?? null} mr="xs" color="blue" size="xs" radius="xl">
                    {orgAvatar}
                  </Avatar>
                  <Text fz="sm">{organization.name}</Text>
                </Flex>
              </UnstyledButton>
            </Menu.Item>
          )
        })}
        {showCreate && (
          <Menu.Item>
            <Link href="/manage/organizations/new" passHref legacyBehavior>
              <UnstyledButton component="a" className={classes.dropdownLink}>
                <Flex align="center">
                  <Avatar size="xs" mr="xs" color="white" radius="xl">
                    <IconPlus />
                  </Avatar>
                  <Text fz="sm">Create New Organization</Text>
                </Flex>
              </UnstyledButton>
            </Link>
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  )
}
