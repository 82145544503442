import { Aircraft, RegisteredUser } from "@soar/shared/types"
import { isAircraft } from "@soar/shared/utils"
import { CSSProperties } from "react"
import { PlaneDisplay } from "../../../planeDisplay/"
import { UserDisplay } from "../../../userDisplay/"

const defaultRowHeaderStyle: CSSProperties = {
  width: "100%",
  height: "100%",
  border: "thin solid #EEE",
  padding: "4px 10px",
}

export function RowHeaderSwitcher({
  source,
}: {
  source: Aircraft | RegisteredUser
  index: number
}) {
  const renderPlane = isAircraft(source)
  return <div style={defaultRowHeaderStyle}>{renderPlane ? <PlaneDisplay plane={source} /> : <UserDisplay user={source} />}</div>
}
