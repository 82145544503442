import { TRPCClientError, createTRPCProxyClient, httpBatchLink, httpLink as trpcHttpLink, splitLink as trpcSplitLink } from "@trpc/client"
import { createTRPCNext } from "@trpc/next"
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server"

import type { AppRouter } from "@soar/backend/trpc-api"

export function getBaseUrl() {
  if (process.env["API_URL"]) {
    return process.env["API_URL"]
  }

  if (process.env["NEXT_PUBLIC_API_URL"]) {
    return process.env["NEXT_PUBLIC_API_URL"]
  }

  // assume localhost
  return "http://localhost:3333"
}

type TokenGetter = () => Promise<string | null>

let authToken: string | TokenGetter | undefined

export function setAuthToken(token: string | TokenGetter) {
  authToken = token
}
export async function getAuthToken() {
  if (typeof authToken === "function") {
    const token = await authToken()
    return token
  }
  return authToken
}

export function isTRPCClientError(cause: unknown): cause is TRPCClientError<AppRouter> {
  return cause instanceof TRPCClientError
}

const batchLink = httpBatchLink<AppRouter>({
  /**
   * If you want to use SSR, you need to use the server's full URL
   * @link https://trpc.io/docs/ssr
   **/
  url: `${getBaseUrl()}/trpc`,
  headers: async () => {
    const baseHeaders = {}

    if (authToken) {
      const token = await getAuthToken()

      const headers = {
        ...baseHeaders,
        Authorization: `Bearer ${token}`,
      }

      return headers
    }
    return baseHeaders
  },
})
const httpLink = trpcHttpLink<AppRouter>({
  url: `${getBaseUrl()}/trpc`,
  headers: async () => {
    const baseHeaders = {}

    if (authToken) {
      const token = await getAuthToken()

      const headers = {
        ...baseHeaders,
        Authorization: `Bearer ${token}`,
      }

      return headers
    }
    return baseHeaders
  },
})

const splitLink = trpcSplitLink({
  condition(op) {
    return op.context.skipBatch === true
  },
  true: httpLink,
  false: batchLink,
})

export const trpcHttp = createTRPCProxyClient<AppRouter>({
  links: [batchLink],
})

export const trpc = createTRPCNext<AppRouter>({
  config({ ctx }) {
    return {
      links: [splitLink],
      abortOnUnmount: true,

      /**
       * @link https://tanstack.com/query/v4/docs/reference/QueryClient
       **/
      queryClientConfig: { defaultOptions: { queries: { staleTime: 60, refetchOnWindowFocus: false, retry: false } } },
    }
  },
  /**
   * @link https://trpc.io/docs/ssr
   **/
})

export type RouterInputs = inferRouterInputs<AppRouter>
export type RouterOutputs = inferRouterOutputs<AppRouter>
