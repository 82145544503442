import { Box, BoxProps, Center, SimpleGrid, Skeleton, Stack, Text, TextProps, Title } from "@mantine/core"
import { AdditionalWeatherData, IWeatherDetails, LoadingStatus, MetarResponse, Risk, TafResponse } from "@soar/shared/types"
import { translateWeather } from "@soar/shared/utils"
import { IconCloud } from "@tabler/icons-react"
import { StatusDisplay } from "../statusDisplay"
import { AlternateTafBanner } from "./AlternateTafBanner"
import { CloudCoverDetails } from "./CloudCoverDetails"
import { VisibilityDetails } from "./VisibilityDetails"
import { WindSpeedDetails } from "./WindSpeedDetails"

import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import { P, match } from "ts-pattern"
import { RawWeatherDetails } from "./RawWeatherDetails"
import { RiskBadge } from "./RiskBadge"
import { WeatherDataBanner } from "./WeatherDataBanner"

dayjs.extend(utc)
dayjs.extend(timezone)

const numberFormatter = new Intl.NumberFormat()

const commonBodyProps: TextProps = {
  fz: { base: "sm", md: "md" },
  c: "gray.7",
}

export function SafetyWeatherDetails({
  loadingStatus,
  rawMode = false,
  risks = [],
  weatherDetails,
  airport,
  boxProps,
  taf,
  metar,
  additionalWeatherDetails,
}: {
  taf?: TafResponse
  metar?: MetarResponse
  risks?: Risk[]
  rawMode?: boolean
  loadingStatus: LoadingStatus
  weatherDetails?: IWeatherDetails
  airport: string
  boxProps?: BoxProps
  additionalWeatherDetails?: AdditionalWeatherData
}) {
  const loading = loadingStatus !== "success"

  const windRisk = risks.find((r) => r.category === "weather" && r.subcategory === "wind")
  const visibilityRisk = risks.find((r) => r.category === "weather" && r.subcategory === "visibility")
  const ceilingRisk = risks.find((r) => r.category === "weather" && r.subcategory === "ceiling")
  const weatherPhenomenonRisks = risks.filter((r) => r.category === "weather" && r.subcategory === "weather_phenomenon")
  const maxSeverityWeatherPhenomenonRisk =
    weatherPhenomenonRisks.length > 0 ? Math.max(...weatherPhenomenonRisks.map((risk) => risk.severity)) : undefined

  const tempDewpointSpreadRisk = risks.find((r) => r.riskIdentifier === "temperature_dewpoint_spread")
  const densityAltitudeRisk = risks.find((r) => r.riskIdentifier === "density_altitude")

  if (rawMode) {
    return (
      <Box {...boxProps}>
        <RawWeatherDetails taf={taf} metar={metar} weatherDetails={weatherDetails} />
      </Box>
    )
  }

  return (
    <Box {...boxProps}>
      <Box mb="md" fw={600}>
        {loading && <Skeleton radius="xl" height={20} />}
        {!loading && (
          <Stack spacing="4px">
            {weatherDetails?.type === "taf" && weatherDetails.tafSource !== airport && (
              <AlternateTafBanner source={weatherDetails.tafSource ?? ""} target={airport} />
            )}
            {(weatherDetails?.type === "pirateWeatherDailyData" || weatherDetails?.type === "pirateWeatherHourlyData") && (
              <WeatherDataBanner />
            )}
          </Stack>
        )}
      </Box>
      {(loading || (weatherDetails?.type !== "no-data-past" && weatherDetails?.type !== "no-data-future")) && (
        <SimpleGrid cols={2}>
          <WindSpeedDetails
            speed={weatherDetails?.windSpd}
            direction={weatherDetails?.windDir}
            gusts={weatherDetails?.windGst}
            loading={loading}
            risk={windRisk}
          />
          <VisibilityDetails visibility={weatherDetails?.visibility} loading={loading} risk={visibilityRisk} />
          <CloudCoverDetails clouds={weatherDetails?.clouds} cloudCover={weatherDetails?.cloudCover} loading={loading} risk={ceilingRisk} />

          <Box>
            {maxSeverityWeatherPhenomenonRisk == null ? (
              <Title order={5} tt="uppercase">
                Weather
              </Title>
            ) : (
              <RiskBadge severity={maxSeverityWeatherPhenomenonRisk} label="Significant Weather" size="md" showIcon={false} />
            )}
            {!loading ? <Text {...commonBodyProps}>{weatherDetails?.weather}</Text> : <Skeleton radius="xl" height={20} />}
          </Box>

          <Box>
            {tempDewpointSpreadRisk == null ? (
              <Title order={5} tt="uppercase">
                Temp/Dew
              </Title>
            ) : (
              <RiskBadge severity={tempDewpointSpreadRisk.severity} label={tempDewpointSpreadRisk.label} size="md" showIcon={false} />
            )}
            {match(additionalWeatherDetails)
              .with(P.nullish, () => <Skeleton radius="xl" height={20} />)
              .with({ temperature: P.number, dewpoint: P.number }, (result) => (
                <Text {...commonBodyProps}>
                  {Math.floor(result.temperature)}/{Math.floor(result.dewpoint)}
                </Text>
              ))
              .otherwise(() => (
                <>Not available</>
              ))}
          </Box>

          <Box>
            {densityAltitudeRisk == null ? (
              <Title order={5} tt="uppercase">
                Density Altitude
              </Title>
            ) : (
              <RiskBadge severity={densityAltitudeRisk.severity} label={densityAltitudeRisk.label} size="md" showIcon={false} />
            )}
            {additionalWeatherDetails != null ? (
              <Text {...commonBodyProps}>
                {additionalWeatherDetails.densityAltitude != null
                  ? `${numberFormatter.format(Math.floor(additionalWeatherDetails.densityAltitude))}'`
                  : "Not available"}
              </Text>
            ) : (
              <Skeleton radius="xl" height={20} />
            )}
          </Box>
        </SimpleGrid>
      )}
      {!loading && (weatherDetails?.type === "no-data-past" || weatherDetails?.type === "no-data-future") && (
        <Center px="sm">
          <StatusDisplay
            icon={IconCloud}
            label={
              weatherDetails.type === "no-data-past"
                ? "Choose future time for weather. Past time selected."
                : "Weather information is not available this far into the future."
            }
            labelProps={{
              ta: "center",
            }}
          />
        </Center>
      )}
    </Box>
  )
}
