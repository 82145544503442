import { Aircraft, AircraftSchema, IPlane, IUser, RegisteredUser, RegisteredUserSchema } from "@soar/shared/types"

export function isPlane(item: IPlane | IUser | undefined): item is IPlane {
  const isUndefined = item == null
  if (isUndefined) {
    return false
  }

  return "tailNumber" in item
}
export function isUser(item: IPlane | IUser | undefined): item is IUser {
  const isUndefined = item == null
  if (isUndefined) {
    return false
  }

  return !isPlane(item)
}

export function isAircraft(item: any): item is Aircraft {
  const isUndefined = item == null
  if (isUndefined) {
    return false
  }

  return AircraftSchema.safeParse(item).success
}

export function isRegisteredUser(item: any): item is RegisteredUser {
  const isUndefined = item == null
  if (isUndefined) {
    return false
  }

  return RegisteredUserSchema.safeParse(item).success
}
