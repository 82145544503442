import { ComponentProps, useContext } from "react"
import { CustomLinkContext } from "./LinkContext"

import Link from "next/link"

type LinkType = typeof Link

export function CustomLink({ children, ...restProps }: ComponentProps<LinkType>) {
  const CustomLinkComponent = useContext(CustomLinkContext)

  if (CustomLinkComponent == null) {
    const { href, ...restLinkProps } = restProps

    const url = typeof href === "string" || typeof href === "undefined" ? href : href.toString()

    return (
      <a href={url} {...restLinkProps}>
        {children}
      </a>
    )
  }

  return <CustomLinkComponent {...restProps}>{children}</CustomLinkComponent>
}
