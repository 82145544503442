import { Popover, Text, UnstyledButton } from "@mantine/core"
import { DatePicker as MDatePicker } from "@mantine/dates"
import dayjs from "dayjs"
import { ScheduleView } from "./schedule.interface"

const dateFormat = "MMMM D, YYYY" as const

export function DatePicker({
  view = "day",
  value = new Date(),
  onChange = () => {},
}: {
  view?: ScheduleView
  value: Date
  onChange?: (date: Date) => void
}) {
  let label = ""
  if (view === "day") {
    label = dayjs(value).format(dateFormat)
  } else {
    label = `${dayjs(value).startOf("week").format(dateFormat)} - ${dayjs(value).endOf("week").format(dateFormat)}`
  }
  return (
    <Popover>
      <Popover.Target>
        <UnstyledButton px="sm">
          <Text inherit fw={600}>
            {label}
          </Text>
        </UnstyledButton>
      </Popover.Target>
      <Popover.Dropdown>
        <MDatePicker value={value} onChange={onChange} />
      </Popover.Dropdown>
    </Popover>
  )
}
