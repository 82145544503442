import { createContext, useContext } from "react"

export type TimezoneContextBody = {
  departureTimeZone?: string
  destinationTimeZone?: string
}

export const TimezoneContext = createContext<TimezoneContextBody>({
  departureTimeZone: undefined,
  destinationTimeZone: undefined,
})

export const useTimezone = () => useContext(TimezoneContext)
