import { User } from "@soar/shared/types"
import { isRegisteredUser } from "./subject"

export const VIP_PLAN_NAME = "Soar VIP" as const

export function getUserName(user: User | undefined | null, defaultEmail = false) {
  if (user == null) {
    return ""
  }

  if (isRegisteredUser(user)) {
    return `${user.firstName} ${user.lastName}`
  } else {
    return defaultEmail ? user.email : ""
  }
}

export function hasActiveSubscription(user: User | undefined | null) {
  if (user == null) {
    return false
  }
  if (isRegisteredUser(user)) {
    const now = new Date()
    const userHasValidFreeTrial = user.freeTrialEndDate != null && now < user.freeTrialEndDate
    const userIsVip = user.stripeSubscriptionPlan === VIP_PLAN_NAME
    const userHasActiveSubscription =
      user.stripeSubscriptionEndDate != null && now < user.stripeSubscriptionEndDate && user.stripeSubscriptionStatus === "active"
    return userHasValidFreeTrial || userIsVip || userHasActiveSubscription
  }

  return false
}
