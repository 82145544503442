import { z } from "zod"

export const FeedbackResponseSchema = z.object({
  id: z.string().ulid(),
  body: z.string().min(20).max(1500),
  source: z.string().nullish(),
  userId: z.string().ulid(),
  createdAt: z.coerce.date(),
})

export const FeedbackResponseDetailsSchema = FeedbackResponseSchema.pick({
  body: true,
  source: true,
})

export type FeedbackResponse = z.infer<typeof FeedbackResponseSchema>
export type FeedbackResponseDetails = z.infer<typeof FeedbackResponseDetailsSchema>
