export const ArtccCenters = [
  { id: "FIMM", name: "Mauritius ACC" },
  { id: "NZZO", name: "Auckland Oceanic ACC" },
  { id: "SBAO", name: "Atlantico ACC" },
  { id: "ZAB", name: "Albuquerque" },
  { id: "ZAK", name: "Oakland Oceanic" },
  { id: "ZAN", name: "Anchorage" },
  { id: "ZAP", name: "Anchorage Oceanic" },
  { id: "ZAU", name: "Chicago" },
  { id: "ZBW", name: "Boston" },
  { id: "ZDC", name: "Washington D.C." },
  { id: "ZDV", name: "Denver" },
  { id: "ZEG", name: "Edmonton" },
  { id: "ZFW", name: "Fort Worth" },
  { id: "ZHN", name: "Honolulu" },
  { id: "ZHU", name: "Houston" },
  { id: "ZID", name: "Indianapolis" },
  { id: "ZJX", name: "Jacksonville" },
  { id: "ZKC", name: "Kansas City" },
  { id: "ZLA", name: "Los Angeles" },
  { id: "ZLC", name: "Salt Lake City" },
  { id: "ZMA", name: "Miami" },
  { id: "ZME", name: "Memphis" },
  { id: "ZMP", name: "Minneapolis" },
  { id: "ZNY", name: "New York" },
  { id: "ZOA", name: "Oakland" },
  { id: "ZOB", name: "Cleveland" },
  { id: "ZSE", name: "Seattle" },
  { id: "ZSU", name: "San Juan" },
  { id: "ZTL", name: "Atlanta" },
  { id: "ZUA", name: "Agana, Guam" },
  { id: "ZUL", name: "Montreal" },
  { id: "ZVR", name: "Vancouver" },
  { id: "ZWG", name: "Winnipeg" },
  { id: "ZYZ", name: "Toronto" },
]
