import { Organization } from "@soar/shared/types"

export function getOrganizationShortName(organization: Organization) {
  if (organization.shortName != null) {
    return organization.shortName
  }

  return initializeOrgName(organization.name)
}

export function initializeOrgName(input: string) {
  const orgNameSplit = input.split(" ")
  if (orgNameSplit.length > 1) {
    return orgNameSplit
      .map((word) => {
        return word.charAt(0).toUpperCase()
      })
      .join("")
  }

  return input.slice(0, 5)
}
