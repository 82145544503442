import { Box, Skeleton, Stack, Text, Title } from "@mantine/core"
import { AirportResult, IWeatherDetails, LoadingStatus, MetarResponse, TafResponse } from "@soar/shared/types"

const tafGroupPattern = /((FM\d+)|(BECMG)|(TEMPO))/g
const tafSplitString = "#{SPLIT}#"
const tafReplacement = `${tafSplitString}$1`

export function RawWeatherDetails({
  metar,
  taf,
  metarStatus,
  tafStatus,
  weatherDetails,
}: {
  weatherDetails?: IWeatherDetails
  metar?: MetarResponse
  taf?: TafResponse
  metarStatus?: LoadingStatus
  tafStatus?: LoadingStatus
}) {
  // @ts-ignore this is available
  const splitTaf = taf?.rawTAF.replaceAll!(tafGroupPattern, tafReplacement).split(tafSplitString)

  return (
    <Box>
      <Stack spacing="xs">
        {metarStatus === "loading" ? (
          <Skeleton />
        ) : (
          <Box>
            <Title order={5} tt="uppercase" color="dimmed">
              Metar
            </Title>

            <Text fw={weatherDetails?.type === "metar" ? 700 : undefined}>{metar?.rawOb ?? "No METAR available"}</Text>
          </Box>
        )}

        {tafStatus === "loading" ? (
          <Skeleton />
        ) : (
          <Box>
            <Title order={5} tt="uppercase" color="dimmed">
              Taf
            </Title>

            {splitTaf?.map((tafGroup: string, index: number) => (
              <Text
                fw={weatherDetails?.type === "taf" && index === weatherDetails.tafGroup ? 700 : undefined}
                pl={index > 0 ? "5px" : undefined}
                key={index}
              >
                {tafGroup}
              </Text>
            ))}
          </Box>
        )}
      </Stack>
    </Box>
  )
}
