const environment = process.env.NEXT_PUBLIC_ENV ?? process.env.NODE_ENV
const titleBase = "Soar" as const

export function generateTitle(pageName?: string) {
  let titleStart = `${titleBase}`

  if (environment === "staging") {
    titleStart = `${titleStart} Staging`
  } else if (environment === "development") {
    titleStart = `${titleStart} Dev`
  }

  if (pageName == null) {
    return titleStart
  }

  return `${titleStart} - ${pageName}`
}
