import { Box, Button, Divider, Flex, Grid, Select, Text, TextInput } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import {
  FileDetails,
  Organization,
  OrganizationDetailsWithAvatar,
  OrganizationDetailsWithAvatarSchema,
  OrganizationToEdit,
} from "@soar/shared/types"
import { usStates } from "@soar/shared/utils"
import { IconAt, IconPhone } from "@tabler/icons-react"
import { ReactNode, useEffect } from "react"
import { EditableAvatar } from "../../avatar"

const statesSelectOptions = usStates.map((state) => ({ value: state.abbreviation, label: state.name }))

export function NewOrganizationForm({
  onSubmit = () => {},
  initialValues,
  submitButtonText = "Create Organization",
  resetButton,
}: {
  resetButton?: ReactNode
  onSubmit?: (organizationDetails: OrganizationDetailsWithAvatar) => void
  initialValues?: Organization | OrganizationToEdit
  submitButtonText?: string
}) {
  const form = useForm<OrganizationDetailsWithAvatar>({
    initialValues: initialValues,
    validate: zodResolver(OrganizationDetailsWithAvatarSchema),
    validateInputOnChange: true,
  })

  useEffect(() => {
    if (initialValues != null) {
      form.reset()
      form.setValues(initialValues)
    }
  }, [initialValues])

  const onAvatarChange = (avatarFile: FileDetails) => {
    form.setValues({ avatar: avatarFile })
  }

  return (
    <Box>
      <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
        <EditableAvatar value={form.values.avatarUrl} onChange={onAvatarChange} />
        <TextInput label="Name" withAsterisk {...form.getInputProps("name")} />
        <TextInput label="Short name" withAsterisk {...form.getInputProps("shortName")} />
        <Divider
          label={
            <Text fz="sm" fw="500">
              Business Address
            </Text>
          }
          mt="xl"
          mb="xs"
        />

        <TextInput label="Street Address" {...form.getInputProps("businessAddressStreet")} />
        <TextInput label="Street Address 2" {...form.getInputProps("businessAddressStreet2")} />
        <Grid>
          <Grid.Col xs={12} sm={6}>
            <TextInput label="City" {...form.getInputProps("businessAddressCity")} />
          </Grid.Col>
          <Grid.Col xs={6} sm={3}>
            <Select label="State" searchable data={statesSelectOptions} {...form.getInputProps("businessAddressState")} />
          </Grid.Col>
          <Grid.Col xs={6} sm={3}>
            <TextInput label="Zip" {...form.getInputProps("businessAddressZip")} />
          </Grid.Col>
        </Grid>
        <Divider
          label={
            <Text fz="sm" fw="500">
              Contact Information
            </Text>
          }
          mt="xl"
          mb="xs"
        />
        <TextInput label="Contact Phone" icon={<IconPhone size={14} />} {...form.getInputProps("contactPhone")} />
        <TextInput label="Contact Email" icon={<IconAt size={14} />} {...form.getInputProps("contactEmail")} />
        <Flex justify="space-between" mt="xl" pt="md">
          {resetButton ? (
            resetButton
          ) : (
            <Button onClick={form.reset} color="gray">
              Reset
            </Button>
          )}
          <Button type="submit" disabled={!form.isValid()}>
            {submitButtonText}
          </Button>
        </Flex>
      </form>
    </Box>
  )
}
