import { Badge, Center, MantineSize } from "@mantine/core"
import { RiskSeverity } from "@soar/shared/types"
import { ThreatColors, determineThreatCategory } from "@soar/shared/utils"
import { CSSProperties, ReactNode } from "react"

const circleSizes: Record<MantineSize, string> = {
  xs: "0.2rem",
  sm: "0.5rem",
  md: "0.75rem",
  lg: "1rem",
  xl: "2rem",
}
const IconStyle: CSSProperties = {
  border: "1px solid transparent",
  display: "inline-flex",
  borderRadius: "50%",
  marginRight: "5px",
  backgroundColor: "white",
  alignItems: "center",
  justifyContent: "center",
}

export function RiskBadge({
  severity,
  label = "Risk",
  size = "lg",
  showIcon = true,
  icon,
  color,
}: {
  color?: string
  severity: RiskSeverity
  label?: string
  size?: MantineSize
  showIcon?: boolean
  icon?: ReactNode
}) {
  const threatCategory = determineThreatCategory(severity)
  const riskSeverityColor = threatCategory != null ? ThreatColors[threatCategory] : "#555"
  const resolvedColor = color ?? riskSeverityColor
  const circleSize = circleSizes[size]
  let iconDom = showIcon ? (
    <Center>
      <div
        style={{
          ...IconStyle,
          width: circleSize,
          height: circleSize,
        }}
      />
    </Center>
  ) : undefined
  if (icon != null) {
    iconDom = (
      <Center>
        <div
          style={{
            ...IconStyle,
            height: circleSize,
            borderRadius: "20px",
            color: resolvedColor,
            padding: "0 4px",
          }}
        >
          {icon}
        </div>
      </Center>
    )
  }
  return (
    <Badge leftSection={iconDom} styles={{ root: { backgroundColor: resolvedColor, color: "white" } }} size={size}>
      {label}
    </Badge>
  )
}
