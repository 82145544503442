import { Button, Text } from "@mantine/core"
import { SafetyBriefMode } from "@soar/shared/types"
import { IconSwitch, IconSwitchHorizontal } from "@tabler/icons-react"
import { useCallback } from "react"

export function SafetyBriefModeToggle({
  safetyBriefMode,
  onSafetyBriefModeChange = () => {},
}: {
  safetyBriefMode: SafetyBriefMode
  onSafetyBriefModeChange?: (mode: SafetyBriefMode) => void
}) {
  const onButtonClick = useCallback(() => {
    if (safetyBriefMode === "data") {
      onSafetyBriefModeChange("assessment")
    } else {
      onSafetyBriefModeChange("data")
    }
  }, [safetyBriefMode, onSafetyBriefModeChange])
  return (
    <Button
      variant="subtle"
      onClick={onButtonClick}
      style={{ paddingLeft: 0 }}
      leftIcon={
        <Text span className="material-symbols-outlined">
          sync_alt
        </Text>
      }
    >
      {safetyBriefMode === "assessment" ? "See source data" : "See risk assessment"}
    </Button>
  )
}
