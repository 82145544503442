import { Box, MantineStyleSystemProps } from "@mantine/core"
import { ReactNode } from "react"
import { PAGE_BACKGROUND_COLOR } from "../tokens"

export function PageWrapper({ children, p = "lg" }: { children: ReactNode | ReactNode[]; p?: MantineStyleSystemProps["p"] }) {
  return (
    <Box bg={PAGE_BACKGROUND_COLOR} p={p} mih="100%">
      {children}
    </Box>
  )
}
