import styles from "./Schedule.module.scss"

import { ReactNode } from "react"
import { IScheduleSegment } from "./schedule.interface"

export type renderColumnHeaderElement = (args: {
  from: number
  to: number
  index: number
}) => ReactNode

export type renderTitleElement = () => ReactNode

export interface IScheduleHeaderProps {
  rowHeaderWidth: number
  segmentWidth: number
  headerRowHeight: number
  renderColumnHeader?: renderColumnHeaderElement
  renderTitle?: renderTitleElement
  segments: IScheduleSegment[]
}

function DefaultHeaderLabel({ to }: { to: number }) {
  return <div className={styles["schedule-header-label-default"]}>{to}</div>
}

export function ScheduleHeader({
  rowHeaderWidth,
  segmentWidth,
  headerRowHeight,
  segments,
  renderColumnHeader,
  renderTitle,
}: IScheduleHeaderProps) {
  const hasRenderHeaderLabel = renderColumnHeader != null
  return (
    <div className={`${styles["schedule-header"]} ${styles["schedule-row"]}`} style={{ height: `${headerRowHeight}px` }}>
      <div className={styles["schedule-row-header"]} style={{ width: rowHeaderWidth }}>
        {typeof renderTitle === "function" && renderTitle()}
      </div>
      <div style={{ width: rowHeaderWidth }} className={styles["schedule-row-header-placeholder"]} />
      {segments.map((segment, index) => (
        <div className={styles["schedule-header-label"]} key={index} style={{ width: segmentWidth }}>
          {hasRenderHeaderLabel ? (
            renderColumnHeader({
              to: segment.to,
              from: segment.from,
              index: index,
            })
          ) : (
            <DefaultHeaderLabel to={segment.to} />
          )}
        </div>
      ))}
    </div>
  )
}
