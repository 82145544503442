import { ReactNode, useContext } from "react"

import { Box, Card, Flex, Grid, Stack, Text, useMantineTheme } from "@mantine/core"
import {
  Icon123,
  IconClockHour4,
  IconClockHour8,
  IconPlaneArrival,
  IconPlaneDeparture,
  IconPlaneTilt,
  IconReport,
  IconUsers,
} from "@tabler/icons-react"

import { usePreferences } from "@soar/frontend/contexts"
import { Aircraft, RegisteredUser, Reservation } from "@soar/shared/types"
import { formatDate, getStatusForReservation } from "@soar/shared/utils"
import { ReservationStatusBadge } from "../.."
import { locationObjectsCacheContext } from "../locations"
import { PlaneDisplay } from "../planeDisplay"
import { UserDisplay } from "../userDisplay"
import { getReservationTypeColors } from "./colors"
import { ReservationActionMenu } from "./reservationActionMenu"

export function CardRow({
  label,
  value,
}: {
  label: ReactNode
  value: ReactNode
}) {
  return (
    <Flex justify="flex-start">
      <div style={{ width: "40%", flexShrink: 0 }}>{label}</div>
      <div style={{ flexGrow: 1 }}>{value}</div>
    </Flex>
  )
}

export function ReservationCardPilotsRow({
  reservation,
}: {
  reservation: Reservation
}) {
  if (reservation.users == null || reservation.users.length === 0) {
    return <></>
  }
  const { getUser } = useContext(locationObjectsCacheContext)

  return (
    <CardRow
      label={
        <Flex align="center">
          <IconUsers stroke={2} size={20} />
          <Text span ml="5px">
            Pilots:
          </Text>
        </Flex>
      }
      value={
        <Stack spacing="xs">
          {reservation.users.map((u) => {
            const user = getUser(u.userId)
            if (user == null) {
              return <Box key={u.userId}>Unknown User</Box>
            }
            return (
              <Box key={u.userId}>
                <UserDisplay user={user} />
              </Box>
            )
          })}
        </Stack>
      }
    />
  )
}

export function ReservationCardLegsRow({
  reservation,
}: {
  reservation: Reservation
}) {
  if (reservation.legs == null || reservation.legs.length === 0) {
    return <></>
  }
  return (
    <CardRow
      label={
        <Flex align="center">
          <IconPlaneTilt style={{ fill: "black" }} stroke={1} size={20} />
          <Text span ml="5px">
            Flight route
          </Text>
        </Flex>
      }
      value={(reservation?.legs ?? []).map((leg) => (
        <Grid gutter={0}>
          <Grid.Col span={4}>
            <Flex align="center">
              <IconPlaneDeparture style={{ fill: "black" }} stroke={1} size={18} />
              <Text ml="4px" span>
                {leg.from}
              </Text>
            </Flex>
          </Grid.Col>

          <Grid.Col ta="center" span={2}>
            <Text span>to</Text>
          </Grid.Col>

          <Grid.Col span={4}>
            <Flex align="center">
              <IconPlaneArrival style={{ fill: "black" }} stroke={1} size={18} />
              <Text ml="4px" span>
                {leg.to}
              </Text>
            </Flex>
          </Grid.Col>
        </Grid>
      ))}
    />
  )
}

export function ReservationCardStartRow({
  reservation,
}: {
  reservation: Reservation
}) {
  const { timeFormat } = usePreferences()
  return (
    <CardRow
      label={
        <Flex align="center">
          <IconClockHour4 stroke={2} size={20} />
          <Text span ml="5px">
            Start:
          </Text>
        </Flex>
      }
      value={
        <Flex align="flex-start" direction="column">
          <Text>
            {formatDate(reservation.startTime, {
              date: { format: "long", includeWeekday: true },
              time: true,
              timeFormatOverride: timeFormat,
            })}
          </Text>
        </Flex>
      }
    />
  )
}

export function ReservationCardEndRow({
  reservation,
}: {
  reservation: Reservation
}) {
  const { timeFormat } = usePreferences()
  return (
    <CardRow
      label={
        <Flex align="center">
          <IconClockHour8 stroke={2} size={20} />
          <Text span ml="5px">
            End:
          </Text>
        </Flex>
      }
      value={
        <Flex align="flex-start" direction="column">
          <Text>
            {formatDate(reservation.endTime, {
              date: { format: "long", includeWeekday: true },
              time: true,
              timeFormatOverride: timeFormat,
            })}
          </Text>
        </Flex>
      }
    />
  )
}

export function ReservationCardEstFlightHoursRow({
  reservation,
}: {
  reservation: Reservation
}) {
  if (reservation.estimatedFlightHours == null || reservation.estimatedFlightHours === 0) {
    return <></>
  }
  return (
    <CardRow
      label={
        <Flex align="center">
          <Icon123 stroke={2} size={20} />
          <Text span ml="5px">
            Flight Hours
          </Text>
        </Flex>
      }
      value={
        <Flex align="flex-start" direction="column">
          <Text>{reservation.estimatedFlightHours}</Text>
        </Flex>
      }
    />
  )
}

export function ReservationCardCommentRow({
  reservation,
}: {
  reservation: Reservation
}) {
  if (reservation.comment == null || reservation.comment.length === 0) {
    return <></>
  }
  return (
    <CardRow
      label={
        <Flex align="center">
          <IconReport stroke={2} size={20} />
          <Text span ml="5px">
            Comment
          </Text>
        </Flex>
      }
      value={
        <Flex align="flex-start" direction="column">
          <Text>{reservation.comment}</Text>
        </Flex>
      }
    />
  )
}

export function ReservationCard({
  reservation,
  onSignOutClick = () => {},
  onUpdateClick = () => {},
  onCancelClick = () => {},
  closeParent = () => {},
}: {
  source?: Aircraft | RegisteredUser
  reservation?: Reservation
  onSignOutClick?: (reservation: Reservation) => void
  onUpdateClick?: (reservation: Reservation) => void
  onCancelClick?: (reservation: Reservation) => void
  closeParent?: () => void
}) {
  const { getAircraft } = useContext(locationObjectsCacheContext)
  const theme = useMantineTheme()
  if (reservation == null) {
    return <></>
  }
  const aircraft = getAircraft(reservation.aircraftId)
  const status = getStatusForReservation(reservation)
  return (
    <Card w="375px" bg="white">
      <Card.Section withBorder inheritPadding py="xs" bg={getReservationTypeColors(theme, reservation.type).primary}>
        <Flex justify="space-between">
          <Flex gap="lg" align="center">
            <Text fw={700} tt="capitalize" color="white">
              {reservation.type}
            </Text>
          </Flex>
          <Flex gap="sm" align="center">
            <ReservationStatusBadge status={status} />
            <ReservationActionMenu
              reservation={reservation}
              color="gray.0"
              onSignOutClick={onSignOutClick}
              onUpdateClick={onUpdateClick}
              onCancelClick={onCancelClick}
              onSelect={closeParent}
            />
          </Flex>
        </Flex>
      </Card.Section>

      {aircraft != null && (
        <Card.Section px="xs">
          <Flex
            align="center"
            justify="center"
            py="xs"
            sx={(theme) => ({
              borderBottom: `thin solid ${theme.colors.gray[2]}`,
            })}
          >
            <PlaneDisplay plane={aircraft} size="sm" />
          </Flex>
        </Card.Section>
      )}

      <Card.Section inheritPadding py="sm">
        <Stack spacing="sm">
          <ReservationCardStartRow reservation={reservation} />
          <ReservationCardEndRow reservation={reservation} />
          <ReservationCardPilotsRow reservation={reservation} />
          <ReservationCardLegsRow reservation={reservation} />
          <ReservationCardEstFlightHoursRow reservation={reservation} />
          <ReservationCardCommentRow reservation={reservation} />
        </Stack>
      </Card.Section>
    </Card>
  )
}
