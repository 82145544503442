import { Badge, Flex, Text } from "@mantine/core"
import { FlightLegStatus } from "@soar/shared/types"
import { FlightLegStatusConfig } from "@soar/shared/utils"
import { MaterialSymbol } from "../icons"

export function FlightLegStatusBadge({
  status,
}: {
  status: FlightLegStatus
}) {
  const statusConfig = FlightLegStatusConfig[status]
  return (
    <Flex align="center" gap={5} fw={600}>
      <MaterialSymbol name={statusConfig.icon} />
      {statusConfig.label}
    </Flex>
  )
}
