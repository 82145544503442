import {
  InstructorCertType,
  InstructorCertTypeEnum,
  MedicalCertType,
  MedicalCertTypeEnum,
  PilotLicenseType,
  PilotLicenseTypeEnum,
} from "@soar/shared/types"

const pilotLicenseTypeLabels: Record<PilotLicenseType, string> = {
  [PilotLicenseTypeEnum.enum.STUDENT]: "Student Pilot",
  [PilotLicenseTypeEnum.enum.COMMERCIAL]: "Commercial Pilot",
  [PilotLicenseTypeEnum.enum.SPORT]: "Sport Pilot",
  [PilotLicenseTypeEnum.enum.PRIVATE]: "Private Pilot",
  [PilotLicenseTypeEnum.enum.RECREATIONAL]: "Recreational Pilot",
  [PilotLicenseTypeEnum.enum.AIRLINE]: "Airline Transport Pilot",
} as const

const medicalCertLabels: Record<MedicalCertType, string> = {
  [MedicalCertTypeEnum.enum.CLASS_1]: "Class 1",
  [MedicalCertTypeEnum.enum.CLASS_2]: "Class 2",
  [MedicalCertTypeEnum.enum.CLASS_3]: "Class 3",
} as const

const instructorCertLabels: Record<InstructorCertType, string> = {
  [InstructorCertTypeEnum.enum.CFI]: "Certified Flight Instructor",
  [InstructorCertTypeEnum.enum.CFII]: "Instrument Instructor",
  [InstructorCertTypeEnum.enum.MEI]: "Multi-engine Instructor",
} as const

export function labelForPilotLicenseType(type: PilotLicenseType) {
  return pilotLicenseTypeLabels[type]
}

export function labelForMedicalCertType(type: MedicalCertType) {
  return medicalCertLabels[type]
}

export function labelForInstructorCertType(type: InstructorCertType) {
  return instructorCertLabels[type]
}
