import { inspect } from "util"
import { IntersectionResult } from "@soar/shared/types"
import booleanIntersects from "@turf/boolean-intersects"
import booleanPointOnLine from "@turf/boolean-point-on-line"
import booleanWithin from "@turf/boolean-within"
import turfDistance from "@turf/distance"
import greatCircle from "@turf/great-circle"
import {
  Feature,
  LineString,
  MultiPolygon,
  Point,
  Polygon,
  feature as turfFeature,
  lineString as turfLineString,
  point as turfPoint,
  polygon as turfPolygon,
} from "@turf/helpers"
import { getType as turfGetType } from "@turf/invariant"
import turfLength from "@turf/length"
import lineIntersect from "@turf/line-intersect"
import lineSegment from "@turf/line-segment"
import { geomEach } from "@turf/meta"
import turfMidpoint from "@turf/midpoint"
import nearestPointOnLine from "@turf/nearest-point-on-line"
import pointToLineDistance from "@turf/point-to-line-distance"
import { GeometryCollection } from "geojson"

export type RouteDefinition = {
  exact?: Feature<LineString>
  buffer: Feature<Polygon | MultiPolygon>
  beginTime: Date
  endTime: Date
}

const nonIntersection: IntersectionResult = {
  intersects: false,
  withinBuffer: false,
  intersectionPoint: undefined,
  intersectionTime: undefined,
}

export function computeIntersectionWithRoute(
  feature: Feature<LineString> | Feature<Polygon> | Feature<Point> | Feature<GeometryCollection> | null | undefined,
  route: RouteDefinition,
  output = false,
): IntersectionResult {
  const hasExactRoute = route.exact != null
  if (feature == null) {
    return nonIntersection
  }
  // console.log(`${turfGetType(feature)}: `, feature)

  if (turfGetType(feature) === "GeometryCollection") {
    const results: IntersectionResult[] = []
    // console.log("GEOMETRY COLLECTION: ", feature)
    // @ts-ignore: weird geo error
    geomEach(feature, (geometry) => {
      results.push(computeIntersectionWithRoute(turfFeature(geometry), route, output))
    })
    const sortedResults = [...results].sort((a, b) => {
      if (a.intersectionTime != null && b.intersectionTime != null) {
        return a.intersectionTime.getTime() - b.intersectionTime.getTime()
      } else if (a.intersectionTime == null && b.intersectionTime != null) {
        return -1
      } else if (a.intersectionTime != null && b.intersectionTime == null) {
        return 1
      }
      return 0
    })
    // console.log("sorted results: ", sortedResults)
    return sortedResults.at(0)!
  }

  const isPointFeature = feature.geometry.type === "Point"
  const bufferIntersects = booleanIntersects(route.buffer, feature)
  const bufferContains = isPointFeature ? false : booleanWithin(route.buffer, feature)
  const bufferIsContained = booleanWithin(feature, route.buffer)
  const withinBuffer = bufferIntersects || bufferContains || bufferIsContained

  if (output) {
    console.log("data1: ", { isPointFeature, withinBuffer, bufferIntersects, bufferContains, bufferIsContained })
  }

  if (!hasExactRoute) {
    return {
      intersects: false,
      withinBuffer,
      intersectionPoint: undefined,
      intersectionTime: undefined,
    }
  } else {
    const exactRoute = route.exact!
    const intersectionPoints = lineIntersect(
      exactRoute,
      //@ts-ignore: weird error
      feature,
    )
    const intersects = intersectionPoints.features.length > 0
    let intersectionPoint: Feature<Point>
    if (withinBuffer) {
      // only calculate intersection point and time if within buffer
      const startOfRoute = turfPoint(exactRoute.geometry.coordinates[0])
      const containsStartOfRoute = isPointFeature ? false : booleanWithin(startOfRoute, feature)

      if (containsStartOfRoute) {
        return {
          intersects: true,
          withinBuffer: true,
          intersectionPoint: startOfRoute,
          intersectionTime: route.beginTime,
        }
      } else if (isPointFeature) {
        intersectionPoint = nearestPointOnLine(exactRoute, feature as Feature<Point>)
      } else if (intersects) {
        intersectionPoint = intersectionPoints.features[0]
      } else {
        const segments = lineSegment(
          //@ts-ignore: weird error
          feature,
        )
        const segmentsWithDistance = segments.features.map((segment) => {
          const beginningDistance = pointToLineDistance(segment.geometry.coordinates[0], exactRoute)
          const endingDistance = pointToLineDistance(segment.geometry.coordinates[1], exactRoute)
          const distance = beginningDistance < endingDistance ? beginningDistance : endingDistance
          return {
            distance,
            segment,
          }
        })
        const closestSegment = [...segmentsWithDistance].sort((a, b) => a.distance - b.distance).at(0)!
        const closestPointOnSegment = binarySearchLine(exactRoute, closestSegment.segment, 5)
        intersectionPoint = nearestPointOnLine(exactRoute, closestPointOnSegment)
      }
      let timeIntersection: Date

      if (turfDistance(intersectionPoint, startOfRoute, { units: "miles" }) <= 0.1) {
        timeIntersection = route.beginTime
      } else {
        const startDateTimestamp = route.beginTime.valueOf()
        const endDateTimestamp = route.endTime.valueOf()
        const secondsOnRoute = endDateTimestamp - startDateTimestamp

        const routeLength = turfLength(exactRoute)

        const routeToIntersection = greatCircle(turfPoint(exactRoute.geometry.coordinates[0]), intersectionPoint)
        const distanceAlongRoute = turfLength(routeToIntersection)
        const percentageAlongRoute = distanceAlongRoute / routeLength
        const timeDeltaOfIntersection = percentageAlongRoute * secondsOnRoute
        timeIntersection = new Date(timeDeltaOfIntersection + startDateTimestamp)
        if (output) {
          console.log("data2: ", inspect({ exactRoute, intersectionPoint }, true, null))
        }
      }

      return {
        intersects,
        withinBuffer,
        intersectionPoint: intersectionPoint,
        intersectionTime: timeIntersection,
      } satisfies IntersectionResult
    }
  }

  return nonIntersection
}

export function computeDistanceAlongRoute(pointAlongRoute: Feature<Point>, route: Feature<LineString>) {
  const segments = lineSegment(route)
  let distance = 0
  for (const segment of segments.features) {
    const lineOnSegment = booleanPointOnLine(pointAlongRoute, segment)
    if (lineOnSegment) {
      distance = distance + turfDistance(pointAlongRoute, segment.geometry.coordinates[0])
      break
    } else {
      distance = distance + turfLength(segment)
    }
  }
  return distance
}

export function binarySearchLine(target: Feature<LineString>, line: Feature<LineString>, stopCounter: number) {
  const midpoint = turfMidpoint(line.geometry.coordinates[0], line.geometry.coordinates[1])
  if (stopCounter <= 0) {
    return midpoint
  }

  const beginningDistance = pointToLineDistance(line.geometry.coordinates[0], target)
  const endingDistance = pointToLineDistance(line.geometry.coordinates[1], target)
  let subsearchLine: Feature<LineString>
  if (beginningDistance < endingDistance) {
    subsearchLine = turfLineString([line.geometry.coordinates[0], midpoint.geometry.coordinates])
  } else {
    subsearchLine = turfLineString([midpoint.geometry.coordinates, line.geometry.coordinates[1]])
  }
  return binarySearchLine(target, subsearchLine, stopCounter - 1)
}
