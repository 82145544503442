import { AirSigmet, DataWithIntersections, NewNotam, Pirep } from "@soar/shared/types"
import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react"

export interface ISafetyDataContext {
  getNotam: (id: string) => DataWithIntersections<NewNotam> | undefined
  getPirep: (id: string) => DataWithIntersections<Pirep> | undefined
  getAirsigmet: (id: string) => DataWithIntersections<AirSigmet> | undefined
}

export const SafetyDataContext = createContext<ISafetyDataContext>({
  getNotam: () => undefined,
  getPirep: () => undefined,
  getAirsigmet: () => undefined,
})

export function SafetyDataProvider({
  notams,
  pireps,
  airsigmets,
  children,
}: {
  notams: DataWithIntersections<NewNotam>[]
  pireps: DataWithIntersections<Pirep>[]
  airsigmets: DataWithIntersections<AirSigmet>[]
  children: ReactNode
}) {
  const { notamMap, getNotam } = useMemo(() => {
    const newNotamMap = new Map<string, DataWithIntersections<NewNotam>>()
    for (const notamWithIntersections of notams) {
      newNotamMap.set(`${notamWithIntersections.data.id}`, notamWithIntersections)
    }
    return {
      notamMap: newNotamMap,
      getNotam: (id: string) => newNotamMap.get(id),
    }
  }, [notams])

  const { pirepMap, getPirep } = useMemo(() => {
    const newPirepMap = new Map<string, DataWithIntersections<Pirep>>()
    for (const pirepWithIntersections of pireps) {
      newPirepMap.set(`${pirepWithIntersections.data.pirepId}`, pirepWithIntersections)
    }
    return {
      pirepMap: newPirepMap,
      getPirep: (id: string) => newPirepMap.get(id),
    }
  }, [pireps])

  const { airsigmetMap, getAirsigmet } = useMemo(() => {
    const newAirsigmetMap = new Map<string, DataWithIntersections<AirSigmet>>()
    for (const airsigmetWithIntersections of airsigmets) {
      newAirsigmetMap.set(`${airsigmetWithIntersections.data.airSigmetId}`, airsigmetWithIntersections)
    }
    return {
      airsigmetMap: newAirsigmetMap,
      getAirsigmet: (id: string) => newAirsigmetMap.get(id),
    }
  }, [airsigmets])

  return (
    <SafetyDataContext.Provider
      value={{
        getNotam: getNotam,
        getPirep: getPirep,
        getAirsigmet: getAirsigmet,
      }}
    >
      {children}
    </SafetyDataContext.Provider>
  )
}

export const useSafetyData = () => useContext(SafetyDataContext)
