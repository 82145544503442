import { Box, Flex } from "@mantine/core"
import { AssessmentThreatCutoffs } from "@soar/shared/types"
import { AssessmentThreatColors } from "@soar/shared/utils"
import { ReactNode, useMemo } from "react"

function convertPercentageToDegrees(percentage: number) {
  const convertedPercentage = 270 + percentage * 180
  const normalizedPercentage = convertedPercentage >= 360 ? convertedPercentage - 360 : convertedPercentage

  return normalizedPercentage
}

type ColorSection = {
  color: string
  from: number
  to: number
  foreground?: string
}

function makeColorGradients(sections: ColorSection[], valuePercentage?: number) {
  const transformedSections = sections.flatMap((section, index) => {
    if (index === 0) {
      return [
        {
          ...section,
          to: section.to - 1,
        },
        {
          ...section,
          from: section.to - 1,
          to: section.to + 1,
          color: "transparent",
        },
      ]
    } else if (index !== sections.length - 1) {
      return [
        {
          ...section,
          from: section.from + 1,
          to: section.to - 1,
        },
        {
          ...section,
          from: section.to - 1,
          to: section.to + 1,
          color: "transparent",
        },
      ]
    } else if (index === sections.length - 1) {
      return [
        {
          ...section,
          from: section.from + 1,
        },
      ]
    } else {
      return section
    }
  })
  if (valuePercentage == null) {
    return transformedSections
  }

  const valueDegree = convertPercentageToDegrees(valuePercentage)
  let consumedValue = false
  const transformedSectionsWithMark = transformedSections.flatMap((section) => {
    if (!consumedValue && valueDegree >= section.from && valueDegree <= section.to) {
      consumedValue = true

      return [
        {
          ...section,
          to: valueDegree - 1,
        },
        {
          color: "#000",
          from: valueDegree - 1,
          to: valueDegree,
        },
        {
          ...section,
          from: valueDegree,
        },
      ]
    } else {
      return [section]
    }
  })
  return transformedSectionsWithMark
}

export function SafetyThermometer({
  cutoffs,
  value,
  maxValue,
  size = 200,
  thickness = 30,
  children,
  innerBgColor,
}: {
  cutoffs: AssessmentThreatCutoffs
  value?: number
  maxValue: number
  size?: number
  thickness?: number
  children?: ReactNode
  innerBgColor?: string
}) {
  const colors = useMemo(() => {
    const base = [
      {
        color: AssessmentThreatColors.clear,
        from: convertPercentageToDegrees(0),
        to: convertPercentageToDegrees(cutoffs.orange / maxValue),
      },
      {
        color: AssessmentThreatColors.orange,
        from: convertPercentageToDegrees(cutoffs.orange / maxValue),
        to: convertPercentageToDegrees(cutoffs.red / maxValue),
      },
      {
        color: AssessmentThreatColors.red,
        from: convertPercentageToDegrees(cutoffs.red / maxValue),
        to: convertPercentageToDegrees(1),
      },
    ]
    const startIndex = base.findIndex((section) => section.from >= section.to)
    const beginning = base.slice(startIndex).map((section, index) => {
      if (index === 0) {
        return {
          ...section,
          from: 0,
        }
      }
      return section
    })
    const end = base.slice(0, startIndex + 1)

    const colors = [
      ...beginning,
      {
        color: "transparent",
        from: 90,
        to: 270,
      },
      ...end.map((section, index) => {
        if (index === end.length - 1) {
          return {
            ...section,
            to: 360,
          }
        }
        return section
      }),
    ]
    // console.log("degrees: ", {base, beginning, end, colors})
    const transformed = makeColorGradients(colors, value != null ? value / maxValue : undefined)

    const background = `conic-gradient(
      ${transformed.map((section) => `${section.color} ${section.from}deg ${section.to}deg`).join(", ")}
    )`

    return {
      background,
    }
  }, [cutoffs, value, maxValue])

  const innerSize = size - thickness

  return (
    <Box
      h={size / 2}
      style={{
        overflow: "hidden",
      }}
    >
      <Flex
        style={{
          background: colors.background,
          borderRadius: "100%",
        }}
        w={size}
        h={size}
        justify="center"
        align="center"
      >
        <Flex
          w={innerSize}
          h={innerSize}
          bg={innerBgColor ?? "white"}
          style={{
            borderRadius: "100%",
          }}
          justify="center"
          align="center"
        >
          {children}
        </Flex>
      </Flex>
    </Box>
  )
}
