import { Avatar, Flex, MantineSize, Text } from "@mantine/core"
import { User } from "@soar/shared/types"

import { decreaseSize, isRegisteredUser } from "@soar/shared/utils"

export function UserDisplay({
  user,
  size = "sm",
}: {
  user: User
  size?: MantineSize
}) {
  const decreasedSize = decreaseSize(size)

  const isRegistered = isRegisteredUser(user)
  const userName = isRegistered ? `${user.firstName} ${user.lastName}` : user.email

  return (
    <Flex align="center" h="100%">
      <Avatar src={isRegistered ? user.avatarUrl : null} size={size} radius="xl" mr={decreasedSize} />
      <Text fz={size}>{userName}</Text>
    </Flex>
  )
}

export function UserNameDisplay({
  user,
  size = "sm",
}: {
  user: User
  size?: MantineSize
}) {
  const isRegistered = isRegisteredUser(user)
  const userName = isRegistered ? `${user.firstName} ${user.lastName}` : user.email

  return (
    <Text fz={size} span>
      {userName}
    </Text>
  )
}
