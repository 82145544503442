import { Organization, Profile } from "@soar/shared/types"
import { createContext, useContext } from "react"

export interface ISelectionContext {
  refetchOrgs: () => void
  refetchLocations: () => void
  onOrganizationSelect: (organization: Organization) => void
  onUserSelect: () => void
  selectedProfile?: Profile
  ensureSelected: (profileType: Profile["type"]) => void
}

export const SelectionContext = createContext<ISelectionContext>({
  refetchOrgs: () => {},
  refetchLocations: () => {},
  onOrganizationSelect: () => {},
  onUserSelect: () => {},
  ensureSelected: () => {},
})

export const useSelections = () => useContext(SelectionContext)
