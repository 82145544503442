import { Button, Center, Flex, Space, TextInput } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { useEffect, useRef } from "react"
import isMobilePhone from "validator/lib/isMobilePhone"
import z from "zod"

export const SignUpFormSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  middleName: z.string().optional(),
  nickname: z.string().optional(),
  email: z.string().email(),
  phone: z.string().refine(isMobilePhone, { message: "must be a valid phone number" }).optional(),
  avatarUrl: z.string(),
})

export type SignUpFormData = z.infer<typeof SignUpFormSchema>

export interface ISignUpFormProps {
  initialData?: Partial<SignUpFormData>
  onSubmit?: (formData: SignUpFormData) => void
  onCancel?: () => void
}

function noop() {
  console.log("noop called")
}

export function SignUpForm({ initialData, onSubmit = noop, onCancel = noop }: ISignUpFormProps) {
  const form = useForm<SignUpFormData>({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      avatarUrl: "",
    },
    validate: zodResolver(SignUpFormSchema),
    validateInputOnBlur: true,
  })

  const formRef = useRef(form)
  useEffect(() => {
    formRef.current = form
  }, [form])

  useEffect(() => {
    if (!formRef.current.isDirty() && initialData != null) {
      formRef.current.setValues(initialData)
    }
  }, [initialData])

  return (
    <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
      <Flex direction="column" gap="md" mb="xl">
        <TextInput placeholder="Peter" label="First Name" withAsterisk {...form.getInputProps("firstName")} />

        <TextInput placeholder="Parker" label="Middle Name" {...form.getInputProps("middleName")} />

        <TextInput placeholder="Mitchell" label="Last Name" withAsterisk {...form.getInputProps("lastName")} />

        <TextInput placeholder="pete@example.com" label="E-mail Address" withAsterisk {...form.getInputProps("email")} />

        <TextInput placeholder="123-456-7890" label="Phone Number" {...form.getInputProps("phone")} />
      </Flex>

      <Space h="xs" />

      <Flex justify="space-between">
        <Button variant="gradient" gradient={{ from: "red.9", to: "pink.9", deg: 60 }} onClick={onCancel}>
          Nevermind
        </Button>
        <Button variant="gradient" gradient={{ from: "teal", to: "blue", deg: 60 }} type="submit">
          Confirm
        </Button>
      </Flex>
    </form>
  )
}
