import { Box, Button, Divider, Flex, ScrollArea, Skeleton, Space, Switch, Text, Title, UnstyledButton } from "@mantine/core"
import { SafetyDataProvider } from "@soar/frontend/contexts"
import {
  AirSigmet,
  AirportResult,
  DataWithIntersections,
  IEnrouteRisk,
  LoadingStatus,
  NewNotam,
  Pirep,
  SafetyAssessmentGroup,
  SafetyBriefMode,
} from "@soar/shared/types"
import { StageOfFlightLabels } from "@soar/shared/utils"
import { IconMap } from "@tabler/icons-react"
import { useMemo } from "react"
import { MaterialSymbol } from "../icons"
import { SidewayAirplaneIcon } from "../icons/sidewayAirplaneIcon"
import { SegmentedControl } from "../segmentedControl"
import { AssessmentThreatList } from "./AssessmentThreatList"
import { RiskList } from "./RiskList"
import { SafetyBriefModeToggle } from "./detailCardCommon"

export function EnrouteDetailSafetyView({
  from,
  to,
  flightTime,
  notams,
  risks,
  rawMode = false,
  airsigmetsStatus,
  pireps,
  airSigmets,
  onRawModeChange = () => {},
  onSafetyBriefModeChange = () => {},
  onMapClick = () => {},
  assessmentThreats,
  departureAirport,
  safetyBriefMode,
}: {
  from: string
  to: string
  flightTime?: string
  pireps?: DataWithIntersections<Pirep>[]
  notams?: DataWithIntersections<NewNotam>[]
  risks?: IEnrouteRisk
  rawMode?: boolean
  airsigmetsStatus: LoadingStatus
  airSigmets?: DataWithIntersections<AirSigmet>[]
  onRawModeChange?: (checked: boolean) => void
  onSafetyBriefModeChange?: (checked: SafetyBriefMode) => void
  onMapClick?: () => void
  assessmentThreats?: SafetyAssessmentGroup
  departureAirport?: AirportResult
  safetyBriefMode: SafetyBriefMode
}) {
  const hasRisks = useMemo(() => {
    const totalRisks =
      (risks?.airSigmetRisks.length ?? 0) +
      (risks?.notamRisks.length ?? 0) +
      (risks?.aerodromeRisks.length ?? 0) +
      (risks?.pirepsRisks.length ?? 0)

    return totalRisks > 0
  }, [risks])

  return (
    <ScrollArea h="100%">
      <SafetyDataProvider notams={notams ?? []} pireps={pireps ?? []} airsigmets={airSigmets ?? []}>
        <Flex pb="xl" pt="md" direction="column" mih="97vh">
          <Flex direction={{ base: "column", md: "row" }} justify="space-between" align={{ base: "stretch", md: "flex-start" }} px="xl">
            <Box>
              <Title order={4} fz="1.3rem">
                {StageOfFlightLabels.enroute}
              </Title>
              <Title order={3} fz="2.3rem">
                {from}
                <Text span mx="lg" lh={1}>
                  <SidewayAirplaneIcon />
                </Text>
                {to}
              </Title>
              <Text fw={600}>
                Flight time: {flightTime != null ? flightTime : <Skeleton display="inline-block" height={13} width={40} mx="2px" />}
              </Text>
              {safetyBriefMode === "data" && (
                <Switch
                  labelPosition="left"
                  label="Raw"
                  fw={700}
                  checked={rawMode}
                  onChange={(e) => {
                    onRawModeChange(e.target.checked)
                  }}
                  mt="sm"
                />
              )}
              <Flex justify="space-between" align="flex-start" mt="xs" pb="sm" bg="white" direction="column">
                <SafetyBriefModeToggle onSafetyBriefModeChange={onSafetyBriefModeChange} safetyBriefMode={safetyBriefMode} />

                <Button variant="subtle" p="0" leftIcon={<MaterialSymbol name="map" fz="22px" />} onClick={onMapClick}>
                  View map
                </Button>
              </Flex>
            </Box>
            <Box>
              <UnstyledButton
                hidden
                onClick={onMapClick}
                p="xs"
                sx={(theme) => ({
                  color: theme.colors["brandBlue"][6],
                  fontSize: "60px",
                  [theme.fn.smallerThan("md")]: {
                    backgroundColor: theme.colors["brandBlue"][5],
                    color: theme.white,
                    fontSize: "24px",
                    width: "100%",
                    borderRadius: theme.radius.md,
                  },
                })}
              >
                <Flex direction={{ base: "row", md: "column" }} align="center" justify="center" gap={8}>
                  <MaterialSymbol name="map" />
                  <Text fw={700} fz="sm">
                    View Map
                  </Text>
                </Flex>
              </UnstyledButton>
            </Box>
          </Flex>
          <Box bg="#F2F7FF" style={{ flexGrow: 1, paddingBottom: "75px" }}>
            {safetyBriefMode === "data" ? (
              <>
                <Box pt="xs" bg="white">
                  {!hasRisks && false && <Divider mx="xl" mb="lg" />}
                </Box>
                <RiskList
                  loading={false}
                  rawMode={rawMode}
                  airSigmets={airSigmets}
                  notams={notams}
                  pireps={pireps}
                  airsigmetRisks={[] ?? risks?.airSigmetRisks}
                  aerodromeRisks={[] ?? risks?.aerodromeRisks}
                  notamRisks={[] ?? risks?.notamRisks}
                  pirepsRisks={[] ?? risks?.pirepsRisks}
                  airspace={[] ?? risks?.airspace}
                  stageOfFlight="enroute"
                />
              </>
            ) : (
              <>
                <Space h="xl" />
                <Box px="lg" fw={700}>
                  Score ({assessmentThreats?.score})
                </Box>
                <AssessmentThreatList assessmentThreats={assessmentThreats} />
              </>
            )}
          </Box>
        </Flex>
      </SafetyDataProvider>
    </ScrollArea>
  )
}
