import Typesense from "typesense"
const environmentName = process.env["RAILWAY_ENVIRONMENT"] ?? process.env["NEXT_PUBLIC_ENV"] ?? process.env["NODE_ENV"] ?? "unknown"

export const localNode = {
  nodes: [
    {
      host: "localhost", // For Typesense Cloud use xxx.a1.typesense.net
      port: 8108, // For Typesense Cloud use 443
      protocol: "http", // For Typesense Cloud use https
    },
  ],
  apiKey: "xyz",
}

export const typesenseCloudNode = {
  nodes: [
    {
      host: "chqldrvtsb1g602jp-1.a1.typesense.net", // For Typesense Cloud use xxx.a1.typesense.net
      port: 443, // For Typesense Cloud use 443
      protocol: "https", // For Typesense Cloud use https
    },
  ],
}

export const typesenseClient = new Typesense.Client({
  ...typesenseCloudNode,
  apiKey: process.env["TYPESENSE_API_KEY"] ?? "",
  connectionTimeoutSeconds: 2,
})

export const typesenseSearchClient = new Typesense.SearchClient({
  ...typesenseCloudNode,
  apiKey: process.env["TYPESENSE_SEARCH_KEY"] ?? process.env["NEXT_PUBLIC_TYPESENSE_SEARCH_KEY"] ?? "",
  connectionTimeoutSeconds: 2,
})

export function getTypesenseCollectionName(baseName: string) {
  return `${environmentName}-${baseName}`
}
