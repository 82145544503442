import { ReservationType, ReservationTypes } from "@soar/shared/types"
import dayjs from "dayjs"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

type FieldConfiguration = {
  label: string
}

type FieldRelevancy = {
  users: boolean | FieldConfiguration
  aircraft: boolean
  pic: boolean | FieldConfiguration
  legs: boolean
}

export const reservationTypeRelevantFields: Record<ReservationType, FieldRelevancy> = {
  [ReservationTypes.enum.flight]: {
    users: true,
    aircraft: true,
    pic: { label: "Instructor" },
    legs: true,
  },
  [ReservationTypes.enum.ground]: {
    users: true,
    aircraft: false,
    pic: { label: "Instructor" },
    legs: false,
  },
  [ReservationTypes.enum.maintenance]: {
    users: false,
    aircraft: true,
    pic: false,
    legs: false,
  },
  [ReservationTypes.enum.unavailable]: {
    users: false,
    aircraft: false,
    pic: { label: "Instructor" },
    legs: false,
  },
} as const

export function isFieldRelevantForReservationType(fieldName: keyof FieldRelevancy, type: ReservationType) {
  const relevantFieldEntry = reservationTypeRelevantFields[type][fieldName]
  return relevantFieldEntry !== false
}
export function getFieldLabelForReservationType(fieldName: keyof FieldRelevancy, type: ReservationType) {
  const relevantFieldEntry = reservationTypeRelevantFields[type][fieldName]
  if (typeof relevantFieldEntry !== "boolean" && "label" in relevantFieldEntry) {
    return relevantFieldEntry.label
  }
  return undefined
}

export const reservationTypeLabels: Record<ReservationType, string> = {
  [ReservationTypes.enum.flight]: "Flight",
  [ReservationTypes.enum.ground]: "Ground",
  [ReservationTypes.enum.maintenance]: "Maintenance",
  [ReservationTypes.enum.unavailable]: "Block",
} as const
