import { Avatar, Flex, MantineSize, Text } from "@mantine/core"
import { Aircraft, IPlane } from "@soar/shared/types"

import { decreaseSize, increaseSize, isAircraft } from "@soar/shared/utils"
import { IconPlane } from "@tabler/icons-react"

export function PlaneDisplay({
  plane,
  size = "sm",
  variant = "normal",
}: {
  plane: IPlane | Aircraft
  size?: MantineSize
  variant?: "normal" | "text"
}) {
  const increasedSize = increaseSize(size)
  const decreasedSize = decreaseSize(size)

  const planeIsAircraft = isAircraft(plane)
  const planeModel = planeIsAircraft ? `${plane.manufacturer} ${plane.model}` : plane.model

  if (variant === "text") {
    return <PlaneDisplayText tailNumber={plane.tailNumber} planeModel={planeModel} />
  }

  return (
    <Flex align="center" h="100%">
      <Avatar src={plane.avatarUrl} size={increasedSize} radius="xl" mr={decreasedSize}>
        <IconPlane />
      </Avatar>
      <div>
        <Text fw="bold" size={increasedSize}>
          {plane.tailNumber}
        </Text>
        <Text size={size}>{planeModel}</Text>
      </div>
    </Flex>
  )
}

function PlaneDisplayText({ tailNumber, planeModel }: { tailNumber: string; planeModel: string }) {
  return (
    <span>
      <Text fw="bold" inherit span>
        {tailNumber}
      </Text>
      <Text ml="5px" inherit span>
        {planeModel}
      </Text>
    </span>
  )
}
