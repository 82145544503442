import { Box, Button, Flex, Paper, SimpleGrid, Text, TextInput } from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"
import { useForm, zodResolver } from "@mantine/form"
import { TimePickerNew } from "../timePicker/"
import { WeatherInput, WeatherInputSchema } from "./types"

import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)
dayjs.extend(timezone)

export function WeatherControlsDeprecated({
  onSubmit = () => {},
  disabled = false,
}: {
  disabled?: boolean
  onSubmit?: (data: WeatherInput) => void
}) {
  const form = useForm<WeatherInput>({
    initialValues: {
      date: dayjs().add(1, "hour").startOf("hour").toDate(),
      departure: "",
      destination: "",
      aircraft: "",
    },
    validate: zodResolver(WeatherInputSchema),
  })

  const onDateChange = (newDate: Date) => {
    const newDateDayjs = dayjs(newDate)
    const resolvedDay = dayjs(form.values.date)
      .set("year", newDateDayjs.get("year"))
      .set("month", newDateDayjs.get("month"))
      .set("day", newDateDayjs.get("day"))

    form.setFieldValue("date", resolvedDay.toDate())
  }

  const onTimeChange = (newTime: Date) => {
    const newTimeDayjs = dayjs(newTime)
    const resolvedDay = dayjs(form.values.date).set("hour", newTimeDayjs.get("hour")).set("minute", newTimeDayjs.get("minute"))

    form.setFieldValue("date", resolvedDay.toDate())
  }

  return (
    <Paper
      component="form"
      onSubmit={form.onSubmit((values) => {
        onSubmit(values)
      })}
      p="md"
      radius="lg"
      shadow="lg"
    >
      <SimpleGrid
        breakpoints={[
          {
            maxWidth: "sm",
            cols: 2,
          },
        ]}
        cols={5}
      >
        <DatePickerInput label="Date" {...form.getInputProps("date")} onChange={onDateChange} labelProps={{ fw: 700 }} />
        <Box>
          <Text fw={700} fz="sm" mb={4}>
            Time
          </Text>
          <TimePickerNew value={form.values.date} onChange={onTimeChange} />
        </Box>
        <TextInput label="Departure" placeholder="KXXX" {...form.getInputProps("departure")} labelProps={{ fw: 700 }} />
        <TextInput label="Destination" placeholder="KXXX" {...form.getInputProps("destination")} labelProps={{ fw: 700 }} />
        <TextInput label="Aircraft" {...form.getInputProps("aircraft")} labelProps={{ fw: 700 }} />
      </SimpleGrid>
      <Flex justify="flex-end">
        <Button
          type="submit"
          mt="md"
          variant="gradient"
          gradient={{ from: "#136DFB", to: "#0351CC", deg: 180 }}
          disabled={disabled || !form.isValid()}
        >
          Get Brief
        </Button>
      </Flex>
    </Paper>
  )
}
