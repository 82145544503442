import { ActionIcon, Box, Drawer, DrawerProps, Flex, Modal, ModalProps, createStyles, useMantineTheme } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { IconX } from "@tabler/icons-react"
import { ReactNode } from "react"

export const useDrawerStyles = createStyles((theme) => ({
  drawerRadius: {
    borderRadius: `${theme.radius.md} ${theme.radius.md} 0 0`,
  },
  fullWidth: {
    width: "100%",
  },
}))

export function ModalOrDrawer({
  id,
  children,
  title,
  onClose,
  opened,
  modalProps = {},
  drawerProps = {},
}: {
  id?: string
  title?: ReactNode
  children: ReactNode
  opened: boolean
  onClose: () => void
  modalProps?: Partial<ModalProps>
  drawerProps?: Partial<DrawerProps>
}) {
  const { classes } = useDrawerStyles()
  const theme = useMantineTheme()
  const shouldUseDrawer = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)
  const titleComponent =
    title == null ? undefined : (
      <Flex justify="space-between" w="100%">
        <Box style={{ flexGrow: 1, flexShrink: 0 }}>{title}</Box>
        <Flex align="center" justify="center">
          <ActionIcon onClick={onClose}>
            <IconX fontWeight={600} strokeWidth={3} color="black" />
          </ActionIcon>
        </Flex>
      </Flex>
    )

  if (shouldUseDrawer) {
    return (
      <Drawer
        id={id}
        position="bottom"
        title={titleComponent}
        onClose={onClose}
        opened={opened}
        withCloseButton={false}
        size="95%"
        padding="sm"
        classNames={{
          content: classes.drawerRadius,
          title: classes.fullWidth,
        }}
        {...drawerProps}
      >
        {children}
      </Drawer>
    )
  } else {
    return (
      <Modal
        title={titleComponent}
        opened={opened}
        onClose={onClose}
        withCloseButton={false}
        classNames={{
          title: classes.fullWidth,
        }}
        {...modalProps}
        id={id}
      >
        {children}
      </Modal>
    )
  }
}
