import { MantineNumberSize, MantineSize, Text, TextProps, createStyles, getSize } from "@mantine/core"
import { getFontSizeValue } from "@mantine/core/lib/Box/style-system-props/value-getters/get-font-size-value"
import { CSSProperties, useMemo } from "react"

const useStyles = createStyles((theme, props: { size: MantineNumberSize | undefined }) => {
  return {
    fontSize: {
      ["&.material-symbols-outlined"]: {
        fontSize: props.size != null ? getSize({ size: props.size, sizes: theme.fontSizes }) : "inherit",
      },
    },
  }
})

export function MaterialSymbol({
  name,
  fill = false,
  fz,
  stroke = 400,
  ...rest
}: TextProps & {
  fz?: MantineNumberSize
  name: string
  fill?: boolean
  stroke?: number
}) {
  const { classes, cx } = useStyles({ size: fz })
  const mergedStyle = useMemo(() => {
    const style: CSSProperties = rest.style ?? {}
    const variationSettings = [`'wght' ${stroke}`]
    if (fill) {
      variationSettings.push("'FILL' 1")
    }
    style.fontVariationSettings = variationSettings.join(", ")
    return style
  }, [fill, stroke, rest.style])
  return (
    <Text span className={cx("material-symbols-outlined", classes.fontSize)} style={mergedStyle} {...rest}>
      {name}
    </Text>
  )
}
