import { Box, Button, Divider, Flex, Group, Paper, ScrollArea, Skeleton, Space, Stack, Switch, Text, Title } from "@mantine/core"
import { SafetyDataProvider } from "@soar/frontend/contexts"
import { AirsigmetTriggerList, MultiTriggerList, PirepTriggerList } from "@soar/shared/threats"
import {
  AdditionalWeatherData,
  AirSigmet,
  AirportFlightMetrics,
  AirportResult,
  DataWithIntersections,
  FlightRule,
  IWeatherDetails,
  MetarResponse,
  NewNotam,
  Pirep,
  Risk,
  SafetyAssessmentGroup,
  SafetyBriefMode,
  StageOfFlight,
  TafResponse,
} from "@soar/shared/types"
import { StageOfFlightLabels, formatDate } from "@soar/shared/utils"
import { useMemo } from "react"
import { P, match } from "ts-pattern"
import { SafetyWeatherDetails } from "../weather/SafetyWeatherDetails"
import { useFlightRuleStyles } from "../weather/useFlightRuleStyles"
import { calculateTimeOfDayTimeToDisplay } from "../weather/utils"
import { AssessmentThreatList } from "./AssessmentThreatList"
import { RiskList } from "./RiskList"
import { SafetyBriefModeToggle } from "./detailCardCommon"

const numberFormatter = new Intl.NumberFormat("en-US", { maximumFractionDigits: 1 })
export function AirportDetailSafetyView({
  airport,
  weatherRisks,
  notamRisks,
  stageOfFlight,
  date,
  flightRules,
  weatherDetails,
  notams,
  taf,
  metar,
  rawMode = false,
  onRawModeChange = () => {},
  airportFlightMetrics,
  additionalWeatherData,
  onSafetyBriefModeChange = () => {},
  assessmentThreats,
  safetyBriefMode,
}: {
  date?: Date
  airport?: AirportResult
  weatherRisks: Risk[]
  notamRisks: Risk[]
  notams: DataWithIntersections<NewNotam>[]
  stageOfFlight: StageOfFlight
  flightRules?: FlightRule
  taf?: TafResponse
  metar?: MetarResponse
  weatherDetails?: IWeatherDetails
  rawMode?: boolean
  onRawModeChange?: (checked: boolean) => void
  airportFlightMetrics?: AirportFlightMetrics
  additionalWeatherData?: AdditionalWeatherData
  onSafetyBriefModeChange?: (checked: SafetyBriefMode) => void
  assessmentThreats?: SafetyAssessmentGroup
  safetyBriefMode: SafetyBriefMode
}) {
  const { classes: flightFulesClasses } = useFlightRuleStyles()
  const timeOfDayTimeToDisplay = calculateTimeOfDayTimeToDisplay(date, airportFlightMetrics)

  const { airsigmets, pireps } = useMemo(() => {
    const { airsigmets, pireps } = (assessmentThreats?.threats ?? []).reduce(
      (memo, threat) => {
        const airsigmets: Array<DataWithIntersections<AirSigmet>> = []
        const pireps: Array<DataWithIntersections<Pirep>> = []

        if (threat.triggers.structured == null || !["airsigmet", "pirep", "multi"].includes(threat.triggers.structured.type)) {
          return memo
        }

        if (threat.triggers.structured.type === "pirep") {
          const typedTrigger = threat.triggers.structured as PirepTriggerList
          for (const pirep of typedTrigger.data) {
            pireps.push(pirep)
          }
        }
        if (threat.triggers.structured.type === "airsigmet") {
          const typedTrigger = threat.triggers.structured as AirsigmetTriggerList
          for (const airsigmet of typedTrigger.data) {
            airsigmets.push(airsigmet)
          }
        }

        if (threat.triggers.structured.type === "multi") {
          const multiTrigger = threat.triggers.structured as MultiTriggerList
          const airsigmetTrigger = multiTrigger.data.find((trigger) => trigger.type === "airsigmet") as AirsigmetTriggerList | undefined
          const pirepTrigger = multiTrigger.data.find((trigger) => trigger.type === "pirep") as PirepTriggerList | undefined
          for (const airsigmet of airsigmetTrigger?.data ?? []) {
            airsigmets.push(airsigmet)
          }
          for (const pirep of pirepTrigger?.data ?? []) {
            pireps.push(pirep)
          }
        }

        return {
          airsigmets: memo.airsigmets.concat(airsigmets),
          pireps: memo.pireps.concat(pireps),
        }
      },
      {
        airsigmets: [] as Array<DataWithIntersections<AirSigmet>>,
        pireps: [] as Array<DataWithIntersections<Pirep>>,
      },
    )
    console.log("AIRSIGMETS AND PIREPS: ", { airsigmets, pireps })

    return {
      airsigmets,
      pireps,
    }
  }, [assessmentThreats])

  return (
    <ScrollArea h="100%">
      <SafetyDataProvider notams={notams} pireps={[]} airsigmets={[]}>
        <Flex pb="xl" pt="md" direction="column" mih="97vh">
          <Flex justify="space-between" align="flex-start" px="xl">
            <Box>
              <Title order={4} fz="1.3rem">
                {StageOfFlightLabels[stageOfFlight]}
              </Title>
              <Title order={3} fz="2.3rem">
                {airport?.icao_id ?? airport?.faa_airport_id}
              </Title>
              {flightRules != null && (
                <Text className={flightFulesClasses[flightRules]} fw={600}>
                  {flightRules} conditions
                </Text>
              )}
              {safetyBriefMode === "data" && (
                <Switch
                  labelPosition="left"
                  label="Raw"
                  fw={700}
                  checked={rawMode}
                  onChange={(e) => {
                    onRawModeChange(e.target.checked)
                  }}
                  mt="sm"
                />
              )}
            </Box>
            <Box>
              <Stack spacing="xs">
                <Flex align="center" fw={600}>
                  <span className="material-symbols-outlined">calendar_today</span>
                  <Text span ml="xs">
                    {date == null ? (
                      <Skeleton width={95} height={12} display="inline-block" />
                    ) : (
                      formatDate(date, { date: { format: "long" }, timeZone: airportFlightMetrics?.timeZoneName })
                    )}
                  </Text>
                </Flex>
                <Flex align="center" fw={600}>
                  <span className="material-symbols-outlined">{stageOfFlight === "departure" ? "flight_takeoff" : "flight_land"}</span>
                  <Text span ml="xs" tt="lowercase">
                    {date == null ? (
                      <Skeleton width={95} height={12} display="inline-block" />
                    ) : (
                      formatDate(date, { time: true, timeZone: airportFlightMetrics?.timeZoneName })
                    )}
                  </Text>
                </Flex>
                <Flex align="center" fw={600}>
                  <span className="material-symbols-outlined">{timeOfDayTimeToDisplay === "sunset" ? "clear_night" : "sunny"}</span>
                  <Text span ml="xs" w={130}>
                    {match({ timeOfDayTimeToDisplay, airportFlightMetrics })
                      .with({ airportFlightMetrics: P.nullish }, () => <Skeleton width={95} height={12} display="inline-block" />)
                      .with({ airportFlightMetrics: { sunrise: P.not(P.nullish) }, timeOfDayTimeToDisplay: "sunrise" }, (result) =>
                        formatDate(result.airportFlightMetrics.sunrise, {
                          time: true,
                          timeZone: result.airportFlightMetrics?.timeZoneName,
                        }),
                      )
                      .with({ airportFlightMetrics: { sunset: P.not(P.nullish) }, timeOfDayTimeToDisplay: "sunset" }, (result) =>
                        formatDate(result.airportFlightMetrics.sunset, { time: true, timeZone: result.airportFlightMetrics?.timeZoneName }),
                      )
                      .otherwise(() => (
                        <>Not available</>
                      ))}
                  </Text>
                </Flex>
                {/*
              <Flex align="center" fw={600} display="none">
                <span className="material-symbols-outlined">landscape</span>
                <Text span ml="xs" w={130} tt="lowercase">
                  {match(airport?.elevation)
                    .with(P.number, (num) => `${numberFormatter.format(num)}'`)
                    .otherwise(() => '')
                  }
                </Text>
              </Flex>
              */}
              </Stack>
            </Box>
          </Flex>
          <Flex justify="space-between" align="flex-start" px="xl" mt="xs" pb="sm" bg="white">
            <SafetyBriefModeToggle onSafetyBriefModeChange={onSafetyBriefModeChange} safetyBriefMode={safetyBriefMode} />
          </Flex>
          <Box bg="#F2F7FF" style={{ flexGrow: 1, paddingBottom: "75px" }}>
            {safetyBriefMode === "data" ? (
              <>
                <Box bg="white" pt="sm">
                  <Divider mx="xl" mb="lg" />
                  <SafetyWeatherDetails
                    risks={weatherRisks}
                    rawMode={rawMode}
                    metar={metar}
                    taf={taf}
                    loadingStatus="success"
                    weatherDetails={weatherDetails}
                    airport={airport?.icao_id ?? airport?.faa_airport_id ?? ""}
                    additionalWeatherDetails={additionalWeatherData}
                    boxProps={{
                      px: "xl",
                    }}
                  />
                  {notamRisks.length <= 0 ? <Divider mx="xl" mt="xl" mb="lg" /> : <Space h="xl" />}
                </Box>
                <RiskList
                  loading={false}
                  rawMode={rawMode}
                  notams={notams}
                  notamRisks={[]}
                  stageOfFlight={stageOfFlight}
                  airsigmetRisks={[]}
                  airSigmets={airsigmets}
                  pireps={pireps}
                  pirepsRisks={[]}
                  aerodromeRisks={[]}
                  airspace={[]}
                />
              </>
            ) : (
              <>
                <Space h="xl" />
                {assessmentThreats?.score != null && assessmentThreats.score > 0 && (
                  <Box px="lg" fw={700}>
                    Score ({assessmentThreats?.score})
                  </Box>
                )}
                <AssessmentThreatList assessmentThreats={assessmentThreats} />
              </>
            )}
          </Box>
        </Flex>
      </SafetyDataProvider>
    </ScrollArea>
  )
}
