import { Reservation, ReservationActions, ReservationStatus, ReservationType, ReservationTypes } from "@soar/shared/types"
import { getStatusForReservation } from "./status"

// Since these functions can be used on backend and frontend, it's up to the user to retrieve the latest data to ensure these are accurate
export function canSignOutReservation(reservation: Reservation): boolean {
  return reservationHasSignOutCapability(reservation) && getStatusForReservation(reservation) == null
}

export function canSignInReservation(reservation: Reservation): boolean {
  return reservationHasSignOutCapability(reservation) && getStatusForReservation(reservation) === ReservationStatus.enum.inProgress
}

const SIGN_OUT_TYPES: ReservationType[] = [ReservationTypes.enum.flight, ReservationTypes.enum.maintenance]
export function reservationHasSignOutCapability(reservation: Reservation) {
  const canSignOut = SIGN_OUT_TYPES.includes(reservation.type)
  return canSignOut
}

export function getActionsForReservation(reservation: Reservation): ReservationActions[] {
  const status = getStatusForReservation(reservation)
  const canSignOut = reservationHasSignOutCapability(reservation)

  if (status == null) {
    if (canSignOut) {
      return ReservationActions.options
    } else {
      return ReservationActions.options.filter((action) => action !== ReservationActions.enum.signOut)
    }
  } else if (status === ReservationStatus.enum.inProgress) {
    const inProgressActions: ReservationActions[] = [ReservationActions.enum.update, ReservationActions.enum.cancel]
    if (canSignOut) {
      return [...inProgressActions, ReservationActions.enum.signOut]
    } else {
      return inProgressActions
    }
  }
  return []
}
