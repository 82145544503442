import { Box, Flex, Skeleton, Text, Title } from "@mantine/core"
import { Risk } from "@soar/shared/types"
import { commonBodyProps } from "../safety/utils"
import { RiskBadge } from "./RiskBadge"

export function VisibilityDetails({
  visibility,
  loading,
  risk,
}: {
  visibility?: number | string
  loading: boolean
  risk?: Risk
}) {
  return (
    <Box>
      {risk != null ? (
        <RiskBadge severity={risk.severity} label={risk.label} size="md" showIcon={false} />
      ) : (
        <Title order={5} tt="uppercase">
          Visibility
        </Title>
      )}
      {loading ? (
        <Skeleton radius="xl" height={20} />
      ) : (
        <Flex gap="sm" align={{ md: "center", base: "flex-start" }} direction={{ base: "column", md: "row" }}>
          <Text {...commonBodyProps}>{visibility} sm</Text>
        </Flex>
      )}
    </Box>
  )
}
