// import { atomWithStorage, RESET } from 'jotai/utils'
import { Location } from "@soar/shared/types"
import { atom } from "jotai"
import { storeItems } from "./localStorage"

export const locationsForOrganizationAtom = atom<Location[] | null>(null)
export const selectedLocationAtom = atom<string | null>(null)
export const selectedLocationAtomWithStorage = atom(
  (get) => get(selectedLocationAtom),
  (_get, set, payload: string | null) => {
    set(selectedLocationAtom, payload)

    // store in local storage
    const storeValue = payload == null ? undefined : payload
    const stored = storeItems({ locationId: storeValue })
  },
)
export const selectedLocationsAtom = atom<string[] | null>(null)
export const selectedLocationsAtomWithStorage = atom(
  (get) => get(selectedLocationsAtom),
  (_get, set, payload: string[] | null) => {
    set(selectedLocationsAtom, payload)

    // store in local storage
    const storeValue = payload == null ? undefined : payload
    const stored = storeItems({ locationIds: storeValue })
  },
)
