import { SafetyReportFactorType, SafetyReportStatus } from "@soar/shared/types"

export type SafetyReportFactorConfigDetails = {
  label: string
}

export const SafetyReportFactorConfig: Record<SafetyReportFactorType, SafetyReportFactorConfigDetails> = {
  weather_condition: {
    label: "Weather condition",
  },
  pilot_error: {
    label: "Pilot error",
  },
  mechanical_failure: {
    label: "Mechanical failure",
  },
  atc_issue: {
    label: "ATC issue",
  },
  fuel_management: {
    label: "Fuel management",
  },
  runway_incursion: {
    label: "Runway incursion",
  },
  bird_strike: {
    label: "Bird strike",
  },
  procedure_deviation: {
    label: "Procedure deviation",
  },
  emergency_readiness: {
    label: "Emergency readiness",
  },
  crew_fatigue: {
    label: "Crew fatigue",
  },
  training_gap: {
    label: "Training gap",
  },
  communication_failure: {
    label: "Communication failure",
  },
  security_threat: {
    label: "Security threat",
  },
  cargo_handling: {
    label: "Cargo handling",
  },
  navigation_error: {
    label: "Navigation error",
  },
  ground_incident: {
    label: "Ground incident",
  },
  regulatory_compliance: {
    label: "Regulatory compliance",
  },
  passenger_safety: {
    label: "Passenger safety",
  },
  medical_emergency: {
    label: "Medical emergency",
  },
  maintenance_issue: {
    label: "Maintenance issue",
  },
}

export type SafetyReportStatusConfigDetails = {
  label: string
  colorType: "mantine" | "custom"
  color: string
}

export const SafetyReportStatusConfig: Record<SafetyReportStatus, SafetyReportStatusConfigDetails> = {
  created: {
    label: "Open",
    colorType: "mantine",
    color: "dark.8",
  },
  inProgress: {
    label: "In progress",
    colorType: "mantine",
    color: "yellow.8",
  },
  resolved: {
    label: "Resolved",
    colorType: "mantine",
    color: "brandBlue.8",
  },
}
