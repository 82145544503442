import { Badge, Box, Flex, List, Paper, Popover, Skeleton, Stack, Text, Title, TitleOrder } from "@mantine/core"
import { AirSigmet, AirSigmetWithIntersections, DataWithIntersections, EnhancedAirSigmet, Risk, StageOfFlight } from "@soar/shared/types"
import { ThreatColors, determineThreatCategory, formatDate } from "@soar/shared/utils"
import dayjs from "dayjs"
import timezonePlugin from "dayjs/plugin/timezone"
import utcPlugin from "dayjs/plugin/utc"
import { ReactNode } from "react"
import { ActivityWindowDisplay, TimeIntersectionDisplay } from "../safety"
import { useTimezone } from "../safety/timezoneContext"
dayjs.extend(utcPlugin)
dayjs.extend(timezonePlugin)

export function AirsigmetHeader({
  altLabel,
  icon,
  order = 4,
  risk,
}: { altLabel?: string; icon?: ReactNode; order?: TitleOrder; risk?: Risk }) {
  const threatCategory = determineThreatCategory(risk?.severity ?? 0)
  const threatColor = threatCategory != null ? ThreatColors[threatCategory] ?? "" : ""
  return (
    <Title order={order} tt="uppercase" mb="xs">
      <Flex align="center">
        <Box style={{ backgroundColor: threatColor, width: "26px", height: "26px", borderRadius: "100%" }} mr="5px">
          {icon}
        </Box>
        {risk?.label ?? altLabel ?? ""}
      </Flex>
    </Title>
  )
}

export function AirsigmetIntersectionTime({
  airsigmetWithIntersections,
}: { airsigmetWithIntersections: DataWithIntersections<AirSigmet> }) {
  const { departureTimeZone } = useTimezone()
  const intersectionTime =
    airsigmetWithIntersections.routeIntersection.intersectionTime != null
      ? dayjs(airsigmetWithIntersections.routeIntersection.intersectionTime)
      : undefined
  const activeDuringFlight = airsigmetWithIntersections.timeIntersection.withinExact
  const color = activeDuringFlight ? "brandBlue.7" : "gray.7"
  const activeText = activeDuringFlight ? "Active" : "Inactive"

  if (intersectionTime == null) {
    return (
      <Text c={color} fw={600}>
        {activeText} during flight
      </Text>
    )
  }

  return (
    <Text c={color} fw={600}>
      {activeText} @ pass time (
      <Text span tt="lowercase">
        {formatDate(intersectionTime, { time: true, timeZone: departureTimeZone })}
      </Text>
      )
    </Text>
  )
}

const numberFormatter = new Intl.NumberFormat()
type AltitudeDescriptor = number | string | null | undefined
function getAltitudeDescription(
  altitudeLow1: AltitudeDescriptor,
  altitudeHigh1: AltitudeDescriptor,
  altitudeLow2: AltitudeDescriptor,
  altitudeHigh2: AltitudeDescriptor,
) {
  let altitudeLow = altitudeLow1
  let altitudeHigh = altitudeHigh1
  const stringParts: string[] = []

  if (typeof altitudeLow1 === "string" || altitudeLow1 == null) {
    altitudeLow = altitudeLow2
  }
  if (typeof altitudeHigh1 === "string" || altitudeHigh1 == null) {
    altitudeHigh = altitudeHigh2
  }
  const hasValidLow = altitudeLow != null && typeof altitudeLow !== "string"
  const hasValidHigh = altitudeHigh != null && typeof altitudeHigh !== "string"
  if (hasValidLow || hasValidHigh) {
    stringParts.push("from")
    stringParts.push(hasValidLow ? numberFormatter.format(altitudeLow as number) : "surface")
    stringParts.push("to")
    stringParts.push(hasValidHigh ? numberFormatter.format(altitudeHigh as number) : "undetermined")

    return stringParts.join(" ")
  } else {
    return undefined
  }
}

function AirsigmetBody({
  airsigmet,
}: {
  airsigmet: AirSigmet
}) {
  const altitudeDescription = getAltitudeDescription(
    airsigmet.altitudeLow1,
    airsigmet.altitudeHi1,
    airsigmet.altitudeLow2,
    airsigmet.altitudeHi2,
  )
  if (airsigmet.airSigmetType === "AIRMET" && airsigmet.hazard === "MT_OBSC") {
    return <Text span>Mountains obscured.</Text>
  } else if (airsigmet.airSigmetType === "AIRMET" && airsigmet.hazard === "IFR") {
    return <Text span>IFR conditions.</Text>
  } else if (airsigmet.airSigmetType === "AIRMET" && airsigmet.hazard === "SFC_WND") {
    return <Text span>High surface winds.</Text>
  } else if (airsigmet.airSigmetType === "AIRMET" && airsigmet.hazard === "LLWS") {
    return <Text span>Low level wind shear.</Text>
  } else if (airsigmet.airSigmetType === "SIGMET") {
    const hasMovement = airsigmet.movementDir != null && airsigmet.movementSpd != null

    return (
      <Text span>
        <Text span>{airsigmet.hazard === "CONVECTIVE" ? "Convective activity" : airsigmet.hazard}</Text>
        {hasMovement && ` moving ${airsigmet.movementDir} @ ${airsigmet.movementSpd} kts`}
        {altitudeDescription != null && ` ${altitudeDescription}`}.
      </Text>
    )
  } else if (airsigmet.airSigmetType === "AIRMET" && (airsigmet.hazard === "TURB-LO" || airsigmet.hazard === "TURB-HI")) {
    return (
      <Text span>
        Turbulence
        {altitudeDescription != null && ` ${altitudeDescription}`}.
      </Text>
    )
  } else if (airsigmet.airSigmetType === "AIRMET" && airsigmet.hazard === "ICE") {
    return (
      <Text span>
        Icing
        {altitudeDescription != null && ` ${altitudeDescription}`}.
      </Text>
    )
  } else if (airsigmet.airSigmetType === "AIRMET" && airsigmet.hazard === "M_FZLVL") {
    return (
      <Text span>
        Multiple freezing levels
        {altitudeDescription != null && ` ${altitudeDescription}`}.
      </Text>
    )
  }

  return <></>
}

export function AirsigmetDisplay({
  airsigmet,
  rawMode = false,
  stageOfFlight,
  condensed = false,
}: { airsigmet: DataWithIntersections<AirSigmet>; rawMode?: boolean; stageOfFlight?: StageOfFlight; condensed?: boolean }) {
  const { departureTimeZone } = useTimezone()
  const coreAirSigmet = airsigmet.data
  return (
    <Popover width={400} disabled={coreAirSigmet.translation != null && coreAirSigmet.translation.length > 0}>
      <Popover.Target>
        <Box fz={{ base: "sm", md: "md" }}>
          <Text c="gray.7">
            {condensed && `${airsigmet.data.airSigmetType}: `}
            {rawMode ? coreAirSigmet.rawAirSigmet : <AirsigmetBody airsigmet={coreAirSigmet} />}
          </Text>

          {!condensed && (
            <Text mt="lg">
              <TimeIntersectionDisplay
                timeIntersection={airsigmet.timeIntersection}
                intersectionTime={airsigmet.routeIntersection.intersectionTime}
                stageOfFlight={stageOfFlight}
              />
              <Text span fw={600}>
                Active :{" "}
              </Text>
              <Text span>
                <ActivityWindowDisplay
                  from={new Date(coreAirSigmet.validTimeFrom * 1000)}
                  to={new Date(coreAirSigmet.validTimeTo * 1000)}
                  timeZone={departureTimeZone ?? ""}
                />
              </Text>
            </Text>
          )}
        </Box>
      </Popover.Target>
      <Popover.Dropdown>
        <Text>{coreAirSigmet.rawAirSigmet}</Text>
      </Popover.Dropdown>
    </Popover>
  )
}
/*
export function AirsigmetList({
  airsigmets,
  loading,
  rawMode = false,
  onMapColor,
}: {
  onMapColor: string
  rawMode?: boolean
  airsigmets?: EnhancedAirSigmet[]
  loading: boolean
}) {
  const hideAirsigmets = loading || airsigmets == null
  if (hideAirsigmets) {
    return (
      <Stack spacing="xs">
        <Skeleton radius="xl" height={10} />
        <Skeleton radius="xl" height={10} />
        <Skeleton radius="xl" height={10} />
        <Skeleton radius="xl" height={10} />
        <Skeleton radius="xl" height={10} />
        <Skeleton radius="xl" height={10} />
      </Stack>
    )
  }

  return (
    <Stack spacing={8} maw="100%" px="sm">
      {airsigmets.map((airsigmet, index) => (
        <AirsigmetDisplay key={airsigmet.airSigmetId} airsigmet={airsigmet} rawMode={rawMode} />
      ))}
    </Stack>
  )
}
*/
