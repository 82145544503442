import {
  CloudCoverageEnum,
  FlightLevelType,
  Pirep,
  PirepIcing,
  PirepIcingIntensity,
  PirepIcingIntensityOptionEnum,
  PirepIcingTypeOptionEnum,
  PirepTurbulence,
  PirepTurbulenceFrequencyOptionEnum,
  PirepTurbulenceIntensity,
  PirepTurbulenceIntensityOptionEnum,
  PirepTurbulenceTypeOptionEnum,
  RawPirep,
} from "@soar/shared/types"
import dayjs from "dayjs"
import UTC from "dayjs/plugin/utc"
dayjs.extend(UTC)

const flightLevelRegex = /(?:(?<mod>(?:ABV)|(?:BLO)) )?(?<flightLevel>\d+)/
export function convertFlightLevelToFeet(input: string | null) {
  if (input == null) {
    return null
  }

  const match = flightLevelRegex.exec(input)

  if (match == null) {
    return null
  }
  const matchGroups: Record<string, string> = match.groups ?? {}
  const flightLevelString = matchGroups["flightLevel"] ?? ""
  const flightLevel = Number.parseInt(flightLevelString)

  if (Number.isNaN(flightLevel)) {
    return null
  }
  return {
    flightLevel: flightLevel * 100,
    mod: (matchGroups["mod"] ?? undefined) as "ABV" | "BLO" | undefined,
  }
}
export function convertFeetToFlightLevel(flightLevel: number) {
  const nearestFlightLevel = Math.round(flightLevel / 100)
  return `${nearestFlightLevel}`.padStart(3, "0")
}
export function convertCloudCoverage(input: string | null) {
  const result = CloudCoverageEnum.safeParse(input)
  if (result.success) {
    return result.data
  } else {
    return null
  }
}

export function convertIcingIntensity(input: string | null): PirepIcingIntensity {
  if (input == null) {
    return null
  }
  const hasRange = input.indexOf("-") > 0
  if (!hasRange) {
    if (input === "NEG") {
      return "NEG"
    }
    const parseResult = PirepIcingIntensityOptionEnum.safeParse(input)
    if (parseResult.success) {
      return parseResult.data
    }
    return null
  } else {
    const splitInput = input.split("-")
    const firstInput = splitInput[0]
    const secondInput = splitInput[1]
    const firstParseResult = PirepIcingIntensityOptionEnum.safeParse(firstInput)
    const secondParseResult = PirepIcingIntensityOptionEnum.safeParse(secondInput)
    if (firstParseResult.success && secondParseResult.success) {
      return {
        from: firstParseResult.data,
        to: secondParseResult.data,
      }
    } else {
      return null
    }
  }
}
export function convertIcingType(input: string | null) {
  if (input == null) {
    return null
  }
  const parseResult = PirepIcingTypeOptionEnum.safeParse(input)
  if (parseResult.success) {
    return parseResult.data
  } else {
    return null
  }
}

export function convertRawIcing(base: string | null, top: string | null, intensity: string | null, type: string | null) {
  if (base == null && top == null && intensity == null && type == null) {
    return null
  }
  const baseFlightLevel = convertFlightLevelToFeet(base)
  const topFlightLevel = convertFlightLevelToFeet(top)
  const returnData = {
    base: baseFlightLevel?.flightLevel ?? null,
    baseMod: baseFlightLevel?.mod ?? null,
    top: topFlightLevel?.flightLevel ?? null,
    topMod: topFlightLevel?.mod ?? null,
    intensity: convertIcingIntensity(intensity),
    type: convertIcingType(type),
  } satisfies PirepIcing

  if (Object.values(returnData).every((val) => val == null)) {
    return null
  }
  return returnData
}

export function convertPirepTurbulenceIntensity(input: string | null) {
  if (input == null) {
    return null
  }

  if (input == null) {
    return null
  }
  const hasRange = input.indexOf("-") > 0
  if (!hasRange) {
    if (input === "NEG") {
      return "NEG"
    }
    const parseResult = PirepTurbulenceIntensityOptionEnum.safeParse(input)
    if (parseResult.success) {
      return parseResult.data
    }
    return null
  } else {
    const splitInput = input.split("-")
    const firstInput = splitInput[0]
    const secondInput = splitInput[1]
    const firstParseResult = PirepTurbulenceIntensityOptionEnum.safeParse(firstInput)
    const secondParseResult = PirepTurbulenceIntensityOptionEnum.safeParse(secondInput)
    if (firstParseResult.success && secondParseResult.success) {
      return {
        from: firstParseResult.data,
        to: secondParseResult.data,
      }
    } else {
      return null
    }
  }
}

export function convertPirepTurbulenceType(input: string | null) {
  if (input == null) {
    return null
  }
  const parseResult = PirepTurbulenceTypeOptionEnum.safeParse(input)
  if (parseResult.success) {
    return parseResult.data
  }
  return null
}

export function convertPirepTurbulenceFrequency(input: string | null) {
  if (input == null) {
    return null
  }
  const parseResult = PirepTurbulenceFrequencyOptionEnum.safeParse(input)
  if (parseResult.success) {
    return parseResult.data
  }
  return null
}

export function convertRawTurbulence(
  base: string | null,
  top: string | null,
  intensity: string | null,
  type: string | null,
  frequency: string | null,
) {
  if (base == null && top == null && intensity == null && type == null && frequency == null) {
    return null
  }
  const baseFlightLevel = convertFlightLevelToFeet(base)
  const topFlightLevel = convertFlightLevelToFeet(top)
  const returnData = {
    base: baseFlightLevel?.flightLevel ?? null,
    baseMod: baseFlightLevel?.mod ?? null,
    top: topFlightLevel?.flightLevel ?? null,
    topMod: topFlightLevel?.mod ?? null,
    intensity: convertPirepTurbulenceIntensity(intensity),
    type: convertPirepTurbulenceType(type),
    frequency: convertPirepTurbulenceFrequency(frequency),
  } satisfies PirepTurbulence

  if (Object.values(returnData).every((val) => val == null)) {
    return null
  }
  return returnData
}

export function calculateIsUrgent(input: "PIREP" | "AIREP" | "Urgent PIREP") {
  return input === "Urgent PIREP"
}
export function convertPirepType(input: "PIREP" | "AIREP" | "Urgent PIREP") {
  if (input === "AIREP") {
    return "AIREP"
  }
  return "PIREP"
}

export function mapRawPirepToPirep(rawPirep: RawPirep): Pirep {
  return {
    pirepId: rawPirep.pirepId,
    receiptTime: dayjs(rawPirep.receiptTime).utc(true).toDate(),
    obsTime: dayjs.unix(rawPirep.obsTime).toDate(),
    qcField: rawPirep.qcField,
    icaoId: rawPirep.icaoId,
    aircraftType: rawPirep.acType,
    lat: rawPirep.lat,
    lng: rawPirep.lon,
    fltLvl: convertFlightLevelToFeet(rawPirep.fltLvl)?.flightLevel ?? null,
    fltLvlType: convertFlightLevelType(rawPirep.fltLvlType),
    cloudBase1: rawPirep.cloudBas1,
    cloudTop1: rawPirep.cloudTop1,
    cloudCvg1: convertCloudCoverage(rawPirep.cloudCvg1),
    cloudBase2: rawPirep.cloudBas2,
    cloudTop2: rawPirep.cloudTop2,
    cloudCvg2: convertCloudCoverage(rawPirep.cloudCvg2),
    visib: rawPirep.visib,
    wxString: rawPirep.wxString,
    temp: rawPirep.temp,
    wdir: rawPirep.wdir,
    wspd: rawPirep.wspd,
    icing1: convertRawIcing(rawPirep.icgBas1, rawPirep.icgTop1, rawPirep.icgInt1, rawPirep.icgType1),
    icing2: convertRawIcing(rawPirep.icgBas2, rawPirep.icgTop2, rawPirep.icgInt2, rawPirep.icgType2),
    turbulence1: convertRawTurbulence(rawPirep.tbBas1, rawPirep.tbTop1, rawPirep.tbInt1, rawPirep.tbType1, rawPirep.tbFreq1),
    turbulence2: convertRawTurbulence(rawPirep.tbBas2, rawPirep.tbTop2, rawPirep.tbInt2, rawPirep.tbType2, rawPirep.tbFreq2),
    brkAction: rawPirep.brkAction,
    vertGust: rawPirep.vertGust,
    isUrgent: calculateIsUrgent(rawPirep.pirepType),
    pirepType: convertPirepType(rawPirep.pirepType),
    rawOb: rawPirep.rawOb,
  }
}
export function convertFlightLevelType(input: string): FlightLevelType {
  if (["OTHER", "DURD", "DURC", "GRND", "UNKN"].includes(input)) {
    return input as FlightLevelType
  }
  return "OTHER"
}

// biome-ignore lint/complexity/noBannedTypes: not really used
export function findPirepTypes(pireps: Object[]) {
  const fieldTypes = new Map<string, Map<string, number>>()
  for (const pirep of pireps) {
    const entries = Object.entries(pirep)
    for (const [key, value] of entries) {
      const valueType = typeof value
      let valueTypeLabel: string = valueType

      const storedValueTypes = fieldTypes.get(key) ?? new Map<string, number>()

      if (valueType === "undefined") {
        valueTypeLabel = "undefined"
      } else if (value == null) {
        valueTypeLabel = "null"
      } else if (valueType === "string") {
        if (value.length === 0) {
          valueTypeLabel = "empty-string"
        }
      }
      const existingCount = storedValueTypes.get(valueTypeLabel) ?? 0
      storedValueTypes.set(valueTypeLabel, existingCount + 1)

      fieldTypes.set(key, storedValueTypes)
    }
  }
  return fieldTypes
}

const noStoreKeysForValues = [
  "pirepId",
  "receiptTime",
  "obsTime",
  "acType",
  "lat",
  "lon",
  "cloudBas1",
  "cloudTop1",
  // "temp",
  // "wdir",
  // "wspd",
  "rawOb",
]

// biome-ignore lint/complexity/noBannedTypes: not really used
export function findPirepValues(pireps: Object[]) {
  const fieldTypes = new Map<string, Map<number | string | null | undefined, any>>()
  for (const pirep of pireps) {
    const entries = Object.entries(pirep)
    for (const [key, value] of entries) {
      if (noStoreKeysForValues.includes(key)) {
        continue
      }
      const storedValueTypes = fieldTypes.get(key) ?? new Map<number | string | null | undefined, number>()
      const existingCount = storedValueTypes.get(value) ?? 0
      storedValueTypes.set(value, existingCount + 1)

      fieldTypes.set(key, storedValueTypes)
    }
  }
  return fieldTypes
}

export function getTopPirepRiskFactor(intensity: PirepIcingIntensity | PirepTurbulenceIntensity | undefined | null) {
  if (intensity == null) {
    return undefined
  } else if (typeof intensity === "string") {
    return intensity
  } else {
    return intensity?.to
  }
}

export function getPirepTurbulenceType(pirepTurbulence: PirepTurbulence | undefined | null) {
  if (pirepTurbulence == null) {
    return undefined
  } else if (pirepTurbulence.type === "LLWS") {
    return "LLWS"
  } else {
    return "OTHER"
  }
}

type PirepRiskCategories = ReturnType<typeof getTopPirepRiskFactor> | "LLWS"
const riskLevels: Record<Exclude<PirepRiskCategories, undefined>, number> = {
  NEG: 0,
  TRC: 1,
  LGT: 2,
  MOD: 3,
  SEV: 4,
  HVY: 4,
  EXTM: 5,
  LLWS: 6,
}

export function determinePrimaryRiskFromPirep(pirep: Pirep) {
  const pirepRiskFactors: { type: "icing" | "turbulence"; factor: PirepRiskCategories }[] = [
    { type: "icing", factor: getTopPirepRiskFactor(pirep.icing1?.intensity) },
    { type: "icing", factor: getTopPirepRiskFactor(pirep.icing2?.intensity) },
    {
      type: "turbulence",
      factor: getPirepTurbulenceType(pirep.turbulence1) === "LLWS" ? "LLWS" : getTopPirepRiskFactor(pirep.turbulence1?.intensity),
    },
    {
      type: "turbulence",
      factor: getPirepTurbulenceType(pirep.turbulence2) === "LLWS" ? "LLWS" : getTopPirepRiskFactor(pirep.turbulence2?.intensity),
    },
  ]
  const sortedRiskFactors = [...pirepRiskFactors].sort((a, b) => {
    const riskLevelA = a.factor == null ? -1 : riskLevels[a.factor]
    const riskLevelB = b.factor == null ? -1 : riskLevels[b.factor]
    return riskLevelB - riskLevelA
  })
  const firstRiskFactor = sortedRiskFactors.at(0)
  if (firstRiskFactor == null || firstRiskFactor?.factor === "NEG") {
    return { type: "other", factor: undefined }
  } else {
    return firstRiskFactor
  }
}

export function translatePirep(observation: string, rawPirep?: Pirep): string | undefined {
  return ""
}
