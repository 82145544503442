import { Chip, Flex, Text } from "@mantine/core"
import { Location } from "@soar/shared/types"

export function LocationPicker({
  selectedLocationIds,
  locations,
  onChange = () => {},
}: {
  selectedLocationIds: string[]
  locations: Location[]
  onChange: (selectedLocationIds: string[]) => void
}) {
  return (
    <Flex align="center">
      <Text fw="bold" mr="sm">
        Locations:{" "}
      </Text>
      <Chip.Group multiple={true} value={selectedLocationIds} onChange={onChange}>
        {locations.map((location) => (
          <Chip value={location.id} key={location.id}>
            {location.name}
          </Chip>
        ))}
      </Chip.Group>
    </Flex>
  )
}
