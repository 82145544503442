import { z } from "zod"
import { SortEnumSchema } from "../utility"
import { ReservationStatus } from "./components"

export type PrismaDateFilter = {
  gt?: Date
  gte?: Date
  lt?: Date
  lte?: Date
}

export type ReservationStatusFilterParam = {
  in?: ReservationStatus[]
  notIn?: ReservationStatus[]
}

export type DateFilterParam = {
  from?: Date
  to?: Date
  exclusiveRange?: boolean
}

export type ReservationFilterParams = {
  date?: DateFilterParam
  status?: ReservationStatusFilterParam
}

export const ReservationSortParamsSchema = z.object({
  startTime: SortEnumSchema.optional(),
})

export type ReservationSortParams = z.infer<typeof ReservationSortParamsSchema>

export const OrgReservationReportFiltersSchema = z.object({
  date: z
    .object({
      from: z.coerce.date(),
      to: z.coerce.date().optional(),
    })
    .optional(),
})
export type OrgReservationReportFilters = z.infer<typeof OrgReservationReportFiltersSchema>
