import { z } from "zod"
import { UserSchema } from "../User"
import { idSchema } from "../utility"

export const ReservationTypes = z.enum(["flight", "ground", "maintenance", "unavailable"])
export type ReservationTypeEnum = z.infer<typeof ReservationTypes>

export const ReservationStatus = z.enum(["inProgress", "completed", "canceled"])
export type ReservationStatus = z.infer<typeof ReservationStatus>

export const reservationLegLengthErrorMessage = "Must be 4 characters long" as const

export const ReservationLegSchema = z.object({
  id: z.string(),
  from: z.string().nonempty(),
  to: z.string().nonempty(),
})
export type ReservationLeg = z.infer<typeof ReservationLegSchema>

export const ReservationUserSchema = z.object({
  userId: idSchema,
  isPic: z.boolean().nullish(),
  user: UserSchema.optional(),
})
export type ReservationUser = z.infer<typeof ReservationUserSchema>

export const ReservationActions = z.enum(["update", "signOut", "cancel"])
export type ReservationActions = z.infer<typeof ReservationActions>
