import { Text } from "@mantine/core"
import dayjs from "dayjs"
import timezonePlugin from "dayjs/plugin/timezone"
import utcPlugin from "dayjs/plugin/utc"
dayjs.extend(utcPlugin)
dayjs.extend(timezonePlugin)

const dateFormat = "MMM D, "
const yearFormat = "YYYY "
const hourFormat = "h:mm"

export function ActivityWindowDisplay({
  from,
  to,
  timeZone,
  continuous,
}: {
  from?: Date
  to?: Date | null
  timeZone: string
  continuous?: boolean
}) {
  if (continuous) {
    return <Text span>Continuous</Text>
  }
  const today = dayjs().tz(timeZone)
  const fromDay = dayjs(from).tz(timeZone)
  const toDay = dayjs(to).tz(timeZone)

  const fromDifferentYear = today.year() !== fromDay.year()
  const toDifferentYear = today.year() !== toDay.year()
  const fromToDifferentYear = fromDay.year() !== toDay.year()

  const fromMeridian = fromDay.hour() < 12 ? "AM" : "PM"
  const toMeridian = toDay.hour() < 12 ? "AM" : "PM"

  const differentMeridian = fromMeridian !== toMeridian
  const differentDay = fromDay.year() !== toDay.year() || fromDay.month() !== toDay.month() || fromDay.date() !== toDay.date()

  let fromFormat = `${dateFormat}${fromDifferentYear ? yearFormat : ""}${hourFormat}`
  if (differentMeridian || differentDay) {
    fromFormat = `${fromFormat}a`
  }

  let toFormat = `${hourFormat}a`
  if (differentDay) {
    toFormat = dateFormat + (toDifferentYear || fromToDifferentYear ? yearFormat : "") + toFormat
  }
  const timeZoneDisplay = (
    <Text tt="lowercase" span>
      {fromDay.format(" z")}
    </Text>
  )

  return (
    <Text span>
      {fromDay.format(fromFormat)}
      {to == null && timeZoneDisplay}
      {" - "}
      {to != null ? (
        <>
          {toDay.format(toFormat)}
          {timeZoneDisplay}
        </>
      ) : (
        "Indefinite"
      )}
    </Text>
  )
}
