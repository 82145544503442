import { TimeFormat } from "@soar/shared/types"
import { createContext, useContext } from "react"

function noop() {
  console.warn("noop called")
}

export interface PreferencesContextType {
  timeFormat: TimeFormat
}

export const PreferencesContext = createContext<PreferencesContextType>({
  timeFormat: "format_12h",
})

export const usePreferences = () => useContext(PreferencesContext)
