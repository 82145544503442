import { FlightLegDecision, FlightLegDecisionEnum, FlightLegStatus } from "@soar/shared/types"

export const FlightLegStatusConfig: Record<
  FlightLegStatus,
  {
    label: string
    color: string
    icon: string
    darkForeground: boolean
  }
> = {
  created: {
    label: "Created",
    color: "#495057",
    icon: "pending_actions",
    darkForeground: false,
  },
  on_hold: {
    label: "On hold",
    color: "#F95349",
    icon: "block",
    darkForeground: false,
  },
  approved: {
    label: "Released",
    color: "#2072fA",
    icon: "task_alt",
    darkForeground: false,
  },
  awaiting_approval: {
    label: "In review",
    color: "#F88113",
    icon: "policy",
    darkForeground: false,
  },
}

export const FlightLegDecisionConfig: Record<
  FlightLegDecision,
  {
    action: string
    cta: string
    status: string
  }
> = {
  [FlightLegDecisionEnum.enum.approve]: {
    action: "released",
    cta: "Release",
    status: "released",
  },
  [FlightLegDecisionEnum.enum.deny]: {
    action: "put on hold",
    cta: "Hold",
    status: "On hold",
  },
}
