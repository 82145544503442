import {
  Box,
  Button,
  Flex,
  Grid,
  Group,
  Popover,
  Radio,
  RadioStylesNames,
  SimpleGrid,
  Stack,
  Text,
  Title,
  createStyles,
  useMantineTheme,
} from "@mantine/core"
import { IconClock } from "@tabler/icons-react"
import dayjs from "dayjs"

const useStyles = createStyles((theme) => ({
  radio: {
    display: "none",
  },
  radioBody: {
    cursor: "pointer",
    ["&:hover"]: {
      backgroundColor: theme.colors.blue[2],
    },
    ["&[data-checked=true]"]: {
      backgroundColor: theme.colors.blue[7],
    },
  },
  inner: {
    display: "none",
  },
  label: {
    padding: "3px 5px",
    cursor: "pointer",
  },
  labelWrapper: {
    textAlign: "center",
    width: "100%",
    ["[data-checked=true] &"]: {
      color: theme.white,
    },
  },
  hidden: {
    visibility: "hidden",
  },
}))

export function TimePicker({
  value = new Date(),
  onChange = () => {},
}: {
  value?: Date
  onChange?: (date: Date) => void
}) {
  const theme = useMantineTheme()
  const { classes } = useStyles()
  const dayValue = dayjs(value)
  const formattedTime = dayValue.format("hh:mm A")
  const radioClassNames: Partial<Record<RadioStylesNames, string>> = {
    radio: classes.radio,
    root: classes.radioBody,
    labelWrapper: classes.labelWrapper,
    label: classes.label,
    inner: classes.inner,
  }

  const onHourChange = (selectedHour: string) => {
    let hour = parseInt(selectedHour, 10)
    if (dayValue.hour() >= 12) {
      // PM is selected
      if (hour !== 12) {
        hour = hour + 12
      }
    }
    onChange(dayValue.set("hour", hour).toDate())
  }

  const onMinuteChange = (selectedMinute: string) => {
    onChange(dayValue.set("minute", parseInt(selectedMinute, 10)).toDate())
  }

  const onMeridianChange = (selectedMeridian: "PM" | "AM") => {
    const currentHour = dayValue.hour()
    const currentHourIsPM = currentHour >= 12
    if (currentHourIsPM && selectedMeridian === "AM") {
      onChange(dayValue.set("hour", currentHour - 12).toDate())
    } else if (!currentHourIsPM && selectedMeridian === "PM") {
      onChange(dayValue.set("hour", currentHour + 12).toDate())
    }
  }

  return (
    <Box>
      <Popover position="bottom" width={300} shadow="xs" withArrow>
        <Popover.Target>
          <Button variant="default" fw={400} px="xs">
            <IconClock stroke={2} size={22} color={theme.colors.gray[5]} />
            <Text ml="sm">{formattedTime}</Text>
          </Button>
        </Popover.Target>
        <Popover.Dropdown p={0}>
          <Grid m={0}>
            <Grid.Col span={5} bg="gray.0">
              <Title order={5}>Hour</Title>
              <Radio.Group value={`${dayValue.hour() % 12}`} onChange={onHourChange} p={0}>
                <Stack justify="stretch" w="100%" spacing={3}>
                  <Radio value="0" label="12" classNames={radioClassNames} />
                  <Radio value="1" label="1" classNames={radioClassNames} />
                  <Radio value="2" label="2" classNames={radioClassNames} />
                  <Radio value="3" label="3" classNames={radioClassNames} />
                  <Radio value="4" label="4" classNames={radioClassNames} />
                  <Radio value="5" label="5" classNames={radioClassNames} />
                  <Radio value="6" label="6" classNames={radioClassNames} />
                  <Radio value="7" label="7" classNames={radioClassNames} />
                  <Radio value="8" label="8" classNames={radioClassNames} />
                  <Radio value="9" label="9" classNames={radioClassNames} />
                  <Radio value="10" label="10" classNames={radioClassNames} />
                  <Radio value="11" label="11" classNames={radioClassNames} />
                </Stack>
              </Radio.Group>
            </Grid.Col>
            <Grid.Col span={4}>
              <Title order={5}>Minute</Title>
              <Radio.Group value={`${dayValue.minute()}`} onChange={onMinuteChange}>
                <Stack justify="stretch" spacing={3} w="100%">
                  <Radio value="0" label="00" classNames={radioClassNames} />
                  <Radio value="15" label="15" classNames={radioClassNames} />
                  <Radio value="30" label="30" classNames={radioClassNames} />
                  <Radio value="45" label="45" classNames={radioClassNames} />
                </Stack>
              </Radio.Group>
            </Grid.Col>
            <Grid.Col span={3} bg="gray.0">
              <Title order={5} className={classes.hidden}>
                AM/PM
              </Title>
              <Radio.Group value={dayValue.hour() >= 12 ? "PM" : "AM"} onChange={onMeridianChange}>
                <Stack justify="stretch" spacing={3} w="100%">
                  <Radio value="AM" label="AM" classNames={radioClassNames} />
                  <Radio value="PM" label="PM" classNames={radioClassNames} />
                </Stack>
              </Radio.Group>
            </Grid.Col>
          </Grid>
        </Popover.Dropdown>
      </Popover>
    </Box>
  )
}
