import { Button, Center, Container, Title } from "@mantine/core"

interface IUnauthenticatedProps {
  onLoginClick?: () => void
}

const noop = () => {} // eslint-disable-line

export function Unauthenticated({ onLoginClick = noop }: IUnauthenticatedProps) {
  return (
    <Container size={420} my={150}>
      <Title
        align="center"
        sx={(theme) => ({
          fontFamily: `Greycliff CF, ${theme.fontFamily}`,
          fontWeight: 900,
        })}
      >
        Welcome to Soar!
      </Title>
      <Center mt={80}>
        <Button size="xl" variant="gradient" gradient={{ from: "blue", to: "cyan" }} mt="xl" onClick={onLoginClick}>
          Login to get started!
        </Button>
      </Center>
    </Container>
  )
}
