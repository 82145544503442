import type { GeoJSON } from "geojson"
import { z } from "zod"

export const NewNotamStatusEnum = z.enum(["ACTIVE", "EXPIRED", "CANCELLED", "REPLACED"])
export type NewNotamStatus = z.infer<typeof NewNotamStatusEnum>

export const NewNotamClassificationEnum = z.enum(["DOM", "LMIL", "MIL", "FDC", "INTL"])
export type NewNotamClassification = z.infer<typeof NewNotamClassificationEnum>

export const NewNotamSchema = z.object({
  id: z.number(),
  text: z.string(),
  effectiveStart: z.coerce.date(),
  effectiveEnd: z.coerce.date().nullish(),
  translation: z.string().nullish(),
  status: NewNotamStatusEnum,
  classification: NewNotamClassificationEnum,
  keyword: z.string().nullish(),
  locationDesignator: z.string().optional(),
  accountability: z.string().optional(),
  geojson: z.custom<GeoJSON>().optional(),
})
export type NewNotam = z.infer<typeof NewNotamSchema>
