import { Flex, Select, Text } from "@mantine/core"
import { Location } from "@soar/shared/types"
import { IconBuilding } from "@tabler/icons-react"

export function SingleLocationPicker({
  selectedLocationId,
  locations,
  onChange = () => {},
  label,
}: {
  label?: string
  selectedLocationId?: string | null
  locations: Location[]
  onChange: (selectedLocationId: string) => void
}) {
  const locationsOptions = locations.map((l) => {
    const label = l.airportCode == null ? l.name : `${l.airportCode} - ${l.name}`

    return { value: l.id, label }
  })
  const hasLocations = locations.length > 0
  const placeholder = hasLocations ? "" : "No locations found"

  return (
    <Flex align="center">
      {label && (
        <Text fw="bold" mr="sm">
          Locations:
        </Text>
      )}
      <Select
        icon={<IconBuilding />}
        value={selectedLocationId ?? null}
        data={locationsOptions}
        onChange={onChange}
        placeholder={placeholder}
        disabled={!hasLocations}
      />
    </Flex>
  )
}
