import { Reservation } from "@soar/shared/types"
import dayjs from "dayjs"

export function getSortedReservationEvents(reservation: Reservation) {
  return (reservation.events ?? []).sort((a, b) => dayjs(b.createdAt).diff(a.createdAt, "milliseconds"))
}

export function sortReservationsByStartTime(reservations: Reservation[]) {
  return reservations.sort((a, b) => dayjs(a.startTime).diff(b.startTime, "milliseconds"))
}
