import { Airport, AirportResult } from "@soar/shared/types"

export function convertAirportToAirportResult(airport: Airport) {
  return {
    id: airport.id,
    name: airport.name,
    faa_airport_id: airport.faaAirportId,
    icao_id: airport.icaoId,
    city: airport.city,
    state_code: airport.stateCode,
    country_code: airport.countryCode,
    county: airport.county,
    elevation: airport.elevation,
    longitude: airport.longitude,
    latitude: airport.latitude,
  } satisfies AirportResult
}
export function convertAirportResultToAirport(airport: AirportResult) {
  return {
    id: airport.id,
    name: airport.name,
    faaAirportId: airport.faa_airport_id,
    icaoId: airport.icao_id,
    city: airport.city,
    stateCode: airport.state_code,
    countryCode: airport.country_code,
    county: airport.county,
    elevation: airport.elevation,
    longitude: airport.longitude,
    latitude: airport.latitude,
  } satisfies Airport
}
