import maplibregl from "maplibre-gl"
import { Ref, forwardRef, useEffect, useImperativeHandle, useRef } from "react"

type MapComponentProps = {
  width?: string
  height?: string
  center?: [number, number]
  zoom?: number
  onLoad?: (map: maplibregl.Map) => void
}
export type MapRefPayload = {
  map?: maplibregl.Map
}

export const MapComponent = forwardRef(
  (
    { width = "100%", height = "95vh", onLoad = () => {}, center = [-103.771556, 44.967243], zoom = 4 }: MapComponentProps,
    ref: Ref<MapRefPayload>,
  ) => {
    const mapRef = useRef<maplibregl.Map>()

    useImperativeHandle(
      ref,
      () => ({
        map: mapRef.current,
      }),
      [mapRef.current],
    )

    useEffect(() => {
      const map = new maplibregl.Map({
        container: "map",
        // style: "https://api.maptiler.com/maps/dataviz-dark/style.json?key=NFgBZ2AifTVODne2dypf", // stylesheet location
        style: "https://api.maptiler.com/maps/outdoor-v2/style.json?key=NFgBZ2AifTVODne2dypf", // stylesheet location
        center: center, // starting position [lng, lat]
        zoom: zoom, // starting zoom
      })
      mapRef.current = map

      map.on("load", () => {
        const currentMap = mapRef.current!
        onLoad(map)
        // currentMap.addControl(new maplibregl.NavigationControl(), "top-right")
        /*
      //@ts-ignore
      addNotamData(zhudata)

      //@ts-ignore
      addNotamData(data)
      */

        /*
      const marker = new maplibregl.Marker({color: "#FF0000"})
      .setLngLat([-122.03, 38.65])
      .addTo(currentMap);
      */
      })
    }, [])

    return (
      <div
        id="map"
        style={{
          width,
          height,
        }}
      />
    )
  },
)

export default MapComponent
