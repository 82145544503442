import { ActionIcon, Flex, Text, TextInput } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { ReservationLeg } from "@soar/shared/types"
import { IconMinus, IconPlaneArrival, IconPlaneDeparture, IconPlus } from "@tabler/icons-react"
import { ChangeEvent } from "react"
import { ulid } from "ulid"

export function generateEmptyFlightRoute(start = ""): ReservationLeg {
  const route = { id: ulid(), to: "", from: start }
  return route
}

interface ILegs {
  legs: ReservationLeg[]
}

function uppercaseValueFactory(onChange: (value: string) => void) {
  return (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target?.value?.toUpperCase() ?? "")
  }
}

export function FlightLegsInput<T extends ILegs>({
  form,
}: {
  form: UseFormReturnType<T>
}) {
  return (
    <>
      {form.values.legs?.map((flightRoute, index) => {
        const isFirstItem = index === 0
        const lastLeg = form.values.legs.length === 0 ? undefined : form.values.legs.at(-1)
        const action = isFirstItem
          ? () => form.insertListItem("legs", generateEmptyFlightRoute(lastLeg?.to))
          : () => form.removeListItem("legs", index)
        const fromInputProps = form.getInputProps(`legs.${index}.from`)
        const toInputProps = form.getInputProps(`legs.${index}.to`)
        return (
          <Flex justify="space-between" align="center" w="100%" key={flightRoute.id} mb="xs">
            <Flex align="center">
              <IconPlaneDeparture />
              <TextInput
                w="70px"
                ml="xs"
                placeholder="KXXX"
                {...fromInputProps}
                onChange={uppercaseValueFactory(fromInputProps.onChange)}
                error={fromInputProps.error != null}
              />
              <Text mx="md"> to </Text>
              <IconPlaneArrival />
              <TextInput
                w="70px"
                ml="xs"
                placeholder="KXXX"
                {...toInputProps}
                onChange={uppercaseValueFactory(toInputProps.onChange)}
                error={toInputProps.error != null}
              />
            </Flex>
            <ActionIcon color="primary" variant="outline" size="xs" onClick={action}>
              {isFirstItem ? <IconPlus /> : <IconMinus />}
            </ActionIcon>
          </Flex>
        )
      })}
    </>
  )
}
