import {
  Aircraft,
  AirportFlightMetrics,
  AirportResult,
  ApiFlightMetrics,
  FlightMetrics,
  MetarResponse,
  TafForecastResponse,
} from "@soar/shared/types"
import { default as turfDistance } from "@turf/distance"

import dayjs, { Dayjs } from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)
dayjs.extend(timezone)

export const SAFETY_BRIEFING_COLORS = {
  notam: {
    base: "#5f3dc4",
    border: "#271852",
  },
  sigmet: {
    base: "#8f1a0d",
    border: "#590b03",
  },
  airmet: {
    base: "#1971c2",
    border: "#1864ab",
  },
}

function getCeiling(weather: MetarResponse | TafForecastResponse) {
  if (weather.vertVis != null) {
    return Number(weather.vertVis)
  }

  const sortedClouds = weather.clouds.sort((cloudA, cloudB) => {
    return (cloudA.base ?? 0) - (cloudB.base ?? 0)
  })

  if (sortedClouds.length === 0) {
    return null
  }

  if (sortedClouds[0].cover === "CLR") {
    return null
  }

  const ceiling = sortedClouds.find((cloudGroup) => cloudGroup.cover === "BKN" || cloudGroup.cover === "OVC")

  if (ceiling == null) {
    return null
  }

  return ceiling.base
}

function parseVisibility(weather: MetarResponse | TafForecastResponse) {
  const rawVisibility = weather.visib
  if (typeof rawVisibility === "string") {
    return parseInt(rawVisibility.replace(/[+-]/g, ""))
  }
  return rawVisibility
}

export function getFlightRules(weather: MetarResponse | TafForecastResponse) {
  const ceiling = getCeiling(weather)
  const visibility = parseVisibility(weather)

  if (visibility == null) {
    return null
  }

  const hasCeiling = ceiling != null

  if ((hasCeiling && ceiling <= 1000) || visibility <= 3) {
    return "IFR"
  } else if ((hasCeiling && ceiling > 1000 && ceiling <= 3000) || visibility <= 5) {
    return "MVFR"
  } else {
    return "VFR"
  }
}

export function computeFlightMetrics({
  destination,
  departure,
  aircraft,
  date,
  departureTimeZone,
}: {
  destination?: AirportResult
  departure?: AirportResult
  aircraft?: Aircraft
  date?: Date
  departureTimeZone?: string
}) {
  if (destination == null || departure == null || date == null || departureTimeZone == null) {
    return
  }

  const departureDate = dayjs(date).tz(departureTimeZone, true)

  const distanceNm =
    turfDistance([destination.longitude, destination.latitude], [departure.longitude, departure.latitude], { units: "miles" }) * 0.868976
  const ete = distanceNm / (aircraft?.airspeed ?? 110)
  const eta = departureDate.add(ete, "hour")
  const totalFuelBurn = (aircraft?.fuelBurn ?? 9) * ete

  return {
    departureDate,
    distanceNm,
    ete,
    eta,
    totalFuelBurn,
  }
}

export function convertFlightMetrics(input: ApiFlightMetrics): FlightMetrics {
  const departure = {
    ...input.departure,
    sunrise: dayjs(input.departure.sunrise).tz(input.departure.timeZoneName),
    sunset: dayjs(input.departure.sunrise).tz(input.departure.timeZoneName),
  }

  const destination = {
    ...input.destination,
    sunrise: dayjs(input.destination.sunrise).tz(input.destination.timeZoneName),
    sunset: dayjs(input.destination.sunrise).tz(input.destination.timeZoneName),
  }

  return {
    ...input,
    departure,
    destination,
    departureDate: dayjs(input.departureDate).tz(input.departure.timeZoneName),
    eta: dayjs(input.eta).tz(input.destination.timeZoneName),
  }
}

export function calculateTimeOfDayTimeToDisplay(date: Date | undefined, airportFlightMetrics: AirportFlightMetrics | undefined) {
  return calculateTimeOfDayTimeToDisplayCore(date, airportFlightMetrics?.sunrise, airportFlightMetrics?.sunset)
}

export function calculateTimeOfDayTimeToDisplayCore(
  date: Date | undefined,
  sunrise: Date | Dayjs | undefined,
  sunset: Date | Dayjs | undefined,
) {
  if (date == null || sunrise == null || sunset == null) {
    return null
  }
  const day = dayjs(date)
  const diffSunrise = Math.abs(day.diff(sunrise, "second"))
  const diffSunset = Math.abs(day.diff(sunset, "second"))
  if (diffSunrise < diffSunset) {
    return "sunrise"
  } else {
    return "sunset"
  }
}
