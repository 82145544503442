import { Reservation, Timeslot } from "@soar/shared/types"
import dayjs from "dayjs"
import isSameOrAfter from "dayjs/plugin/isSameOrAfter"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import { filterReservationsByTimespan } from "./filters"
import { sortReservationsByStartTime } from "./sorts"
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

/**
 * Gets open timespans for a list of reservations between a timespan
 */
export function getOpenTimeslots(reservations: Reservation[], timespanStart: Date, timespanEnd: Date) {
  const availableTimeslots: Timeslot[] = []

  if (reservations.length === 0) {
    return [{ start: timespanStart, end: timespanEnd }]
  }
  const reservationsInTimespan = filterReservationsByTimespan(reservations, timespanStart, timespanEnd)
  const sortedReservations = sortReservationsByStartTime(reservationsInTimespan)

  // temporary open timeslot start
  let openTimeslotStart: Date | undefined = timespanStart
  // console.log("sorted reservations: ", sortedReservations)

  for (const reservation of sortedReservations) {
    if (dayjs(openTimeslotStart).isSameOrAfter(timespanEnd)) {
      // this timeslot is at or after the timespan so it is invalid
      openTimeslotStart = undefined
      break
    } else if (dayjs(reservation.startTime).isSameOrAfter(timespanEnd)) {
      // this reservation is at or after the timespan so it is invalid
      break
    } else if (dayjs(reservation.startTime).isSameOrBefore(openTimeslotStart)) {
      // this reservation is before or the same time as the open time slot start
      // set the timeslot start to the end of the reservation
      openTimeslotStart = reservation.endTime
    } else {
      // this timeslot is valid

      availableTimeslots.push({
        start: openTimeslotStart,
        end: reservation.startTime,
      })

      openTimeslotStart = reservation.endTime
    }
  }

  // last reservation was in the middle of the day
  if (openTimeslotStart != null && openTimeslotStart.getTime() !== timespanEnd.getTime()) {
    availableTimeslots.push({
      start: openTimeslotStart,
      end: timespanEnd,
    })
  }

  return availableTimeslots
}
