import { Flex, Popover, Text, UnstyledButton, useMantineTheme } from "@mantine/core"
import { ISchedule, IScheduleItem } from "../../schedule.interface"

import { useDisclosure } from "@mantine/hooks"
import { usePreferences } from "@soar/frontend/contexts"
import { Aircraft, RegisteredUser, Reservation } from "@soar/shared/types"
import { formatDate } from "@soar/shared/utils"
import { ReservationCard, getReservationTypeColors } from "../../../reservations"

export function DayReservationItem({
  reservation,
}: {
  reservation?: Reservation
}) {
  const { timeFormat } = usePreferences()
  if (reservation == null) {
    return <></>
  }
  return (
    <>
      <Flex direction="column" justify="center">
        <Text fw={600} fz="xs" lh={1.3}>
          {formatDate(reservation.startTime, { time: true, timeFormatOverride: timeFormat })} -{" "}
          {formatDate(reservation.endTime, { time: true, timeFormatOverride: timeFormat })}
        </Text>
        <Text fz="xs" lh={1.3} tt="capitalize">
          {reservation.type}
        </Text>
      </Flex>
    </>
  )
}

export function renderDayItem({
  item,
  schedule,
  onSignOutClick = () => {},
  onUpdateClick = () => {},
  onCancelClick = () => {},
}: {
  schedule: ISchedule<Aircraft | RegisteredUser, Reservation>
  item: IScheduleItem<Reservation>
  onSignOutClick?: (reservation: Reservation) => void
  onUpdateClick?: (reservation: Reservation) => void
  onCancelClick?: (reservation: Reservation) => void
}) {
  const reservation = item.source
  const reservationExists = reservation != null
  const theme = useMantineTheme()
  const [popoverOpened, popoverHandlers] = useDisclosure(false)
  return (
    <Popover
      transitionProps={{ transition: "pop" }}
      position="top-start"
      shadow="xl"
      styles={{ dropdown: { padding: 0 } }}
      withinPortal
      opened={popoverOpened}
      onClose={popoverHandlers.close}
    >
      <Popover.Target>
        <UnstyledButton
          style={{
            width: "100%",
            height: "70%",
            backgroundColor: reservationExists ? getReservationTypeColors(theme, reservation.type).primary : theme.colors.grape[5],
            color: "white",
            borderRadius: "20px",
            padding: "0 14px",
          }}
          onClick={popoverHandlers.open}
        >
          <Flex style={{ width: "100%" }} align="center" justify="space-between">
            <DayReservationItem reservation={reservation} />
          </Flex>
        </UnstyledButton>
      </Popover.Target>
      <Popover.Dropdown>
        <ReservationCard
          reservation={reservation}
          source={schedule.source}
          onSignOutClick={onSignOutClick}
          onUpdateClick={onUpdateClick}
          onCancelClick={onCancelClick}
          closeParent={popoverHandlers.close}
        />
      </Popover.Dropdown>
    </Popover>
  )
}
